import React, { useState, useEffect } from "react";
import classes from "../DataPoints/admindatapoints.module.css";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../Components/LoadingSpinner";
import { useParams } from "react-router-dom";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
const FeaturedEmployerDetail = () => {
  const [isLoading, setLoading] = useState(false);
  const [admindata, setAdmindata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const currentURL = window.location.href.split("/").pop();
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    AdmindataApi();
  }, [currentPage]);

  const { id } = useParams();
  console.log("companyid", id);
  const AdmindataApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CompanyId: currentURL,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getJobThroughId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAdmindata(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Featured Employers by Id",
    previousPageName: "Featured Company",
    previousPage: "/BiDashboard/admindatapoints",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Comapany Id : {currentURL}</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={admindata}
            filename={"featuredemploayerby_id.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={admindata}
            fileName="featuredemploayerby_id"
          />
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {admindata.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {admindata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>Id</th>
                  <th className={classes.table_heading}>Position Title</th>

                  <th className={classes.table_heading}>PageView</th>
                  <th className={classes.table_heading}>Entrance</th>
                  <th className={classes.table_heading}>Redirected From</th>
                  <th className={classes.table_heading}>View</th>
                </tr>
                <tbody>
                  {admindata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.ids}</td>
                        <td className={classes.table_td}>
                          {item.positionTitle}
                        </td>

                        <td className={classes.table_td}>{item.pageview}</td>
                        <td className={classes.table_td}>{item.entrence}</td>
                        <td className={classes.table_td}>
                          {item.redirectedFrom}
                        </td>

                        <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/FeaturedJobdetail/" + item.KeyIndex
                            }
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturedEmployerDetail;
