import React from "react";
import classes from "./Modal.module.css";

const WriteForUsModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }
  console.log(props.data);
  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        <div className={classes.eventdetail_container}>
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <div className={classes.images_div}>
                <div className={classes.table_th} style={{ fontWeight: "700" }}>
                  Profile Image:
                </div>
                <img
                  src={props.data.UploadProfilePicture}
                  alt=""
                  className={classes.courseDisplayCard}
                  style={{ width: "200px", height: "200px" }}
                ></img>
              </div>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                    <tr>
                      <th className={classes.table_th}>Full Name:</th>
                      <td className={classes.table_td}>
                        {props.data.FullName}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Email:</th>
                      <td className={classes.table_td}>
                        {props.data.EmailID}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Mobile:</th>
                      <td className={classes.table_td}>{props.data.Mobile} </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>
                        Title of your Article:
                      </th>
                      <td className={classes.table_td}>{props.data.Title}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Reference Webiste:</th>
                      <td className={classes.table_td}>
                        {props.data.ReferenceWebsite}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Industry:</th>
                      <td className={classes.table_td}>
                        {props.data.Industry}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Topic:</th>
                      <td className={classes.table_td}>{props.data.Topic}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>
                        Reference Image Credits:
                      </th>
                      <td className={classes.table_td}>
                        {props.data.ReferenceImageCredits}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Reference Source:</th>
                      <td className={classes.table_td}>
                        {props.data.Referencesource}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course Created At:</th>
                      <td className={classes.table_td}>
                        {props.data.createdAt}
                        {/* {props.data.createdAt.split("T") ? props.data.createdAt.split("T")[0] : props.data.createdAt} */}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course UpdatedAt :</th>
                      <td className={classes.table_td}>
                        {props.data.updatedAt}
                        {/* {props.data.updateAt.split("T") ? props.data.updateAt.split("T")[0] : props.data.updateAt} */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className={classes.detail_bottom}>
              <h4>Article Text:</h4>
              <p className={classes.description}>
                <div dangerouslySetInnerHTML={{ __html: props.data.Article }} />
              </p>
            </div>

            <div
              className={classes.table_th}
              style={{ fontWeight: "700", marginTop: "10px", marginBottom: "10px" }}
            >
              Relevant Creative for the Article :
            </div>
            <div className={classes.multipleImage}>
              {props.data.Upload.split(",").length > 0 && (
                <>
                  {props.data.Upload.split(",").map((item, index) => (
                    <img
                      src={item}
                      alt=""
                      key={index}
                      className={classes.courseLogo}
                      style={{ width: "100px", height: "100px" }}
                    ></img>
                  ))}
                </>
              )}
            </div>
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WriteForUsModal;
