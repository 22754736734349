import React, { useEffect } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import classes from './Dashboard.module.css';

const DashNav = () => {

    const access = sessionStorage.getItem("level")
    const { pathname } = useLocation();
    const history = useHistory()
    useEffect(() => {
        if (pathname === "/printAd") {
            history.push("/printAd/fetchAds")
        }
        else if (pathname === "/NonAscentJobs") {
            history.push("/NonAscentJobs/adsList")
        }
        else if (pathname === "/postAJob") {
            history.push("/postAJob/adsList")
        }
    }, [pathname])
    return (
        <div className={classes.headerJobs}>
            {access === "ADSDB" ?
                <div className={classes.navbar}>
                    {/* <NavLink to="/BiDashboard/Dashboard" activeClassName={classes.activeNav}>Inbox</NavLink>
                    <NavLink to="/BiDashboard/DashJobList" activeClassName={classes.activeNav}>Fetch Ads </NavLink>
                    <NavLink to="/BiDashboard/AdsQc" activeClassName={classes.activeNav}>Ads Entry</NavLink>
                    <NavLink to="/BiDashboard/RecruiterDetails"> Recruiter Details</NavLink>
                    <NavLink to="/BiDashboard/Reports" activeClassName={classes.activeNav}>Reports</NavLink>
                    <NavLink to="/BiDashboard/CreateCompany" activeClassName={classes.activeNav}>Create Company</NavLink>
                    <NavLink to="/BiDashboard/NewJobAds" activeClassName={classes.activeNav}>Non Ascent Jobs</NavLink> */}
                    <NavLink to="/printAd" activeClassName={classes.activeNav}>Print Ad</NavLink>
                    <NavLink to="/NonAscentJobs" activeClassName={classes.activeNav}>Non ascent jobs</NavLink>
                    <NavLink to="/postAJob" activeClassName={classes.activeNav}>Post a job ads</NavLink>
                </div> :
                <div className={classes.navbarSub}>
                    {/* <NavLink to="/BiDashboard/Dashboard" activeClassName={classes.activeNav}>Inbox</NavLink>
                    <NavLink to="/BiDashboard/DashJobList" activeClassName={classes.activeNav}>Fetch Ads </NavLink>
                    <NavLink to="/BiDashboard/AdsQc" activeClassName={classes.activeNav}>Ads Entry</NavLink> */}
                    <NavLink to="/printAd" activeClassName={classes.activeNav}>Print Ad</NavLink>


                </div>

            }
        </div>
    )
}

export default DashNav
