import React from 'react';
import classes from './DashboardHeader.module.css';
import { FaArrowDown } from 'react-icons/fa';

const DashboardHeader = (props) => {

    return (
        <div className={classes.dashboard_users}>
            <button onClick={props.onClick} className={classes.users_title}><h3>{props.heading}</h3></button>
            <button onClick={props.onClick}>  <FaArrowDown /></button>
        </div>
    )
}

export default DashboardHeader
