import React, { useState, useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import classes from "../Webstory/webstrorydetail.module.css";

import { Link, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import Modal from "../Modal";
import { useHistory } from "react-router-dom";
import EventInputs from "../Events/EventInputs";
const Webstorydetail = () => {
  const [loading, setLoading] = useState(false);
  const [webstorylist, setWebstorylist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [live, setIsLive] = useState("");
  const [errors, setErrors] = useState(false);
  const [check, setCheck] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  let { id } = useParams();
  useEffect(() => {
    WebstoryDetailApi();
  }, [currentPage]);

  const WebstoryDetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WebStoriesSummaryID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/WebStoriesDetailsAdminAPI",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setWebstorylist(result.WebStoriesDetails);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const deleteApi = (WebStoriesDetailID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WebStoriesDetailID: WebStoriesDetailID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/WebStoriesDetailsDelete",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          WebstoryDetailApi();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const Live = (WebStoriesDetailID, isLive) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WebStoriesDetailID: WebStoriesDetailID,
      IsLive: isLive == "1" ? "0" : "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/WebstoriesDetailLive",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          WebstoryDetailApi();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const pageInfo = {
    currentPageName: "Webstory Detail Page",
    previousPageName: "WebStory List",
    previousPage: "/BiDashboard/webstorylist",
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/WeCareList")}
        />
      )}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={webstorylist ? webstorylist : ""}
              filename={"/AddWebstorySummary.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload
              csvData={webstorylist}
              fileName="/AddWebstorySummary"
            />
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{webstorylist?.length < 20 ? <></> : currentPage}</span>
                {webstorylist?.length < 20 ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            <div className={classes.container}>
              {webstorylist?.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>

                    <th className={classes.table_heading}>Detail Images </th>
                    <th className={classes.table_heading}>Title </th>
                    <th className={classes.table_heading}>Description </th>

                    <th className={classes.table_heading}>Heading </th>
                    <th className={classes.table_heading}>Url </th>
                    <th className={classes.table_heading}>Created At</th>
                    <th className={classes.table_heading}>Updated At</th>
                    <th className={classes.table_heading}>Status</th>

                    <th className={classes.table_heading}>Update</th>

                    <th className={classes.table_heading}>Delete</th>
                  </tr>

                  <tbody>
                    {webstorylist?.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.ID}</td>
                          <td className={classes.table_td}>
                            <img
                              src={item.DImage}
                              alt="icon"
                              className={classes.IconImage}
                            />
                          </td>

                          <td className={classes.table_td}>
                            {item.TitleType === "" ? "N/A" : item.TitleType}
                          </td>
                          <td className={classes.table_td}>
                            {item.DescriptionType === ""
                              ? "N/A"
                              : item.DescriptionType}
                          </td>
                          <td className={classes.table_td}>
                            {item.headtext === null ? "N/A" : item.headtext}
                          </td>
                          <td className={classes.table_td}>
                            <a href={item.url} target="_blank" rel="noreferrer">
                              {item.url === null ? "N/A" : "RedirectionUrl"}
                            </a>
                          </td>

                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>{item.UpdatedAt}</td>
                          <td className={classes.table_td}>
                            <EventInputs
                              input={"CheckBox"}
                              disabled={item.IsApproved == 1 ? true : false}
                              onChange={() =>
                                Live(item.WebStoriesDetailID, item.IsLive)
                              }
                              label={item.IsLive == 1 ? "Live" : "Not Live"}
                              value={item.IsLive == 1 ? true : false}
                            />
                          </td>
                          <td className={classes.table_td}>
                            <Link
                              to={
                                "/BiDashboard/updatewebstorydetail/" +
                                item.WebStoriesDetailID
                              }
                              className={classes.Edit_link_two}
                            >
                              Edit
                            </Link>
                          </td>

                          <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => deleteApi(item.WebStoriesDetailID)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Webstorydetail;
