import React from "react";
import classes from "./Modal.module.css";
import { FaCopy } from "react-icons/fa";

const FormViewModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('URL copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        />
        <h2 style={{ textAlign: "center" }}>
          Form has been submitted successfully
        </h2> */}
        <div className={classes.eventdetail_container}>
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <div className={classes.images_div}>
                <div className={classes.table_th} style={{fontWeight: '700'}}>student image</div>
                <img
                  src={props.data.ImageURL}
                  alt=""
                  className={classes.courseDisplayCard}
                  style={{width: '200px', height: '200px'}}
                ></img>

                <div className={classes.table_th} style={{fontWeight: '700', marginTop: '10px'}}>Video Resume</div>
                <video
  src={props.data.VideoResumeURL}
  className={classes.SupportingCollaterals}
  style={{ width: '200px', height: '150px' }}
  controls
>
  Your browser does not support the video tag.
</video>



       


           

              </div>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                    <tr>
                      <th className={classes.table_th}>Institution Name</th>
                      <td className={classes.table_td}>{props.data.Institutionname}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Career Goals</th>
                      <td className={classes.table_td}>{props.data.CareerGoals} </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Skills</th>
                      <td className={classes.table_td}>
                        {props.data.Skills}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Certifications</th>
                      <td className={classes.table_td}>{props.data.Certifications}</td>
                    </tr>
                    {/* <tr>
                      <th className={classes.table_th}>Course Source :</th>
                      <td className={classes.table_td}>{props.data.course_source}</td>
                    </tr> */}
                    <tr>
                      <th className={classes.table_th}>Language</th>
                      <td className={classes.table_td}>{props.data.Language}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Salary Expectations</th>
                      <td className={classes.table_td}>
                        {props.data.SalaryExpectations}
                      </td>
                    </tr>
                     <tr>
                      <th className={classes.table_th}>Hobbies</th>
                      <td className={classes.table_td}>
                        {props.data.Hobbies}
                      </td>
                    </tr> 
                     <tr>
                      <th className={classes.table_th}>ResumeURL</th>
                      <td className={classes.table_td}>
                      <FaCopy
        onClick={() => copyToClipboard(props.data.ResumeURL)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }} 
      />
    
    </td>
                    </tr>
                     <tr>
                      <th className={classes.table_th}>CoverLetterURL</th>
                      <td className={classes.table_td}>
                      <FaCopy
        onClick={() => copyToClipboard(props.data.CoverLetterURL)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }} 
      />
                      </td>
                    </tr> 
                     <tr>
                      <th className={classes.table_th}>Linkedin URL</th>
                      <td className={classes.table_td}>
                      <FaCopy
        onClick={() => copyToClipboard(props.data.LinkedinURL)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }} 
      />
                      </td>
                    </tr> 
                    {/* <tr>
                      <th className={classes.table_th}>Course Group :</th>
                      <td className={classes.table_td}>{props.data.course_group}</td>
                    </tr> */}

                  
                    
                  
                  </tbody>
                </table>
              </div>
            </div>

           
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormViewModal;
