import React, { useState, useEffect } from "react";

import classes from "./HrProfessionalDetails.module.css";
import { Link, useParams } from "react-router-dom";
import BackButton from "../BackButton";


const HrProfessionalDetails = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    viewHr();
  }, []);
  console.log(data);
  const viewHr = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/v1/api/apiTimes/GetHRprofessionalById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        console.log("data:" + result.data.profilePicture);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className={classes.eventdetail_container}>
      {/* {data.map((item) => ( */}
      <div className={classes.eventdetail_main}>
        <div className={classes.event_detail}>
          <img
            src={data.profilePicture}
            alt=""
            className={classes.event_image}
          ></img>
          <div className={classes.event_text}>
            <table>
              <tbody>
                <tr>
                  <th className={classes.table_th}>Full name :-</th>
                  <td className={classes.table_td}>{data.fullName}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Designation :</th>
                  <td className={classes.table_td}>{data.designation} </td>
                </tr>

                <tr>
                  <th className={classes.table_th}>Current Company :</th>
                  <td className={classes.table_td}>{data.currentCompany}</td>
                </tr>

                <tr>
                  <th className={classes.table_th}>Location :</th>
                  <td className={classes.table_td}>{data.location}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Position :</th>
                  <td className={classes.table_td}>{data.position}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Category :</th>
                  <td className={classes.table_td}>{data.category}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Total Experience :</th>
                  <td className={classes.table_td}>{data.totalExperience}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Created Date :</th>
                  <td className={classes.table_td}>{data.createdDate}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Updated Date :</th>
                  <td className={classes.table_td}>{data.updatedDate}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Start Date :</th>
                  <td className={classes.table_td}>{data.startdate}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>End Date :</th>
                  <td className={classes.table_td}>{data.enddate}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={classes.detail_bottom}>
          <h4>Short Description:</h4>
          <p className={classes.description}>{data.shortDescription}</p>
          <h4>Area Of Expertise:</h4>
          <p className={classes.description}>
            <div dangerouslySetInnerHTML={{ __html: data.areaOfExpertise }} />
          </p>
          <h4>Work History:</h4>
          <p className={classes.description}>
            <div dangerouslySetInnerHTML={{ __html: data.workHistory }} />
          </p>
          <h4>Awards Achievements:</h4>
          <p className={classes.description}>
            <div
              dangerouslySetInnerHTML={{ __html: data.awardsAchievements }}
            />
          </p>
          <h4>Years of Org:</h4>
          <p className={classes.description}>{data.yrsCurrentOrg}</p>
          <h4>Website:</h4>
          <p className={classes.description}>{data.website}</p>
          <h4>Linkdin:</h4>
          <p className={classes.description}>{data.connectToLinkdin}</p>
          <h4>Twitter:</h4>
          <p className={classes.description}>{data.connectToTwitter}</p>
          <h4>Featured Media:</h4>
          <p className={classes.description}>{data.featuredMedia}</p>
        </div>
        <Link to="/BiDashboard/hrprofessionnal" className={classes.BackButton}>
          <BackButton />
        </Link>
      </div>
      {/* ))
            } */}
    </div>
  );
};

export default HrProfessionalDetails;
