import React, { useState, useEffect } from "react";
import classes from "./CreateCompany.module.css";
import AdsEntry from "./AdsEntry";
import DashboardButton from "./DashboardButton";
import AdminLoader from "./AdminLoader";
import { useHistory } from 'react-router-dom';



const CreateCompany = () => {

  let history = useHistory()

  const [company, setCompany] = useState([]);
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [compDesc, setCompDesc] = useState();
  const [address, setAddress] = useState();
  const [location, setLocation] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [selectedLocation, setSelectedLocation] = useState()
  const [website, setWebsite] = useState();
  const [existingCompId, setExistingCompId] = useState();
  const [industryData, setIndustryData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState([]);
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companySuggestionShow, setCompanySuggestionShow] = useState();
  const [isFoundCompany, setIsFoundCompany] = useState(false);
  const [isGovernment, setIsGovernment] = useState(false);
  const [isdefaultIndustry, setisDefaultIndustry] = useState(false)
  const [existingLoc, setExistingLoc] = useState()
  const [governmentComp, setGovernmentComp] = useState(false)


  useEffect(() => {
    selectionDetailsApi();
    details()

  }, []);






  // updatecompanyapi
  const UpdateApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: existingCompId,
      UpdatedBy: "",
      Name: companyNameInput,
      Email: email,
      CompanyDescription: compDesc,
      Phone: phone,
      Website: website,
      Address: address,
      Industry: selectedIndustry,
      Location: selectedLocation,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/v1/api/apiTimes/UpdateCompany",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        console.log(result);
        if (result.status === "SUCCESS" && result.status_code === 200) {
          alert("Company Updated Successfully");
          // setCompanyNameInput("")
          // setCompDesc("");
          // setAddress("");
          // setWebsite("");
          // setPhone("");
          // setEmail("");
          // setIsFoundCompany(false);
          // setGovernmentComp('0')
          history.push("/BiDashboard/Dashboard")
        } else {
          alert("Please fill the required fields");
        }

      })
      .finally(() => setLoading(false))
      .catch((error) => console.log("error", error));
  };

  // createCompanyApi
  const createCompanyApi = () => {

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Name: companyNameInput,
      CompanyDescription: compDesc,
      Industry: selectedIndustry,
      Location: selectedLocation,
      Website: website,
      email: email,
      Phone: phone,
      Address: address,
      GovernmentCompany: isGovernment.toString(),
      Fax: "",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/v1/api/apiTimes/CreateCompany",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        console.log(result);
        if (result.status === "SUCCESS" && result.status_code === 200) {
          alert("Company Created");
          history.push("/BiDashboard/Dashboard")
          setIsFoundCompany(false);
          setIsGovernment(false)
        } else {
          alert("Please enter the required field details");
        }

      })
      .finally(() => setLoading(false))
      .catch((error) => console.log("error", error));
  };


  // selectiondetailsapi

  const selectionDetailsApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api.onecorp.co.in/adminapi/selection-details",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCompany(result.companydata);
        setIndustryData(result.industrydata);
        setLocation(result.locationdata)
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (companyName !== "") {
      let val = false;
      setCompanySuggestions(
        company.filter((val) => {
          if (val.compName === companyName) {
            val = true;
          }
          return val.compName
            ? val.compName.toLowerCase().startsWith(companyName.toLowerCase())
            : null;
        })
      );
      if (val) {
        setCompanySuggestionShow(false);
      }
      setCompanySuggestionShow(true);
    } else {
      setCompanySuggestions([]);
      setCompanySuggestionShow(false);
    }
  }, [companyName]);


  // companydetailsapi

  const details = (companyName, companyId) => {
    setCompanyNameInput(companyName);
    setCompanyName(companyName);
    setCompanySuggestionShow(false);
    setExistingCompId(companyId);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CompanyId: companyId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/v1/api/apiTimes/GetCompanyDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCompDesc(result.data.companyDescription);
        setAddress(result.data.address);
        setWebsite(result.data.website);
        setPhone(result.data.phone);
        setEmail(result.data.email);
        setisDefaultIndustry(result.data.Industry)
        setExistingLoc(result.data.city)
        setGovernmentComp(result.data.governmentJob)
      })
      .catch((error) => console.log("error", error));
  };

  // function for blank input data on Click at CANCEL button
  const onCancelHandler = () => {
    setCompanyNameInput("");
    setCompDesc("");
    setAddress("");
    setWebsite("");
    setPhone("");
    setEmail("");
    setCompanyName("")
    setIsFoundCompany(false);
    setGovernmentComp('0')
  };

  const Route = () => {
    history.push("/BiDashboard/Dashboard")
  }

  // phone validation
  const NumericOnly = (e) => {
    const reg = /^[0-9\b]+$/;
    let preval = e.target.value;
    if (e.target.value === "" || reg.test(e.target.value)) {
      return true;
    } else {
      e.target.value = preval.substring(0, preval.length - 1);
    }
  };



  return loading ? (

    <div className={classes.loadingLayout}>
      <AdminLoader />
    </div>
  ) : (

    <div className={classes.createCompany}>
      <div className={classes.leftSide}>
        <div className={classes.searchWrapper}>
          <div className={classes.companySearch}>
            <label htmlFor="companySearch">Company Name : </label>
            <input
              type="text"
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />
            {/* <button>Search</button> */}
          </div>
        </div>
        <div className={classes.searchResultBox}>
          <div className={classes.searchResultHeader}>
            <h3>Company Search Result </h3>
          </div>
          <div className={classes.searchItems}>
            <div className={classes.suggestion}>
              {companySuggestionShow ? (
                <div>
                  {companySuggestions.map((val) => {
                    return (
                      <p
                        key={val.id}
                        onClick={() => {
                          details(val.compName, val.id);
                          setIsFoundCompany(true);
                        }}
                        className={classes.companyList}
                      >
                        {val.compName}
                      </p>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.rightSide}>
        {isFoundCompany ? <h3>Edit Company</h3> : <h3>Create Company</h3>}
        <div className={classes.companyForm}>
          <AdsEntry
            input={"SingleLineInput"}
            label={"CompanyName"}
            onChange={(e) => setCompanyNameInput(e.target.value)}
            value={companyNameInput}
          />
          {/* <AdsEntry input={"SingleLineInput"} label={"Company Description"} /> */}
          <AdsEntry
            input={"smalldropdown"}
            label={"Industry"}
            onChange={(e) => setSelectedIndustry(e.target.value)}
            displayData={industryData}
            defaultIndustry={isdefaultIndustry}
          />
          <AdsEntry
            input={"smalldropdown"}
            label={"Location"}
            onChange={(e) => setSelectedLocation(e.target.value)}
            displayData={location}
            defaultLocation={existingLoc}
          />
          <AdsEntry
            input={"MultiLineInput"}
            label={"Company Description"}
            onChange={(e) => setCompDesc(e.target.value)}
            value={compDesc}
          />
          <AdsEntry
            input={"MultiLineInput"}
            label={"Address"}
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
          <AdsEntry
            input={"SingleLineInput"}
            label={"Phone"}
            onChange={(e) => {
              NumericOnly(e);
              setPhone(e.target.value)
            }}
            value={phone}
          />
          <AdsEntry
            input={"email"}
            label={"Email"}
            onChange={(e) => {
              setEmail(e.target.value);

            }}
            value={email}
          />

          {/* {!isValid && <p className={classes.errorMessage}>invalid email</p>} */}

          <AdsEntry
            input={"SingleLineInput"}
            label={"Website"}
            onChange={(e) => setWebsite(e.target.value)}
            value={website}
          />

          <AdsEntry
            input={"checkBox"}
            label={"Government Organisation : "}
            onChange={(e) =>
              setGovernmentComp((pre) => {
                return pre === "1" ? "0" : "1";
              })
            }
            checked={governmentComp}
          />


        </div>
        <div className={classes.buttonWrapper}>
          {isFoundCompany ? (
            <div className={classes.updateButton}>
              <DashboardButton
                buttontext={"Update"}
                onClick={() => UpdateApi()}
              />
            </div>
          ) : (
            <div className={classes.saveButton}>
              <DashboardButton
                buttontext={"Save"}
                onClick={() => createCompanyApi()}
              />
            </div>
          )}

          <div className={classes.cancelButton}>
            <DashboardButton
              buttontext={"Cancel"}
              onClick={() => onCancelHandler()}
            />
          </div>
          <div>
            <DashboardButton
              buttontext={"Back"}
              onClick={() => Route()}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default CreateCompany;
