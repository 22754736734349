import React, { useState, useEffect } from "react";
import classes from "./Adminadsentry.module.css";
import { AiOutlineClose } from "react-icons/ai";
import AdsEntry from "../Component/AdsEntry";
import Heading from "../Component/Heading";
import DashboardButton from "../Component/DashboardButton";
import AdminLoader from "../Component/AdminLoader";
import { states } from '../Component/States'
import { curr } from '../Component/Currency'
import { useParams, useHistory } from "react-router-dom";



const AdminAdsEntry = (props) => {


  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false)
  const [jobDesc, setJobDesc] = useState()
  const [posTitle, setPosTitle] = useState()
  const [jobAppDetails, setJobAppDetails] = useState("")
  const [url, setUrl] = useState("")
  const [jobFunction, setJobFunction] = useState([])
  const [selectedJobFunction, setSelectedJobFunction] = useState([])
  const [location, setLocation] = useState();
  const [levelName, setLevelName] = useState([])
  const [selectedLevelName, setSelectedLevelName] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState([]);
  const [minCtc, setMinCtc] = useState()
  const [maxCtc, setMaxCtc] = useState()
  const [isViewNextPositionClicked, setIsViewNextPositionClicked] = useState(false);
  const [uploadImage, setUploadImage] = useState()
  const [isPublished, setIsPublished] = useState(false)
  const [employmentType, setEmploymentType] = useState()
  const [postalCode, setPostalCode] = useState()
  const [street, setStreet] = useState()
  const [currency, setCurrency] = useState()
  const [region, setRegion] = useState()
  const [imageUrl, setImageUrl] = useState("")
  const [startDate, setStartDate] = useState()
  const [time, setTime] = useState()
  const [compImage, setCompImage] = useState()





  const { companyId } = useParams()


  const employment = [
    {
      type: "Full-Time"
    },
    {
      type: "Part-Time"
    },
    {
      type: "Contract"
    },
    {
      type: "Temporary"
    },
    {
      type: "Seasonsl"
    },
    {
      type: "Internship"
    }
  ]



  let history = useHistory();

  const Back = () => {
    history.push("/BiDashboard/Dashboard")
  }

  useEffect(() => {
    selectionDetailsApi()
    getCompanyDetail()
  }, []);


  const uploadApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");


    var formdata = new FormData();
    formdata.append("Name", "12334");
    formdata.append("Image", uploadImage);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/v1/api/apiTimes/OnlyFileMedia", requestOptions)
      .then(response => response.json())
      .then(result => {
        setImageUrl(global.apiLink + "" + result.data.path + "/" + result.data.filename)
      })
      .catch(error => console.log('error', error));
  }




  const findLocationIdHandler = (locationName) => {
    location.find((val) => {
      if (locationName === val.locName) {
        setSelectedLocationId((preLocationId) => {
          return [...preLocationId, val.id];
        });
      }

    });
  };

  //add function for list of locations

  const addLocationHandler = (selectedLocation) => {
    setSelectedLocationName((preLocation) => {
      return [...preLocation, selectedLocation];
    });
  };

  //delete function for delete location box

  const deleteLocationName = (id) => {
    setSelectedLocationName((preLocation) => {
      return preLocation.filter((item, index) => {
        return index !== id;
      });
    });
  };
  const deleteLocationId = (id) => {
    setSelectedLocationId((preLocationId) => {
      return preLocationId.filter((item, index) => {
        return index !== id;
      });
    });
  };

  console.log(isPublished);

  //SAVE POSTING API

  const savePosting = () => {
    const locations = selectedLocationId.toString()
    const images = imageUrl.toString()

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({

      "applicationDetails": (jobAppDetails),
      "positionTitle": (posTitle),
      "jobRefCode": "12",
      "level": (selectedLevelName),
      "jobDescription": (jobDesc),
      "imageUrl": (compImage),
      "email": (email),
      "externalApplyUrl": (url),
      "location": (locations.toString()),
      "jobCategory": (selectedJobFunction),
      "minCtc": (minCtc),
      "maxCtc": (maxCtc),
      "isWalkin": "0",
      "isPublished": "0",
      "AddressRegion": (region),
      "Currency": (currency),
      "EmploymentType": (employmentType),
      "StreetAddress": (street),
      "PostalCode": (postalCode)
    });
    console.log(raw);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/job-posting-detail", requestOptions)
      .then(response => response.json())
      .then((result) => {
        console.log(result);

        if (result.status === "SUCCESS" && result.status_code === 200) {
          alert("Ad Created")
          setPosTitle('')
          setEmail('')
          setMinCtc('')
          setMaxCtc('')
          setJobDesc('')
          setUrl('')
          setJobAppDetails('')
          setSelectedLocationName([])
          setStreet("")
          setPostalCode("")

        } else {
          alert("Please fill the required fields")
        }
      })
      .catch(error => console.log('error', error))
  }
  console.log(isPublished);

  const selectionDetailsApi = (data) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/selection-details", requestOptions)
      .then(response => response.json())
      .then(result => {
        setLevelName(result.leveldata)
        setJobFunction(result.jobFunctiondata)
        setLocation(result.locationdata);
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(false));
  }


  const getCompanyDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "CompanyId": companyId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(global.apiLink + "/v1/api/apiTimes/GetCompanyDetail", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setCompImage("https://timesascent.com/" + result.data.imagePath)
      })
      .catch(error => console.log('error', error));
  }


  return loading ? (
    <div className={classes.loadingLayout}>
      <AdminLoader />
    </div>
  ) : (
    <div className={classes.jobAds}>
      <div className={classes.jobAdsImageField}>

        <div className={classes.jobAdsImage}>
          <img
            src={compImage}
            alt=""
          />
        </div>
      </div>


      <div className={classes.JobAdsEntry}>
        <Heading headingtype={"BgHeading"} headingtext={"NON-ASCENT JOBS"} />
        <div className={classes.adEntry}>
          {!isViewNextPositionClicked && <AdsEntry input={"MultiLineInput"} label={"Job Application Details"} onChange={(e) => setJobAppDetails(e.target.value)} value={jobAppDetails} />}

          <AdsEntry input={"SingleLineInput"} label={"Position Title"} onChange={(e) => setPosTitle(e.target.value)} value={posTitle} text={"*"} />
          <AdsEntry input={'smalldropdown'} label={"Job Function"} displayData={jobFunction} onChange={(e) => setSelectedJobFunction(e.target.value)} text={"*"} />
          <AdsEntry input={'smalldropdown'} label={"Level"} displayData={levelName} onChange={(e) => setSelectedLevelName(e.target.value)} text={"*"} />
          <AdsEntry input={"MultiLineInput"} label={"Job Description"} value={jobDesc} onChange={(e) => setJobDesc(e.target.value)} text={"*"} />
          <AdsEntry input={"SingleLineInput"} label={"Min CTC"} value={minCtc} onChange={(e) => setMinCtc(e.target.value)} text={"*"} />
          <AdsEntry input={"SingleLineInput"} label={"Max CTC"} value={maxCtc} onChange={(e) => setMaxCtc(e.target.value)} text={"*"} />
          <AdsEntry input={'email'} label={"Email"} onChange={(e) => { setEmail(e.target.value) }} value={email} />
          <AdsEntry input={"SingleLineInput"} label={"Postal Code"} onChange={(e) => setPostalCode(e.target.value)} value={postalCode} />
          <AdsEntry input={'SingleLineInput'} label={"External Apply URL"} value={url} onChange={(e) => setUrl(e.target.value)} />
          <p className={classes.text}>(Please enter external URL from print ads if email is not present)</p>
          <AdsEntry input={"largedropdown"} label={"Employment Type"} onChange={(e) => setEmploymentType(e.target.value)} displayData={employment} text={"*"} />
          <AdsEntry input={"largedropdown"} label={"Currency"} onChange={(e) => setCurrency(e.target.value)} displayData={curr} defaultCurrency={curr} text={"*"} />
          <AdsEntry input={"SingleLineInput"} label={"Street Address"} onChange={(e) => setStreet(e.target.value)} value={street} />

          {/* mapping of selected location part  */}

          <div className={classes.locationBoxWrapper}>
            {selectedLocationName.map((currentLocationValue, index) => {
              return (
                <div key={index} id={index} className={classes.locationBox}>
                  {currentLocationValue}
                  <AiOutlineClose
                    onClick={() => {
                      deleteLocationName(index);
                      deleteLocationId(index);
                    }}
                    className={classes.closeIcon}
                  />
                </div>
              );
            })}
          </div>
          <AdsEntry
            input={"largedropdown"}
            label={"Locations"}
            // selected = {"disabled"}
            displayData={location}
            onChange={(e) => { addLocationHandler(e.target.value); findLocationIdHandler(e.target.value) }}
            text={"*"}
          />
          <AdsEntry input={"largedropdown"} label={"Region"} onChange={(e) => setRegion(e.target.value)} displayData={states} text={"*"} />
          <div className={classes.publishDiv}>
            <label htmlFor="checkbox" className={classes.label}>Publish</label>
            <input type="checkbox" name="publish" onChange={() => setIsPublished(!isPublished === true ? 1 : 0)} value={isPublished} />
            {/* <AdsEntry  input={"checkBox"} label = {"Publish"} onChange = {(e) => setIsPublished((pre) => { return pre === "1" ? "0" : "1";})} value = {isPublished}/> */}
          </div>
          <div className={classes.walkInDetails}>
            <Heading headingtype={"SimpleHeading"} headingtext={"Walk in Details"} />
            <AdsEntry input={"DateInput"} label={"Start Date"} onChange={(e) => setStartDate(e.target.value)} value={startDate} />
            <AdsEntry input={"DateInput"} label={"End Date"} />
            <AdsEntry input={"Time"} label={"Time"} onChange={(e) => setTime(e.target.value)} value={time} />
            <AdsEntry input={"MultiLineInput"} label={"Address"} />
          </div>
          <div className={classes.walkInInterviewButtons}>
            <DashboardButton buttontext={"Save"} onClick={() => { savePosting(); uploadApi(); }} />
            <DashboardButton buttontext={"Back"} onClick={() => Back()} className={classes.backButton} />

          </div>
        </div>
      </div>

    </div>
  )
}

export default AdminAdsEntry;






