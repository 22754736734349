import React, { useState, useEffect } from "react";
import classes from "./FeaturedCompanyList.module.css";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";

const FeaturedCompanyList = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [islogopossi, setIslogpossi] = useState("ASC");
  const [isname, setIsname] = useState("ASC");
  const [islogoid, setIslogoid] = useState("ASC");
  const [filteredData, setFilteredData] = useState([]);
  const [unlivecount, setunlivecount] = useState([]);
  const [livedata, setlivedata] = useState([]);

  useEffect(() => {
    FeaturedComapnyList();
  }, [currentPage]);

  const history = useHistory();
  const AddEventsHandler = () => {
    history.push("/BiDashboard/featuredcompanies");
  };

  const FeaturedComapnyList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      start_date: startDate,
      end_date: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/GetFeaturedCompany",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(
          result.featuredCompany.sort((a, b) => {
            if (parseInt(a.logoPosition) > parseInt(b.logoPosition)) return 1;
            if (parseInt(a.logoPosition) < parseInt(b.logoPosition)) return -1;
            return 0;
          })
        );
        setFilteredData(result.featuredCompany);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  // console.log(data);
  const filterdata = (active) => {
    if (active === "1") {
      const filtereddata = data.filter((item) => {
        if (item.active === "1") {
          return item;
        }
      });
      setFilteredData(filtereddata);
    }
  };

  const Unlivedata = (active) => {
    if (active === "0") {
      const filtereddata = data.filter((item) => {
        if (item.active === "0") {
          return item;
        }
      });
      setFilteredData(filtereddata);
    }
  };

  const sortinglogoid = (col) => {
    if (islogoid === "ASC") {
      const sortedlogoid = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedlogoid);
      setIslogoid("DSC");
    }
    if (islogoid === "DSC") {
      const sortedlogoid = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedlogoid);
      setIslogoid("ASC");
    }
  };
  const sortingName = (col) => {
    if (isname === "ASC") {
      const sortname = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortname);
      setIsname("DSC");
    }
    if (isname === "DSC") {
      const sortname = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortname);
      setIsname("ASC");
    }
  };
  const sortingposi = (col) => {
    if (islogopossi === "ASC") {
      const sortposi = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortposi);
      setIslogpossi("DSC");
    }
    if (islogopossi === "DSC") {
      const sortposi = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortposi);
      setIslogpossi("ASC");
    }
  };
  const sortingupdate = (col) => {
    if (islogopossi === "ASC") {
      const sortedupdate = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedupdate);
      setIslogpossi("DSC");
    }
    if (islogopossi === "DSC") {
      const sortedupdate = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedupdate);
      setIslogpossi("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.table_container}>
        <div className={classes.container}>
          {props.accessLevel === "TIMES" ? (
            ""
          ) : (
            <div className={classes.flex_btn}>
              <div>
                <button
                  onClick={() => {
                    filterdata("1");
                  }}
                >
                  Live
                </button>

                <button
                  onClick={() => {
                    Unlivedata("0");
                  }}
                >
                  Unlive
                </button>

                <button
                  onClick={() => {
                    FeaturedComapnyList();
                  }}
                >
                  All
                </button>
              </div>
              <div className={classes.button_wrapper}>
                <button
                  onClick={AddEventsHandler}
                  className={classes.Add_event_button}
                >
                  Add Featured Company
                </button>
              </div>
            </div>
          )}

          <h3 className={classes.events_title}>Featured Comapny List</h3>

          <div className={classes.DateFilter_container}>
            <div className={classes.button_container}>
              <CSVLink
                data={data}
                filename={"Featured_comapny_table.csv"}
                target="_blank"
              >
                <button className={classes.button_articleList}>CSV</button>
              </CSVLink>
              <ExcelDownload csvData={data} fileName="Featured_comapny_table" />
            </div>
            <div className={classes.dateDiv}>
              <div>
                <label className={classes.dateLable}>Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <label className={classes.dateLable}>End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className={classes.buttons}>
                <button
                  type="button"
                  className="button"
                  onClick={() => FeaturedComapnyList()}
                >
                  {" "}
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className={classes.headerPageNavigation}>
            <div className={classes.buttons1}>
              {currentPage > 0 ? (
                <button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  Prev
                </button>
              ) : (
                <></>
              )}
              <span>{currentPage}</span>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : (
            <table className={classes.events_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Logo Image</th>
                <th className={classes.table_heading}>Logo Id </th>
                <th className={classes.table_heading}>
                  Company Id{" "}
                  {islogoid === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortinglogoid("company_id")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortinglogoid("company_id")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Name
                  {isname === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingName("name")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingName("name")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Logo Position{" "}
                  {islogopossi === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingposi("logoPosition")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingposi("logoPosition")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Status</th>
                <th className={classes.table_heading}>Start Date</th>
                <th className={classes.table_heading}>End date</th>
                <th className={classes.table_heading}>Updated By</th>
              </tr>

              <tbody>
                {filteredData.map((item) => (
                  <tr className={classes.table_row}>
                    <td className={classes.table_td}>
                      <img
                        src={item.image}
                        className={classes.table_td_image}
                        alt="no media"
                      />
                    </td>
                    <td className={classes.table_td}>{item.id}</td>
                    <td className={classes.table_td}>{item.company_id}</td>
                    <td className={classes.table_td}>{item.name}</td>
                    <td className={classes.table_td}>{item.logoPosition}</td>
                    <td className={classes.table_td}>
                      {item.active === "1" ? "Live" : "Unlive"}
                    </td>

                    <td className={classes.table_td}>{item.startDate}</td>
                    <td className={classes.table_td}>{item.endDate}</td>
                    <td className={classes.table_td}>{item.updated_by}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCompanyList;
