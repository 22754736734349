import React, { useState} from 'react';
import { FaArrowUp } from 'react-icons/fa';



import classes from "../DataPoints/UserDataPoints.module.css"

import { Link } from 'react-router-dom';
import Card from '../Components/Dashboard/Card';


const UsersDataPoints = () => {

  

    return (

        <div>
        
                <div className={classes.dashboard_users_container}>
                    <div className={classes.dashboard_users_open}>
                        <button  className={classes.users_button}><h3>Admin Data Points</h3></button>
                  
                    </div>
                   
                            <div className={classes.table_container}>
                        
                                <div className={classes.card}>
                                   
                                <Link to="/BiDashboard/SignupLastMonth" className={classes.LinkCard}><Card UserProfile={"New Signup"}/></Link>
                                               
                                <Link to="/BiDashboard/ResumeLastMonth" className={classes.LinkCard}><Card UserProfile={"Resume Upload"}/></Link>
                                                
                                <Link to="/BiDashboard/CourseDataPoints" className={classes.LinkCard}><Card UserProfile={"Course"} /></Link>  
                                <br/>
                                
                                
                                <Link to="/BiDashboard/RecruiterLoginDataPoints" className={classes.LinkCard}><Card UserProfile={"Recruiter Login"} /></Link> 
                                <Link to="/BiDashboard/Login" className={classes.LinkCard}><Card UserProfile={"Login"} /></Link>  
                                <Link to="/BiDashboard/ArticleData" className={classes.LinkCard}><Card UserProfile={"Article"} /></Link>  
                                <Link to="/BiDashboard/ImpressionWidget" className={classes.LinkCard}><Card UserProfile={"Widgets"} /></Link>  
                                
                                <Link to="/BiDashboard/RediectionPartner" className={classes.LinkCard}><Card UserProfile={"Partner Clicks"}/></Link>
                                <Link to="/BiDashboard/EasyApplyDataPointsLastMonth" className={classes.LinkCard}><Card UserProfile={"Easy Apply Clicks"} /></Link>
                                <Link to="/BiDashboard/admindatapoints" className={classes.LinkCard}><Card UserProfile={"Featured Company"} /></Link>
                                <Link to="/BiDashboard/mentorkart" className={classes.LinkCard}><Card UserProfile={"MentorKart"} /></Link>
                                <Link to="/BiDashboard/featuredApply" className={classes.LinkCard}><Card UserProfile={"Apply Via Resume"} /></Link>
                            {/*<Link to="/BiDashboard/landingpage" className={classes.LinkCard}><Card UserProfile={"Landing Page"} /></Link> */}
                                
                                </div>
                            
                            </div>
                      
                        
                    
                </div>
            
        </div>
    )
}

export default UsersDataPoints
