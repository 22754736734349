import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classes from "./CompanyLogoInsert.module.css";
import EventInputs from "../Components/Events/EventInputs";
import Modal from "../Components/Modal";
import BackButton from "../Components/BackButton";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddSheroCompanyLogo = () => {
  const history = useHistory();
  // const [name, setName] = useState()
  const [position, setPosition] = useState();
  const [image, setImage] = useState("");
  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
  const [editableCImage, setEditableCImage] = useState(true);
  const [logoImage, setLogoImage] = useState("");

  const [id, setId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);

  // const [seoUrl, setSeoUrl] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [isSuggetion, setIsSuggetion] = useState(false);

  const [loader, setLoader] = useState(false);

  const selectionApi = () => {
    setIsSuggetion(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchTearm: companyName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/Search-Company", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setCompany(result.data);
        } else setCompany([]);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setIsSuggetion(false));
  };

  const createLeadershipFactory = () => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("CompanyID", id);
    formdata.append("CompanyName", companyName);
    formdata.append("CompanyLogo", logoImage);

    // formdata.append("CompanyDescription ", paidCompany);

    formdata.append("IsLive ", isLive ? "1" : "0");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/InsertSHEroCompanies",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    selectionApi();
  }, [companyName]);

  useEffect(() => {
    if (companyName !== "") {
      let val = false;

      setCompanySuggestions(
        company.filter((val) => {
          if (val.name === companyName) {
            val = true;
          }
          return val.name
            ? val.name.toLowerCase().startsWith(companyName.toLowerCase())
            : null;
        })
      );
      if (val) {
        setCompanySuggestionsShow(false);
      }
      setCompanySuggestionsShow(true);
    } else {
      setCompanySuggestions([]);
      setCompanySuggestionsShow(false);
    }
  }, [company]);

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => {
            setModalShow(false);
            history.push("/BiDashboard/SheroCompanyLogo");
          }}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Company Logo Insert</div>
            <Link
              to="/BiDashboard/SheroCompanyLogo"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <div className={classes.searchCompany}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Company Name"}
                  placeholder={"Company Name"}
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                />
                {!isSuggetion ? (
                  <div
                    className={
                      companyName !== ""
                        ? classes.dropDownContainer
                        : classes.dropDownNoValue
                    }
                  >
                    {companySuggestionsShow && (
                      <div className={classes.dropdownWrapper}>
                        {
                          companySuggestions.length > 0 &&
                            companySuggestions.map((item) => {
                              return (
                                <p
                                  className={classes.dropDownOption}
                                  onClick={() => {
                                    setCompanyName(item.name);
                                    setPosition(item.logoPosition);
                                    setImage(item.fileImage);
                                    setId(item.id);
                                    setCompanySuggestionsShow(false);
                                  }}
                                >
                                  {item.name}
                                </p>
                              );
                            })
                          // : <div>No data found</div>
                        }
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={classes.dropDownContainer}>Searching...</div>
                )}
              </div>
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Company ID"}
                placeholder={"Company ID"}
                disabled={true}
                value={id}
              />

              {editableCImage ? (
                <div>
                  <div className={classes.fieldName}>
                    <label htmlFor="input7">Logo</label>
                  </div>
                  <div className={classes.field1}>
                    <div className={classes.inputField}>
                      <div className={classes.inputFieldText}>
                        {" "}
                        <img src={image} width={60} height={60} />
                      </div>
                      <div>
                        {" "}
                        <button
                          onClick={() => setEditableCImage(false)}
                          className={classes.input_button}
                        >
                          edit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <EventInputs
                  input={"UpdateImageInput"}
                  label={"Logo"}
                  placeholder={"Logo"}
                  onChange={(e) => setLogoImage(e.target.files[0])}
                  type={"file"}
                  accept=".png, .jpg, .jpeg"
                />
              )}
            </div>
          </div>
          <div className={classes.checkbox}>
            <EventInputs
              input={"CheckBox"}
              onChange={() => setIsLive(!isLive)}
              label={"Want to publish"}
            />
          </div>
        </div>
        <button
          className={classes.Upload_button}
          onClick={createLeadershipFactory}
        >
          {loader ? (
            <Loader
              type="ThreeDots"
              color="#fff"
              height={10}
              width={70}
              visible={true}
              //3 secs
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddSheroCompanyLogo;
