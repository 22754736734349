import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AdminLoader from '../Component/AdminLoader'

import classes from './JobDetails.module.css'


const JobDetails = () => {

    const [loading, setLoading] = useState(false)
    const [recJobDet, setRecJobDet] = useState([])

    const { id } = useParams()

    useEffect(() => {
        RecruiterDetailsApi()
    }, [])

    console.log(recJobDet);
    // console.log(id);

    const RecruiterDetailsApi = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "RecruiterId": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/GetrecruterDetails", requestOptions)
            .then(response => response.json())
            .then((result) => {
                setRecJobDet(result.jobDetails)
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false));
    }

    return loading ?
        (<div className={classes.loadingLayout}><AdminLoader /></div>)
        : (
            <div className={classes.mainDiv}>
                <table className={classes.tableMain}>
                    <tr className={classes.headRow}>
                        <th className={classes.tableHeader}>Recruiter Id</th>
                        <th className={classes.tableHeader}>Job Type</th>
                        <th className={classes.tableHeader}>Position</th>
                        <th className={classes.tableHeader}>Job Description</th>
                        <th className={classes.tableHeader}>Location</th>
                        <th className={classes.tableHeader}>Company Name</th>
                        <th className={classes.tableHeader}>Company Website</th>
                        <th className={classes.tableHeader}>Company Address</th>
                        <th className={classes.tableHeader}>Average Salary Offered</th>
                        <th className={classes.tableHeader}>Job Start Date</th>
                        <th className={classes.tableHeader}>Address</th>
                        <th className={classes.tableHeader}>Contact</th>
                    </tr>
                    {recJobDet.map((item, index) => {
                        return <tr>
                            <td className={classes.tableData}>{item.recruiterId}</td>
                            <td className={classes.tableData}>{item.JobType}</td>
                            <td className={classes.tableData}>{item.Position}</td>
                            <td className={classes.tableDesc}>{item.JobDescription}</td>
                            <td className={classes.tableData}>{item.Location}</td>
                            <td className={classes.tableData}>{item.CompanyName}</td>
                            <td className={classes.tableData}>{item.CompanyWebsite}</td>
                            <td className={classes.tableData}>{item.CompanyAddress}</td>
                            <td className={classes.tableData}>{item.AverageSalaryOffered}</td>
                            <td className={classes.tableDate}>{item.JobStartedDate}</td>
                            <td className={classes.tableData}>{item.Address}</td>
                            <td className={classes.tableData}>{item.ContactPerson}</td>

                        </tr>
                    })}
                </table>

            </div>
        )
}

export default JobDetails
