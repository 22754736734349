import React, { useEffect, useState } from "react";
import classes from "./AddQuestion.module.css";
import { Link, useHistory } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";
import BackButton from "../../Components/BackButton";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";
import Loader from "react-loader-spinner";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditQuestion = () => {
    const history = useHistory();

    const [modalShow, setModalShow] = useState(false);
    const [erorrshow, setErrorShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState("");
    const [Option1, setOption1] = useState("")
    const [Option2, setOption2] = useState("")
    const [Option3, setOption3] = useState("")
    const [Option4, setOption4] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState("");
    const [explanation, setExplanation] = useState("");
    const [isLive, setIsLive] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState("");
    const { id } = useParams();

    const updateQuizQuestion = (e) => {
        e.preventDefault();
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );

        var formdata = new FormData();
        formdata.append("QuizDataBankUUID", id);
        formdata.append("Question", question);
        formdata.append("Option1", Option1);
        formdata.append("Option2", Option2);
        formdata.append("Option3", Option3);
        formdata.append("Option4", Option4);
        formdata.append("CorrectAnswer", correctAnswer);
        formdata.append("Explanation", explanation);
        formdata.append("QACategoryName  ", category);
        formdata.append("IsLive", isLive ? 1 : 0);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(
            global.apiLink +"/adminapi/UpdateQuizDataBank",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === "SUCCESS" && result.status_code == 200) {
                    setModalShow(true);
                }
                return result;
            })
            .then((result) => {
                if (result.status_code == 200 && result.status === "Success") {
                    setQuestion('');
                    setOption1('')
                    setOption2('')
                    setOption3('')
                    setOption3('')
                    setCorrectAnswer('')
                    setExplanation('')
                    setCategory('')
                    setIsLive(false);
                }
                else if (result.status_code == 300) {
                    setErrorShow(true)
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const fetchQuestionDetails = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        var formdata = new FormData();

        formdata.append("QuizDataBankUUID", id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: formdata
        };

        fetch(global.apiLink +"/adminapi/GetQuizByIdDataBank", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                setQuestion(result.data.Question);
                setOption1(result.data.Option1)
                setOption2(result.data.Option2)
                setOption3(result.data.Option3)
                setOption4(result.data.Option4)
                setCorrectAnswer(result.data.CorrectAnswer)
                setExplanation(result.data.Explanation)
                setCategory(result.data.QACategoryName)
                setIsLive(result.data.IsLive == "1" ? true : false)
            }
            )
            .catch((error) => console.log("error", error));
    }


    const fetchCategoryList = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(global.apiLink +"/adminapi/QuizListCategory", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setCategoryList(result.data);
            }
            )
            .catch((error) => console.log("error", error));
    }

    useEffect(() => {
        fetchQuestionDetails()
        fetchCategoryList();
    }, []);


    return (
        <div className={classes.top_div}>
            {modalShow && (
                <Modal
                    heading={"Data updated successfully"}
                    onClick={() => history.push("/BiDashboard/QuizQuestionList")}
                />
            )}
            {erorrshow && <Modal heading={"Required Fields Missing"} onClick={() => setErrorShow(false)} />}

            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Edit Quiz Question</div>
                        <Link
                            to="/BiDashboard/QuizQuestionList"
                            className={classes.BackButton}
                        >
                            <BackButton />
                        </Link>
                    </div>
                    <form
                        onSubmit={updateQuizQuestion}
                        className={classes.form_input}
                    >
                        <div className={classes.input}>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Question Title"}
                                required={true}
                                placeholder={"Enter Question Title..."}
                                onChange={(e) => setQuestion(e.target.value)}
                                value={question}
                            />
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Option 2"}
                                required={true}
                                placeholder={"Enter Option 2..."}
                                onChange={(e) => setOption2(e.target.value)}
                                value={Option2}
                            />

                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Option 4"}
                                required={true}
                                placeholder={"Enter Option 4..."}
                                onChange={(e) => setOption4(e.target.value)}
                                value={Option4}
                            />
                            <EventInputs
                                input={"MultiLineInput"}
                                label={"Explanation"}
                                required={true}
                                placeholder={"Enter Explanation of Correct Answer..."}
                                onChange={(e) => setExplanation(e.target.value)}
                                value={explanation}
                            />
                        </div>

                        <div className={classes.input}>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Option 1"}
                                required={true}
                                placeholder={"Enter Option 1..."}
                                onChange={(e) => setOption1(e.target.value)}
                                value={Option1}
                            />


                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Option 3"}
                                required={true}
                                placeholder={"Enter Option 3..."}
                                onChange={(e) => setOption3(e.target.value)}
                                value={Option3}
                            />

                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Correct Answer"}
                                required={true}
                                placeholder={"Enter Correct Answer..."}
                                onChange={(e) => setCorrectAnswer(e.target.value)}
                                value={correctAnswer}
                            />
                            <EventInputs
                                input={"smalldropdown"}
                                label={"Quiz Category"}
                                placeholder={"Quiz Category"}
                                onChange={(e) => setCategory(e.target.value)}
                                displayData={categoryList}
                                value={category}
                            />
                        </div>


                        <div className={classes.checkbox}>
                            <EventInputs
                                input={"CheckBox"}
                                onChange={() => setIsLive(!isLive)}
                                label={"Want to publish"}
                                value={isLive}
                                checked={isLive}
                            />
                            <div className={classes.btnWrapper}>
                                <button
                                    type="submit"
                                    className={classes.Upload_button}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <Loader
                                            type="ThreeDots"
                                            color="#fff"
                                            height={10}
                                            width={70}
                                            visible={true}
                                        //3 secs
                                        />
                                    ) : (
                                        "Update"
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditQuestion;
