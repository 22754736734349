import React, { useState, useEffect } from "react";
import classes from "./DashboardMain.module.css";
import { Link } from "react-router-dom";
import DashboardButton from "./DashboardButton";

const DashboardMain = (props) => {
  const [message, setMessage] = useState('')
  const [ad, setAd] = useState()
  const userEmail = sessionStorage.getItem("googleBiEmail")

  const fetchAds = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=Cq0kel3BZFYQxSoAB0RfXmsGm3HpN2xEMCW0Crr2paehWAdi8k3nbAsJeqmGqrqP");

    var raw = JSON.stringify({
      "printAdId": props.apidata.id,
      "userEmail": userEmail
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/fetchAdsToInboxforUser", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setMessage(result.message)

        }
      })
      .catch(error => console.log('error', error));

  }



  const [timeOut, setTimeOut] = useState(true);

  useEffect(() => {
    if (!message)
      return
    setTimeout(() => {
      setTimeOut(false)
    }, 5000)
  }, [message])

  const dashboard = () => {
    switch (props.adType) {
      case ("inbox"): {
        return (
          <>
            <Link
              to={"/BiDashboard/JobAds/" + props.adsData.id}
              className={classes.jobItem}
            >
              <div className={classes.jobItemImage}>
                <img src={props.adsData.imageUrl} alt="jobImage" />
              </div>
              <div className={classes.jobItemInfo}>
                <h3 className={classes.jobInfoTitle}>
                  Publication : {props.adsData.positionTitle}
                </h3>
                <p>
                  Edition :{" "}
                  {props.adsData.edition ? props.adsData.edition : "null"}
                </p>
                <p>Issue Date : {props.adsData.issueDate}</p>
                {/* <p>
                  Order No :{" "}
                  {props.adsData.orderNumber
                    ? props.apidata.orderNumber
                    : "null"}
                </p> */}
                <p>
                  Client :{" "}
                  {props.adsData.companyDetails
                    ? props.adsData.companyDetails
                    : "null"}{" "}
                </p>
                <p>
                  Number of Posting :{" "}
                  {props.adsData.vacancies ? props.adsData.vacancies : "null"}
                </p>
                <p>
                  Status :{" "}
                  {props.adsData.status ? props.adsData.status : "null"}
                </p>
                {/* <div className={classes.fetchButton}>
                  <DashboardButton buttontext={"Fetch"} />
                </div> */}
              </div>
            </Link>
          </>
        );
      }
      case ("fetch ads"): {
        // let timeOut = 1;

        return (
          <>
            <div className={classes.fetchMeassage}>
              {timeOut ? message : ""}
            </div>
            <div
              className={classes.jobItem}
            >

              <div className={classes.jobItemImage}>
                <img src={props.apidata.imageUrl} alt="jobImage" />
              </div>
              <div className={classes.jobItemInfo}>
                <h3 className={classes.jobInfoTitle}>
                  Publication : {props.apidata.positionTitle}
                </h3>
                <p>
                  Edition :{" "}
                  {props.apidata.edition ? props.apidata.edition : "null"}
                </p>
                <p>Issue Date : {props.apidata.issueDate}</p>
                <p>
                  Order No :{" "}
                  {props.apidata.orderNumber
                    ? props.apidata.orderNumber
                    : "null"}
                </p>
                <p>
                  Client :{" "}
                  {props.apidata.companyDetails
                    ? props.apidata.companyDetails
                    : "null"}{" "}
                </p>
                <p>
                  Number of Posting :{" "}
                  {props.apidata.vacancies ? props.apidata.vacancies : "null"}
                </p>
                <p>
                  Status :{" "}
                  {props.apidata.status ? props.apidata.status : "null"}
                </p>
                <div className={classes.fetchButton}>
                  <DashboardButton buttontext={"Fetch"} onClick={() => fetchAds()} />
                </div>
              </div>
            </div>
          </>
        );
      }
      case ("adsqc"): {
        return (
          <>
            <Link
              to={"/timePaperads/Qcform/" + props.apidata.id}
              className={classes.jobItem}
            >
              <div className={classes.jobItemImage}>
                <img src={props.apidata.imageUrl} alt="jobImage" />
              </div>
              <div className={classes.jobItemInfo}>
                <h3 className={classes.jobInfoTitle}>
                  Publication : {props.apidata.positionTitle}
                </h3>
                <p>
                  Edition :{" "}
                  {props.apidata.edition ? props.apidata.edition : "null"}
                </p>
                <p>Issue Date : {props.apidata.issueDate}</p>
                <p>
                  Order No :{" "}
                  {props.apidata.orderNumber
                    ? props.apidata.orderNumber
                    : "null"}
                </p>
                <p>
                  Client :{" "}
                  {props.apidata.companyDetails
                    ? props.apidata.companyDetails
                    : "null"}{" "}
                </p>
                <p>
                  Number of Posting :{" "}
                  {props.apidata.vacancies ? props.apidata.vacancies : "null"}
                </p>
                <p>
                  Status :{" "}
                  {props.apidata.status ? props.apidata.status : "null"}
                </p>
                <div className={classes.fetchButton}>
                  <DashboardButton buttontext={"QC"} />
                </div>
              </div>
            </Link>
          </>
        );
      }
      case ("editform"): {
        return (
          <>
            <Link
              to={"/timePaperads/Editform/" + props.apidata.id}
              className={classes.jobItem}
            >
              <div className={classes.jobItemImage}>
                <img src={props.apidata.imageUrl} alt="jobImage" />
              </div>
              <div className={classes.jobItemInfo}>
                <h3 className={classes.jobInfoTitle}>
                  Publication : {props.apidata.positionTitle}
                </h3>
                <p>
                  Edition :{" "}
                  {props.apidata.edition ? props.apidata.edition : "null"}
                </p>
                <p>Issue Date : {props.apidata.issueDate}</p>
                <p>
                  Order No :{" "}
                  {props.apidata.orderNumber
                    ? props.apidata.orderNumber
                    : "null"}
                </p>
                <p>
                  Client :{" "}
                  {props.apidata.companyDetails
                    ? props.apidata.companyDetails
                    : "null"}{" "}
                </p>
                <p>
                  Number of Posting :{" "}
                  {props.apidata.vacancies ? props.apidata.vacancies : "null"}
                </p>
                <p>
                  Status :{" "}
                  {props.apidata.status ? props.apidata.status : "null"}
                </p>
                <div className={classes.fetchButton}>
                  <DashboardButton buttontext={"Edit"} />
                </div>
              </div>
            </Link>
          </>
        );
      }
    }
  };

  return <>{dashboard(props.adType)}</>;
};

export default DashboardMain;
