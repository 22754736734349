import React, { useState, useEffect } from "react";
import EventInputs from "./EventInputs";
import Modal from "../Modal";
import classes from "./UpdateEvent.module.css";
import Loader from "react-loader-spinner";
import { Link, useHistory, useParams } from "react-router-dom";
import BackButton from "../BackButton";
import TextEditor from "../TextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const UpdateEvent = () => {
  const history = useHistory();
  const { id } = useParams();
  const [passLoader, setPassLoader] = useState();
  const [eventName, setEventName] = useState();
  const [location, setLocation] = useState();
  const [description, setDescription] = useState();
  const [type, setType] = useState();
  const [organizer, setOrganizer] = useState();
  const [speakerName, setSpeakerName] = useState();
  const [speakerDesignation, setSpeakerDesignation] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [eventImage, setEventImage] = useState();
  const [eventImageUrl, setEventImageUrl] = useState();
  const [eventSpeakerImage, setEventSpeakerImage] = useState();
  const [eventSpeakerUrl, setEventSpeakerUrl] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [categories, setCategories] = useState();
  const [check, setCheck] = useState(0);
  const [editable, setEditable] = useState(true);
  const [editableEventImage, setEditableEventImage] = useState(true);
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [url, setUrl] = useState();

  useEffect(() => {
    getEventDetail();
  }, []);
  const NewEvent = () => {
    setPassLoader(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("id", id);
    if (eventImage) formdata.append("event_image", eventImage);
    if (eventName) formdata.append("eventName", eventName);
    if (location) formdata.append("eventLocation", location);
    if (description) formdata.append("eventDescription", description);
    if (type) formdata.append("eventType", type);
    if (time) formdata.append("event_time", time);
    if (date) formdata.append("event_date", date);
    if (organizer) formdata.append("event_organizer", organizer);
    if (speakerName) formdata.append("event_speaker_name ", speakerName);
    if (speakerDesignation)
      formdata.append("event_speaker_designation", speakerDesignation);
    if (eventSpeakerImage)
      formdata.append("event_speaker_image", eventSpeakerImage);
    if (eventImage) formdata.append("event_image", eventImage);
    if (startTime) formdata.append("event_start_time", startTime);
    if (endTime) formdata.append("event_end_time", endTime);
    if (startDate) formdata.append("eventStartDate", startDate);
    if (endDate) formdata.append("eventEndDate", endDate);
    formdata.append("isPublished", check);
    if (categories) formdata.append("eventCategories", categories);
    if (url) formdata.append("eventUrl", url);

    // formdata.append("id", id);
    // if (eventImage)
    // formdata.append("event_image", eventImage);
    // formdata.append("eventName", eventName);
    // formdata.append("eventLocation", location);
    // formdata.append("eventDescription", description);
    // formdata.append("eventType", type);
    // formdata.append("event_time", time);
    // formdata.append("event_date", date);
    // formdata.append("event_organizer", organizer);
    // formdata.append("event_speaker_name ", speakerName);
    // formdata.append("event_speaker_designation", speakerDesignation);
    // formdata.append("event_start_time", startTime);
    // formdata.append("event_end_time", endTime);
    // formdata.append("eventStartDate", startDate);
    // formdata.append("eventEndDate", endDate);
    // formdata.append("isPublished", check);
    // formdata.append("eventCategories", categories);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(global.apiLink + "/adminapi/update-event", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setPassLoader(false);
      });
  };

  const getEventDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/view-event", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setEventName(result.data.eventName);
        setDescription(result.data.eventDescription);
        setLocation(result.data.eventLocation);
        setType(result.data.eventType);
        setCategories(result.data.eventCategories);
        setTime(result.data.event_time);
        setDate(result.data.event_date);
        setStartTime(result.data.event_start_time);
        setEndTime(result.data.event_end_time);
        setStartDate(result.data.eventStartDate);
        setEndDate(result.data.eventEndDate);
        setEventSpeakerUrl(result.data.event_speaker_image);
        setSpeakerDesignation(result.data.event_speaker_designation);
        setSpeakerName(result.data.event_speaker_name);
        setEventImageUrl(result.data.event_image);
        setCheck(result.data.isPublished);
        setOrganizer(result.data.event_organizer);
        setUrl(result.data.eventUrl);

        //   console.log(result.data)
      })
      .catch((error) => console.log("error", error));
  };

  const onBodyChangeHandler = (textEditorValue) => {
    setDescription(textEditorValue);
  };

  return (
    <>
      <div className={classes.top_div}>
        {modalShow && (
          <Modal
            heading={"Data updated successfully"}
            onClick={() => history.push("/BiDashboard/events")}
          />
        )}
        {erorrshow && (
          <Modal
            heading={"Required Fields Missing"}
            onClick={() => setErrorShow(false)}
          />
        )}
        {internalErrorShow && (
          <Modal
            heading={"Internal server error"}
            onClick={() => setInternalErrorShow(false)}
          />
        )}

        <div className={classes.container}>
          <div className={classes.form}>
            <div className={classes.form_header_container}>
              <div className={classes.form_header}>Edit Event</div>
              <Link to="/BiDashboard/events" className={classes.BackButton}>
                <BackButton />
              </Link>
            </div>
            {/* {data.map((item) => ( */}
            <div className={classes.form_input}>
              <div className={classes.input}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Event name"}
                  placeholder={"Event Name"}
                  onChange={(e) => setEventName(e.target.value)}
                  value={eventName}
                />
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Location"}
                  placeholder={"Location"}
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                />
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Event Organizer"}
                  placeholder={"Event Organizer"}
                  onChange={(e) => setOrganizer(e.target.value)}
                  value={organizer}
                />
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Event Speaker Name"}
                  placeholder={"Event Speaker Name"}
                  onChange={(e) => setSpeakerName(e.target.value)}
                  value={speakerName}
                />
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Type"}
                  placeholder={"Type"}
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                />
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Speaker Designation"}
                  placeholder={"Speaker Designation"}
                  onChange={(e) => setSpeakerDesignation(e.target.value)}
                  value={speakerDesignation}
                />

                <EventInputs
                  input={"SingleLineInput"}
                  label={"categories"}
                  placeholder={"categories"}
                  onChange={(e) => setCategories(e.target.value)}
                  value={categories}
                />
                {/* <EventInputs
                  input={"MultiLineInput"}
                  label={"Description"}
                  placeholder={"Description"}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                /> */}
                <div className={classes.AddHrProfessional_Description}>
                  Description
                </div>
                <div className={classes.AddHrProfessional_Desc}>
                  {/* <SunEditor
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    value={description}
                    setContents={description}
                  /> */}
                  <div>
                    <TextEditor
                      onChangeContent={setDescription}
                      value={description}
                    />
                  </div>
                </div>
                {/* <div className={classes.AddHrProfessional_Description}>Description</div>
                <div className={classes.textEditorWrapper}>
                  <TextEditor onChange={onBodyChangeHandler} value={description} />
                </div> */}
              </div>
              <div className={classes.input}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Redirection Url"}
                  placeholder={"Redirection Url"}
                  onChange={(e) => setUrl(e.target.value)}
                  value={url}
                />
                <EventInputs
                  input={"TimeInput"}
                  label={"Event Start Time"}
                  placeholder={"Event Start Time"}
                  onChange={(e) => setStartTime(e.target.value)}
                  value={startTime}
                />
                <EventInputs
                  input={"TimeInput"}
                  label={"Event End Time"}
                  placeholder={"Event End Time"}
                  onChange={(e) => setEndTime(e.target.value)}
                  value={endTime}
                />
                <EventInputs
                  input={"DateInput"}
                  label={"Event Start Date"}
                  placeholder={"Event Start Date"}
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                />
                <EventInputs
                  input={"DateInput"}
                  label={"Event End Date"}
                  placeholder={"Event End Date"}
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                />
                <EventInputs
                  input={"TimeInput"}
                  label={"Event Time"}
                  placeholder={"Event Time"}
                  onChange={(e) => setTime(e.target.value)}
                  value={time}
                />
                <EventInputs
                  input={"DateInput"}
                  label={"Event Date"}
                  placeholder={"Event Date"}
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                />

                {editable && eventSpeakerUrl ? (
                  <div>
                    <div className={classes.fieldName}>
                      <label htmlFor="input6">Upload Card Event Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          {" "}
                          <img
                            src={eventSpeakerUrl}
                            alt=""
                            width={50}
                            height={50}
                          />
                        </div>
                        <div>
                          {" "}
                          <button
                            onClick={() => setEditable(false)}
                            className={classes.input_button}
                          >
                            edit{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <EventInputs
                    input={"UpdateImageInput"}
                    label={"Upload Card Event Image "}
                    placeholder={"Event Speaker Image "}
                    onChange={(e) => setEventSpeakerImage(e.target.files[0])}
                    value={eventSpeakerImage}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
                {editableEventImage && eventImageUrl ? (
                  <div>
                    <div className={classes.fieldName}>
                      <label htmlFor="input7">Upload Event Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          {" "}
                          <img
                            src={eventImageUrl}
                            alt=""
                            width={50}
                            height={50}
                          />
                        </div>
                        <div>
                          {" "}
                          <button
                            onClick={() => setEditableEventImage(false)}
                            className={classes.input_button}
                          >
                            edit{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <EventInputs
                    input={"UpdateImageInput"}
                    label={"Select Image"}
                    placeholder={"Event Image"}
                    onChange={(e) => setEventImage(e.target.files[0])}
                    value={eventImage}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
              </div>
              <div className={classes.checkbox}>
                <EventInputs
                  input={"CheckBox"}
                  onChange={() => setCheck(!check === true ? 1 : 0)}
                  label={"Want to publish this event"}
                  value={check}
                />
              </div>
            </div>
            {/* ))
                    } */}
          </div>
          <button className={classes.Upload_button} onClick={() => NewEvent()}>
            {passLoader ? (
              <Loader type="TailSpin" color="white" height={20} width={20} />
            ) : (
              <> Update</>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default UpdateEvent;
