import React from "react";
import classes from './BackButton.module.css'

const BackButton = (props) => {
  return (
    <div className={classes.button_wrapper}>
      <button className={classes.Add_event_button}>
        Back
      </button>
    </div>
  );
};

export default BackButton;
