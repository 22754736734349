import React, { useEffect, useState } from 'react'
import classes from './FetchAds.module.css'
import { MdError } from 'react-icons/md';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import ConfirmModal from '../../Components/ConfirmModal';

const FetchAds = () => {
    const [fetchAdsData, setFetchAdsData] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalShow, setModalShow] = useState(false);
    const [adDetail, setAdDetail] = useState("");
    const [junkMessage, setJunkMessage] = useState("");

    const fetchAds = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "start_date": (startDate),
            "end_date": (endDate),
            "page": currentPage
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/fetch-ads", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code == 200 && result.status === "SUCCESS") {
                    setFetchAdsData(result.data);
                    setCount(result.count);

                }

            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }

    const moveToJunkApi = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=Cq0kel3BZFYQxSoAB0RfXmsGm3HpN2xEMCW0Crr2paehWAdi8k3nbAsJeqmGqrqP");

        var raw = JSON.stringify({
            "Id": adDetail.id,
            "Value": 1
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/DeleteJob", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    fetchAds();
                    setJunkMessage("Moved to junk");
                    setTimeout(() => {
                        setJunkMessage("")
                    }, 2000)
                }
            })
            .then(() => setModalShow(false))
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false); });

    }

    useEffect(() => {
        fetchAds()
    }, [currentPage])


    if (junkMessage) {


        return (
            <div className={classes.screen}>
                <div className={classes.screenDiv}>
                    <div className={classes.dateDiv}>
                        <label className={classes.dateLable}>Start Date</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <label className={classes.dateLable}>End Date</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className={classes.buttons}>
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                fetchAds();
                            }}
                        >
                            {" "}
                            Search
                        </button>
                    </div>
                    <div className={classes.header}>
                        <div>Total Ads:{count}</div>
                        <div className={classes.buttons1}>
                            {currentPage > 0 ? (
                                <button
                                    onClick={() => {
                                        setCurrentPage(currentPage - 1);
                                    }}
                                >
                                    Prev
                                </button>
                            ) : (
                                <></>
                            )}
                            <span>{currentPage}</span>
                            <button
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                    {loading ? (
                        <div className={classes.msg}>
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={80}
                                width={80}
                            />{" "}
                        </div>
                    ) : (
                        <>
                            {fetchAdsData.length === 0 ? (
                                <div className={classes.msg}>
                                    <MdError className={classes.icon} />
                                    <p>Data not found</p>
                                </div>
                            ) :
                                <div className={classes.msg}>Moved to junk</div>
                            }
                        </>
                    )}
                </div>
            </div>
        );

    }

    return (
        <div className={classes.screen}>
            {modalShow && (
                <ConfirmModal
                    heading={"Are you sure you want to move to junk"}
                    onClose={() => setModalShow(false)}
                    onClick={() => moveToJunkApi()}
                />
            )}
            <div className={classes.screenDiv}>
                <div className={classes.dateDiv}>
                    <label className={classes.dateLable}>Start Date</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <label className={classes.dateLable}>End Date</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className={classes.buttons}>
                    <button type="button" className="button" onClick={() => { fetchAds() }}> Search</button>
                </div>
                <div className={classes.header}>
                    <div>Total Ads:{count}</div>
                    <div className={classes.buttons1}>

                        {currentPage > 0 ? <button onClick={() => { setCurrentPage(currentPage - 1) }}>Prev</button> : <></>}
                        <span>{currentPage}</span>
                        <button onClick={() => { setCurrentPage(currentPage + 1) }}>Next</button>
                    </div>

                </div>
                {loading ? <div className={classes.msg} ><Loader type="TailSpin" color="#00BFFF" height={80} width={80} /> </div> : <>{fetchAdsData.length === 0 ? <div className={classes.msg}><MdError className={classes.icon} /><p>Data not found</p></div> :
                    fetchAdsData.map((item, key) => {
                        if (item.createdBy === "NON_ASCENT_JOB" || item.createdBy === "postajob") {
                            return <></>
                        }
                        return <AdComp setModalShow={setModalShow} junkMessage={junkMessage} setAdDetail={setAdDetail} item={item} adType={"fetch ads"} />
                    })}</>}

            </div>
        </div>

    )
}
export const AdComp = ({ item, adType, setAdDetail, setModalShow }) => {
    const [message, setMessage] = useState('')
    const [hideComp, setHideComp] = useState(false)
    const [loading, setLoading] = useState();
    const userEmail = sessionStorage.getItem("googleBiEmail")

    const fetchAdsToInboxforUser = () => {
        console.log("ad fetch" + item.orderNumber)
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=Cq0kel3BZFYQxSoAB0RfXmsGm3HpN2xEMCW0Crr2paehWAdi8k3nbAsJeqmGqrqP");

        var raw = JSON.stringify({
            "printAdId": item.id,
            "userEmail": userEmail,
            "orderNumber": item.orderNumber
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/fetchAdsToInboxforUser", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setMessage(result.message);
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false); });

    }


    const [timeOut, setTimeOut] = useState(true);
    const history = useHistory()

    useEffect(() => {
        if (!message)
            return
        setTimeout(() => {
            setTimeOut(false);
            setHideComp(true)
        }, 2000)
    }, [message])
    const LiveprintAdstoQC = (orderNumber, id) => {
        setLoading(true)
        const userEmail = sessionStorage.getItem("googleBiEmail")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userEmail": userEmail,
            "orderNumber": orderNumber,
            "printAdId": id

        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/LiveprintAdstoQC", requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status_code === 200)
                    setMessage(result.message);
                // history.push("/printAd/jobDashboard/" + orderNumber);
                // else
                //     alert("something went wrong")
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }
    const LiveNonAscenttoQC = (orderNumber, id) => {
        setLoading(true)
        const userEmail = sessionStorage.getItem("googleBiEmail")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userEmail": userEmail,
            "orderNumber": orderNumber,
            "printAdId": id

        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/getNonAscentJobLiveToQC", requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status_code === 200)
                    setMessage(result.message);
                // history.push("/printAd/jobDashboard/" + orderNumber);
                // else
                //     alert("something went wrong")
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }
    return (
        <>
            {hideComp ? <></> :
                loading ? <div className={classes.msg} ><Loader type="TailSpin" color="#00BFFF" height={80} width={80} /> </div> : <>{
                    message && timeOut ? <div className={classes.msg}>{message}</div> :

                        <div className={classes.jobItem}>

                            <div className={classes.jobItemImage}>
                                <img src={item.imageUrl} alt="jobImage" />
                            </div>
                            <div className={classes.jobItemInfo}>
                                <h3 className={classes.jobInfoTitle}>
                                    Publication : {item.positionTitle}
                                </h3>
                                <p>
                                    Edition :{" "}
                                    {item.edition ? item.edition : "null"}
                                </p>
                                <p>Issue Date : {item.issueDate}</p>
                                <p>
                                    Order No :{" "}
                                    {item.orderNumber
                                        ? item.orderNumber
                                        : "null"}
                                </p>
                                <p>
                                    Client :{" "}
                                    {item.companyDetails
                                        ? item.companyDetails
                                        : "null"}{" "}
                                </p>
                                <p>
                                    Number of Posting :{" "}
                                    {item.vacancies ? item.vacancies : "null"}
                                </p>
                                <p>
                                    Status :{" "}
                                    {item.status ? item.status : "null"}
                                </p>
                                {adType === "fetch ads" ? <div className={classes.fetchButton}>
                                    <button type="button" onClick={() => {
                                        setModalShow(true);
                                        setAdDetail(item);
                                    }}>
                                        🗑 Move to junk
                                    </button>
                                    <button type="button" onClick={() => { fetchAdsToInboxforUser() }}>
                                        Fetch
                                    </button>
                                </div> : adType === "liveJobs" ? <div className={classes.fetchButton}>
                                    <button type="button" onClick={() => { LiveprintAdstoQC(item.orderNumber, item.id) }}>
                                        Send to Q.C
                                    </button>
                                </div> : adType === "nonascentjoblivetolist" ? <div className={classes.fetchButton}>
                                    <button type="button" onClick={() => { LiveNonAscenttoQC(item.orderNumber, item.id) }}>
                                        Send to Q.C
                                    </button>
                                </div> : <></>}
                            </div>
                        </div>
                }
                </>}
        </>
    )
}
export default FetchAds
