import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner";
import classes from "../TimesWidget/widgetlist.module.css";

import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const WidgetList = (props) => {
  const [loading, setLoading] = useState(false);
  const [widgetlist, setWidgetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [ispage, setIspage] = useState("ASC");
  const [istitle, setIstitle] = useState("ASC");
  const [isdes, setIsdesc] = useState("ASC");
  const [isstart, setIsstart] = useState("ASC");
  const [isend, setIsend] = useState("ASC");
  useEffect(() => {
    WidgetListApi();
  }, [currentPage]);

  const WidgetListApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/DynamicWidgetList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setWidgetlist(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const DeleteApi = (DynamicWidgetID) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      DynamicWidgetID: DynamicWidgetID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/DeleteDynamicWidget",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          WidgetListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "Widget List",
  };

  const sortingpage = (col) => {
    if (ispage === "ASC") {
      const sortedtitle = [...widgetlist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setWidgetlist(sortedtitle);
      setIspage("DSC");
    }
    if (ispage === "DSC") {
      const sortedtitle = [...widgetlist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setWidgetlist(sortedtitle);
      setIspage("ASC");
    }
  };
  const sortingtitle = (col) => {
    if (istitle === "ASC") {
      const sortedtitle = [...widgetlist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setWidgetlist(sortedtitle);
      setIstitle("DSC");
    }
    if (istitle === "DSC") {
      const sortedtitle = [...widgetlist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setWidgetlist(sortedtitle);
      setIstitle("ASC");
    }
  };
  const sortingdesc = (col) => {
    if (isdes === "ASC") {
      const sorteddescription = [...widgetlist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setWidgetlist(sorteddescription);
      setIsdesc("DSC");
    }
    if (isdes === "DSC") {
      const sorteddescription = [...widgetlist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setWidgetlist(sorteddescription);
      setIsdesc("ASC");
    }
  };
  const sortingstartdate = (col) => {
    if (isstart === "ASC") {
      const sortedstart = [...widgetlist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setWidgetlist(sortedstart);
      setIsstart("DSC");
    }
    if (isstart === "DSC") {
      const sortedstart = [...widgetlist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setWidgetlist(sortedstart);
      setIsstart("ASC");
    }
  };
  const sortingenddate = (col) => {
    if (isend === "ASC") {
      const sortedend = [...widgetlist].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setWidgetlist(sortedend);
      setIsend("DSC");
    }
    if (isend === "DSC") {
      const sortedend = [...widgetlist].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setWidgetlist(sortedend);
      setIsend("ASC");
    }
  };
  return (
    <>
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={widgetlist}
              filename={"WidgetList.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={widgetlist} fileName="WidgetList" />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? WidgetListApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{widgetlist.length < 20 ? <></> : currentPage}</span>
                {widgetlist.length < 20 ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                )}
              </div>

              {props.accessLevel === "TIMES" ? (
                ""
              ) : (
                <div>
                  <button className={classes.Addbtn}>
                    <Link
                      to="/BiDashboard/CreateWidget"
                      className={classes.Edit_link}
                    >
                      Create A Widget
                    </Link>
                  </button>
                </div>
              )}
            </div>
            <div className={classes.container}>
              {widgetlist.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>
                    <th className={classes.table_heading}>Icon Image</th>
                    <th className={classes.table_heading}>
                      Page Name
                      {ispage === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingpage("PageName")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingpage("PageName")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>
                      Title{" "}
                      {istitle === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingtitle("Title")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingtitle("Title")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>
                      Description
                      {isdes === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingdesc("Description")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingdesc("Description")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>

                    <th className={classes.table_heading}>Redirection URL</th>
                    <th className={classes.table_heading}>
                      Created At
                      {isstart === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingstartdate("CreatedAt")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingstartdate("CreatedAt")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>
                      Updated At
                      {isend === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingenddate("UpdatedAt")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingenddate("UpdatedAt")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>Status</th>

                    {props.accessLevel === "TIMES" ? (
                      ""
                    ) : (
                      <th className={classes.table_heading}>Update</th>
                    )}
                    {props.accessLevel === "TIMES" ? (
                      ""
                    ) : (
                      <th className={classes.table_heading}>Delete</th>
                    )}
                  </tr>

                  <tbody>
                    {widgetlist.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.ID}</td>
                          <td className={classes.table_td}>
                            <img
                              src={item.IconImage}
                              alt="icon"
                              className={classes.IconImage}
                            />
                          </td>
                          <td className={classes.table_td}>{item.PageName}</td>
                          <td className={classes.table_td}>{item.Title}</td>
                          <td className={classes.table_td}>
                            {item.Description}
                          </td>

                          <td className={classes.table_td}>
                            <a
                              href={item.RedirectionUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Redirection
                            </a>
                          </td>
                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.UpdatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.IsPublished === "0" ? (
                              <span className={classes.isnotLive}>
                                Not Live
                              </span>
                            ) : (
                              <span className={classes.isLive}>Live</span>
                            )}
                          </td>
                          {props.accessLevel === "TIMES" ? (
                            ""
                          ) : (
                            <td className={classes.table_td}>
                              <Link
                                to={
                                  "/BiDashboard/EditWidget/" +
                                  item.DynamicWidgetID
                                }
                                className={classes.Edit_link_two}
                              >
                                Edit
                              </Link>
                            </td>
                          )}
                          {props.accessLevel === "TIMES" ? (
                            ""
                          ) : (
                            <td className={classes.table_td}>
                              <button
                                className={classes.delbtn}
                                onClick={() => DeleteApi(item.DynamicWidgetID)}
                              >
                                {loading ? (
                                  <Loader
                                    type="TailSpin"
                                    color="white"
                                    height={20}
                                    width={50}
                                    className="text-center"
                                  />
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetList;
