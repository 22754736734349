import React, { useState } from "react";
import EventInputs from "../Events/EventInputs";
import classes from "./AddFeaturedCompanies.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../BackButton";

const AddFeaturedCompanies = () => {
  const history = useHistory();
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [industry, setIndustry] = useState();
  const [website, setWebsite] = useState();
  const [description, setDescription] = useState();
  const [location, setLocation] = useState();
  const [position, setPosition] = useState();
  // const [id, setId] = useState();

  const AddNew = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Image", image);
    formdata.append("CompanyDescription", description);
    formdata.append("Industry", industry);
    formdata.append("Name", name);
    formdata.append("Location", location);
    formdata.append("Website", website);
    formdata.append("LogoPosition", position);

    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(global.apiLink + "/adminapi/Create-FeaturedCompany", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        history.push("/BiDashboard/featuredcompanies");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>
      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Ad d Featured Companies</div>
            <Link
              to="/BiDashboard/featuredcompanies"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Location"}
                placeholder={"Location"}
                onChange={(e) => setLocation(e.target.value)}
                value={location}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Logo Position"}
                placeholder={"1 to 7"}
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />

              <EventInputs
                input={"MultiLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Industry"}
                placeholder={"Industry"}
                onChange={(e) => setIndustry(e.target.value)}
                value={industry}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Website"}
                placeholder={"Website"}
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />

              <EventInputs
                input={"ImageInput"}
                label={"Image"}
                placeholder={"Image"}
                onChange={(e) => setImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg"
              />
            </div>
            {/* <div className={classes.checkbox}>
                            <EventInputs input={"CheckBox"} onChange={() => setCheck(!check === true ? 1 : 0)} label={"Want to publish this event"} value={check} />
                        </div> */}
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddNew()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default AddFeaturedCompanies;
