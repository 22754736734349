import React, { useEffect, useState } from 'react'
import { AdComp } from './FetchAds'
import classes from './FetchAds.module.css'
import { MdError } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const PostaJobsLiveJobs = () => {
    const [loading, setLoading] = useState(false);
    const [liveAdsData, setliveAdsData] = useState([])
    const [adCount, setAdCount] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const fetchedData = () => {
        setLoading(true)
        const userEmail = sessionStorage.getItem("googleBiEmail")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=Cq0kel3BZFYQxSoAB0RfXmsGm3HpN2xEMCW0Crr2paehWAdi8k3nbAsJeqmGqrqP");

        var raw = JSON.stringify({
            "userEmail": userEmail,
            "page": currentPage,
            "start_date": (startDate),
            "end_date": (endDate),
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/getListofPostAJobsLive", requestOptions)
            .then(response => response.json())
            .then(result => {
                setliveAdsData(result.data)
                setAdCount(result.count)

            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }
    const history = useHistory()

    useEffect(() => {
        fetchedData()
    }, [currentPage])
    return (
        <div className={classes.screen}>
            <div className={classes.screenDiv}>
                <div className={classes.dateDiv}>
                    <label className={classes.dateLable}>Start Date</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <label className={classes.dateLable}>End Date</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className={classes.buttons}>
                    <button type="button" className="button" onClick={() => { fetchedData() }}> Search</button>
                </div>
                <div className={classes.header}>
                    <div>Total ads:{adCount}</div>
                    <div className={classes.buttons1}>

                        {currentPage > 0 ? <button onClick={() => { setCurrentPage(currentPage - 1) }}>Prev</button> : <></>}
                        <span>{currentPage}</span>
                        <button onClick={() => { setCurrentPage(currentPage + 1) }}>Next</button>
                    </div>

                </div>

                {loading ? <div className={classes.msg} ><Loader type="TailSpin" color="#00BFFF" height={80} width={80} /> </div> : <>{liveAdsData.length === 0 ? <div className={classes.msg}><MdError className={classes.icon} /><p>Data not found</p></div> :
                    liveAdsData.map((item, key) => {
                        return <AdComp item={item} adType={"nonascentjoblivetolist"} />
                    })}</>}
            </div>
        </div>
    )
}

export default PostaJobsLiveJobs
