import React from "react";
import classes from "./Modal.module.css";
import { FaCopy } from "react-icons/fa";

const CompanyModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('URL copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        />
        <h2 style={{ textAlign: "center" }}>
          Form has been submitted successfully
        </h2> */}
        <div className={classes.eventdetail_container}>
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <div className={classes.images_div}>
                <div className={classes.table_th} style={{fontWeight: '700'}}>Company image</div>
                <img
                  src={props.data.LogoURL}
                  alt=""
                  className={classes.courseDisplayCard}
                  style={{width: '200px', height: '200px'}}
                ></img>

            



       


           

              </div>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                
               
                
               
                    {/* <tr>
                      <th className={classes.table_th}>Course Source :</th>
                      <td className={classes.table_td}>{props.data.course_source}</td>
                    </tr> */}
                  
                    <tr>
                      <th className={classes.table_th}>CompanyDescription</th>
                      <td className={classes.table_td}>
                        {props.data.CompanyDescription}
                      </td>
                    </tr>
                    
                     <tr>
                      <th className={classes.table_th}>Facebook Link</th>
                      <td className={classes.table_td}>
                      <FaCopy
        onClick={() => copyToClipboard(props.data.FacebookLink)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }} 
      />
    
    </td>
                    </tr>
                     <tr>
                      <th className={classes.table_th}>Instagram Link</th>
                      <td className={classes.table_td}>
                      <FaCopy
        onClick={() => copyToClipboard(props.data.InstagramLink)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }} 
      />
                      </td>
                    </tr> 
                     <tr>
                      <th className={classes.table_th}>Twitter Link</th>
                      <td className={classes.table_td}>
                      <FaCopy
        onClick={() => copyToClipboard(props.data.TwitterLink)} 
        style={{ cursor: 'pointer', marginLeft: '10px' }} 
      />
                      </td>
                    </tr> 
                    {/* <tr>
                      <th className={classes.table_th}>Course Group :</th>
                      <td className={classes.table_td}>{props.data.course_group}</td>
                    </tr> */}

                  
                    
                  
                  </tbody>
                </table>
              </div>
            </div>

           
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyModal;
