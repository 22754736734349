import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import PartnerComapniesTable from '../Components/PartnerCompanies/PartnerComapniesTable';
import classes from './PartnerCompanies.module.css'
import PartnerCompaniesHeader from '../Components/PartnerCompanies/PartnerCompaniesHeader';

const PartnerCompanies = () => {
    return (
        <>
            <Router>
                <div className={classes.dashboard_container}>
                    <PartnerCompaniesHeader />
                    <Switch>
                        <Route path="/weekly">
                            <PartnerComapniesTable day={"Weekly"} userType={"weekly"} />
                        </Route>
                        <Route path="/monthly">
                            <PartnerComapniesTable day={"Monthly"} userType={"monthly"} />
                        </Route>
                        <Route path="/custom">
                            <PartnerComapniesTable day={"Custom"} userType={"custom"} />
                        </Route>
                        <Route path="/">
                            <PartnerComapniesTable day={"Today"} userType={"daily"} />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </>
    )
}

export default PartnerCompanies
