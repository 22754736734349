import React, { useState, useEffect } from 'react'
import AdsEntry from './AdsEntry'
import DashboardMain from './DashboardMain'
import DashNav from './DashNav'
import classes from './Dashboard.module.css'
import { MdError } from 'react-icons/md';
import DashboardButton from './DashboardButton'

const DashJobList = (props) => {
  // const [loading, setLoading] = useState()
  const [fetchAds, setFetchAds] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [data, setData] = useState([])


  useEffect(() => {
    inboxApi()
  }, []);

  const inboxApi = () => {
    // setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "start_date": (startDate),
      "end_date": (endDate)
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/fetch-ads", requestOptions)
      .then(response => response.json())
      .then(result => {
        setFetchAds(result.data)
      })
      .catch(error => console.log('error', error));

  }



  return (

    <div className={classes.mainContainer}>
      <DashNav />
      <div>
        <div className={classes.dateDiv}>
          <label className={classes.dateLable}>Start Date</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          <label className={classes.dateLable}>End Date</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div>
        <span className={classes.searchButton}>
          <DashboardButton buttontext={"SEARCH"} onClick={() => inboxApi()} />
        </span>
      </div>
      {fetchAds.length === 0 ? <div className={classes.message}><MdError className={classes.icon} /><p>Data not found</p></div> :
        fetchAds.map((item, key) => {
          return <DashboardMain apidata={item} adType={"fetch ads"} />
        })}
      <div>
      </div>
    </div>
  )
}

export default DashJobList
