import React, { useState, useEffect, useRef } from "react";
import classes from "./CompanyLogo.module.css";

import { BsEye } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AiOutlineArrowUp, AiOutlineConsoleSql } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
// import LoadingSpinner from "../../Components/LoadingSpinner";
import LoadingSpinner from "../Components/LoadingSpinner";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Paginate from "../Components/Pagination";
import ConfirmModal from "../Components/ConfirmModal";
import Modal from "../Components/Modal";

const Hrconclave = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const doc = new jsPDF();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [leadershipUUID, setLeadershipUUID] = useState("");
  const perPage = 20;

  const tableRef = useRef();

  useEffect(() => {
    CompanyLogoList();
  }, [currentPage]);

  const history = useHistory();

  const handleNewCompany = () => {
    history.push("/BiDashboard/AddHrConclave");
  };

  const CompanyLogoList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: perPage,
      StartDate: date.startDate ? date.startDate : "",
      EndDate: date.endDate ? date.endDate : ""
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}/adminapi/GetHrConclaveParticipant`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.data);
          setCount(result.Count);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteLeadershipFactory = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

    var formdata = new FormData();
    formdata.append("Id", leadershipUUID);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_URL}/adminapi/DeleteHrConclaveParticipant`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
          CompanyLogoList();
        }
      })
      .catch(error => console.log('error', error));
  }



  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {/* <div className={classes.header}>Podcasts</div> */}
        {confirmModalShow && (
          <ConfirmModal
            heading={"Are you sure that you want to delete this data?"}
            onClick={deleteLeadershipFactory}
            onClose={() => setConfirmModalShow(false)}
          />
        )}
        {modalShow && (
          <Modal
            heading={"Data deleted successfully"}
            onClick={() => {
              setModalShow(false);
              setConfirmModalShow(false);
            }}
          />
        )}

        <div className={classes.header}>HR List:</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink data={data} filename={"leadership-factory-table.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="leadership-factory-table" />
          </div>
          <div className={classes.search_container}>
            <button className={classes.button} onClick={handleNewCompany}>
              Add HR
            </button>
          </div>
          {/* <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={date.startDate}
                onChange={(e) =>
                  setDate({ ...date, startDate: e.target.value })
                }
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={date.endDate}
                onChange={(e) => setDate({ ...date, endDate: e.target.value })}
              />
            </div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() => leadershipFactoryList()}
              >
                {" "}
                Search
              </button>
            </div>
          </div> */}
        </div>
        <div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Company ID</th>
                  <th className={classes.table_heading}>
                    Company Logo
                  </th>
                  <th className={classes.table_heading}>
                    Company Name
                  </th>
                  <th className={classes.table_heading}>
                    Name
                  </th>
                  <th className={classes.table_heading}>
                    Designation
                  </th>
                  <th className={classes.table_heading}>
                    Start Date
                  </th>
                  <th className={classes.table_heading}>
                    End Date
                  </th>
                  <th className={classes.table_heading}>
                    Status
                  </th>
                  <th className={classes.table_heading}>Edit</th>
                  <th className={classes.table_heading}>Delete</th>
                </tr>
                <tbody>
                  {data.map((item, idx) => (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.Id}</td>
                      <td className={classes.table_td}>
                        <div>
                          <img src={item.ComapnyLogo} className={classes.imageShow} />
                        </div>
                      </td>
                      <td className={classes.table_td}>{item.CompanyName}</td>
                      <td className={classes.table_td}>{item.Name}</td>
                      <td className={classes.table_td}>{item.Designation}</td>
                      <td className={classes.table_td}>{item.CreatedAt}</td>
                      <td className={classes.table_td}>{item.UpdatedAt}</td>

                      <td className={classes.table_td}>{item.IsLive == "1" ? "Live" : "Not Live"}</td>
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/EditHrconclave/" + item.Id}
                          className={classes.articleLink}
                        >
                          <FiEdit size={20} />
                        </Link>
                      </td>
                      <td className={classes.table_td}>
                        <div
                          onClick={() => {
                            setConfirmModalShow(true);
                            setLeadershipUUID(item.Id);
                          }}
                          className={classes.articleLinkDelete}
                        >
                          <MdDeleteForever size={20} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {
                  count > 20 &&
                  <Paginate maxPage={count} currentPage={currentPage} setCurrentPage={setCurrentPage} perPage={perPage} />
                }
              </div>
            </>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hrconclave;
