import React, { useState, useEffect } from "react";
import EventInputs from "../Events/EventInputs";
import classes from "./AddHrProfessional.module.css";
import { Link, useHistory } from "react-router-dom";
import BackButton from "../BackButton";
import Modal from "../Modal";
import TextEditor from "../TextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { AiFillCopy, AiOutlineCloseCircle } from "react-icons/ai";

const AddHrProfessional = () => {
  const history = useHistory();
  const [fullName, setFullName] = useState();
  const [category, setCategory] = useState();
  const [designation, setDesignation] = useState();
  const [position, setPosition] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [yrsCurrentOrg, setYrsCurrentOrg] = useState();
  const [currentCompany, setCurrentCompany] = useState();
  const [location, setLocation] = useState();
  const [totalExperience, setTotalExperience] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [areaOfExpertise, setAreaOfExpertise] = useState();
  const [workHistory, setWorkHistory] = useState();
  const [awardsAchievements, setAwardsAchievements] = useState();
  const [connectToTwitter, setConnectToTwitter] = useState();
  const [website, setWebsite] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [featuredMedia, setFeaturedMedia] = useState();
  const [check, setCheck] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [showImages, setShowImages] = useState([]);
  const [payloadErrorShow, setPayloadErrorShow] = useState(false);
  const [isFeatured, setIsFeatured] = useState(0)
  // console.log(shortDescription);

  const HrCategory = [
    {
      type: "Free",
    },
    {
      type: "Paid",
    },
  ];

  const NewHr = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    if (fullName) formdata.append("FullName", fullName);
    if (designation) formdata.append("Designation", designation);
    if (yrsCurrentOrg) formdata.append("YrsCurrentOrg", yrsCurrentOrg);
    if (currentCompany) formdata.append("CurrentCompany", currentCompany);
    if (location) formdata.append("Location", location);
    if (totalExperience) formdata.append("TotalExperience ", totalExperience);
    if (shortDescription) formdata.append("ShortDescription", shortDescription);
    if (areaOfExpertise) formdata.append("AreaOfExpertise", areaOfExpertise);
    if (workHistory) formdata.append("WorkHistory", workHistory);
    if (awardsAchievements)
      formdata.append("AwardsAchievements", awardsAchievements);
    if (connectToTwitter) formdata.append("ConnectToTwitter", connectToTwitter);
    if (website) formdata.append("Website", website);
    if (profilePicture) formdata.append("ProfilePicture", profilePicture);
    if (position) formdata.append("Position ", position);
    if (category) formdata.append("Category ", category);
    if (startDate) formdata.append("StartDate ", startDate);
    if (endDate) formdata.append("EndDate ", endDate);
    // formdata.append("CurrentCompanyLogo", (currentCompanyLogo?currentCompanyLogo:""));
    if (featuredMedia) formdata.append("FeaturedMedia", featuredMedia);
    formdata.append("IsPublished", check);
    formdata.append("IsFeatured ", isFeatured);

    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      global.apiLink + "/v1/api/apiTimes/CreateHRprofessional",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
    setAreaOfExpertise("");
  };

  const multipleImagesUpload = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      global.apiLink + '/adminapi/UploadHRProfessionalMultipleImages',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setShowImages(result.data);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setPayloadErrorShow(true);
        } else if (result.status_code === 413) {
          setPayloadErrorShow(true);
        }
      })
      .catch((error) => { console.log("error", error); setPayloadErrorShow(true); });
  };

  useEffect(() => {
    multipleImagesUpload();
  }, []);

  const onBodyChangeHandler = (textEditorValue) => {
    setAreaOfExpertise(textEditorValue);
  };
  const onBodyHandler = (textEditorValue) => {
    setShortDescription(textEditorValue);
  };
  const onBodyHandlerAward = (textEditorValue) => {
    setAwardsAchievements(textEditorValue);
  };
  const onChangeWorkHistory = (textEditorValue) => {
    setWorkHistory(textEditorValue);
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/hrprofessionnal")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}
      {payloadErrorShow && (
        <Modal
          heading={"Image file is too large"}
          onClick={() => setPayloadErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}> Add Hr Professional</div>
            <Link
              to="/BiDashboard/hrprofessionnal"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>

            <div className={classes.input}>

              <EventInputs
                input={"SingleLineInput"}
                label={"Full Name"}
                placeholder={"Full Name"}
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Location"}
                placeholder={"Location"}
                onChange={(e) => setLocation(e.target.value)}
                value={location}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Current Company"}
                placeholder={"Current Company"}
                onChange={(e) => setCurrentCompany(e.target.value)}
                value={currentCompany}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Designation"}
                placeholder={"Designation"}
                onChange={(e) => setDesignation(e.target.value)}
                value={designation}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Total Experience"}
                placeholder={"Total Experience"}
                onChange={(e) => setTotalExperience(e.target.value)}
                value={totalExperience}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Years in current organization"}
                placeholder={"Years in current organization"}
                onChange={(e) => setYrsCurrentOrg(e.target.value)}
                value={yrsCurrentOrg}
              />


              <div className={classes.AddHrProfessional_Description}>
                Short Description
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setShortDescription(e);
                    }}
                    value={shortDescription}
                    setContents={shortDescription}
                  /> */}
                <div>
                  <TextEditor
                    onChangeContent={setShortDescription}
                    value={shortDescription}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Short Description</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyHandler} value={shortDescription}/>
              </div> */}
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Area of Expertise"}
                placeholder={"area of Expertise"}
                onChange={(e) => setAreaOfExpertise(e.target.value)}
                value={areaOfExpertise}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Area of Expertise
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setAreaOfExpertise(e);
                    }}
                    value={areaOfExpertise}
                    setContents={areaOfExpertise}
                  /> */}
                <div>
                  <TextEditor
                    onChangeContent={setAreaOfExpertise}
                    value={areaOfExpertise}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Area of Expertise</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyChangeHandler} value={areaOfExpertise}/>
              </div> */}
              <div className={classes.AddHrProfessional_Description}>
                Featured Media
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                <TextEditor
                  onChangeContent={setFeaturedMedia}
                  value={featuredMedia}
                />
              </div>
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Featured Media"}
                placeholder={"Featured Meadia"}
                onChange={(e) => setFeaturedMedia(e.target.value)}
                value={featuredMedia}
              /> */}
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Position"}
                placeholder={"Position"}
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Profile Picture "}
                placeholder={"profile Picture"}
                onChange={(e) => setProfilePicture(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
              <EventInputs
                input={"smalldropdown"}
                label={"Category"}
                placeholder={"Category"}
                onChange={(e) => setCategory(e.target.value)}
                displayData={HrCategory}
                value={category}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Twitter"}
                placeholder={"Twitter"}
                onChange={(e) => setConnectToTwitter(e.target.value)}
                value={connectToTwitter}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Website"}
                placeholder={"Website"}
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />
              <EventInputs
                input={"DateInput"}
                label={"Start Date"}
                placeholder={"Start Date"}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <EventInputs
                input={"DateInput"}
                label={"End Date"}
                placeholder={"End Date"}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Awards Achievements"}
                placeholder={"Awards Achievements"}
                onChange={(e) => setAwardsAchievements(e.target.value)}
                value={awardsAchievements}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Awards Achievements
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setAwardsAchievements(e);
                    }}
                    value={awardsAchievements}
                    setContents={awardsAchievements}
                  /> */}
                <div>
                  <TextEditor
                    onChangeContent={setAwardsAchievements}
                    value={awardsAchievements}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Awards Achievements</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyHandlerAward} value={awardsAchievements}/>
              </div> */}

              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Work History"}
                placeholder={"Work History"}
                onChange={(e) => setWorkHistory(e.target.value)}
                value={workHistory}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Work History
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setWorkHistory(e);
                    }}
                    value={workHistory}
                    setContents={workHistory}
                  /> */}
                <div>
                  <TextEditor
                    onChangeContent={setWorkHistory}
                    value={workHistory}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Work History</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onChangeWorkHistory} value={workHistory}/>
              </div> */}
            </div>

            <div className={classes.multipleImageContainer}>
              <div className={classes.formGroup}>
                <label htmlFor="image"> Multiple Image : </label>
                <input
                  multiple
                  type="file"
                  // required
                  accept=".png, .jpg, .jpeg"
                  name="image"
                  onChange={(e) => {
                    multipleImagesUpload(e);
                  }}
                />
              </div>
              <div className={classes.gridContainer}>
                {showImages.map((item, index) => (
                  <div className={classes.ImageUrlContainer}>
                    <img
                      src={item.ImageURL}
                      alt="hello"
                      className={classes.MultipleImgSelect}
                    />
                    {/* <AiOutlineCloseCircle
                      className={classes.RemoveImgIcon}
                      onClick={() => RemoveMultipleImage(item.id)}
                    /> */}
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(item.ImageURL);
                        alert("Copied");
                      }}
                      className={classes.CopyURLIcon}
                    >
                      <AiFillCopy size={22} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.checkbox1}>
            <EventInputs
              input={"CheckBox"}
              label={"Featured HR"}
              checked={isFeatured}
              // placeholder={"Years in current organization"}
              onChange={() => setIsFeatured(!isFeatured === true ? 1 : 0)}
            />
          </div>

          <div className={classes.checkbox}>
            <EventInputs
              input={"CheckBox"}
              onChange={() => setCheck(!check === true ? 1 : 0)}
              label={"Want to publish"}
              value={check}
            />
          </div>

        </div>
        <button className={classes.Upload_button} onClick={() => NewHr()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default AddHrProfessional;
