import React, { useState, useEffect } from "react";

import classes from "./wecaremoplist.module.css";
import { CSVLink } from "react-csv";

import { Link } from "react-router-dom";

import ExcelDownload from "../Articles/ExcelDownload";
import LoadingSpinner from "../LoadingSpinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import MOPJourneyModel from "../MOPJourneyModel";
import EventInputs from "../Events/EventInputs";
import Modal from "../Modal";
const WeCareMopList = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [wecaremop, setWecaremop] = useState([]);
  const [registerallApi, setRegisterallApi] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [iscreatedate, setIscreatedate] = useState("ASC");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelData, setModelData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    WeCareMopListApi();
    RegisterallApi();
  }, [currentPage]);

  const WeCareMopListApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/WecareMOPList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setWecaremop(result.data);
        setCount(result.datacount);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const RegisterallApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/WecareMOPListwithoutpage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRegisterallApi(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const weCareMopApproveFunc = (uuid, approveStatus) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WecareMOPRequestID: uuid,
      IsApproved: approveStatus == "1" ? "0" : "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/ApproveWecareMOPList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code == 200) {
          setAlertMessage(
            approveStatus == "1"
              ? "Data disapproved successfully"
              : "Data approved successfully"
          );
          setModalShow(true);
          WeCareMopListApi();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  // console.log(startDate, "Start Date");
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 30);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "WeCareMopFormList",
  };

  const sortingdatecreate = (col) => {
    if (iscreatedate === "ASC") {
      const create = [...wecaremop].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setWecaremop(create);
      setIscreatedate("DSC");
    }
    if (iscreatedate === "DSC") {
      const create = [...wecaremop].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setWecaremop(create);
      setIscreatedate("ASC");
    }
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <>
      <MOPJourneyModel
        isModalOpen={isModalOpen}
        data={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
      />{" "}
      {modalShow && (
        <Modal
          heading={alertMessage}
          onClick={() => {
            setModalShow(false);
          }}
        />
      )}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          {" "}
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.events_title}>We Care Mop Form List</div>

        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={registerallApi}
              filename={"WeCareMopFormList.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload
              csvData={registerallApi}
              fileName="WeCareMopFormList"
            />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? WeCareMopListApi() || RegisterallApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.count}>Count :</div>
          <div className={classes.count_main}>{count}</div>
        </div>
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{wecaremop.length > 20 ? <></> : currentPage}</span>
          {wecaremop.length < 20 ? (
            <></>
          ) : (
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
              className={classes.buttonone}
            >
              Next
            </button>
          )}
        </div>
        {isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.container}>
              {wecaremop.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>ID</th>
                    <th className={classes.table_heading}>Type</th>
                    <th className={classes.table_heading}>Name</th>
                    <th className={classes.table_heading}>Email</th>

                    <th className={classes.table_heading}>Mobile</th>

                    <th className={classes.table_heading}>ShortDescription</th>

                    <th className={classes.table_heading}>
                      Created date
                      {iscreatedate === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingdatecreate("Created date")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingdatecreate("Created date")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>Updated date</th>
                    <th className={classes.table_heading}>Approve</th>
                    <th className={classes.table_heading}>View Detail</th>
                  </tr>

                  <tbody>
                    {wecaremop.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.ID}</td>
                          <td className={classes.table_td_type}>{item.Type}</td>
                          <td className={classes.table_td}>{item.Name}</td>
                          <td className={classes.table_td}>{item.Email}</td>
                          <td className={classes.table_td}>{item.Mobile}</td>
                          <td className={classes.table_td}>
                            {item.ShortDescription.slice(0, 40)}...
                          </td>

                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.UpdatedAt.slice(0, 10)}
                          </td>
                          <td
                            className={classes.table_td}
                            style={{ width: "150px" }}
                          >
                            <EventInputs
                              input={"CheckBox"}
                              disabled={item.IsApproved == 1 ? true : false}
                              onChange={() =>
                                weCareMopApproveFunc(
                                  item.WecareMOPRequestID,
                                  item.IsApproved
                                )
                              }
                              label={
                                item.IsApproved == 1
                                  ? "Approved"
                                  : "Not Approved"
                              }
                              value={item.IsApproved == 1 ? true : false}
                            />
                          </td>
                          {/* <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/WeCareMopFormDetail/" +
                              item.WecareMOPRequestID
                            }
                          >
                            View
                          </Link>
                        </td> */}
                          <td
                            className={classes.table_td}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModelData(item);
                              setIsModalOpen(true);
                            }}
                          >
                            <span className={classes.table_td_Link}>
                              <BsEye />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WeCareMopList;
