import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./eventregister.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";

const EventRegisterList = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [registerApi, setRegisterApi] = useState([]);
  const [registerallApi, setRegisterallApi] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  //   const [isdate, setIsdate] = useState("ASC");
  //   const [issno, setIssno] = useState("ASC");
  const [isdate, setIsdate] = useState("ASC");
  const [iscreatedate, setIscreatedate] = useState("ASC");
  useEffect(() => {
    RegisterApi();
    RegisterallApi();
  }, [currentPage]);

  const RegisterApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EventRegistrationList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRegisterApi(result.data);
        setCount(result.datacount);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const RegisterallApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EventRegistrationListWithoutPage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRegisterallApi(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  // console.log(startDate, "Start Date");
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 15);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const sortingdate = (col) => {
    if (isdate === "ASC") {
      const sorteddate = [...registerApi].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRegisterApi(sorteddate);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddate = [...registerApi].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRegisterApi(sorteddate);
      setIsdate("ASC");
    }
  };
  const sortingdatecreate = (col) => {
    if (iscreatedate === "ASC") {
      const create = [...registerApi].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRegisterApi(create);
      setIscreatedate("DSC");
    }
    if (iscreatedate === "DSC") {
      const create = [...registerApi].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRegisterApi(create);
      setIscreatedate("ASC");
    }
  };
  const pageInfo = {
    currentPageName: "Event Register List",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Event Register List</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={registerallApi}
            filename={"Register_All_List.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={registerallApi}
            fileName="Register_All_List"
          />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || date}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={
                endDate ||
                new Date()
                  .toLocaleDateString("in", {
                    day: "2-digit",
                    year: "numeric",
                    month: "2-digit",
                  })
                  .split("/")
                  .reverse()
                  .join("-")
              }
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() =>
                formValidation()
                  ? RegisterApi() || RegisterallApi()
                  : console.log("Something Wrong")
              }
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{registerApi.length > 20 ? <></> : currentPage}</span>
        {registerApi.length < 20 ? (
          <></>
        ) : (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {registerApi.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>FullName</th>
                  <th className={classes.table_heading}>Event Id</th>
                  <th className={classes.table_heading}>Event Name</th>

                  <th className={classes.table_heading}>Mobile</th>
                  <th className={classes.table_heading}>Email</th>
                  <th className={classes.table_heading}>Location</th>
                  <th className={classes.table_heading}>Work Experience</th>
                  <th className={classes.table_heading}>Qualification</th>
                  <th className={classes.table_heading}>
                    Created date
                    {iscreatedate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdatecreate("Created date")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdatecreate("Created date")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Updated date
                    {isdate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Updated date")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Updated date")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>View Detail</th>
                </tr>

                <tbody>
                  {registerApi.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.fullName}</td>
                        <td className={classes.table_td}>{item.events}</td>
                        <td className={classes.table_td}>{item.eventNames}</td>

                        <td className={classes.table_td}>{item.phone}</td>
                        <td className={classes.table_td}>{item.email}</td>
                        <td className={classes.table_td}>{item.location}</td>
                        <td className={classes.table_td}>
                          {item.workExperience}
                        </td>
                        <td className={classes.table_td}>
                          {item.qualification}
                        </td>
                        <td className={classes.table_td}>
                          {item.created_at.slice(0, 10)}
                        </td>
                        <td className={classes.table_td}>
                          {item.updated_at.slice(0, 10)}
                        </td>
                        <td className={classes.table_td}>
                          <Link
                            to={"/BiDashboard/EventRegisterDetail/" + item.id}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventRegisterList;
