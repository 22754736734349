import React, { useState } from "react";

import classes from "../GuestArticle/addguest.module.css";
import EventInputs from "../../Components/Events/EventInputs";
import Modal from "../../Components/Modal";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";

const AddFeaturedHR = () => {
    const [hrprofessionnalId, setHrprofessionalId] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageName, setPageName] = useState("");
    const [erorrshow, setErrorShow] = useState(false);
    const [internalErrorShow, setInternalErrorShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    let history = useHistory();

    const AddGuestApi = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            HrProfessionalId: hrprofessionnalId,
            PageName: pageName,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            global.apiLink + "/adminapi/AddPagewiseHR",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "SUCCESS" && result.status_code === 200) {
                    setModalShow(true);
                } else if (result.status_code === 400 || result.status_code === 500) {
                    setInternalErrorShow(true);
                } else if (result.status_code === 300) {
                    setErrorShow(true);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const pageList = [
        { type: "LeadersOfChange" },
        { type: "Blogs" },
        { type: "Home" },
        { type: "Global" },
        { type: "ForHer" },
        { type: "GMI" },
        { type: "HrConclave" },
        { type: "SHEro" },
        { type: "LeadersConclave" },
        { type: "HRProfessionals" },
        { type: "ActOfCompassion" },
        { type: "Events" }
    ]
    const pageInfo = {
        currentPageName: "Add Pagewise Webstory",
        previousPageName: "Pagewise Webstory List",
        previousPage: "/BiDashboard/PageWiseWebstory",
    };
    const currentURL = window.location.href;

    return (
        <div className={classes.top_div}>
            {modalShow && (
                <Modal
                    heading={"Data inserted successfully"}
                    onClick={() => history.push("/BiDashboard/PageWiseFeaturedHR")}
                />
            )}
            {erorrshow && (
                <Modal
                    heading={"Required Fields Are Missing"}
                    onClick={() => setErrorShow(false)}
                />
            )}
            {internalErrorShow && (
                <Modal
                    heading={"Internal server error"}
                    onClick={() => setInternalErrorShow(false)}
                />
            )}

            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.TimsbiBreadcrumb}>
                        {" "}
                        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
                    </div>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Add PageWise Webstory</div>
                    </div>
                    <div className={classes.form_input}>
                        <div className={classes.input}>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Hr Professional Id"}
                                placeholder={"Enter Hr Professional Id"}
                                onChange={(e) => setHrprofessionalId(e.target.value)}
                                value={hrprofessionnalId}
                            />
                        </div>
                        <EventInputs
                            input={"smalldropdown"}
                            label={"Page Name"}
                            placeholder={"Page Name"}
                            onChange={(e) => setPageName(e.target.value)}
                            displayData={pageList}
                            value={pageName}
                        />
                    </div>
                </div>
                <button className={classes.Upload_button} onClick={() => AddGuestApi()}>
                    {loading ? (
                        <Loader
                            type="TailSpin"
                            color="white"
                            height={20}
                            width={50}
                            className="text-center"
                        />
                    ) : (
                        "Add"
                    )}
                </button>
            </div>
        </div>
    );
};

export default AddFeaturedHR;
