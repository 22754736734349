import React, { useEffect, useState } from "react";

import classes from "../Movement Of Professional/movementedit.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import TextEditor from "../TextEditor";
const MovementEdit = () => {
  const [loading, setLoading] = useState("");
  const [name, setName] = useState("");

  const [organisation, setOrganistion] = useState("");

  const [designation, setDesignation] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [featuredMedia, setFeaturedMedia] = useState("");
  const [website, setWebsite] = useState("");

  const [facebook, setFacebook] = useState("");
  const [email, setEmail] = useState("");
  const [insta, setInsta] = useState("");
  const [profilepic, setProfilepic] = useState("");
  const [twitter, setTwitter] = useState("");
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [newimage, setNewimage] = useState("");
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    getDetailApi();
  }, []);

  const getDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      MovementProfessionalID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/MevementOfProfessionDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setName(result.data.Name);

        setOrganistion(result.data.OrganizationName);

        setEmail(result.data.Email);
        setTwitter(result.data.Twitter);

        setInsta(result.data.Instagram);
        setProfilepic(result.data.ProfilePhoto);
        setFacebook(result.data.Facebook);
        setWebsite(result.data.Website);
        setDesignation(result.data.Designation);
        setFeaturedMedia(result.data.FeaturedMedia);
        setOtherInfo(result.data.OtherInfo);
        // setCreateDate(result.data.CreatedAt);
        // setUpdateDate(result.data.UpdatedAt);

        // setAuthor();
      })
      .catch((error) => console.log("error", error));
  };

  const EditApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Name", name);
    formdata.append("ProfilePhoto", newimage);
    formdata.append("OrganizationName", organisation);
    formdata.append("Designation", designation);
    formdata.append("OtherInfo", otherInfo);
    formdata.append(
      "FeaturedMedia",
      featuredMedia === "" ? null : featuredMedia
    );
    formdata.append("Website", website === "" ? null : website);
    formdata.append("Twitter", twitter === "" ? null : twitter);
    formdata.append("Instagram", insta === "" ? null : insta);
    formdata.append("Facebook", facebook === "" ? null : facebook);
    formdata.append("Email", email === "" ? null : email);
    formdata.append("IsLive", check);
    formdata.append("MovementProfessionalID", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EditMovementOfProfessional",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Update Movement Of Professional",
    previousPageName: "Movement Of Professional List",
    previousPage: "/BiDashboard/WeCareList",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/MovementList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>
              Update Movement Of Professional
            </div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Organisation Name"}
                placeholder={"Organisation Name"}
                onChange={(e) => setOrganistion(e.target.value)}
                value={organisation}
              />

              {/* <EventInputs
                input={"SingleLineInput"}
                label={"Other Info"}
                placeholder={"Other Info"}
                onChange={(e) => setOtherInfo(e.target.value)}
                value={otherInfo}
              /> */}
              <EventInputs
                input={"SingleLineInput"}
                label={"Website"}
                placeholder={"Website"}
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Instagram"}
                placeholder={"Instagram"}
                onChange={(e) => setInsta(e.target.value)}
                value={insta}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Profile Pic"}
                placeholder={"Profile Pic"}
                onChange={(e) => setNewimage(e.target.files[0])}
              />
              {newimage ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(newimage)}
                  className={classes.imgicon}
                />
              ) : (
                <img
                  src={profilepic}
                  alt="movement"
                  className={classes.imgicon}
                />
              )}
              <div className={classes.AddHrProfessional_Description}>
                Other Information
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setShortDescription(e);
                    }}
                    value={shortDescription}
                    setContents={shortDescription}
                  /> */}
                <div>
                  <TextEditor
                    onChangeContent={setOtherInfo}
                    value={otherInfo}
                  />
                </div>
              </div>
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"email"}
                label={"Email"}
                placeholder={"Email"}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Designation"}
                placeholder={"Designation"}
                onChange={(e) => setDesignation(e.target.value)}
                value={designation}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Facebook"}
                placeholder={"Facebook"}
                onChange={(e) => setFacebook(e.target.value)}
                value={facebook}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Twitter"}
                placeholder={"Twitter"}
                onChange={(e) => setTwitter(e.target.value)}
                value={twitter}
              />
              <div className={classes.AddHrProfessional_Description}>
                Featured Media
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                <TextEditor
                  onChangeContent={setFeaturedMedia}
                  value={featuredMedia}
                />
              </div>
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => EditApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default MovementEdit;
