import React from "react";
import classes from "./QuestionModal.module.css";

const QuestionModal = (props) => {
    if (!props.isModalOpen) {
        return null;
    }

    return (
        <>
            <div className={classes.overlay} onClick={props.onClose}></div>
            <div className={classes.ModalStyle}>
                <div className={classes.eventdetail_container}>
                    <div className={classes.eventdetail_main}>
                        <div className={classes.event_detail}>
                            <div className={classes.question_section}>
                                <div className={classes.table_th} style={{ fontWeight: '700' }}>Question:</div>
                                <div className={classes.table_td}>{props.data.Question}</div>

                                <div className={classes.table_th} style={{ fontWeight: '700', marginTop: '10px' }}>Options:</div>
                                <ul className={classes.options_list}>
                                    <li className={classes.table_td}>Option 1: {props.data.Option1}</li>
                                    <li className={classes.table_td}>Option 2: {props.data.Option2}</li>
                                    <li className={classes.table_td}>Option 3: {props.data.Option3}</li>
                                    <li className={classes.table_td}>Option 4: {props.data.Option4}</li>
                                </ul>

                                <div className={classes.table_th} style={{ fontWeight: '700', marginTop: '10px' }}>Correct Answer:</div>
                                <div className={classes.table_td}>{props.data.CorrectAnswer}</div>

                                <div className={classes.table_th} style={{ fontWeight: '700', marginTop: '10px' }}>Explanation:</div>
                                <div className={classes.table_td}>{props.data.Explanation}</div>

                                <div className={classes.table_th} style={{ fontWeight: '700', marginTop: '10px' }}>Category:</div>
                                <div className={classes.table_td}>{props.data.QACategoryName}</div>
                            </div>
                        </div>

                        <div className={classes.BackButton}>
                            <button onClick={props.onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionModal;