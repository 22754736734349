import React, { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import classes from "./OrdersTable.module.css";
import Loader from "react-loader-spinner";

import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
// import ExcelDownload from "../Components/Articles/ExcelDownload";

const OrdersTable = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [passLoader, setPassLoader] = useState();
  const [orderLoading, setOrderLoading] = useState([]);
  const [orderRejectLoading, setOrderRejectLoading] = useState([]);

  const [isdate, setIsdate] = useState("ASC");
  const [issub, setIsSub] = useState("ASC");
  const [mobile, setMobile] = useState("ASC");
  const [isemail, setIsemail] = useState("ASC");
  const [isrec, setIsrec] = useState("ASC");
  const [isamount, setIsamount] = useState("ASC");
  useEffect(() => {
    GetOrderDetail();
  }, []);

  const GetOrderDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/v1/api/apiTimes/GetOrderDetail\n", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setLoading(false);
          console.log("Api success");
          console.log(result.data);
          setData(result.data);
        } else {
          alert("Api not working");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const DeleteOrderLoading = (id) => {
    let index = orderLoading.indexOf(id);
    setOrderLoading(orderLoading.splice(index, 1));
  };

  const approveApi = (id) => {
    setOrderLoading([...orderLoading, id]);
    setPassLoader(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/sapOrderConfirmation",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        GetOrderDetail();
      })

      .catch((error) => console.log("error", error))
      .finally(() => {
        DeleteOrderLoading(id);
      });
  };

  const RejectApi = (id) => {
    setOrderRejectLoading([...orderRejectLoading, id]);
    setPassLoader(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/v1/api/apiTimes/sapOrderRejection", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        GetOrderDetail();
      })

      .catch((error) => console.log("error", error));
    // .finally(() => { DeleteOrderLoading(id) })
  };

  var data_filter_Success = data.filter(
    (val) => val.paymentStatus === "SUCCESS"
  );
  var data_filter_Pending = data.filter(
    (val) => val.paymentStatus === "pending"
  );
  var data_filter_Cancel = data.filter(
    (val) => val.paymentStatus === "ABORTED"
  );

  const sortingsub = (col) => {
    if (issub === "ASC") {
      const sorteddate = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sorteddate);
      setIsSub("DSC");
    }
    if (issub === "DSC") {
      const sorteddate = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sorteddate);
      setIsSub("ASC");
    }
  };

  const sortingrecru = (col) => {
    if (isrec === "ASC") {
      const sortedrec = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedrec);
      setIsrec("DSC");
    }
    if (isrec === "DSC") {
      const sortedrec = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedrec);
      setIsrec("ASC");
    }
  };
  const sortingName = (col) => {
    if (isdate === "ASC") {
      const sorteddata = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sorteddata);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddata = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sorteddata);
      setIsdate("ASC");
    }
  };
  const sortingemail = (col) => {
    if (isemail === "ASC") {
      const sortedemail = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedemail);
      setIsemail("DSC");
    }
    if (isemail === "DSC") {
      const sortedemail = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedemail);
      setIsemail("ASC");
    }
  };

  const sortedTotal = (col) => {
    if (isamount === "ASC") {
      const sortedamount = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedamount);
      setIsamount("DSC");
    }
    if (isamount === "DSC") {
      const sortedamount = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedamount);
      setIsamount("ASC");
    }
  };
  const sortingmobile = (col) => {
    if (mobile === "ASC") {
      const sortedmobile = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedmobile);
      setMobile("DSC");
    }
    if (mobile === "DSC") {
      const sortedmobile = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedmobile);
      setMobile("ASC");
    }
  };
  const userDetails = (type) => {
    switch (type) {
      case "Success":
        return isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.container}>
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Id</th>
                  <th className={classes.table_heading}>Order Id</th>
                  <th className={classes.table_heading}>
                    Subscription Id
                    {issub === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsub("subscriptionId")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsub("subscriptionId")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Recruiter Id
                    {isrec === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingrecru("recruiterId")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingrecru("recruiterId")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Transaction Id</th>
                  <th className={classes.table_heading}>Transaction status</th>
                  <th className={classes.table_heading}>Payment status</th>
                  <th className={classes.table_heading}>Start date</th>
                  <th className={classes.table_heading}> End date</th>
                  <th className={classes.table_heading}>
                    Reacruiter name
                    {isdate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingName("name")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingName("name")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Email
                    {isemail === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Mobile
                    {mobile === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Package name</th>
                  <th className={classes.table_heading}>
                    Total Amount{" "}
                    {isamount === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortedTotal("totalAmount")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortedTotal("totalAmount")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}> Approve</th>
                  <th className={classes.table_heading}> Refund / Cancel</th>
                </tr>
                <tbody>
                  {data_filter_Success.map((item, value) => {
                    return isLoading ? (
                      <div className={classes.spinner}>
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.id}</td>
                        <td className={classes.table_td}>{item.orderId}</td>
                        <td className={classes.table_td}>
                          {item.subscriptionId}
                        </td>
                        <td className={classes.table_td}>{item.recruiterId}</td>
                        <td className={classes.table_td}>
                          {item.transactionId}
                        </td>
                        <td className={classes.table_td}>
                          {item.transactionStatus}
                        </td>
                        <td className={classes.table_td}>
                          {item.paymentStatus}
                        </td>
                        <td className={classes.table_td}>{item.startDate}</td>
                        <td className={classes.table_td}>{item.endDate}</td>
                        <td className={classes.table_td}>{item.name}</td>
                        <td className={classes.table_td}>{item.email}</td>
                        <td className={classes.table_td}>{item.mobile}</td>
                        <td className={classes.table_td}>{item.packageName}</td>
                        <td className={classes.table_td}>{item.totalAmount}</td>
                        <td className={classes.table_td}>
                          {item.adminApproval.length < 3 ? (
                            <button onClick={() => approveApi(item.orderId)}>
                              {orderLoading.indexOf(item.orderId) !== -1 ? (
                                <Loader
                                  type="TailSpin"
                                  color="red"
                                  height={15}
                                  width={50}
                                />
                              ) : (
                                <> Approve</>
                              )}
                            </button>
                          ) : item.adminApproval === "rejected" ? (
                            <div>Rejeggcted</div>
                          ) : (
                            <div>Approved</div>
                          )}
                        </td>
                        <td className={classes.table_td}>
                          {item.adminApproval.length < 3 ||
                          item.adminApproval === "approved" ? (
                            <button onClick={() => RejectApi(item.orderId)}>
                              {orderRejectLoading.indexOf(item.orderId) !==
                              -1 ? (
                                <Loader
                                  type="TailSpin"
                                  color="red"
                                  height={15}
                                  width={50}
                                />
                              ) : (
                                <> Reject</>
                              )}
                            </button>
                          ) : (
                            <div>Rejectedddd</div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );

      case "Pending":
        return isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Id </th>
                <th className={classes.table_heading}>Order Id</th>
                <th className={classes.table_heading}>
                  Subscription Id
                  {issub === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingsub("subscriptionId")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingsub("subscriptionId")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Recruiter Id{" "}
                  {isrec === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingrecru("recruiterId")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingrecru("recruiterId")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Transaction Id</th>
                <th className={classes.table_heading}>Transaction status</th>
                <th className={classes.table_heading}>Payment swtatus</th>
                <th className={classes.table_heading}>Start date</th>
                <th className={classes.table_heading}>End date</th>
                <th className={classes.table_heading}>Reacruiter name</th>
                <th className={classes.table_heading}>
                  Email
                  {isemail === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingemail("email")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingemail("email")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Mobile
                  {mobile === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingmobile("mobile")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingmobile("mobile")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Package name</th>
                <th className={classes.table_heading}>
                  T Amount{" "}
                  {isamount === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortedTotal("totalAmount")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortedTotal("totalAmount")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
              </tr>
              <tbody>
                {data_filter_Pending.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.id}</td>
                      <td className={classes.table_td}>{item.orderId}</td>
                      <td className={classes.table_td}>
                        {item.subscriptionId}
                      </td>
                      <td className={classes.table_td}>{item.recruiterId}</td>
                      <td className={classes.table_td}>{item.transactionId}</td>
                      <td className={classes.table_td}>
                        {item.transactionStatus}
                      </td>
                      <td className={classes.table_td}>{item.paymentStatus}</td>
                      <td className={classes.table_td}>{item.startDate}</td>
                      <td className={classes.table_td}>{item.endDate}</td>
                      <td className={classes.table_td}>{item.name}</td>
                      <td className={classes.table_td}>{item.email}</td>
                      <td className={classes.table_td}>{item.mobile}</td>
                      <td className={classes.table_td}>{item.packageName}</td>
                      <td className={classes.table_td}>{item.totalAmount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );

      case "Cancel":
        return isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Id </th>
                <th className={classes.table_heading}>Order Id</th>
                <th className={classes.table_heading}>
                  Subscription Id
                  {issub === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingsub("subscriptionId")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingsub("subscriptionId")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Recruiter Id{" "}
                  {isrec === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingrecru("recruiterId")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingrecru("recruiterId")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Transaction Id</th>
                <th className={classes.table_heading}>Transaction status</th>
                <th className={classes.table_heading}>Payment swtatus</th>
                <th className={classes.table_heading}>Start date</th>
                <th className={classes.table_heading}>End date</th>
                <th className={classes.table_heading}>Reacruiter name</th>
                <th className={classes.table_heading}>
                  Email
                  {isemail === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingemail("email")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingemail("email")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>
                  Mobile
                  {mobile === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingmobile("mobile")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortingmobile("mobile")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
                <th className={classes.table_heading}>Package name</th>
                <th className={classes.table_heading}>
                  T Amount{" "}
                  {isamount === "ASC" ? (
                    <span
                      className={classes.arrow}
                      onClick={() => sortedTotal("totalAmount")}
                    >
                      <AiOutlineArrowDown />
                    </span>
                  ) : (
                    <span
                      className={classes.arrow}
                      onClick={() => sortedTotal("totalAmount")}
                    >
                      <AiOutlineArrowUp />
                    </span>
                  )}
                </th>
              </tr>
              <tbody>
                {data_filter_Cancel.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.id}</td>
                      <td className={classes.table_td}>{item.orderId}</td>
                      <td className={classes.table_td}>
                        {item.subscriptionId}
                      </td>
                      <td className={classes.table_td}>{item.recruiterId}</td>
                      <td className={classes.table_td}>{item.transactionId}</td>
                      <td className={classes.table_td}>
                        {item.transactionStatus}
                      </td>
                      <td className={classes.table_td}>{item.paymentStatus}</td>
                      <td className={classes.table_td}>{item.startDate}</td>
                      <td className={classes.table_td}>{item.endDate}</td>
                      <td className={classes.table_td}>{item.name}</td>
                      <td className={classes.table_td}>{item.email}</td>
                      <td className={classes.table_td}>{item.mobile}</td>
                      <td className={classes.table_td}>{item.packageName}</td>
                      <td className={classes.table_td}>{item.totalAmount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      default:
        console.log("invalid cases");
    }
  };
  return <div>{userDetails(props.userType)}</div>;
};

export default OrdersTable;
