import React, { useState, useEffect, useRef } from "react";
import classes from "./Podcasts.module.css";
import { BsEye } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AiOutlineArrowUp, AiOutlineConsoleSql } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";

const Podcasts = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const doc = new jsPDF();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const perPage = 20;

  const tableRef = useRef();

  useEffect(() => {
    PodcastList();
  }, [currentPage]);

  const history = useHistory();

  const handleNewArticle = () => {
    history.push("/BiDashboard/newPodcast");
  };

  const PodcastList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: perPage,
      StartDate: date.startDate ? date.startDate : "",
      EndDate: date.endDate ? date.endDate : "",
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/PodcastGetList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.Data);
          setCount(result.Count);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {/* <div className={classes.header}>Podcasts</div> */}

        <div className={classes.header}>Podcast List:</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink data={data} filename={"podcast-table.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="podcast-table" />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={date.startDate}
                onChange={(e) =>
                  setDate({ ...date, startDate: e.target.value })
                }
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={date.endDate}
                onChange={(e) => setDate({ ...date, endDate: e.target.value })}
              />
            </div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() => PodcastList()}
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        <div className={classes.search_container}>
          <button className={classes.button} onClick={handleNewArticle}>
            New Podcasts
          </button>
        </div>
        <div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <thead>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                    <th className={classes.table_heading}>Image</th>
                    <th className={classes.table_heading}>Title</th>
                    <th className={classes.table_heading}>PodcastURL</th>
                    <th className={classes.table_heading}>Status</th>
                    <th className={classes.table_heading}>Provider</th>
                    <th className={classes.table_heading}>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className={classes.table_row}>
                      <td className={classes.table_td}>{item.SrNo}</td>
                      <td className={classes.table_td}>
                        <div>
                          <img
                            src={item.Thumbnail}
                            className={classes.imageShow}
                          />
                        </div>
                      </td>
                      <td className={classes.table_td}>{item.PodcastTitle}</td>
                      <td className={classes.table_td}>{item.PodcastURL}</td>
                      <td className={classes.table_td}>
                        {item.IsLive == "1" ? "Live" : "Not Live"}
                      </td>
                      <td className={classes.table_td}>{item.Provider}</td>
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/editPodcast/" + item.PodcastUUID}
                          className={classes.articleLink}
                        >
                          <FiEdit size={20} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sr. No.</th>
                  <th className={classes.table_heading}>
                    Image
                  </th>
                  <th className={classes.table_heading}>
                    Title
                  </th>
                  <th className={classes.table_heading}>
                    PodcastURL
                  </th>
                  <th className={classes.table_heading}>
                    Status
                  </th>
                  <th className={classes.table_heading}>
                    Provider
                  </th>
                  <th className={classes.table_heading}>Edit</th>
                </tr>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className={classes.table_row}>
                      <td className={classes.table_td}>{item.SrNo}</td>
                      <td className={classes.table_td}>
                        <div>
                          <img src={item.Thumbnail} className={classes.imageShow} />
                        </div>
                      </td>
                      <td className={classes.table_td}>{item.PodcastTitle}</td>
                      <td className={classes.table_td}>{item.PodcastURL}</td>
                      <td className={classes.table_td}>{item.IsLive == "1" ? "Live" : "Not Live"}</td>
                      <td className={classes.table_td}>{item.Provider}</td>
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/editPodcast/" + item.PodcastUUID}
                          className={classes.articleLink}
                        >
                          <FiEdit size={20} />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
              <div>
                {count > 20 && (
                  <Paginate
                    maxPage={count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                  />
                )}
              </div>
            </>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Podcasts;
