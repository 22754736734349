import React, { useState } from "react";

import classes from "../Webstory/addwebstory.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import { useEffect } from "react";

const WebstoryDeatilUpdate = () => {
  const [headingAnimation, setHeadingAnimation] = useState("");
  const [headingDuration, setHeadingDuration] = useState("");
  const [headingDelay, setHeadingDelay] = useState("");
  const [descriptionAnimation, setDescriptionAnimation] = useState("");
  const [descriptionDuration, setDescriptionDuration] = useState("");
  const [descriptionDelay, setDescriptionDelay] = useState("");

  const [loading, setLoading] = useState("");
  const [title, setTitle] = useState("");
  const [alt, setAlt] = useState("");
  const [url, setUrl] = useState("");
  const [headtext, setHeadtext] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [newimage, setNewimage] = useState("");
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const UpdateWebstoryDetail = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("WebStoriesDetailID", id);
    formdata.append("TitleType", title);
    formdata.append("DescriptionType", description);

    formdata.append("DImage", image);
    formdata.append("AltTag", "");
    formdata.append("url", url);
    formdata.append("headtext", headtext);

    formdata.append("AnimationTitle", headingAnimation);
    formdata.append("AnimationTitleDuration", headingDuration);
    formdata.append("AnimationTitleDelay", headingDelay);
    formdata.append("AnimationDescription", descriptionAnimation);
    formdata.append("AnimationDescritptionDuration", descriptionDuration);
    formdata.append("AnimationDescriptionDelay", descriptionDelay);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/UpdateWebstoriesDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ViewApi();
  }, []);

  const ViewApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WebStoriesDetailID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/GetOneWebStoriesDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setHeadingAnimation(result.data.AnimationTitle);
        setHeadingDuration(result.data.AnimationTitleDuration);
        setHeadingDelay(result.data.AnimationTitleDelay);
        setDescriptionAnimation(result.data.AnimationDescription);
        setDescriptionDuration(result.data.AnimationDescritptionDuration);
        setDescriptionDelay(result.data.AnimationDescriptionDelay);

        setNewimage(result.data.DImage);
        setDescription(result.data.DescriptionType);
        setTitle(result.data.TitleType);
        setUrl(result.data.url);
        setHeadtext(result.data.headtext);
      })
      .catch((error) => console.log("error", error));
  };

  const headingAnimationData = [
    {
      name: "drop",
      id: 1,
    },
    {
      name: "fade-in",
      id: 2,
    },
    {
      name: "fly-in-bottom",
      id: 3,
    },
    {
      name: "fly-in-left",
      id: 4,
    },
    {
      name: "fly-in-right",
      id: 5,
    },
    {
      name: "fly-in-top",
      id: 6,
    },
    {
      name: "pulse",
      id: 7,
    },
    {
      name: "rotate-in-left",
      id: 8,
    },
    {
      name: "rotate-in-right",
      id: 9,
    },
    {
      name: "twirl-in",
      id: 10,
    },
    {
      name: "whoosh-in-left",
      id: 11,
    },
    {
      name: "whoosh-in-right",
      id: 12,
    },
    {
      name: "pan-left",
      id: 13,
    },
    {
      name: "pan-right",
      id: 14,
    },
    {
      name: "pan-down",
      id: 15,
    },
    {
      name: "pan-up",
      id: 4,
    },
    {
      name: "zoom-in",
      id: 16,
    },
    {
      name: "zoom-out",
      id: 17,
    },
  ];
  const descriptionAnimationData = [
    {
      name: "drop",
      id: 1,
    },
    {
      name: "fade-in",
      id: 2,
    },
    {
      name: "fly-in-bottom",
      id: 3,
    },
    {
      name: "fly-in-left",
      id: 4,
    },
    {
      name: "fly-in-right",
      id: 5,
    },
    {
      name: "fly-in-top",
      id: 6,
    },
    {
      name: "pulse",
      id: 7,
    },
    {
      name: "rotate-in-left",
      id: 8,
    },
    {
      name: "rotate-in-right",
      id: 9,
    },
    {
      name: "twirl-in",
      id: 10,
    },
    {
      name: "whoosh-in-left",
      id: 11,
    },
    {
      name: "whoosh-in-right",
      id: 12,
    },
    {
      name: "pan-left",
      id: 13,
    },
    {
      name: "pan-right",
      id: 14,
    },
    {
      name: "pan-down",
      id: 15,
    },
    {
      name: "pan-up",
      id: 4,
    },
    {
      name: "zoom-in",
      id: 16,
    },
    {
      name: "zoom-out",
      id: 17,
    },
  ];
  const headingDurationData = [
    {
      name: "1",
      id: 1,
    },
    {
      name: "2",
      id: 2,
    },
    {
      name: "3",
      id: 3,
    },
    {
      name: "4",
      id: 4,
    },
    {
      name: "5",
      id: 5,
    },
    {
      name: "6",
      id: 6,
    },
    {
      name: "7",
      id: 7,
    },
    {
      name: "8",
      id: 8,
    },
    {
      name: "9",
      id: 9,
    },
    {
      name: "10",
      id: 10,
    },
  ];
  const headingDelayData = [
    {
      name: ".1",
      id: 1,
    },
    {
      name: ".2",
      id: 2,
    },
    {
      name: ".3",
      id: 3,
    },
    {
      name: ".4",
      id: 4,
    },
    {
      name: ".5",
      id: 5,
    },
    {
      name: ".6",
      id: 6,
    },
    {
      name: ".7",
      id: 7,
    },
    {
      name: ".8",
      id: 8,
    },
    {
      name: ".9",
      id: 9,
    },
    {
      name: "1",
      id: 10,
    },
  ];
  const discriptionDurationData = [
    {
      name: "1",
      id: 1,
    },
    {
      name: "2",
      id: 2,
    },
    {
      name: "3",
      id: 3,
    },
    {
      name: "4",
      id: 4,
    },
    {
      name: "5",
      id: 5,
    },
    {
      name: "6",
      id: 6,
    },
    {
      name: "7",
      id: 7,
    },
    {
      name: "8",
      id: 8,
    },
    {
      name: "9",
      id: 9,
    },
    {
      name: "10",
      id: 10,
    },
  ];
  const descriptioDelayData = [
    {
      name: ".1",
      id: 1,
    },
    {
      name: ".2",
      id: 2,
    },
    {
      name: ".3",
      id: 3,
    },
    {
      name: ".4",
      id: 4,
    },
    {
      name: ".5",
      id: 5,
    },
    {
      name: ".6",
      id: 6,
    },
    {
      name: ".7",
      id: 7,
    },
    {
      name: ".8",
      id: 8,
    },
    {
      name: ".9",
      id: 9,
    },
    {
      name: "1",
      id: 10,
    },
  ];

  const handleAnimationChange = (e) => {
    setHeadingAnimation(e.target.value);
  };

  const handleDurationChange = (e) => {
    setHeadingDuration(e.target.value);
  };

  const handleheadingDelayData = (e) => {
    setHeadingDelay(e.target.value);
  };

  const handleDescriptionAnimation = (e) => {
    setDescriptionAnimation(e.target.value);
  };

  const handleDescriptionDuration = (e) => {
    setDescriptionDuration(e.target.value);
  };

  const handleDescriptionDelay = (e) => {
    setDescriptionDelay(e.target.value);
  };
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/webstorylist")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            {/* <TimesBreadCrumbs pageInfo={pageInfo} />{" "} */}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update Webstory Detail</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Id"}
                placeholder={"Id"}
                value={id}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              {/* <EventInputs
                input={"ImageInput"}
                label={"Card Image"}
                placeholder={"Card Image"}
                onChange={(e) => setImage(e.target.files[0])}
              /> */}
              <EventInputs
                input={"SingleLineInput"}
                label={"Heading Last"}
                placeholder={"Heading Last"}
                onChange={(e) => setHeadtext(e.target.value)}
                value={headtext}
              />{" "}
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Title"}
                placeholder={"Title"}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />{" "}
              <EventInputs
                input={"SingleLineInput"}
                label={"Page URL"}
                placeholder={"URL"}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Card Image"}
                placeholder={"Card Image"}
                onChange={(e) => setImage(e.target.files[0])}
              />
              {image ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(image)}
                  className={classes.imgicon}
                />
              ) : (
                <img src={newimage} alt="icon" className={classes.imgicon} />
              )}
              {/* <EventInputs
                input={"SingleLineInput"}
                label={"Image Alt Tag"}
                placeholder={"Image Alt Tag"}
                onChange={(e) => setAlt(e.target.value)}
                value={alt}
              /> */}
            </div>

            <div className={classes.dropdown}>
              <label htmlFor="titleAnimation">Title Animation:</label>
              <select
                className={classes.drop}
                id="titleAnimation"
                onChange={handleAnimationChange}
                value={headingAnimation}
              >
                <option value="">Choose One</option>

                {headingAnimationData.map((animation) => (
                  <option key={animation.id} value={animation.name}>
                    {animation.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={classes.dropdown}>
              <label htmlFor="DelayAnimation">Title Delay Animation:</label>
              <select
                className={classes.drop}
                id="DelayAnimation"
                onChange={handleheadingDelayData}
                value={headingDelay}
              >
                <option value="">Choose One</option>

                {headingDelayData.map((DelayData) => (
                  <option key={DelayData.id} value={DelayData.name}>
                    {DelayData.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={classes.dropdown}>
              <label htmlFor="DescriptiondurationAnimation">
                Description duration Animation
              </label>
              <select
                className={classes.drop}
                id="DescriptiondurationAnimation"
                onChange={handleDescriptionDuration}
                value={descriptionDuration}
              >
                <option value="">Choose One</option>

                {discriptionDurationData.map((DurationData) => (
                  <option key={DurationData.id} value={DurationData.name}>
                    {DurationData.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={classes.dropdown}>
              <label htmlFor="TitleDurationAnimation">
                Title Duration Animation:
              </label>
              <select
                className={classes.drop}
                id="TitleDurationAnimation"
                onChange={handleDurationChange}
                value={headingDuration}
              >
                <option value="">Choose One</option>

                {headingDurationData.map((duration) => (
                  <option key={duration.id} value={duration.name}>
                    {duration.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={classes.dropdown}>
              <label htmlFor="DescriptionAnimation">
                Description Animation:
              </label>
              <select
                className={classes.drop}
                id="DescriptionAnimation"
                onChange={handleDescriptionAnimation}
                value={descriptionAnimation}
              >
                <option value="">Choose One</option>

                {descriptionAnimationData.map((AnimationData) => (
                  <option key={AnimationData.id} value={AnimationData.name}>
                    {AnimationData.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={classes.dropdown}>
              <label htmlFor="DescriptionDelayAnimation">
                Description Delay Animation:
              </label>
              <select
                className={classes.drop}
                id="DescriptionDelayAnimation"
                onChange={handleDescriptionDelay}
                value={descriptionDelay}
              >
                <option value="">Choose One</option>

                {descriptioDelayData.map((DelayData) => (
                  <option key={DelayData.id} value={DelayData.name}>
                    {DelayData.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <button
          className={classes.Upload_button}
          onClick={() => UpdateWebstoryDetail()}
        >
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default WebstoryDeatilUpdate;
