import React, { useState, useEffect } from "react";

import classes from "./FeaturedCompaniesDetails.module.css";
import { Link, useParams } from "react-router-dom";
import BackButton from "../BackButton";

const FeaturedCompaniesDetails = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    viewCourse();
  }, []);
  console.log(data);

  const viewCourse = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/Get-Featured-Company", requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        //   console.log(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  // console.log("id:" + id, data.course_category)
  return (
    <div className={classes.eventdetail_container}>
      {/* {data.map((item) => ( */}
      <div className={classes.eventdetail_main}>
        <div className={classes.event_detail}>
          <div className={classes.images_div}>
            <div className={classes.table_th}>Image:</div>
            <img
              src={data.image}
              alt=""
              className={classes.course_image}
            />

          </div>
          <div className={classes.event_text}>
            <table>
              <tbody>
                <tr>
                  <th className={classes.table_th}>Name :</th>
                  <td className={classes.table_td}>{data.name}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Industry :</th>
                  <td className={classes.table_td}>{data.industry} </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Phone :</th>
                  <td className={classes.table_td}>{data.phone}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Email :</th>
                  <td className={classes.table_td}>{data.email}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Website :</th>
                  <td className={classes.table_td}>{data.website}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Address :</th>
                  <td className={classes.table_td}>{data.address}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Location :</th>
                  <td className={classes.table_td}>{data.location}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>User Profile:</th>
                  <td className={classes.table_td}>{data.userProfile} </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Government Company:</th>
                  <td className={classes.table_td}>{data.governmentCompany}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Company Id :</th>
                  <td className={classes.table_td}>{data.company_id}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <div className={classes.detail_bottom}>
          <h4>Company Description:</h4>
          <p className={classes.description}>
            {data.companyDescription}
          </p>
        </div>
        <Link to="/BiDashboard/featuredcompanies" className={classes.BackButton}>
          <BackButton />
        </Link>
      </div>

      {/* ))
            } */}
    </div>
  );
};

export default FeaturedCompaniesDetails;
