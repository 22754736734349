import React, { useState, useEffect } from "react";

import classes from "../PostanPoll/createpoll.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";

const CreatePoll = () => {
  const [question, setQuestion] = useState("");
  const [optionone, setOptionone] = useState("");
  const [optiontwo, setOptiontwo] = useState("");
  const [optionthree, setOptionthree] = useState("");
  const [optionfour, setOptionfour] = useState("");
  const [optionfive, setOptionfive] = useState("");

  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  console.log(check);

  const CreatePollApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      question: question,
      status: "0",
      isCurrent: check,
      option1: optionone,
      option2: optiontwo,
      option3: optionthree,
      option4: optionfour,
      option5: optionfive,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/CreateNewOpnionPoll",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => setModalShow(false)}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Create A Poll</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Question"}
                placeholder={"Question"}
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Option Two"}
                placeholder={"Optione Two"}
                onChange={(e) => setOptiontwo(e.target.value)}
                value={optiontwo}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Option Four"}
                placeholder={"Optione Four"}
                onChange={(e) => setOptionfour(e.target.value)}
                value={optionfour}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Option One"}
                placeholder={"Optione One"}
                onChange={(e) => setOptionone(e.target.value)}
                value={optionone}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Option Three"}
                placeholder={"Optione Three"}
                onChange={(e) => setOptionthree(e.target.value)}
                value={optionthree}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Option Five"}
                placeholder={"Optione Five"}
                onChange={(e) => setOptionfive(e.target.value)}
                value={optionfive}
              />
            </div>
          </div>
        </div>
        <button
          className={classes.Upload_button}
          onClick={() => CreatePollApi()}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CreatePoll;
