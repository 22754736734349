import React, { useState, useEffect } from "react";
import classes from "../Mailer/actofcompassion.module.css";

import { BsEye } from "react-icons/bs";
import LoadingSpinner from "../Components/LoadingSpinner";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";

// import Modal from "../Components/Modal";
const Eventregistered = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [actofcompassion, setActOfCompassion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
 
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ActList();
  }, [currentPage]);
 

  const ActList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/adminapi/GetMailerStats`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setActOfCompassion(result.data.eventregistered_count);
        console.log(result.data.mailer_data, "rhrghrhdfghg")
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Register List",
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <>
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}></div>

        <div className={classes.table_container}>
          <div className={classes.buttons1}>
            <div>
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                className={classes.buttonone}
              >
                Prev
              </button>

              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </div>
          </div>
          <div className={classes.container}>
            {isLoading ? (
              <div className={classes.spinner}>
                <LoadingSpinner />{" "}
              </div>
            ) : actofcompassion.length ? (
              <>
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                    <th className={classes.table_heading}>Event Id</th>
                    <th className={classes.table_heading}>Event Name</th>
                    <th className={classes.table_heading}>Registered User Count</th>
                    </tr>


                  <tbody>
                    {actofcompassion.map((item, i) => {

                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{i + 1}</td>
                          <td className={classes.table_td}>
                            {item.EventId}
                          </td>
                          <td className={classes.table_td}>
                            {item.eventName}
                          </td>
                          <td className={classes.table_td}>
                            {item.RegisteredUserCount}
                          </td>
                         

                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h2 className={classes.serachErrorText}>"No Data found !"</h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Eventregistered;
