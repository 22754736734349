import React, { useState, useEffect } from 'react';
import { FaApple, FaLaptop, FaUserAlt, FaPowerOff, FaArrowUp } from 'react-icons/fa';
import { DiAndroid } from 'react-icons/di'
import Card from './Card';
import DashboardHeader from './DashboardHeader';
import classes from './Users.module.css'
import LoadingSpinner from '../LoadingSpinner';
import { Link } from 'react-router-dom';

const Users = (props) => {
    const [showUsers, setShowUsers] = useState(false);
  

    return (

        <div>
            {!showUsers ?
                <DashboardHeader onClick={() => setShowUsers(true)} heading={"Users"} />
                :

                <div className={classes.dashboard_users_container}>
                    <div className={classes.dashboard_users_open}>
                        <button onClick={() => setShowUsers(false)} className={classes.users_button}><h3>Users</h3></button>
                        <button onClick={() => setShowUsers(false)}>  <FaArrowUp /></button>
                    </div>
                   
                            <div className={classes.table_container}>
                                <h3 className={classes.dashboard_title}>Today's Data</h3>
                                <div className={classes.card}>
                                   
                                                <Card UserProfile={"signup"} value={props.apidata.dailysignup} />
                                                <Card UserProfile={"Login"} value={props.apidata.dailylogin} />
                                                <Link to="/BiDashboard/RecruiterSignupDet" className={classes.LinkCard}><Card UserProfile={"Recruiter Signup"} value={props.apidata.dailyrsignup} /></Link>
                                                <Card UserProfile={"Recruiter Login"} value={props.apidata.dailyrlogin} />
                                                

                                         
                                </div>
                                <div className={classes.card}>
                                    
                                                <Card UserProfile={"Resume Upload"} value={props.apidata.totalRUploadT} />
                                     
                                </div>

                            </div>
                            <div className={classes.table_container}>
                                <h3 className={classes.dashboard_title}>Monthly Data</h3>
                                <div className={classes.card}>
                                    
                                                <Card UserProfile={"Signup"} value={props.apidata.monthlysignup} />
                                                <Card UserProfile={"Login"} value={props.apidata.monthlylogin} />
                                                <Link to="/BiDashboard/RecruiterSignupMonthlty" className={classes.LinkCard}><Card UserProfile={"Recruiter Signup"} value={props.apidata.monthlyrsignup} /></Link>
                                                <Card UserProfile={"Recruiter Login"} value={props.apidata.monthlyrlogin} />
                                       
                                </div>
                                <div className={classes.card}>
                                    
                                                <Card UserProfile={"Resume Upload"} value={props.apidata.totalRUploadM} />
                                       
                                </div>
                            </div>
                            <div className={classes.table_container}>
                                <h3 className={classes.dashboard_title}>Signup Via</h3>
                                <table className={classes.dashborad_table}>
                                    <tr className={classes.table_row}>
                                        <th className={classes.table_heading}><FaLaptop /></th>
                                        <th className={classes.table_heading}><DiAndroid /></th>
                                        <th className={classes.table_heading}>< FaApple /></th>
                                    </tr>
                                    <tr className={classes.table_row}>
                                        <td className={classes.table_td}>N/A</td>
                                        <td className={classes.table_td}>N/A</td>
                                        <td className={classes.table_td}>N/A</td>
                                    </tr>
                                </table>
                            </div>
                        
                    
                </div>
            }
        </div>
    )
}

export default Users
