import React from 'react'
import OrdersHeader from '../Components/Orders/OrdersHeader';
import classes from './Orders.module.css'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import OrdersTable from '../Components/Orders/OrdersTable';

const Orders = () => {
    return (
        <Router>
            <div className={classes.dashboard_container}>
                <OrdersHeader />
                <Switch>
                    <Route path="/Pending">
                        <OrdersTable  userType={"Pending"} />
                    </Route>
                    <Route path="/Cancel">
                        <OrdersTable  userType={"Cancel"} />
                    </Route>
                    <Route path="/">
                        <OrdersTable  userType={"Success"} />
                    </Route>
                </Switch>
            </div>
        </Router>
    )
}

export default Orders
