import Loader from "react-loader-spinner";


const AdminLoader = () =>{

    return(
    <Loader
    type="Puff"
    color="#a050db"
    height={60}
    width={60}
    visible={true}
   //3 secs
  />
    )
}

export default AdminLoader;