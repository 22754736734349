import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import CreateLocationIndustry from './CreateLocationIndustry';
import EditCompanyModal from './EditCompanyModal';
import classes from './header.module.css'
import ShowCompanyAndJobModal from './ShowCompanyAndJobModal';
const BiHeader = () => {
    const [showCompanyModal, setShowCompanyModal] = useState(false);
    const [showCreateJobModal, setShowCreateJobModal] = useState();
    const [locationList, setLocationList] = useState([]);
    const [jobFunctionList, setJobFunctionList] = useState([]);
    const [levelList, setLevelList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [industryList, setIndustryList] = useState([])
    const [loading, setLoading] = useState([])
    const { pathname } = useLocation();

    const selectionDetailsApi = (data) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            global.apiLink + "/adminapi/selectionDetails",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setLevelList(result.leveldata);
                setJobFunctionList(result.jobFunctiondata);
                setLocationList(result.locationdata);
                setCompanyList(result.companydata);
                setIndustryList(result.industrydata);
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (showCompanyModal) {
            selectionDetailsApi()
        }
    }, [showCompanyModal])
    if (pathname.includes("/printAd")) {
        return (
            <div>

                {/* <div className={classes.header}>
                <img src="/assets/times_logo.png" height={"30"} />
            </div> */}
                <ul className={classes.listHeader}>
                    <li>
                        <Link to="/printAd/fetchAds" className={pathname.includes("fetchAds") ? classes.active : ""}>
                            Fetch Ads
                        </Link>
                    </li>
                    <li>
                        <Link to="/printAd/inbox" className={pathname.includes("inbox") ? classes.active : ""}>
                            Inbox
                        </Link>
                    </li>

                    <li>
                        <Link to="/printAd/QCList" className={pathname.includes("QCList") ? classes.active : ""}>
                            Q.C. List
                        </Link>
                    </li>
                    <li>
                        <Link to="/printAd/draft" className={pathname.includes("draft") ? classes.active : ""}>
                            Drafts
                        </Link>
                    </li>
                    <li>
                        <Link to="/printAd/junkList" className={pathname.includes("junkList") ? classes.active : ""}>
                            Junk list
                        </Link>
                    </li>
                    {sessionStorage.getItem("level") !== "ADSDB" ? <></> : <>
                        <li>
                            <Link to="/printAd/liveJobs" className={pathname.includes("liveJobs") ? classes.active : ""}>
                                Live jobs
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={(e) => { e.preventDefault(); setShowCompanyModal(true) }} className={pathname.includes("RecruiterDetails") ? classes.active : ""}>
                                Create Company
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={(e) => { e.preventDefault(); setShowCreateJobModal(true) }} >
                                Create Industry/Job Function
                            </Link>
                        </li>
                    </>
                    }

                    {showCompanyModal && <EditCompanyModal createCompany={true} loading={loading} show={showCompanyModal} setShow={setShowCompanyModal} industryList={industryList} locationList={locationList} jobFunctionList={jobFunctionList} levelList={levelList} companyList={companyList} />
                    }
                    {showCreateJobModal && <CreateLocationIndustry loading={loading} show={showCreateJobModal} setShow={setShowCreateJobModal} industryList={industryList} locationList={locationList} jobFunctionList={jobFunctionList} levelList={levelList} companyList={companyList} />
                    }
                </ul>
            </div>
        )
    }
    else if (pathname.includes("/NonAscentJobs")) {
        return (
            <div>

                {/* <div className={classes.header}>
                <img src="/assets/times_logo.png" height={"30"} />
            </div> */}
                <ul className={classes.listHeader}>
                    <li>
                        <Link to="/NonAscentJobs/adsList" className={pathname.includes("adsList") ? classes.active : ""}>
                            Ads list
                        </Link>
                    </li>

                    <li>
                        <Link to="/NonAscentJobs/liveJobs" className={pathname.includes("liveJobs") ? classes.active : ""}>
                            Live jobs
                        </Link>
                    </li>
                    <li>
                        <Link to="/NonAscentJobs/addNewJobs" onClick={(e) => { e.preventDefault(); setShowCompanyModal(true) }} className={pathname.includes("addNewJobs") ? classes.active : ""}>
                            Add new jobs
                        </Link>
                    </li>


                    {/* <li>
                    <Link to="/" onClick={(e) => { e.preventDefault(); setShowCompanyModal(true) }} className={pathname.includes("RecruiterDetails") ? classes.active : ""}>
                        Create Company
                    </Link>
                </li>
                 */}
                    {showCompanyModal && <ShowCompanyAndJobModal createJob={true} createCompany={true} loading={loading} show={showCompanyModal} setShow={setShowCompanyModal} industryList={industryList} locationList={locationList} jobFunctionList={jobFunctionList} levelList={levelList} companyList={companyList} />
                    }

                </ul>
            </div>
        )
    }
    else if (pathname.includes("/postAJob")) {
        return (
            <div>

                {/* <div className={classes.header}>
                <img src="/assets/times_logo.png" height={"30"} />
            </div> */}
                <ul className={classes.listHeader}>
                    <li>
                        <Link to="/postAJob/adsList" className={pathname.includes("adsList") ? classes.active : ""}>
                            Ads list
                        </Link>
                    </li>
                    <li>
                        <Link to="/postAJob/liveJobs" className={pathname.includes("liveJobs") ? classes.active : ""}>
                            Live jobs
                        </Link>
                    </li>



                    {/* <li>
                    <Link to="/" onClick={(e) => { e.preventDefault(); setShowCompanyModal(true) }} className={pathname.includes("RecruiterDetails") ? classes.active : ""}>
                        Create Company
                    </Link>
                </li>
                {showCompanyModal && <EditCompanyModal createCompany={true} loading={loading} show={showCompanyModal} setShow={setShowCompanyModal} industryList={industryList} locationList={locationList} jobFunctionList={jobFunctionList} levelList={levelList} companyList={companyList} />
                } */}
                </ul>
            </div>
        )
    }
    return <></>

}

export default BiHeader
