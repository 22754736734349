import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import classes from './OrderDetail.module.css'

const OrderDetails = () => {
    const { id } = useParams()

    const [recOrderDet, setRecOrderDet] = useState([])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "RecruiterId": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/GetrecruterDetails", requestOptions)
            .then(response => response.json())
            .then((result) => {
                setRecOrderDet(result.orderDetails)
            })
            .catch(error => console.log('error', error));
    }, [id])

    return (
        <div className={classes.mainDiv}>
            <table className={classes.tableMain}>
                <tr className={classes.headRow}>
                    <th className={classes.tableHeader}>Order Id</th>
                    <th className={classes.tableHeader}>Subscription Id</th>
                    <th className={classes.tableHeader}>Name</th>
                    <th className={classes.tableHeader}>Admin Approval</th>
                    <th className={classes.tableHeader}>Transaction Status</th>
                    <th className={classes.tableHeader}>Payment Status</th>
                    <th className={classes.tableHeader}>Start Date</th>
                    <th className={classes.tableHeader}>End Date</th>
                    <th className={classes.tableHeader}>Email</th>
                    <th className={classes.tableHeader}>Mobile</th>
                    <th className={classes.tableHeader}>Total Amount</th>
                    <th className={classes.tableHeader}>Package Name</th>
                </tr>
                {recOrderDet.map((item, index) => {
                    return <tr>
                        <td className={classes.tableData}>{item.orderId}</td>
                        <td className={classes.tableData}>{item.subscriptionId}</td>
                        <td className={classes.tableData}>{item.name}</td>
                        <td className={classes.tableData}>{item.adminApproval}</td>
                        <td className={classes.tableData}>{item.transactionStatus}</td>
                        <td className={classes.tableData}>{item.paymentStatus}</td>
                        <td className={classes.tableData}>{item.startDate}</td>
                        <td className={classes.tableData}>{item.endDate}</td>
                        <td className={classes.tableData}>{item.email}</td>
                        <td className={classes.tableData}>{item.mobile}</td>
                        <td className={classes.tableData}>{item.totalAmount}</td>
                        <td className={classes.tableData}>{item.packageName}</td>

                    </tr>
                })}
            </table>
        </div>
    )
}

export default OrderDetails
