import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./coursedatapoints.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const CourseDataPoints = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [coursedata, setCoursedata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [entrance, setEntrance] = useState("ASC");
  const [redirectedFrom, setRedirectedFrom] = useState("ASC");
  const [issno, setIssno] = useState("DSC");
  const [isfirstname, setIsfirstname] = useState("ASC");
  useEffect(() => {
    CoursedataApi();
  }, [currentPage]);

  const CoursedataApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getCourse",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCoursedata(result.data);
        setCount(result.totalCount);
        setFirstDate(result.startdate);
        setLastDate(result.endDate);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  console.log(startDate, "Start Date");
  let articleCount = 0;
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };
  const pageInfo = {
    currentPageName: "Course Data Points",
    previousPageName: "DataPoints",
    previousPage: "DataPoints",
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...coursedata].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setCoursedata(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...coursedata].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setCoursedata(sorted);
      setOrder("ASC");
    }
  };
  const sortingentrance = (col) => {
    if (entrance === "ASC") {
      const sortedentrnce = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortedentrnce);
      setEntrance("DSC");
    }
    if (entrance === "DSC") {
      const sortedentrnce = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortedentrnce);
      setEntrance("ASC");
    }
  };
  const sortingredirected = (col) => {
    if (redirectedFrom === "ASC") {
      const sortedredirected = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortedredirected);
      setRedirectedFrom("DSC");
    }
    if (redirectedFrom === "DSC") {
      const sortedredirected = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortedredirected);
      setRedirectedFrom("ASC");
    }
  };
  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortsno);
      setIssno("ASC");
    }
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...coursedata].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCoursedata(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...coursedata].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCoursedata(sortedfirst);
      setIsfirstname("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Course Count Page</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={coursedata}
            filename={"Course_Table.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={coursedata} fileName="Course_Table" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || firstdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || lastdate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() =>
                formValidation()
                  ? CoursedataApi()
                  : console.log("Something Wrong")
              }
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{currentPage}</span>
        {coursedata.length >= 9 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {coursedata.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>
                    Provider Name
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("providerName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("providerName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Page View
                    {order === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sorting("pageview")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Entrance
                    {entrance === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrence")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingentrance("entrence")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Redirected From
                    {redirectedFrom === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingredirected("redirectedFrom")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingredirected("redirectedFrom")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>View</th>
                </tr>

                <tbody>
                  {coursedata.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{++articleCount}</td>

                        <td className={classes.table_td}>
                          {item.providerName}
                        </td>
                        <td className={classes.table_td}>{item.pageview}</td>
                        <td className={classes.table_td}>{item.entrence}</td>
                        <td className={classes.table_td}>
                          {item.redirectedFrom}
                        </td>
                        <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/CourseById/" + item.course_provider
                            }
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDataPoints;
