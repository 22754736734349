import React, { useState, useEffect } from "react";
import classes from "./Events.module.css";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../Components/LoadingSpinner";
import { BsEye, BsPencilSquare } from "react-icons/bs";

const Events = (props) => {

  
  const [data, setData] = useState([]);
  const [count, setcount]= useState([]);
  const [livecount,setlivecount]=useState([]);
  const [unlivecount, setunlivecount]=useState([]);
  const [filteredData,setFilteredData] = useState([])
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    EventList();
  }, []);

  const history = useHistory();
  const AddEventsHandler = () => {
    history.push("/BiDashboard/addevents");
  };
  const EventList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/all-event", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setcount(result.count);
        setFilteredData(result.data)
        setlivecount(result.live_count);
        setunlivecount(result.unlive_count);
        console.log(result.data);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  }

  const filterdata = (isPublished)=>{
    
    if (isPublished=="1") {
      const filtereddata= data.filter((item)=>{
        if (item.isPublished=="1") {
          return item

          
        }
        
      })
      setFilteredData(filtereddata);
     console.log(filtereddata, "testttttttt");
      

      
    }
  }

  const filterunlive = (isPublished)=>{
    
    if (isPublished=="0") {
      const unlivedata= data.filter((item)=>{
        if (item.isPublished=="0") {
          return item
          
        }
        
      })
      setFilteredData(unlivedata);
    

      
    }
  };

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.table_container}>
        <div className={classes.container}>
          {
            props.accessLevel !== "TIMES" &&
            
            <div div className={classes.button_wrapper}>
              <button
                onClick={AddEventsHandler}
                className={classes.Add_event_button}
              >
                Add Events
              </button>
              
            </div>
            
            
            
          }
          <div className={classes.card}>
          <div>
            <button
                  onClick={() => {
                    filterdata("1");
                  }}
                >
                  Live
                </button>

                <button
                  onClick={() => {
                    filterunlive("0");
                  }}
                >
                  Unlive
                </button>

                <button
                  onClick={() => {
                    EventList();
                  }}
                >
                  All
                </button>
                </div>

               <div className={classes.counts}>
              <div className={classes.count}>Count : {count}</div>
            
         
          
              <div className={classes.livecount}>Live Count : {livecount}</div>
              
           

          
              <div className={classes.unlivecount}>Unlive Count : {unlivecount}</div>
              </div>
              </div>
          
          <h4 className={classes.events_title}>Events</h4>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : (
            <table className={classes.events_table}>
            
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Event Image</th>
                <th className={classes.table_heading}>Event Name</th>
                <th className={classes.table_heading}>Organizer</th>
                <th className={classes.table_heading}>Start Date</th>
                <th className={classes.table_heading}>End Date</th>
                <th className={classes.table_heading}>Status</th>
                <th className={classes.table_heading}>View Details</th>
                {
                  props.accessLevel !== "TIMES" &&
                  <th className={classes.table_heading}>Edit</th>
                }
              </tr>

              <tbody>
                {filteredData.map((item) => (
                  <tr className={classes.table_row}>
                    <td className={classes.table_td}>
                      <img
                        src={item.event_image}
                        className={classes.table_td_image}
                        alt=""
                      />
                    </td>
                    <td className={classes.table_td}>{item.eventName}</td>
                    <td className={classes.table_td}>{item.event_organizer}</td>
                    <td className={classes.table_td}>{item.eventStartDate}</td>
                    <td className={classes.table_td}>{item.eventEndDate}</td>
                    <td className={classes.table_td}>{item.isPublished == 1 ? "Live" : "Not published"}</td>
                    <td className={classes.table_td}>
                      <Link
                        to={"/BiDashboard/eventdetail/" + item.id}
                        className={classes.table_td_Link}
                      >
                        <BsEye />
                      </Link>
                    </td>
                    {
                      props.accessLevel !== "TIMES" &&
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/updateevent/" + item.id}
                          className={classes.table_td_Link}
                        >
                          <BsPencilSquare />
                        </Link>
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div >
  );
};

export default Events;
