import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Pagination from '../Components/Pagination'
import classes from './RecruiterDetails.module.css'
// import AdminLoader from '../Component/AdminLoader'
// import Pagination from '../Component/Pagination'

const RecruiterDetails = () => {

    const [recruitersData, setRecruitersData] = useState([])
    const [currentpage, setCurrentPage] = useState(1);



    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "page": currentpage
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/GetrecruiterList", requestOptions)
            .then(response => response.json())
            .then(result => {
                setRecruitersData(result.data)
            })
            .catch(error => console.log('error', error))
    }, [currentpage])



    const pageChangeHandler = (page) => {
        setCurrentPage(page);
    }


    return (

        <div className={classes.main_div}>

            <table className={classes.recruiter_table}>
                <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Id</th>
                    <th className={classes.table_heading}>Name</th>
                    <th className={classes.table_heading}>Address</th>
                    <th className={classes.table_heading}>Orders</th>
                    <th className={classes.table_heading}>Jobs</th>
                    <th className={classes.table_heading}>Live Jobs</th>
                    <th className={classes.table_heading}>View Details</th>




                </tr>
                {recruitersData.map((item, index) => {
                    return <tbody className={classes.recruiter_table} key={index}>

                        <tr >
                            <td className={classes.table_td}>{item.recruiter}</td>
                            <td className={classes.table_td}>{item.name}</td>
                            <td className={classes.table_add}>{item.address1}</td>
                            <td className={classes.table_td}>{item.numberOfOrder}</td>
                            <td className={classes.table_td}>{item.totaljobs}</td>
                            <td className={classes.table_td}>{item.live_jobs}</td>
                            <td className={classes.table_td}><Link to={"/BiDashboard/Recruiters/" + item.recruiter} className={classes.viewLink}><>View</></Link> </td>


                        </tr>


                    </tbody>
                })}
            </table>

            <Pagination onPageChangeHandler={pageChangeHandler} />
            <div className={classes.linkDiv}><Link to="/BiDashboard/Dashboard" className={classes.backlink}>BACK</Link></div>
        </div>
    )
}

export default RecruiterDetails
