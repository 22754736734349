import React, { useEffect, useState } from "react";
import classes from "./ArticlePreview.module.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const sideData = [
  {
    keyIndex: "158822",
    ArticleId: "158822",
    articleUrl: "http://15.206.210.253:3000/article",
    title: "Demand Under Rural Jobs Scheme Drops Sharply in June",
    shortDescription:
      "More than 34 million individuals sought work in June under the scheme, down 22.5% from a year earlier",
    videoLink: "By Banikinkar P",
    isPickedByEditor: "0",
    isFeaturedArticle: "0",
    readCount: "",
    updatedDate: "3rd Jul, 2024",
    createdDate: "3rd Jul, 2024",
    category: "Jobs",
    subCategory: "Careers & Training",
  },
  {
    keyIndex: "158821",
    ArticleId: "158821",
    articleUrl: "http://15.206.210.253:3000/article",
    title: "India Inc Takes a Greater Liking to Flexible Office Spaces",
    shortDescription:
      "Expanding commercial centres, pent-up demand set to increase companies’ allocation to flexible workspaces to 58% by 2026, finds a report",
    videoLink: "By Sobia Khan",
    isPickedByEditor: "0",
    isFeaturedArticle: "0",
    readCount: "",
    updatedDate: "3rd Jul, 2024",
    createdDate: "3rd Jul, 2024",
    category: "Jobs",
    subCategory: "Corporate Life",
  },
  {
    keyIndex: "158820",
    ArticleId: "158820",
    articleUrl: "http://15.206.210.253:3000/article",
    title: "You Can Future-Proof Your Career With Diverse Skill-Sets",
    shortDescription:
      "The conventional idea of a linear career path is becoming outdated. Automation, AI and globalisation have brought about an era of where entire industries can be disrupted in the blink of an eye. How can you future-proof your career?",
    videoLink:
      "By Rajorshi Ganguli, President and Global HR head, Alkem Laboratories",
    isPickedByEditor: "0",
    isFeaturedArticle: "0",
    readCount: "",
    updatedDate: "3rd Jul, 2024",
    createdDate: "3rd Jul, 2024",
    category: "Career Advice",
    subCategory: "Personal Growth",
  },
];

const ArticlePreview = () => {
  const [post, setPosts] = useState("");
  const [loading, setLoading] = useState("");
  const { id } = useParams();

  const ArticleDetail = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      ArticleId: id,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/GetArticlePreview",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setPosts(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    ArticleDetail();
  }, []);

  return (
    <div className={classes.articleContainer}>
      <nav className={classes.breadcrumbNav} aria-label="Breadcrumb">
        <ol role="list" className={classes.breadcrumbList}>
          <li>
            <div>
              <a
                href="/"
                className={`${classes.breadcrumbHomeLink} ${classes.gray}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className={`${classes.icon} ${classes.gray}`}
                >
                  <path d="M8.543 2.232a.75.75 0 0 0-1.085 0l-5.25 5.5A.75.75 0 0 0 2.75 9H4v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V9h1.25a.75.75 0 0 0 .543-1.268l-5.25-5.5Z" />
                </svg>
              </a>
            </div>
          </li>
          <li>
            <div className={classes.breadcrumbItem}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className={`${classes.icon} ${classes.gray}`}
              >
                <path
                  fill-rule="evenodd"
                  d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
              <a
                href={post.href}
                className={`${classes.breadcrumbLink} ${classes.gray}`}
                aria-current={post.current ? "post" : undefined}
              >
                {post.title}
              </a>
            </div>
          </li>
        </ol>
      </nav>
      <div className={classes.mainContainer}>
        <div className={classes.gridContainer}>
          <div className={classes.gridMain} key={post.ArticleId}>
            <div className={classes.articleHeader}></div>
            <main className={classes.articleMain}>
              <div className={classes.articleContentContainer}>
                <div className={classes.articleContent}>
                  <section aria-labelledby={classes.profileOverviewTitle}>
                    <div className={classes.articleProfileOverview}>
                      <div className={classes.articleDetails}>
                        <div className={classes.articleTitleSection}>
                          <h1 className={classes.articleTitle}>{post.title}</h1>
                          <h2 className={classes.articleShortDescription}>
                            {post.shortDescription}
                          </h2>
                        </div>
                        <div className={classes.articleShare}></div>
                        <div className={classes.articleAuthorInfo}>
                          <a
                            href={`/authorprofile/${post.AutherUUid}`}
                            className={
                              post.AutherUUid
                                ? classes.authorLink
                                : classes.authorLinkDisabled
                            }
                          >
                            <span className={classes.authorName}>
                              {post.author}
                            </span>{" "}
                            | {post.createdDate}
                          </a>
                        </div>
                      </div>
                      <div className={classes.articleContentHtml}>
                        <style>
                          {`
                                                        .${classes.articleContentHtml} img {
                                                            max-width: 100%;
                                                            height: auto;
                                                            display: block;
                                                        }
                                                    `}
                        </style>
                        <div
                          className={classes.articleDetail}
                          dangerouslySetInnerHTML={{ __html: post.content }}
                        />
                      </div>
                    </div>
                    {(post.category === "Finance" ||
                      post.category === "SHEro") && (
                      <div className={classes.articleDisclaimer}>
                        <div className={classes.disclaimerTitle}>
                          Disclaimer
                        </div>
                        <p className={classes.disclaimerText}>
                          BCCL disclaims any and all representation or
                          warranties of any kind - expressed or implied - about
                          the completeness, accuracy, reliability, or
                          availability with respect to the website or the
                          information, products, services, or related graphics
                          contained on the website for any purpose. Views
                          expressed herein are independent opinions. You may act
                          at your own risk while relying on the information
                          available on the website. Should you decide to act, or
                          omit to act, you should seek appropriate professional
                          advice.
                        </p>
                      </div>
                    )}
                  </section>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className={classes.sidebar}>
          <div className={classes.widgetContainer}>
            <div className={classes.widgetHeader}>
              <div
                className={`${classes.imageContainer} ${classes.defaultWidth}`}
              >
                <img
                  src={"https://timesascent.com/mentorIcon/TopResume.webp"}
                  style={{
                    objectFit: "contain",
                    width: "150px",
                    height: "100px",
                  }}
                  fill
                  sizes=""
                  alt="card"
                />
              </div>
            </div>
            <div className={classes.widgetBody}>
              <div className={classes.textContainer}>
                <h3 className={classes.widgetTitle}>
                  Get a Free confidential review from a resume expert
                </h3>
                <p className={classes.widgetDescription}>
                  Upload your resume and get expert resume analysis
                </p>
              </div>
              <a
                href={"https://timesascent.com/top-resume"}
                target="_blank"
                className={classes.widgetButton}
              >
                <span className={classes.buttonText}>Upload Resume</span>
                <span>
                  <svg
                    aria-hidden="true"
                    className={classes.buttonIcon}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
          <div>
            <div className={classes.flowRoot}>
              <ul role="list" className={classes.articleList}>
                {sideData?.map((item, index) => (
                  <div key={index} className={classes.articleItem}>
                    <a
                      href={`/articles/${item.title
                        ?.replace(/[^a-zA-Z0-9]/g, " ")
                        .split(" ")
                        .join("-")
                        .split(" ")
                        .join("-")
                        .split("--")
                        .join("-")
                        .split("---")
                        .join("-")
                        .toLowerCase()}/${item.ArticleId}`}
                      className={classes.cardLink}
                    >
                      <div className={classes.cardContainer}>
                        <div>
                          <p
                            className={`${classes.cardTitle} ${classes.lineClamp2}`}
                          >
                            {item.title}
                          </p>
                          <p
                            className={`${classes.lineClamp5} ${classes.cardDescription}`}
                          >
                            {item.shortDescription}
                          </p>
                        </div>
                        <div className={classes.cardDate}>
                          {item.updatedDate}
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </ul>
            </div>
            <div className={classes.viewAllContainerDesktop}>
              <a
                href={"https://timesascent.com/articleslist/recommended-read"}
                className={classes.viewAllButtonDesktop}
              >
                View All
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePreview;
