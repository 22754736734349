import React from "react";
import classes from "./Modal.module.css";

const CourseModel = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        />
        <h2 style={{ textAlign: "center" }}>
          Form has been submitted successfully
        </h2> */}
        <div className={classes.eventdetail_container}>
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <div className={classes.images_div}>
                <div className={classes.table_th} style={{fontWeight: '700'}}>Course Image:</div>
                <img
                  src={props.data.courseDisplayCard}
                  alt=""
                  className={classes.courseDisplayCard}
                  style={{width: '200px', height: '200px'}}
                ></img>

                <div className={classes.table_th} style={{fontWeight: '700', marginTop: '10px'}}>Provider Logo:</div>
                <img
                  src={props.data.courseLogo}
                  alt=""
                  className={classes.courseLogo}
                  style={{width: '200px', height: '200px'}}
                ></img>
              </div>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                    <tr>
                      <th className={classes.table_th}>Course Title :-</th>
                      <td className={classes.table_td}>{props.data.courseName}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course Type :</th>
                      <td className={classes.table_td}>{props.data.course_type} </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course Category :</th>
                      <td className={classes.table_td}>
                        {props.data.courseCategory}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course Id :</th>
                      <td className={classes.table_td}>{props.data.id}</td>
                    </tr>
                    {/* <tr>
                      <th className={classes.table_th}>Course Source :</th>
                      <td className={classes.table_td}>{props.data.course_source}</td>
                    </tr> */}
                    <tr>
                      <th className={classes.table_th}>Course url :</th>
                      <td className={classes.table_td}>{props.data.redirectionUrl}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course Relevant :</th>
                      <td className={classes.table_td}>
                        {props.data.courseRelevance}
                      </td>
                    </tr>
                    {/* <tr>
                      <th className={classes.table_th}>Certification:</th>
                      <td className={classes.table_td}>
                        {props.data.certification}{" "}
                      </td>
                    </tr> */}
                    <tr>
                      <th className={classes.table_th}>Course Created At:</th>
                      <td className={classes.table_td}>
                        {props.data.createdAt.split("T") ? props.data.createdAt.split("T")[0] : props.data.createdAt}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Course UpdatedAt :</th>
                      <td className={classes.table_td}>
                      {props.data.updateAt.split("T") ? props.data.updateAt.split("T")[0] : props.data.updateAt}
                      </td>
                    </tr>
                    {/* <tr>
                      <th className={classes.table_th}>Industry :</th>
                      <td className={classes.table_td}>{props.data.industry}</td>
                    </tr> */}
                    {/* <tr>
                      <th className={classes.table_th}>Course Group :</th>
                      <td className={classes.table_td}>{props.data.course_group}</td>
                    </tr> */}

                    <tr>
                      <th className={classes.table_th}>Course Duration:</th>
                      <td className={classes.table_td}>
                        {props.data.courseDuration}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Mobile :</th>
                      <td className={classes.table_td}>{props.data.mobile}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Provider Name :</th>
                      <td className={classes.table_td}>
                        {props.data.platformName}
                      </td>
                    </tr>
                    {/* <tr>
                      <th className={classes.table_th}>Start Date :</th>
                      <td className={classes.table_td}>{props.data.startDate}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>End Date :</th>
                      <td className={classes.table_td}>{props.data.endDate}</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={classes.detail_bottom}>
              <h4>Description:</h4>
              <p className={classes.description}>
                <div dangerouslySetInnerHTML={{ __html: props.data.course_desc }} />
              </p>
            </div>
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseModel;
