import React, { useEffect, useState } from 'react';
import classes from "./InterstitialAd.module.css"
const InterstitialAd = () => {
    const [campaignName, setCampaignName] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [status, setStatus] = useState("")
    const [campaignNameCurrent, setCampaignNameCurrent] = useState("")
    const [startDateCurrent, setStartDateCurrent] = useState("")
    const [endDateCurrent, setEndDateCurrent] = useState("")
    const [startTimeCurrent, setStartTimeCurrent] = useState("")
    const [endTimeCurrent, setEndTimeCurrent] = useState("")
    const [statusCurrent, setStatusCurrent] = useState("")
    const [IDCurrent, setIDCurrent] = useState("")
    const [allAdDetails, setAllAdDetails] = useState([])
    console.log(startDate)
    useEffect(() => {
        getallAdDetails()
    }, [])
    const getallAdDetails = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({});

        var requestOptions = {
            body: raw,
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(global.apiLink + "/adminapi/getAdEventDetailofAll", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "SUCCESS") {
                    setAllAdDetails(result.data)
                    let arr = result.data.filter((item) => {
                        if (item.status === "active") {
                            return (item)
                        }
                    })
                    if (arr.length > 0) {
                        setCampaignNameCurrent(arr[0].event)
                        setStatusCurrent(arr[0].status)
                        setStartDateCurrent(arr[0].startDate.slice(0, 10))
                        setEndDateCurrent(arr[0].endDate.slice(0, 10))
                        setStartTimeCurrent(arr[0].startTime)
                        setEndTimeCurrent(arr[0].endTime)
                        setIDCurrent(arr[0].id)
                    }
                }
                else {
                    alert("Job could not be updated")
                }
            }
            )
    }
    const createAd = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "event": campaignName,
            "startDate": startDate,
            "endDate": endDate,
            "startTime": startTime,
            "endTime": endTime,
            "status": "active"
        });

        var requestOptions = {
            body: raw,
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(global.apiLink + "/adminapi/createNewAdevent", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "SUCCESS") {
                    getallAdDetails();
                }
                else {
                    alert("Ad could not be created")
                }
            }
            )


    }
    const updateAd = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "event": campaignNameCurrent,
            "startDate": startDateCurrent,
            "endDate": endDateCurrent,
            "startTime": startTimeCurrent,
            "endTime": endTimeCurrent,
            "id": IDCurrent,
            "status": statusCurrent
        });

        var requestOptions = {
            body: raw,
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(global.apiLink + "/adminapi/updateNewAdEvent", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "SUCCESS") {
                    getallAdDetails();

                }
                else {
                    alert("Ad could not be created")
                }
            }
            )


    }
    // useEffect(() => {
    //     var myHeaders = new Headers();
    //     myHeaders.append(
    //         "Authorization",
    //         "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    //     );
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({});

    //     var requestOptions = {
    //         body: raw,
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow",
    //     };

    //     fetch(global.apiLink + "/v1/api/apiTimes/getAdEventTimes", requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             if (result.status === "SUCCESS") {
    //                 setCampaignNameCurrent(result.data.event)

    //                 setStartDateCurrent(result.data.startDate)
    //                 setEndDateCurrent(result.data.endDate)
    //                 setStartTimeCurrent(result.data.startTime)
    //                 setEndTimeCurrent(result.data.endTime)
    //             }
    //             else {
    //                 alert("Job could not be updated")
    //             }
    //         }
    //         )

    // }, [])
    return <div>
        <div className={classes.header}>
            <h4>Interstitial Ad</h4>
        </div>
        <div>
            <div className={classes.forms}>
                <form className={classes.mainDiv} onSubmit={(e) => { createAd(e) }}>
                    <h5>New ad campaign</h5>
                    <div><h6>Campaign Name</h6><input type="text" onChange={(e) => { setCampaignName(e.target.value) }} value={campaignName} /></div>
                    <div><h6>Start Date</h6><input type="date" onChange={(e) => { setStartDate(e.target.value) }} value={startDate} /></div>
                    <div><h6>End Date</h6><input type="date" onChange={(e) => { setEndDate(e.target.value) }} value={endDate} /></div>
                    <div><h6>Start Time</h6><input type="time" onChange={(e) => { setStartTime(e.target.value) }} value={startTime} /></div>
                    <div><h6>End Time</h6><input type="time" onChange={(e) => { setEndTime(e.target.value) }} value={endTime} /></div>
                    <div><h6>Status</h6><input type="text" onChange={(e) => { setStatus(e.target.value) }} value={status} placeholder='active or inactive' /></div>
                    <div className={classes.submitBtn}><button>Create New Ad</button></div>
                </form>
                <form className={classes.mainDiv} onSubmit={(e) => { updateAd(e) }}>
                    <h5>Current ad Campaign</h5>
                    <div><h6>Campaign Name</h6><input type="text" onChange={(e) => { setCampaignNameCurrent(e.target.value) }} value={campaignNameCurrent} /></div>
                    <div><h6>Start Date</h6><input type="date" onChange={(e) => { setStartDateCurrent(e.target.value) }} value={startDateCurrent} /></div>
                    <div><h6>End Date</h6><input type="date" onChange={(e) => { setEndDateCurrent(e.target.value) }} value={endDateCurrent} /></div>
                    <div><h6>Start Time</h6><input type="time" onChange={(e) => { setStartTimeCurrent(e.target.value) }} value={startTimeCurrent} /></div>
                    <div><h6>End Time</h6><input type="time" onChange={(e) => { setEndTimeCurrent(e.target.value) }} value={endTimeCurrent} /></div>
                    <div><h6>Status</h6><input type="text" onChange={(e) => { setStatusCurrent(e.target.value) }} value={statusCurrent} placeholder='active or inactive' /></div>
                    <div className={classes.submitBtn}><button>Update Current Ad</button></div>
                </form>
            </div>
            <div className={classes.header}>
                <h4>
                    All Ad Details

                </h4><table className={classes.table}>

                    <tr>
                        <th>Sr No</th>
                        <th>Campaign Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                    </tr>
                    {allAdDetails.map((item, key) => {
                        var startDate = new Date(item.startDate);
                        var endDate = new Date(item.endDate);
                        var ddstartDate = String(startDate.getDate()).padStart(2, '0');
                        var mmstartDate = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
                        var yyyystartDate = startDate.getFullYear();
                        startDate = mmstartDate + '/' + ddstartDate + '/' + yyyystartDate;
                        var ddendDate = String(endDate.getDate()).padStart(2, '0');
                        var mmendDate = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
                        var yyyyendDate = endDate.getFullYear();
                        endDate = mmendDate + '/' + ddendDate + '/' + yyyyendDate;
                        return <tr className={classes.allAds}>
                            <td>
                                {key + 1}
                            </td>
                            <td>
                                {item.event}
                            </td>
                            <td>
                                {startDate}
                            </td>
                            <td>
                                {endDate}
                            </td>
                            <td>
                                {item.startTime}
                            </td>
                            <td>
                                {item.endTime}
                            </td>
                            <td>
                                {item.status}
                            </td>
                        </tr>
                    })}
                </table>
            </div>
        </div>
    </div>;
};

export default InterstitialAd;
