import React, { useState, useEffect } from "react";
import { GrImage, GrList, GrZoomIn, GrZoomOut } from "react-icons/gr";
import { AiOutlineCloseCircle, } from "react-icons/ai";
import classes from "../Component/JobAds.module.css";
import AdsEntry from "../Component/AdsEntry";
import Heading from "../Component/Heading";
import DashboardButton from "../Component/DashboardButton";
import { useHistory } from "react-router-dom";
import AdminLoader from "../Component/AdminLoader";




const JobAds = (props) => {
  const [mobile, setMobile] = useState(null);
  const [loading, setLoading] = useState("")
  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState([])
  const [compDesc, setCompDesc] = useState()
  const [selectedLoc, setSelectedLoc] = useState()
  const [address, setAddress] = useState()
  const [existingLoc, setExistingLoc] = useState()
  const [website, setWebsite] = useState()
  const [location, setLocation] = useState([]);
  const [image, setImage] = useState("")
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
  const [selectedCompId, setSelectedCompId] = useState();
  const [preFilledId, setPreFilledId] = useState()
  useEffect(() => {
    selectionDetailsApi();
    getCompanyDetail();
  }, []);


  let history = useHistory();


  const selectionDetailsApi = (data) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/selection-details", requestOptions)
      .then(response => response.json())
      .then(result => {
        setLocation(result.locationdata);
        setCompany(result.companydata)

      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(false));
  }
  console.log(selectedCompId);

  const NumericOnly = (e) => {
    const reg = /^[0-9\b]+$/;
    let preval = e.target.value;
    if (e.target.value === "" || reg.test(e.target.value)) {
      return true;
    } else {
      e.target.value = preval.substring(0, preval.length - 1);
    }
  };
  // save company api
  const saveCompany = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");


    var formdata = new FormData();
    formdata.append("Id", selectedCompId);
    formdata.append("Name", companyName);
    formdata.append("CompanyDescription", compDesc);
    formdata.append("Image", image);
    formdata.append("Website", website);
    formdata.append("Phone", mobile);
    formdata.append("Address", address);
    formdata.append("Location", selectedLoc ? selectedLoc : preFilledId);

    console.log(formdata);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/v1/api/apiTimes/UpdateCompany", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          history.push("/BiDashboard/AdminAdsEntry/" + selectedCompId)

        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (companyName !== "") {
      let val = false;
      setCompanySuggestions(company.filter((val) => {
        if (val.compName === companyName) {
          val = true
        }
        return (val.compName ? val.compName.toLowerCase().startsWith(companyName.toLowerCase()) : null)
      }))
      if (val) {
        setCompanySuggestionsShow(false)

      }
      setCompanySuggestionsShow(true)
    }
    else {
      setCompanySuggestions([])
      setCompanySuggestionsShow(false)
    }
  }, [companyName])


  const getCompanyDetail = (companyId, companyName) => {

    setCompanyName(companyName);
    setCompanySuggestionsShow(false);
    setSelectedCompId(companyId)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "CompanyId": companyId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(global.apiLink + "/v1/api/apiTimes/GetCompanyDetail", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setCompDesc(result.data.companyDescription)
        setAddress(result.data.address)
        setWebsite(result.data.website)
        setMobile(result.data.phone)
        setExistingLoc(result.data.city)
        setPreFilledId(result.data.locid)
      })
      .catch(error => console.log('error', error));
  }


  console.log(selectedLoc);

  console.log(image);

  return (loading ? <div className={classes.loadingLayout}> <AdminLoader /> </div> :
    <div className={classes.jobAds}>

      <div className={classes.jobAdsImageField}>
        <div className={classes.jobAdsIcons}>
          <GrZoomIn className={classes.icon} />
          <GrZoomOut className={classes.icon} />
          <GrImage className={classes.icon} />
          <GrList className={classes.icon} />
          <AiOutlineCloseCircle className={classes.icon} />
        </div>
        <div className={classes.jobAdsImage}>
          <img
            src={image ? URL.createObjectURL(image) : "https://timesascent.com/images/cms/articles/2021/8/13/article_default_large_1628854036974_1628854037021_100_67.png"}
            alt=""
          />
          <AdsEntry input={"File"} onChange={(e) => setImage(e.target.files[0])} name={"image"} />
        </div> :
      </div>



      <div className={classes.JobAdsEntry}>
        <Heading headingtype={"BgHeading"} headingtext={"Company Details"} />

        <div className={classes.adEntry}>
          {/* {text ?<div className={classes.text}>{showText}</div> : null} */}
          <div>



            <AdsEntry input={"SingleLineInput"} name={"Company Name"} label={"Company Name"} onChange={(e) => { setCompanyName(e.target.value) }} value={companyName} />
            <div className={classes.suggestion}>
              {companySuggestionsShow ? <div>
                {
                  companySuggestions.map((val) => {
                    return <p onClick={() => {
                      getCompanyDetail(val.id, val.compName)
                    }}>
                      {val.compName}
                    </p>
                  })
                }
              </div>
                :
                <></>
              }</div>
          </div>

          <AdsEntry input={"MultiLineInput"} label={"Company Description"} onChange={(e) => setCompDesc(e.target.value)} value={compDesc} />
          <AdsEntry input={"Mobile"} label={"Phone"} onChange={(e) => { NumericOnly(e); setMobile(e.target.value) }} value={mobile} />
          {/* <div className={classes.errors}>{mobileError}</div> */}
          <AdsEntry input={"SingleLineInput"} label={"Website"} value={website} onChange={(e) => setWebsite(e.target.value)} />
          <AdsEntry input={"MultiLineInput"} label={"Address"} value={address} onChange={(e) => setAddress(e.target.value)} />
          <AdsEntry input={'smalldropdown'} label={"Location"} displayData={location} onChange={(e) => setSelectedLoc(e.target.value)} defaultLocation={existingLoc} />
          {/* <AdsEntry input={'SingleLineInput'} label={"Advertiser Name"} onChange={(e) => setAdvName(e.target.value)} /> */}
          <div className={classes.buttonDiv} >
            <DashboardButton buttontext={"Save"} onClick={() => saveCompany()} />
          </div>
        </div>

      </div>


    </div>
  );
};

export default JobAds;
