import React, { useState, useEffect } from "react";
import classes from "./studentInformation.module.css";
import LoadingSpinner from "../LoadingSpinner";
import Modal from "../Modal";
import TimesBreadCrumbs from "../TimesBreadCrumbs";

// import Modal from "../Components/Modal";
const ConnectGlobal = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [connectData, setConnectData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modelData, setModelData] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        FetchData();
    }, [currentPage])

    const FetchData = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = ""

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            "https://vb1pzsq0m2.execute-api.ap-southeast-1.amazonaws.com/TA/v1/api/apiTimes/GlobalGraduateConnectwithus",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200) {
                    setConnectData(result.data);
                    setData(result.data);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const pageInfo = {
        currentPageName: "Global Connect Us List",
    };

    const onModalCloseHandler = () => {
        setIsModalOpen(false);
        // history.push("/BiDashboard/articles");
    };

    return (
        <>

            <div className={classes.dashboard_container}>
                <div className={classes.TimsbiBreadcrumb}>
                    <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
                </div>
                <div className={classes.DateFilter_container}>
                </div>

                <div className={classes.table_container}>
                    <div className={classes.buttons1}>
                        <div>

                            <button
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                }}
                                className={classes.buttonone}
                            >
                                Prev
                            </button>



                            <button
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                }}
                                className={classes.buttonone}
                            >
                                Next
                            </button>

                        </div>
                    </div>
                    <div className={classes.container}>
                        {isLoading ? (
                            <div className={classes.spinner}>
                                <LoadingSpinner />{" "}
                            </div>
                        ) : data.length ? (
                            <>

                                <table className={classes.orders_table}>
                                    <tr className={classes.table_row}>
                                        <th className={classes.table_heading}>Sr. No.</th>
                                        <th className={classes.table_heading}>Name</th>
                                        <th className={classes.table_heading}>Email</th>
                                        <th className={classes.table_heading}>Company Name</th>
                                        <th className={classes.table_heading}>Contact No.</th>
                                        <th className={classes.table_heading}>Message</th>
                                    </tr>

                                    <tbody>
                                        {connectData.map((item, i) => {
                                            return (
                                                <tr className={classes.table_row}>
                                                    <td className={classes.table_td}>{item.Id}</td>
                                                    <td className={classes.table_td}>{item.Name}</td>
                                                    <td className={classes.table_td}>{item.Email}</td>
                                                    <td className={classes.table_td}>{item.CompanyName}</td>
                                                    <td className={classes.table_td}>{item.Contact}</td>
                                                    <td className={classes.table_td}>{item.Message}</td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </>
                        ) : (
                            <h2 className={classes.serachErrorText}>"No Data found !"</h2>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

export default ConnectGlobal;


