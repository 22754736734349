import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import Card from './Card';
import DashboardHeader from './DashboardHeader';
import classes from './General.module.css'

const General = (props) => {
    const [showGneral, setShowGeneral] = useState(false);


    return (
        <div>
            {!showGneral ?
                <DashboardHeader onClick={() => setShowGeneral(true)} heading={"General"} />
                :
                <div className={classes.dashboard_general_container}>
                    <div className={classes.dashboard_general_open}>
                        <button onClick={() => setShowGeneral(false)} className={classes.general_button}><h3>General</h3></button>
                        <button onClick={() => setShowGeneral(false)}>  <FaArrowUp /></button>
                    </div>

                    <div className={classes.dashboard_container}>
                        <h3 className={classes.General_title}>Total Data</h3>
                        <div className={classes.card}>

                            <Card UserProfile={"Jobs Live"} value={props.apidata.total} />
                            <Card UserProfile={"Article Live"} value={props.apidata.totalALive} />
                            <Card UserProfile={"Partner Companies"} value={props.apidata.totalPcompany} />
                            <Link to="/BiDashboard/AdvertiseNowDetails" className={classes.LinkCard}> <Card UserProfile={" Advertise Now"} value={props.apidata.advertise} /></Link>

                        </div>
                        <div className={classes.card}>

                            <Link to="/BiDashboard/ContactUsDetails" className={classes.LinkCard}><Card UserProfile={"Contact Us"} value={props.apidata.contact} /></Link>
                            <Card UserProfile={"Resume Upload"} value={props.apidata.totalRUploadG} />

                        </div>
                    </div>


                </div>
            }

        </div>
    )
}

export default General
