import React, { useState, useEffect } from 'react';
import classes from './Dashboard.module.css';
import General from '../Components/Dashboard/General';
import Jobs from '../Components/Dashboard/Jobs';
import Users from '../Components/Dashboard/Users';
import LoadingSpinner from '../Components/LoadingSpinner';
import DashboardHeader from '../Components/Dashboard/DashboardHeader';


const Dashboard = () => {

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [showUsers, setShowUsers] = useState(false);

    useEffect(() => {

        fetchApi();

    }, []);


    const fetchApi = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", " Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = "";

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.onecorp.co.in/adminapi/analytics", requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status_code === 200) {
                    console.log("Api success")
                    setLoading(false)
                    // console.log(result.data)
                    setData(result.data);
                }
                else {
                    alert("Api not working")
                }
            }
            )
            .catch(error => console.log('error', error));
    }

    return (
        <div className={classes.dashboard_container}>
            {isLoading ? (<div className={classes.spinner}>
                <LoadingSpinner />
            </div>)
                :
                (<>
                    {data.map((item, value) => {
                        return (
                            <div className={classes.table_container}>
                                {!showUsers ?
                                    <DashboardHeader onClick={() => setShowUsers(true)} heading={"Users"} />
                                    :
                                    <Users apidata={item} />
                                }
                                <Jobs apidata={item} isLoading={isLoading} />
                                <General apidata={item} />
                            </div>
                        )
                    })}
                </>)
            }
        </div>
    )
}

export default Dashboard
