import React, { useState, useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import classes from "./PartnerComapniesTable.module.css";

const PartnerComapniesTable = (props) => {
  const [dailyData, setDailyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  // const [customData, setCustomData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    PartnerComapnies();
  }, []);

  const PartnerComapnies = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      " Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/v1/api/apiTimes/PartnerCompanies", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setLoading(false);
          console.log("Api success");
          setDailyData(result.datatoday);
          setWeeklyData(result.data7days);
          setMonthlyData(result.datamonth);
          // setCustomData(result.datapartner);
        } else {
          alert("Api not working");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const userDetails = (type) => {
    switch (type) {
      case "daily":
        return isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <table className={classes.partner_companies_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Partner Name</th>
                <th className={classes.table_heading}>Impression</th>
                <th className={classes.table_heading}>Landing Page</th>
                <th className={classes.table_heading}>Clicks on Proceed</th>
                <th className={classes.table_heading}>
                  Clicks on Redirection Window{" "}
                </th>
              </tr>
              <tbody>
                {dailyData.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.company}</td>
                      <td className={classes.table_td}>{item.impression}</td>
                      <td className={classes.table_td}>
                        <a href={item.page} className={classes.redirectionUrl}>
                          {item.page}
                        </a>
                      </td>
                      <td className={classes.table_td}>
                        {item.clickonproceed}
                      </td>
                      <td className={classes.table_td}>
                        <a
                          href={item.redirectionUrl}
                          className={classes.redirectionUrl}
                        >
                          Redirection Window Url
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );

      case "weekly":
        return isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <table className={classes.partner_companies_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Partner Name</th>
                <th className={classes.table_heading}>Impression</th>
                <th className={classes.table_heading}>Landing Page</th>
                <th className={classes.table_heading}>Clicks on Proceed</th>
                <th className={classes.table_heading}>
                  Clicks on Redirection Window{" "}
                </th>
              </tr>
              <tbody>
                {weeklyData.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.company}</td>
                      <td className={classes.table_td}>{item.impression}</td>
                      <td className={classes.table_td}>
                        <a href={item.page} className={classes.redirectionUrl}>
                          {item.page}
                        </a>
                      </td>
                      <td className={classes.table_td}>
                        {item.clickonproceed}
                      </td>
                      <td className={classes.table_td}>
                        <a
                          href={item.redirectionUrl}
                          className={classes.redirectionUrl}
                        >
                          Redirection Window Url
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );

      case "monthly":
        return isLoading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <table className={classes.partner_companies_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Partner Name</th>
                <th className={classes.table_heading}>Impression</th>
                <th className={classes.table_heading}>Landing Page</th>
                <th className={classes.table_heading}>Clicks on Proceed</th>
                <th className={classes.table_heading}>
                  Clicks on Redirection Window{" "}
                </th>
              </tr>
              <tbody>
                {monthlyData.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.company}</td>
                      <td className={classes.table_td}>{item.impression}</td>
                      <td className={classes.table_td}>
                        <a href={item.page} className={classes.redirectionUrl}>
                          {item.page}
                        </a>
                      </td>
                      <td className={classes.table_td}>
                        {item.clickonproceed}
                      </td>
                      <td className={classes.table_td}>
                        <a
                          href={item.redirectionUrl}
                          className={classes.redirectionUrl}
                        >
                          Redirection Window Url
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );

      // case "custom":
      //     return (
      //         isLoading ? <div className={classes.spinner}><LoadingSpinner /></div>
      //             :
      //             <div className={classes.table_container}>
      //                 <div className={classes.custom_date}>
      //                 <div className={classes.custom_date_select}>
      //                     <label >From</label>
      //                     <input type="date" placeholder="Select" className={classes.input}></input>
      //                 </div>
      //                 <div className={classes.custom_date_select}>
      //                     <label >To</label>
      //                     <input type="date" placeholder="Select" className={classes.input}></input>
      //                 </div>
      //                 </div>

      //                 <table className={classes.partner_companies_table}>
      //                     <tr className={classes.table_row}>
      //                         <th className={classes.table_heading}>Partner Name</th>
      //                         <th className={classes.table_heading}>Impression</th>
      //                         <th className={classes.table_heading}>Clicks</th>
      //                     </tr>
      //                     <tbody>
      //                         {customData.map((item, value) => {
      //                             return (
      //                                 <tr className={classes.table_row}>
      //                                     <td className={classes.table_td}>{item.company}</td>
      //                                     <td className={classes.table_td}>23</td>
      //                                     <td className={classes.table_td}>{item.clicks}</td>
      //                                 </tr>
      //                             )
      //                         }
      //                         )}
      //                     </tbody>
      //                 </table>
      //             </div>

      //     );

      default:
        console.log("invalid case");
    }
  };
  return <div>{userDetails(props.userType)}</div>;
};
export default PartnerComapniesTable;
