import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./RecruiterSignupMonthly.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const RecruiterSignupMonthlty = () => {
  const [isLoading, setLoading] = useState(false);
  const [monthly, setMonthly] = useState([]);

  useEffect(() => {
    signUpDetails();
  }, []);

  const signUpDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/recruiter-signup",requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMonthly(result.Monthly);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>Recruiter SignUp Monthly </div>
      <div className={classes.button_container}>
        <CSVLink
          data={monthly}
          filename={"recruitersignup-table.csv"}
          target="_blank"
        >
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <ExcelDownload csvData={monthly} fileName="recruitersignup-table" />
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Id</th>
                <th className={classes.table_heading}>Recruiter Id</th>

                <th className={classes.table_heading}>Name</th>
                <th className={classes.table_heading}>Gender </th>
                <th className={classes.table_heading}>Address </th>
                <th className={classes.table_heading}>City </th>
                <th className={classes.table_heading}>State </th>
                <th className={classes.table_heading}>Pincode </th>
                <th className={classes.table_heading}>Country </th>
                <th className={classes.table_heading}>Email</th>
                <th className={classes.table_heading}>Mobile</th>
                <th className={classes.table_heading}>GSTIN </th>
              </tr>
              <tbody>
                {monthly.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.id}</td>
                      <td className={classes.table_td}>{item.recruiterId}</td>

                      <td className={classes.table_td}>{item.name}</td>
                      <td className={classes.table_td}>{item.gender}</td>
                      <td className={classes.table_td}>
                        {item.address1 + "/" + item.address2}
                      </td>
                      <td className={classes.table_td}>{item.city}</td>
                      <td className={classes.table_td}>{item.state}</td>
                      <td className={classes.table_td}>{item.pincode}</td>
                      <td className={classes.table_td}>{item.country}</td>
                      <td className={classes.table_td}>{item.email}</td>
                      <td className={classes.table_td}>{item.mobile}</td>
                      <td className={classes.table_td}>{item.GSTIN}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecruiterSignupMonthlty;
