import React, { useEffect, useState } from 'react'
import { AdComp } from './FetchAds'
import classes from './FetchAds.module.css'
import { MdError } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import ConfirmModal from '../../Components/ConfirmModal';

const FetchAdsInbox = () => {
    const [loading, setLoading] = useState(false);
    const [fetchAdsData, setFetchAdsData] = useState([])
    const [adCount, setAdCount] = useState()
    const [dataNo, setDataNo] = useState()
    const [changeVal, setChangeVal] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalAdfetcheds, setTotalAdfetched] = useState(0);
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [adDetail, setAdDetail] = useState("");
    const fetchedData = () => {
        setLoading(true)
        const userEmail = sessionStorage.getItem("googleBiEmail")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "csrftoken=Cq0kel3BZFYQxSoAB0RfXmsGm3HpN2xEMCW0Crr2paehWAdi8k3nbAsJeqmGqrqP");

        var raw = JSON.stringify({
            "userEmail": userEmail,
            "page": currentPage,
            "start_date": (startDate),
            "end_date": (endDate),
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/getInboxAccordingToUser", requestOptions)
            .then(response => response.json())
            .then(result => {
                setFetchAdsData(result.data)
                setAdCount(result.totalAdfetched)
                setTotalAdfetched(result.totalAdfetched.totalFetched)

            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }
    const history = useHistory()
    const InboxtodataEntry = (e, orderNumber, id) => {
        e.preventDefault()
        setLoading(true)
        const userEmail = sessionStorage.getItem("googleBiEmail")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "userEmail": userEmail,
            "orderNumber": orderNumber,
            "printAdId": id

        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/InboxtodataEntry", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200)
                    history.push("/printAd/jobDashboard/" + orderNumber);
                else
                    alert("something went wrong")
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }
    const moveToJunkApi = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Id": adDetail.id,
            "Value": 1
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/DeleteJob", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    fetchedData();
                    setModalShow(false);
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false); });

    }
    useEffect(() => {
        fetchedData()
    }, [currentPage])
    return (
        <div className={classes.screen}>
            {modalShow && (
                <ConfirmModal
                    heading={"Are you sure you want to move to junk"}
                    onClose={() => setModalShow(false)}
                    onClick={() => moveToJunkApi()}
                />
            )}
            <div className={classes.screenDiv}>
                <div className={classes.dateDiv}>
                    <label className={classes.dateLable}>Start Date</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <label className={classes.dateLable}>End Date</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className={classes.buttons}>
                    <button
                        type="button"
                        className="button"
                        onClick={() => {
                            fetchedData();
                        }}
                    >
                        {" "}
                        Search
                    </button>
                </div>
                <div className={classes.header}>
                    <div>Total fetched:{totalAdfetcheds}</div>
                    <div className={classes.buttons1}>
                        {currentPage > 0 ? (
                            <button
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                }}
                            >
                                Prev
                            </button>
                        ) : (
                            <></>
                        )}
                        <span>{currentPage}</span>
                        <button
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
                {loading ? (
                    <div className={classes.msg}>
                        <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />{" "}
                    </div>
                ) : (
                    <>
                        {fetchAdsData.length === 0 ? (
                            <div className={classes.msg}>
                                <MdError className={classes.icon} />
                                <p>Data not found</p>
                            </div>
                        ) : (
                            fetchAdsData.map((item, key) => {
                                return (
                                    <div className={classes.btnWrapper}>
                                        <div>
                                            <Link
                                                to={"/printAd/jobDashboard/" + item.orderNumber}
                                                className={classes.inboxLink}
                                                onClick={(e) => {
                                                    InboxtodataEntry(e, item.orderNumber, item.id);
                                                }}
                                            >
                                                {" "}
                                                <AdComp item={item} adType={"inbox"} />
                                            </Link>
                                        </div>
                                        <div className={classes.btnn}>
                                            <div className={classes.fetchButton}>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setModalShow(true);
                                                        setAdDetail(item);
                                                    }}
                                                >
                                                    🗑 Move to junk
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })



                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default FetchAdsInbox
