import React, { useState, useEffect } from "react";
import classes from "./preview.module.css";

const Preview = () => {
  const [storyData, setStoryData] = useState([]); // State to hold fetched story data

  useEffect(() => {
    fetchStoryData();
  }, []);

  const fetchStoryData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/api/apiTimes/GetWebStoriesSummary",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setStoryData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.row}>
      {storyData.map((story) => (
        <div className={classes.card} key={story.id}>
          <a
            href={`https://timesascent.com/web-stories/${storyData[0].Title?.replace(
              / /g,
              "-"
            )}/${storyData[0]?.WebStoriesSummaryID}`}
          >
            <img src={story.Image} alt="Story" className={classes.image} />
          </a>
          <div className={classes.content}>
            <p className={classes.title}>{story.Title}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Preview;
