import React, { useState, useEffect } from "react";
import classes from "./TOIWidgetList.module.css";
import { Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner";
import copy from "copy-to-clipboard";

const TOIWidgetList = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    EventList();
  }, []);

  const history = useHistory();
  const AddEventsHandler = () => {
    history.push("/BiDashboard/TOIWidget");
  };
  const EventList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/widget-list",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        console.log(result.data);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.table_container}>
        <div className={classes.container}>
          <div className={classes.button_wrapper}>
            <button
              onClick={AddEventsHandler}
              className={classes.Add_event_button}
            >
              Add TOIWidget
            </button>
          </div>
          <h4 className={classes.events_title}>TOI Widget List</h4>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : (
            <table className={classes.events_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>articleId</th>
                <th className={classes.table_heading}>target</th>
                <th className={classes.table_heading}>widgetId</th>
                <th className={classes.table_heading}>imageid</th>
                <th className={classes.table_heading}>imageurl</th>
                <th className={classes.table_heading}>MobileThumbnailurl</th>
                <th className={classes.table_heading}>Imagesize</th>
                {/* <th className={classes.table_heading}>wu</th> */}
                <th className={classes.table_heading}>tn</th>
                <th className={classes.table_heading}>hl</th>
                <th className={classes.table_heading}>ag</th>
                <th className={classes.table_heading}>Edit</th>
              </tr>

              <tbody>
                {data.map((item) => (
                  <tr className={classes.table_row}>
                    <td className={classes.table_td}>{item.articleId}</td>
                    <td className={classes.table_td}>{item.target}</td>
                    <td className={classes.table_td}>{item.widgetId}</td>
                    <td className={classes.table_td}>{item.imageid}</td>
                    <td className={classes.table_td}>
                      <div
                        className={classes.CopyImageUrl}
                        onClick={() => {
                          copy(item.imgurl);
                          alert("Copied");
                        }}
                      >
                        Copy Image Url
                      </div>
                    </td>
                    <td className={classes.table_td}>
                      <div
                        className={classes.CopyImageUrl}
                        onClick={() => {
                          copy(item.pthumb);
                          alert("Copied");
                        }}
                      >
                        Copy Thumbnail Url
                      </div>
                    </td>
                    <td className={classes.table_td}>{item.imagesize}</td>
                    {/* <td className={classes.table_td}>{item.wu}</td> */}
                    <td className={classes.table_td}>{item.tn}</td>
                    <td className={classes.table_td}>{item.hl}</td>
                    <td className={classes.table_td}>{item.ag}</td>
                    <td className={classes.table_td}>
                      <Link
                        to={"/BiDashboard/UpdateTOIWidget/" + item.widgetId}
                        className={classes.table_td_Link}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TOIWidgetList;
