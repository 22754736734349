import React, { useEffect, useState } from "react";

import classes from "../WeCare/editwecare.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory, useParams } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import TextEditor from "../TextEditor";
import { AiFillCopy, AiOutlineCloseCircle } from "react-icons/ai";
import copy from "copy-to-clipboard";
const EditWecare = () => {
  const [loading, setLoading] = useState("");
  const [organisation, setOrganistion] = useState("");
  const [companywebsite, setCompanywebsite] = useState("");
  const [companysector, setCompanysector] = useState("");
  const [eventlocation, setEventlocation] = useState("");
  const [eventdate, setEventdate] = useState("");
  const [activitydescription, setActivitydescription] = useState("");
  const [activityname, setActivityname] = useState("");
  const [companylogo, setCompanylogo] = useState("");
  const [images, setImages] = useState([]);
  const [live, setLive] = useState("");
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [newimage, setNewimage] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const [uploadUrls, setUploadUrls] = useState([]);
  const [upload, setUpload] = useState([]);
  const [author, setAuthor] = useState("");
  const [showImages, setShowImages] = useState([]);
  useEffect(() => {
    getDetailApi();
  }, []);

  const getDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      WeCareID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/WeCareDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setOrganistion(result.data.OrganisationName);
        setCompanylogo(result.data.CompanyLogo);

        setCompanywebsite(result.data.CompanyWebsite);

        setCompanysector(result.data.CompanySector);
        setActivityname(result.data.ActivityName);
        setActivitydescription(result.data.ActivityDescription);

        setEventlocation(result.data.EventLocation);
        setEventdate(result.data.EventDate);
        setImages(result.data.Images);
        setLive(result.data.IsLive);
        setAuthor(result.data.AuthorName);
        // setCreateDate(result.data.CreatedAt);
        // setUpdateDate(result.data.UpdatedAt);

        // setAuthor();
      })
      .catch((error) => console.log("error", error));
  };

  const UpdateApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("OrganisationName", organisation);
    formdata.append("CompanyWebsite ", companywebsite);
    formdata.append(
      "CompanySector",
      companysector === "" ? null : companysector
    );
    formdata.append("ActivityName ", activityname);
    formdata.append("EventDate ", eventdate);
    formdata.append(
      "EventLocation ",
      eventlocation === "" ? null : eventlocation
    );
    formdata.append("ActivityDescription", activitydescription);
    formdata.append("CompanyLogo", newimage);
    formdata.append("AuthorName", author);
    formdata.append("IsLive", check);

    formdata.append("Images", "");

    formdata.append("WeCareID", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EditWeCare",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Update Corporates Care",
    previousPageName: "Corporates Care List",
    previousPage: "/BiDashboard/corporates-care",
  };

  const multipleImagesUpload = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/uploadImagesMultiple", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        getmultipleImages();
      })
      .catch((error) => console.log("error", error));
  };
  const getmultipleImages = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var raw = "";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/getNewUploadedImagesMultiple",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setShowImages(result.data || []);
      })
      .catch((error) => console.log("error", error));
  };
  const RemoveMultipleImage = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/removeImageFromArticles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          alert("Image Remove Successfully");
          getmultipleImages();
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/corporates-care")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update We Care</div>
            <div className={classes.checkbox}>
              <span className={classes.live}>Live</span>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                value={check}
              />
            </div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Organisation Name"}
                placeholder={"Organisation Name"}
                onChange={(e) => setOrganistion(e.target.value)}
                value={organisation}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Sector"}
                placeholder={"Company Sector"}
                onChange={(e) => setCompanysector(e.target.value)}
                value={companysector}
              />
              <EventInputs
                input={"DateInput"}
                label={"Event Date "}
                placeholder={"Event Date "}
                onChange={(e) => setEventdate(e.target.value)}
                value={eventdate}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Event Location "}
                placeholder={"Event Location "}
                onChange={(e) => setEventlocation(e.target.value)}
                value={eventlocation}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Website "}
                placeholder={"Company Website "}
                onChange={(e) => setCompanywebsite(e.target.value)}
                value={companywebsite}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Activity Name"}
                placeholder={"Activity Name"}
                onChange={(e) => setActivityname(e.target.value)}
                value={activityname}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Company Logo"}
                placeholder={"Company Logo"}
                onChange={(e) => setNewimage(e.target.files[0])}
              />

              {newimage ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(newimage)}
                  className={classes.imgicon}
                />
              ) : (
                <img src={companylogo} alt="icon" className={classes.imgicon} />
              )}
            </div>

            <div className={classes.input}>
              <div className={classes.AddHrProfessional_Description}>
                Activity Description
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                    onChange={(e) => {
                      setShortDescription(e);
                    }}
                    value={shortDescription}
                    setContents={shortDescription}
                  /> */}
                <div>
                  <TextEditor
                    onChangeContent={setActivitydescription}
                    value={activitydescription}
                  />
                </div>
              </div>

              <EventInputs
                input={"MultiImageInput"}
                label={"Images"}
                placeholder={"Images"}
                onChange={multipleImagesUpload}
              />
              {/* <div className={classes.imageDiv}>{renderPhotos(images)}</div> */}
              <div className={classes.MapImg}>
                {" "}
                {showImages.map((item, index) => (
                  <div className={classes.ImageUrlContainer}>
                    <img
                      src={item.imageURL}
                      alt="hello"
                      className={classes.MultipleImgSelect}
                    />
                    <AiOutlineCloseCircle
                      className={classes.RemoveImgIcon}
                      onClick={() => RemoveMultipleImage(item.id)}
                    />
                    <div
                      onClick={() => {
                        copy(item.imageURL);
                        alert("Copied");
                      }}
                      className={classes.CopyURLIcon}
                    >
                      <AiFillCopy size={22} />
                    </div>
                  </div>
                ))}
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Author Name"}
                  placeholder={"Author Name"}
                  onChange={(e) => setAuthor(e.target.value)}
                  value={author}
                />
              </div>
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => UpdateApi()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditWecare;
