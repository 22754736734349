import React from "react";
import classes from "./Header.module.css";
import { GoogleLogout } from "react-google-login";
import { useHistory, useLocation } from "react-router-dom";
import DashNav from "../TimesPaperAds/Component/DashNav";

const Header = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const onLogoutSuccess = () => {
    sessionStorage.removeItem("googleBiEmail");
    sessionStorage.removeItem("Token");
    history.push("/BiDashboard/SignInWithGoogle");
    history.go(0);
  };

  // return (
  //     <>
  //         <div className={classes.header}>
  //             <div className={classes.header_logo}>
  //                 <img className={classes.logo} src="/assets/times_logo.png" alt="times_ascent_logo.svg"></img>

  //             </div>
  //             <DashNav />
  //             <div className={classes.admin_profile}>

  //                 <GoogleLogout
  //                     //clientId="751473842855-p26dcq7h43fj3k3pc0atfmcaf87l6lq4.apps.googleusercontent.com"
  //                     clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
  //                     buttonText="Logout"
  //                     onLogoutSuccess={() => onLogoutSuccess()}
  //                 />
  //             </div>
  //         </div>
  //     </>
  // )
  const AccessLevel = (type) => {
    switch (type) {
      case "ADSDBSub":
        return (
          <>
            <div className={classes.header}>
              <div className={classes.header_logo}>
                <img
                  className={classes.logo}
                  src="/assets/times_logo.png"
                  alt="times_ascent_logo.svg"
                ></img>
              </div>
              <DashNav />
              <div className={classes.admin_profile}>
                {/* <GoogleLogout
                  // live clientid
                  clientId="751473842855-p26dcq7h43fj3k3pc0atfmcaf87l6lq4.apps.googleusercontent.com"
                  // live clientid
                  //  clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={() => onLogoutSuccess()}
                /> */}
                <button type="button" onClick={() => onLogoutSuccess()}>
                  Logout
                </button>
              </div>
            </div>
          </>
        );

      case "ADSDB":
        return (
          <>
            <div className={classes.header}>
              <div className={classes.header_logo}>
                <img
                  className={classes.logo}
                  src="/assets/times_logo.png"
                  alt="times_ascent_logo.svg"
                ></img>
              </div>
              <DashNav />
              <div className={classes.admin_profile}>
                {/* <GoogleLogout
                  // live clientid
                  clientId="751473842855-p26dcq7h43fj3k3pc0atfmcaf87l6lq4.apps.googleusercontent.com"
                  // live clientid
                  // clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={() => onLogoutSuccess()}
                /> */}
                <button
                  className={classes.logoutBtn}
                  type="button"
                  onClick={() => onLogoutSuccess()}
                >
                  Logout
                </button>
              </div>
            </div>
          </>
        );
      case "TIMES":
        return (
          <>
            <div className={classes.header}>
              <div className={classes.header_logo}>
                <img
                  className={classes.logo}
                  src="/assets/times_logo.png"
                  alt="times_ascent_logo.svg"
                ></img>
              </div>

              <div className={classes.admin_profile}>
                {/* <GoogleLogout
                  // live clientid
                  clientId="751473842855-p26dcq7h43fj3k3pc0atfmcaf87l6lq4.apps.googleusercontent.com"
                  // live clientid
                  // clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={() => onLogoutSuccess()}
                /> */}
                <button type="button" onClick={() => onLogoutSuccess()}>
                  Logout
                </button>
              </div>
            </div>
          </>
        );
      case "SAS":
        return (
          <>
            <div className={classes.header}>
              <div className={classes.header_logo}>
                <img
                  className={classes.logo}
                  src="/assets/times_logo.png"
                  alt="times_ascent_logo.svg"
                ></img>
              </div>

              <div className={classes.admin_profile}>
                {/* <GoogleLogout
                  // live clientid
                  clientId="751473842855-p26dcq7h43fj3k3pc0atfmcaf87l6lq4.apps.googleusercontent.com"
                  // live clientid
                  //clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={() => onLogoutSuccess()}
                /> */}
                <button
                  className={classes.logoutBtn}
                  type="button"
                  onClick={() => onLogoutSuccess()}
                >
                  Logout
                </button>
              </div>
            </div>
          </>
        );
      default:
        console.log("invalid cases");
    }
  };

  return <div>{AccessLevel(props.userType)}</div>;
};

export default Header;
