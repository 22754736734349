import React from "react";
import classes from "./MOPJourneyModel.module.css";
import { Link } from "react-router-dom";

const MOPJourneyModel = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        />
        <h2 style={{ textAlign: "center" }}>
          Form has been submitted successfully
        </h2> */}
        <div className={classes.eventdetail_container}>
          {/* {props.datamap((item) => ( */}
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <img
                src={props.data.Photo || props.data.CompanyLogo}
                alt={props.data.FullName || props.data.Name}
                className={classes.event_image}
              ></img>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                    <tr>
                      <th className={classes.table_th}>Name :</th>
                      <td className={classes.table_td}>
                        {props.data.FullName || props.data.Name}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Mobile :</th>
                      <td className={classes.table_td}>{props.data.Mobile}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Email :</th>
                      <td className={classes.table_td}>{props.data.Email}</td>
                    </tr>
                    {props.data.CurrentCompany && (
                      <tr>
                        <th className={classes.table_th}>Current Company :</th>
                        <td className={classes.table_td}>
                          {props.data.CurrentCompany}
                        </td>
                      </tr>
                    )}
                    {props.data.CompanyWebsite && (
                      <tr>
                        <th className={classes.table_th}>Company Website :</th>
                        <td className={classes.table_td}>
                          {props.data.CompanyWebsite}
                        </td>
                      </tr>
                    )}
                    {props.data.InitiativeName && (
                      <tr>
                        <th className={classes.table_th}>Initiative Name :</th>
                        <td className={classes.table_td}>
                          {props.data.InitiativeName}
                        </td>
                      </tr>
                    )}
                    {props.data.InitiativeDesc && (
                      <tr>
                        <th className={classes.table_th}>
                          Initiative Description :
                        </th>
                        <td className={classes.table_td}>
                          {props.data.InitiativeDesc}
                        </td>
                      </tr>
                    )}
                    {props.data.Type && (
                      <tr>
                        <th className={classes.table_th}>Type :</th>
                        <td className={classes.table_td}>{props.data.Type}</td>
                      </tr>
                    )}
                    {props.data.UploadDocument && (
                      <tr>
                        <th className={classes.table_th}>Upload Document :</th>
                        <td className={classes.table_td}>
                          <a target="_blank" href={props.data.UploadDocument}>
                            {props.data.UploadDocument}
                          </a>
                        </td>
                      </tr>
                    )}
                    {props.data.CurrentDesignation && (
                      <tr>
                        <th className={classes.table_th}>
                          Current Designation :
                        </th>
                        <td className={classes.table_td}>
                          {props.data.CurrentDesignation}{" "}
                        </td>
                      </tr>
                    )}
                    {props.data.CurrentRole && (
                      <tr>
                        <th className={classes.table_th}>Current Role :</th>
                        <td className={classes.table_td}>
                          {props.data.CurrentRole}
                        </td>
                      </tr>
                    )}
                    {props.data.PreviousCompany && (
                      <tr>
                        <th className={classes.table_th}>Previous Company :</th>
                        <td className={classes.table_td}>
                          {props.data.PreviousCompany}
                        </td>
                      </tr>
                    )}
                    {props.data.PreviousDesignation && (
                      <tr>
                        <th className={classes.table_th}>
                          Previous Designation :
                        </th>
                        <td className={classes.table_td}>
                          {props.data.PreviousDesignation}
                        </td>
                      </tr>
                    )}
                    {props.data.PreviousRole && (
                      <tr>
                        <th className={classes.table_th}>Previous Role:</th>
                        <td className={classes.table_td}>
                          {props.data.PreviousRole}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className={classes.detail_bottom}
              style={{ marginLeft: "40px" }}
            >
              <h4>Description:</h4>
              <p className={classes.description}>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      props.data.GrowthStory || props.data.ShortDescription,
                  }}
                />
              </p>
            </div>
            <div className={classes.Time_date}>
              {props.data.EventDate && (
                <div>
                  <div className={classes.date}>Event Date</div>
                  <div className={classes.date_text}>
                    {props.data.EventDate}
                  </div>
                </div>
              )}
              {props.data.EventLocation && (
                <div>
                  <div className={classes.date}>Event Location</div>
                  <div className={classes.date_text}>
                    {props.data.EventLocation}
                  </div>
                </div>
              )}
              <div>
                <div className={classes.date}>Created At</div>
                <div className={classes.date_text}>
                  {props.data.CreatedAt.split("T")[0]
                    ? props.data.CreatedAt.split("T")[0]
                    : props.data.CreatedAt}
                </div>
              </div>
              <div>
                <div className={classes.date}>Updated At</div>
                <div className={classes.date_text}>
                  {props.data.UpdatedAt.split("T")[0]
                    ? props.data.UpdatedAt.split("T")[0]
                    : props.data.UpdatedAt}
                </div>
              </div>
            </div>
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
          {/* ))
            } */}
        </div>
      </div>
    </>
  );
};

export default MOPJourneyModel;
