import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import SignInWithGoogle from './Components/SignInWithGoogle';
import MainRoute from "./Routing/MainRoute";

// import Routing from './Routing/Routing';

const App = () => {
  // global.apiLink = "https://api.timesascent.com";
  global.apiLink =
    "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production";
  global.apiLink8000 = "http://api.timesascent.com:8000";
  global.apiLink8001 = "http://api.timesascent.com:8001";
  return (
    // <Pagination />

    <Router>
      <MainRoute />
      {/* <Routing /> */}
    </Router>
  );
};
export default App;
// "start": "react-scripts --openssl-legacy-provider start",
