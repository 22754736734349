import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./PaymentSucc.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const PaymentSuccToday = () => {
  const [todayPayments, setTodayPayments] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    paymentApi();
  }, []);

  const paymentApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/pay-succ", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTodayPayments(result.Today);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>Payment Success Today</div>
      <div className={classes.button_container}>
        <CSVLink
          data={todayPayments}
          filename={"paymentsuccesstoday-table.csv"}
          target="_blank"
        >
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <ExcelDownload csvData={todayPayments} fileName="paymentsuccesstoday-table" />
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {todayPayments == null && (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Order Id</th>
                  <th className={classes.table_heading}>Subscription Id</th>
                  <th className={classes.table_heading}>Transaction Id</th>
                  <th className={classes.table_heading}>Transaction Status</th>
                  <th className={classes.table_heading}>Payment Status</th>
                  <th className={classes.table_heading}>Start Date</th>
                  <th className={classes.table_heading}>End Date</th>
                  <th className={classes.table_heading}>Admin Approval</th>
                  <th className={classes.table_heading}>Bill URL</th>
                </tr>
                <tbody>
                  {todayPayments.map((item, value) => {
                    return isLoading ? (
                      <div className={classes.spinner}>
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.orderId}</td>
                        <td className={classes.table_td}>
                          {item.subscriptionId}
                        </td>
                        <td className={classes.table_td}>
                          {item.transactionId}
                        </td>
                        <td className={classes.table_td}>
                          {item.transactionStatus}
                        </td>
                        <td className={classes.table_td}>
                          {item.paymentStatus}
                        </td>
                        <td className={classes.table_td}>{item.startDate}</td>
                        <td className={classes.table_td}>{item.endDate}</td>
                        <td className={classes.table_td}>
                          {item.adminApproval}
                        </td>
                        <td className={classes.table_td}>{item.billURL}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccToday;
