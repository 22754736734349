import React, { useState, useEffect } from "react";
import classes from "../WeCare/viewdetail.module.css";
import { useParams } from "react-router-dom";

const MovementView = () => {
  const [name, setName] = useState("");

  const [organisation, setOrganistion] = useState("");

  const [designation, setDesignation] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [featuredMedia, setFeaturedMedia] = useState("");
  const [website, setWebsite] = useState("");
  const [live, setLive] = useState("");
  const [facebook, setFacebook] = useState("");
  const [email, setEmail] = useState("");
  const [insta, setInsta] = useState("");
  const [profilepic, setProfilepic] = useState("");
  const [twitter, setTwitter] = useState("");

  const [create, setCreate] = useState("");
  const [update, setUpdate] = useState("");
  const { id } = useParams();

  useEffect(() => {
    getDetailApi();
  }, []);

  const getDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      MovementProfessionalID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/MevementOfProfessionDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setName(result.data.Name);

        setOrganistion(result.data.OrganizationName);

        setEmail(result.data.Email);
        setTwitter(result.data.Twitter);

        setInsta(result.data.Instagram);
        setProfilepic(result.data.ProfilePhoto);
        setFacebook(result.data.Facebook);
        setWebsite(result.data.Website);
        setDesignation(result.data.Designation);
        setFeaturedMedia(result.data.FeaturedMedia);
        setOtherInfo(result.data.OtherInfo);
        setLive(result.data.IsLive);
        setUpdate(result.data.UpdatedAt);
        setCreate(result.data.CreatedAt);
        // setCreateDate(result.data.CreatedAt);
        // setUpdateDate(result.data.UpdatedAt);

        // setAuthor();
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className={classes.NewArticle}>
        <h4 className={classes.ArticleHeading}>Movement ID : {id}</h4>
        <form>
          <div className={classes.FormContainer}>
            <div className={classes.leftSide}>
              <div className={classes.formGroup}>
                <div className={classes.formGroup}>
                  <p className={classes.artical_date}>Profile Photo :</p>
                  <div>
                    {" "}
                    <img
                      src={profilepic}
                      className={classes.articleImage}
                      alt="Thumbnailimage"
                    />
                  </div>
                </div>

                <p className={classes.artical_date}>Name : {name}</p>
              </div>
              <div>
                <p className={classes.artical_date}>
                  Email : {email === "" ? "NA" : email}
                </p>
              </div>

              <div>
                <p className={classes.artical_date}>
                  Organisation Name : {organisation}
                </p>
              </div>

              <div>
                <p className={classes.artical_date}>
                  Designation : {designation}
                </p>
              </div>
              <div>
                <p className={classes.artical_date}>
                  Featured Media Link :{" "}
                  {featuredMedia === "" ? (
                    "NA"
                  ) : (
                    <a
                      href={{ featuredMedia }}
                      target="_blank"
                      alt="featured"
                      rel="noreferrer"
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: featuredMedia }}
                      />{" "}
                    </a>
                  )}
                </p>
              </div>
              <div className={classes.infoheading}>Other Info : </div>
              <div className={classes.artical_dat}>
                <div dangerouslySetInnerHTML={{ __html: otherInfo }} />
              </div>

              <div>
                {/* <p className={classes.artical_date}>Event Date : {eventdate}</p> */}
              </div>
            </div>
            <div className={classes.rightSide}>
              <h5 className={classes.otherDetailHeading}>Other Details</h5>
              <div className={classes.formGroup}>
                <p className={classes.artical_date}>
                  Website :
                  {website === "" ? (
                    "NA"
                  ) : (
                    <a
                      href={website}
                      target="_blank"
                      alt="featured"
                      rel="noreferrer"
                    >
                      WebsiteUrl
                    </a>
                  )}
                </p>
                <p className={classes.artical_date}>
                  Facebook :{" "}
                  {facebook === "" ? (
                    "NA"
                  ) : (
                    <a
                      href={facebook}
                      target="_blank"
                      alt="featured"
                      rel="noreferrer"
                    >
                      FacebookUrl
                    </a>
                  )}
                </p>
                <p className={classes.artical_date}>
                  Instagram :{" "}
                  {insta === "" ? (
                    "NA"
                  ) : (
                    <a
                      href={insta}
                      target="_blank"
                      alt="featured"
                      rel="noreferrer"
                    >
                      InstagramUrl
                    </a>
                  )}
                </p>
                <p className={classes.artical_date}>
                  Twitter :{" "}
                  {twitter === "" ? (
                    "NA"
                  ) : (
                    <a
                      href={twitter}
                      target="_blank"
                      alt="featured"
                      rel="noreferrer"
                    >
                      TwitterUrl
                    </a>
                  )}
                </p>

                <p className={classes.artical_date}>
                  Create Date : {create.slice(0, 10)}{" "}
                </p>
                <p className={classes.artical_date}>
                  Update Date : {update.slice(0, 10)}{" "}
                </p>
                <p className={classes.artical_date}>
                  Status : {live === "1" ? "Live" : "NotLive"}
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MovementView;
