import React, { useState } from 'react';
import classes from './InterstitialAds.module.css'

const InterstitialAds = () => {

    const [selectedFile, setSelectedFile] = useState('')

    const fileChangedHandler = event => {
        setSelectedFile(event.target.value);
    }

    const uploadHandler = () => {
        console.log(selectedFile);
        // const uploadeFile=selectedFile;
    }
    return (
        <>
            <div className={classes.InterstitialAds_container}>
                <div className={classes.Ads_container}>
                    <div className={classes.Ads_image}>
                        <img className={classes.image} src="/Mask Group 6.jpg" alt="Basics.png"></img>
                        <input type="file" id="actual-btn4" onChange={fileChangedHandler} hidden />
                        <label onClick={uploadHandler()} className={classes.Upload_button} for="actual-btn4" >Upload</label>
                    </div>

                    <div className={classes.date_container}>
                        <div className={classes.set_time}>
                            <h1 className={classes.heading}>Set start and end time</h1>
                        </div>
                        <div className={classes.date_field}>

                            <div>
                                <label className={classes.label}>Start From</label>
                                <input type="date" className={classes.date_picker}>
                                </input>
                            </div>
                            <div>
                                <label className={classes.label}>End to</label>
                                <input type="date" className={classes.date_picker}>
                                </input>
                            </div>

                        </div>

                        <div className={classes.update_btn}>
                            <button className={classes.button}>Update</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default InterstitialAds
