// import React, { useState } from "react";
// import ReactQuill from "react-quill";
// import "../../node_modules/react-quill/dist/quill.snow.css";
// import "./TextEditor.css";

// const TextEditor = (props) => {
//   return (
//     <div>
//       <ReactQuill
//         placeholder={props.placeholder}
//         modules={TextEditor.modules}
//         formats={TextEditor.formats}
//         onChange={props.onChange}
//         value={props.value}
//       />
//     </div>
//   );
// };

// TextEditor.modules = {
//   toolbar: [
//     [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
//     [{ size: [] }],
//     ["bold", "italic", "underline", "strike", "blockquote","indent","outdent"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["link", "image", "video"],
//     ["clean"],
//     ["code-block"],
//   ],
// };

// TextEditor.formats = [
//   "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "link",
//   "image",
//   "video",
//   "code-block",
//   "indent",
//   "outdent"
// ];

// export default TextEditor;
import React, { useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = (props) => {
  const editorRef = useRef(null);

  return (
    <>
      <Editor
        apiKey='j21zsw50vff656s2vj015v1lonzpfm8jwo0kedsimz1694ky'
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue=""
        // onEditorChange={(newValue, editor) => props.onChangeContent(newValue)}
        onEditorChange={(newValue, editor) => {
          props.withSimpleText
            ? props.setDataFunc(newValue, editor.getContent({ format: "text" }))
            : props.onChangeContent(newValue);
        }}
        value={props.value}
        init={{
          height: props.height ? props.height : 500,
          menubar: false,
          plugins: [
            "lists advlist",
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help | code | link image|" +
            "fontselect fontsizeselect |" +
            "fullscreen  preview save print |" +
            "charmap emoticons |" +
            "a11ycheck ltr rtl |" +
            "insertfile image media pageembed template link anchor codesample |" +
            "pagebreak",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px,cursor: pointer; }",
        }}
      />
    </>
  );
};
export default TextEditor;
