import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import classes from "./EditCompanyModal.module.css"
const CreateLocationIndustry = (props) => {
    const handleClose = () => props.setShow(false);
    const [industryName, setIndustryName] = useState("")
    const [jobFunction, setJobFunction] = useState("")
    const createIndustry = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "industry": industryName,
            "userEmail": sessionStorage.getItem("googleBiEmail"),
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink + "/adminapi/createIndustryByadmin", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    alert("industry added")
                    handleClose()
                    window.location.reload();
                }
            })
            .catch(error => console.log('error', error));
    }
    const createJobFunction = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "industry": jobFunction,
            "userEmail": sessionStorage.getItem("googleBiEmail"),
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink + "/adminapi/createJobFuctionByadmin", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    alert("Job function added")
                    handleClose()
                    window.location.reload();
                }
            })
            .catch(error => console.log('error', error));
    }
    return <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
    >
        <Modal.Body>
            <div className={classes.header}>
                <h4>Create Industry/Job Function</h4>
                <ImCross onClick={handleClose} />
            </div>
            {/* <div className={classes.modalCreate}>
                <h4>
                    Create Location
                </h4>
                <div>
                    <input type="text" />
                    <button>Create Location</button>
                </div>
            </div> */}
            <div className={classes.modalCreate}>
                <h4>
                    Create Industry
                </h4>
                <div>
                    <input type="text" onChange={(e) => setIndustryName(e.target.value)} value={industryName} />
                    <button onClick={createIndustry}>Create Industry</button>
                </div>
            </div>

            {/* <div className={classes.modalCreate}>
                <h4>
                    Create Location
                </h4>
                <div>
                    <input type="text" />
                    <button>Create Location</button>
                </div>
            </div> */}
            <div className={classes.modalCreate}>
                <h4>
                    Create Job Function
                </h4>
                <div>
                    <input type="text" onChange={(e) => setJobFunction(e.target.value)} value={jobFunction} />
                    <button onClick={createJobFunction}>Create Job Function</button>
                </div>
            </div>
        </Modal.Body>

    </Modal>;
};

export default CreateLocationIndustry;
