import React, { useState } from "react";

import classes from "../Webinarvideos/InsertVideo.module.css";

import EventInputs from "../Components/Events/EventInputs";
import Modal from "../Components/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";

const FaqInsert = () => {

  const [loading, setLoading] = useState(false);
  
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Answer, setanswer] = useState("");
  const [Question, setQuestion] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  let history = useHistory();

  const AddFaq = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92");

var formdata = new FormData();
formdata.append("Answer", Answer);
formdata.append("Question", Question );
formdata.append("IsWebinarVideo", isPublished ? "1" : "0");

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

    fetch(
        `${process.env.REACT_APP_API_URL}/adminapi/InsertWealthFAQ`,
    
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  
  const pageInfo = {
    currentPageName: "Add FAQ",
    previousPageName: "FAQ List",
    previousPage: "/BiDashboard/FAQ",
  };
  const currentURL = window.location.href;


  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"FAQ added successfully"}
          onClick={() => history.push("/BiDashboard/FAQ")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add FAQ</div>
          </div>
          <div className={classes.form_input}>
          <div className={classes.form_Group}>
                <label htmlFor="shortDescription">
                  Question 
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  id="shortDescription"
                  name="shortDescription"
                  placeholder="Write Your Question"
                  onChange={(e) => setQuestion(e.target.value)}
                  value={Question}
                />
              </div>

              <div className={classes.form_Group}>
                <label htmlFor="shortDescription">
                  Answer
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  id="shortDescription"
                  name="shortDescription"
                  placeholder="Write Your Answer"
                  onChange={(e) => setanswer(e.target.value)}
                  value={Answer}
                />
              </div>
           
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddFaq()}>
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Add"
          )}
        </button>
      </div>
    </div>
  );
};

export default FaqInsert;
