import React, { useState, useEffect } from "react";
import EventInputs from "../Events/EventInputs";
import classes from "./UpdateFeaturedCompanies.module.css";
import { Link, useHistory, useParams } from "react-router-dom";
import BackButton from "../BackButton";

const UpdateFeaturedCompanies = () => {
  const history = useHistory();
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [industry, setIndustry] = useState();
  const [website, setWebsite] = useState();
  const [description, setDescription] = useState();
  const [location, setLocation] = useState();
  const [editableCImage, setEditableCImage] = useState();
  const [position, setPosition] = useState();

  const [data, setData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    viewCourse();
  }, []);
  console.log(data);

  const viewCourse = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/Get-Featured-Company", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setImage(result.data.image);
        setDescription(result.data.companyDescription);
        setIndustry(result.data.industry);
        setLocation(result.data.location);
        setName(result.data.name);
        setWebsite(result.data.website);
        setPosition(result.data.logoPosition);
        //   console.log(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  const AddNew = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Id", id);
    formdata.append("Image", image);
    formdata.append("companyDescription", description);
    formdata.append("industry", industry);
    formdata.append("Name", name);
    formdata.append("location", location);
    formdata.append("website", website);
    formdata.append("LogoPosition", position);

    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(global.apiLink + "/adminapi/Update-FeaturedCompany", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        history.push("/BiDashboard/featuredcompanies");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>
      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add Featured Companies</div>
            <Link
              to="/BiDashboard/featuredcompanies"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Location"}
                placeholder={"Location"}
                onChange={(e) => setLocation(e.target.value)}
                value={location}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Logo Position"}
                placeholder={"1 to 7"}
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />
              <EventInputs
                input={"MultiLineInput"}
                label={"Description"}
                placeholder={"Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Industry"}
                placeholder={"Industry"}
                onChange={(e) => setIndustry(e.target.value)}
                value={industry}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Website"}
                placeholder={"Website"}
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />
              {editableCImage ? (
                <div>
                  <div className={classes.fieldName}>
                    <label htmlFor="input7">Course Image</label>
                  </div>
                  <div className={classes.field1}>
                    <div className={classes.inputField}>
                      <div className={classes.inputFieldText}> {image}</div>
                      <div>
                        {" "}
                        <button
                          onClick={() => setEditableCImage(false)}
                          className={classes.input_button}
                        >
                          edit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <EventInputs
                  input={"ImageInput"}
                  label={"Image"}
                  placeholder={"Image"}
                  onChange={(e) => setImage(e.target.files[0])}
                  type={"file"}
                  accept=".png, .jpg, .jpeg"
                />
              )}
            </div>

            {/* <div className={classes.checkbox}>
                            <EventInputs input={"CheckBox"} onChange={() => setCheck(!check === true ? 1 : 0)} label={"Want to publish this event"} value={check} />
                        </div> */}
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => AddNew()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default UpdateFeaturedCompanies;
