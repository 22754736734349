import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./resumeuploadlast.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const ResumeUploadLastMonth = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [lastresume, setLastresume] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isfirstname, setIsfirstname] = useState("ASC");
  const [islastname, setIslastname] = useState("ASC");
  const [issno, setIssno] = useState("DSC");
  const currentURL = window.location.href.split("/").pop();
  console.log("currentURL", currentURL);
  useEffect(() => {
    ResumeLastMonthApi();
  }, [currentPage]);

  const ResumeLastMonthApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate || currentURL,
      endDate: endDate || currentURL,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getResumeUploadsDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLastresume(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  console.log(startDate, "Start Date");

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...lastresume].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastresume(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...lastresume].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastresume(sortsno);
      setIssno("ASC");
    }
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...lastresume].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastresume(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...lastresume].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastresume(sortedfirst);
      setIsfirstname("ASC");
    }
  };
  const sortinglastname = (col) => {
    if (islastname === "ASC") {
      const sortedlast = [...lastresume].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastresume(sortedlast);
      setIslastname("DSC");
    }
    if (islastname === "DSC") {
      const sortedlast = [...lastresume].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastresume(sortedlast);
      setIslastname("ASC");
    }
  };
  const pageInfo = {
    currentPageName: "Resume Uploads Detailes",
    previousPageName: "Resume Uploads Count Page",
    previousPage: "/BiDashboard/ResumeLastMonth",
  };

  let multidownload = lastresume.map((item) => {
    return item.url;
  });

  const downloadAllFiles = (multidownload) => {
    if (multidownload.length) {
      return multidownload.map((item) => {
        var a = document.createElement("a");
        a.setAttribute("href", item);
        a.setAttribute("download", item);
        a.setAttribute("target", "_blank");
        a.click();
      });
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Resume Uploads Detailed Page</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={lastresume}
            filename={"Resume_Upload_Detail_Table.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={lastresume} fileName="Resume_Upload_Detail" />
        </div>
        <button
          onClick={() => {
            downloadAllFiles(multidownload);
          }}
          className={classes.btnall}
        >
          Download All
        </button>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || currentURL}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || currentURL}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => ResumeLastMonthApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>

      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {lastresume.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {lastresume.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>
                    Sno
                    {issno === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    First Name
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("firstName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("firstName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Last Name
                    {islastname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortinglastname("lastName")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortinglastname("lastName")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>Download Resume</th>
                </tr>

                <tbody>
                  {lastresume.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.firstName}</td>
                        <td className={classes.table_td}>{item.lastName}</td>

                        <td className={classes.table_td}>{item.Date}</td>

                        <td className={classes.table_td}>
                          <a
                            href={item.url}
                            download={item.url}
                            target="_blank"
                          >
                            Download Resume
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeUploadLastMonth;
