import React, { useState } from "react";
import classes from "./TOIWidget.module.css";
import { useHistory } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";

const TOIWidget = () => {
  const history = useHistory();
  const [articlesId, setArticlesId] = useState();
  const [target, setTarget] = useState();
  const [image, setImage] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [imageId, setImageId] = useState();
  const [imgSize, setImgSize] = useState();
  const [hl, setHl] = useState();
  const [ag, setAg] = useState();
  const [wu, setWu] = useState();
  const [tn, setTn] = useState();

  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);

  const NewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("articleId", articlesId ? articlesId : "");
    formdata.append("target", target ? target : "");
    formdata.append("imgurl", image ? image : "");
    formdata.append("imageid", imageId ? imageId : "");
    formdata.append("pthumb", uploadImage ? uploadImage : "");
    formdata.append("hl", hl ? hl : "");
    formdata.append("imagesize", imgSize ? imgSize : "");
    formdata.append("ag", ag ? ag : "");
    formdata.append("tn", tn ? tn : "");
    formdata.append("wu", wu ? wu : "");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/widget-insert",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
          setArticlesId("");
          setTarget("");
          setImageId("");
          setImage("");
          setUploadImage("");
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/TOIWidgetList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Add TOIWidget</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Article Id"}
                placeholder={"Article Id"}
                onChange={(e) => setArticlesId(e.target.value)}
                value={articlesId}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Target"}
                placeholder={"Target"}
                onChange={(e) => setTarget(e.target.value)}
                value={target}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"hl"}
                placeholder={"hl"}
                onChange={(e) => setHl(e.target.value)}
                value={hl}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"ag"}
                placeholder={"ag"}
                onChange={(e) => setAg(e.target.value)}
                value={ag}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"wu"}
                placeholder={"wu"}
                onChange={(e) => setWu(e.target.value)}
                value={wu}
              />
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"tn"}
                placeholder={"tn"}
                onChange={(e) => setTn(e.target.value)}
                value={tn}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Size"}
                placeholder={"Image Size"}
                onChange={(e) => setImgSize(e.target.value)}
                value={imgSize}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Id"}
                placeholder={"Image Id"}
                onChange={(e) => setImageId(e.target.value)}
                value={imageId}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Url"}
                placeholder={"Image Url"}
                onChange={(e) => setImage(e.target.value)}
                value={image}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Upload Image"}
                placeholder={"Upload"}
                onChange={(e) => setUploadImage(e.target.files[0])}
                value={uploadImage}
              />
            </div>
          </div>
        </div>
        <div className={classes.UpdateBtnContainer}>
          <button className={classes.Upload_button} onClick={() => NewEvent()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TOIWidget;
