import React, { useState, useEffect } from "react";
import EventInputs from "./Events/EventInputs";
import classes from "./PostAJobComponent.module.css";
import { Link, useHistory } from "react-router-dom";
// import BackButton from "../BackButton";
import Loader from "react-loader-spinner";
import Modal from "../Components/Articles/Modal";
import { Alert } from "bootstrap";
import BackButton from "./BackButton";

const PostAJobComponent = () => {
    const history = useHistory();
    const [recruiterID, setRecruiterID] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [employementType, setEmployementType] = useState("");
    const [jobLocation, setJobLocation] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [noOfVacancies, setNoOfVacancies] = useState("");
    const [minExp, setMinExp] = useState("");
    const [jobStartDate, setJobStartDate] = useState("");
    const [offeredSalary, setOfferedSalary] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [recieveType, setRecieveType] = useState(["External website"]);
    const [websiteLinkInterview, setWebsiteLinkInterview] = useState("");
    const [emailAddressInterview, setEmailAddressInterview] = useState("");
    const [contactNumberInterview, setContactNumberInterview] = useState("")
    const [locationInterview, setLocationInterview] = useState("");
    const [addressInterview, setAddressInterview] = useState("");
    const [dateInterview, setDateInterview] = useState("");
    const [timeInterview, setTimeInterview] = useState("");
    const [uploadedAdCreative, setUploadedAdCreative] = useState("");
    const [selectedKeyIndexJobLocation, setSelectedKeyIndexJobLocation] = useState("");
    const [selectedKeyIndexLocationInterview, setSelectedKeyIndexLocationInterview] = useState("");
    const [jobCategories, setJobCategories] = useState([]);
    const [category, setCategory] = useState("");
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [show, setShow] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [recruiterData, setRecruiterData] = useState(null);
    const [citySuggestionsShow, setCitySuggestionsShow] = useState(false);
    const [locationInterViewSuggestion, setLocationInterViewSuggestion] = useState([]);
    const [locationInterViewSuggestionShow, setLocationInterViewSuggestionShow] = useState(false);
    // const { pathname } = useLocation();

    useEffect(() => {
        // window.addEventListener('resize', () => {
        //     return setWidth(window.innerWidth);
        // })
        fetchCategories();
        fetchCitySuggestions();
    }, [])
    useEffect(() => {

        setCitySuggestions(cityData.filter((item) => {
            return item.name.toLowerCase().startsWith(jobLocation.toLowerCase())
        }))
    }, [jobLocation])

    useEffect(() => {

        setLocationInterViewSuggestion(cityData.filter((item) => {
            return item.name.toLowerCase().startsWith(locationInterview.toLowerCase())
        }))
    }, [locationInterview])


    const todayDate = new Date(Date.now());
    var dd = todayDate.getDate();
    var mm = todayDate.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = todayDate.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const formValidation = () => {
        let isValid = true;
        let error = {};
        if (recruiterID === "") {
            isValid = false;
            error.recruiterId = "Recruiter ID is required !";
        }
        if (jobTitle === "") {
            isValid = false;
            error.jobTitle = "Job title is required";
        }
        if (employementType === "") {
            isValid = false;
            error.employementType = "Employment title is required";
        }
        if (jobLocation === "") {
            isValid = false;
            error.jobLocation = "Job location is required";
        }
        else {
            if (!validateCity(selectedKeyIndexJobLocation, jobLocation)) {
                isValid = false;
                error.jobLocation = "This city is invalid,please select a valid city";
            }
        }
        if (jobDescription === "") {
            isValid = false;
            error.jobDescription = "Job description is required";
        }
        if (noOfVacancies === "") {
            isValid = false;
            error.noOfVacancies = "No of Vacancies is required";
        }
        if (minExp === "") {
            isValid = false;
            error.minExp = "Minimum experience is required";
        }
        if (jobStartDate === "") {
            isValid = false;
            error.jobStartDate = "Job start date is required";
        }
        if (offeredSalary === "") {
            isValid = false;
            error.offeredSalary = "Offered salary is required";
        }

        if (companyName === "") {
            isValid = false;
            error.companyName = "Company Name is required";
        }
        if (category === "") {
            isValid = false;
            error.category = "Category is required";
        }

        if (recieveType === []) {
            isValid = false;
            error.recieveType = "Please select atleast one field";
        }
        else {
            if (recieveType.includes("External website")) {
                // console.log("External website")

                if (websiteLinkInterview === "") {
                    isValid = false;
                    error.websiteLinkInterview = "External field is required";
                }
            }

            if (contactNumberInterview === "") {
                isValid = false;
                error.contactNumberInterview = "Contact number is required";
            }
            else if (contactNumberInterview.length !== 10) {
                isValid = false;
                error.contactNumberInterview = "Please fill contact number with 10 characters";
            }

            if (recieveType.includes("Email")) {
                // console.log("Email")

                if (emailAddressInterview === "") {
                    isValid = false;
                    error.emailAddressInterview = "Email address is required";
                }
                else if (!emailRegex.test(emailAddressInterview)) {
                    isValid = false;
                    error.emailAddressInterview = "Please fill correct email address";
                }
            }
            if (recieveType.includes("Walk-in")) {
                // console.log("Walkin")

                if (locationInterview === "") {
                    isValid = false;
                    error.locationInterview = "Walk-in time is required";
                }
                else {
                    if (!validateCity(selectedKeyIndexLocationInterview, locationInterview)) {
                        isValid = false;
                        error.locationInterview = "This city is invalid,please select a valid city";
                    }
                }
                if (addressInterview === "") {
                    isValid = false;
                    error.addressInterview = "Address of interview is required";
                }
                if (dateInterview === "") {
                    isValid = false;
                    error.dateInterview = "Date of interview is required";
                }
                else {
                    let re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
                    if (!re.test(dateInterview)) {
                        error.dateInterview = "This is incorrect date format";
                    }
                }
                if (timeInterview === "") {
                    isValid = false;
                    error.timeInterview = "Time is required";
                }
                else {
                    let re = /^\d{1,2}:\d{2}([ap]m)?$/;

                    if (!re.test(timeInterview)) {
                        error.timeInterview = "This is incorrect time format";
                    }
                }
            }
        }
        if (uploadedAdCreative === "") {
            isValid = false;
            error.uploadedAdCreative = "Please upload the file";
        }
        setErrors(error);
        isValid ? uploadAd() : showErrorMessage(error);
    }

    const validateCity = (keyIndex, city) => {
        let filteredArr = (cityData.filter((val) => {
            return (keyIndex === val.KeyIndex && city.toLowerCase() === val.name.toLowerCase())
        }))
        if (filteredArr.length === 0) {
            return false;
        }
        return true
    }
    const uploadAd = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");


        var formdata = new FormData();
        formdata.append("RecruiterId", (recruiterID));
        formdata.append("FileUploads", uploadedAdCreative);
        formdata.append("JobTitle", jobTitle);
        formdata.append("EmploymentType", employementType);
        formdata.append("Category", category);
        formdata.append("JobLocation", selectedKeyIndexJobLocation);
        formdata.append("CompanyName", companyName);
        formdata.append("Jobdescription", jobDescription);
        formdata.append("NumberOfvacancies", noOfVacancies);
        formdata.append("MinimumExperience", minExp);
        formdata.append("JobStartDate", jobStartDate);
        formdata.append("offeredSalary", offeredSalary);
        formdata.append("RecieveApplication", recieveType.join(","));
        formdata.append("WebsiteAddress", websiteLinkInterview);
        formdata.append("ContactNumber", contactNumberInterview);
        formdata.append("ContactMail", emailAddressInterview);
        formdata.append("Address", addressInterview);
        formdata.append("WalkInInterview", (timeInterview + " " + dateInterview));
        formdata.append("InterviewLocation", (locationInterview));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/PostaJobSecond", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    console.log(result);
                    setIsModalOpen(true);
                    history.push('/BiDashboard/postAJob')
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })
    }
    const fetchCategories = () => {
        // window.location.reload(false);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            // "keyindex": localStorage.getItem("recruiterId"),
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/JobFunction", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {

                    setJobCategories(result.data)

                }
            })
            .catch(error => console.log('error', error))
            .finally(() => {
                setLoading(false);
            })

    }
    const showErrorMessage = (error) => {
        let alertMessage = "";
        for (const type in error) {
            alertMessage += error[type] + '\n';
        }
        alert(alertMessage)
    }
    const checkField = (e, name) => {
        if (e.target.checked === true) {
            setRecieveType([...recieveType, name])
        }
        else {
            let arr = recieveType;
            let arr1 = arr.filter(val => (val !== name))
            setRecieveType(arr1)
        }
    }
    const fetchCitySuggestions = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "domain": "City",
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/getResultviaDomain", requestOptions)
            .then(response => response.json())
            .then(result => {
                setCityData(result.data)
            })
            .catch(error => console.log('error', error));
    }
    const onModalCloseHandler = () => {
        setIsModalOpen(false);
    };

    const getRecruiterInfoAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": recruiterID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/recruiter", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "SUCCESS") {
                    setRecruiterData(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }
    const employmentTitles = [
        {
            type: "Choose one"
        },
        {
            type: "Full time"
        }, {
            type: "Part time"
        }, {
            type: "Remote work"
        }, {
            type: "Temporary"
        }, {
            type: "Internship"
        }]

    const salaryRange = [
        {
            type: "Choose the range"
        },
        {
            type: "0 - 1 lac"
        },
        {
            type: "1 - 3 lac"
        },
        {
            type: "3 - 5 lac"
        },
        {
            type: "5 - 8 lac"
        },
        {
            type: "8 - 12 lac"
        },
        {
            type: "12 lac +"
        }
    ];





    return (
        <div className={classes.top_div}>
            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Post A Job</div>
                        <Link to="/BiDashboard/Dashboard" className={classes.BackButton}>
                            <BackButton />
                        </Link>
                    </div>
                    <div className={classes.recruiterInfo}>
                        <EventInputs
                            input={"SingleLineInput"}
                            label={"Get Recruiter Info"}
                            placeholder={"Enter the recruiter ID"}
                            onChange={(e) => setRecruiterID(e.target.value)}
                            value={recruiterID}
                        />
                        <div className={classes.btn_button}>
                            <button type="submit" className={classes.submitButton} onClick={() => recruiterID ? getRecruiterInfoAPI() : alert("Please Enter Recruiter ID")}>
                                Get
                            </button>
                        </div>
                    </div>
                    {
                        recruiterData &&
                        (<div>
                            <div>1) Company ID: {recruiterData.recruiterId}</div>
                            <div>2) Company Name: {recruiterData.CompanyName}</div>
                            <div>3) Company Mail: {recruiterData.Contactmail}</div>
                            <div>4) Company Phone: {recruiterData.ContactPerson}</div>

                        </div>)}

                    <div className={classes.Form_Heading}>
                        Fill out the form below to post your job.
                    </div>

                    <div className={classes.form_input}>
                        <div className={classes.input}>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Job title"}
                                placeholder={"Enter the title of your job"}
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                            />

                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Job location"}
                                placeholder={"Job location"}
                                onChange={(e) => { setJobLocation(e.target.value); setCitySuggestionsShow(e.target.value ? true : false) }}
                                value={jobLocation}
                            />
                            <div className={classes.citySuggestion}>
                                {
                                    citySuggestionsShow &&
                                    (
                                        citySuggestions.map((item) => {
                                            return <div onClick={() => { setJobLocation(item.name); setSelectedKeyIndexJobLocation(item.KeyIndex); setCitySuggestionsShow(false) }}>{item.name}</div>
                                        })
                                    )
                                }
                            </div>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Company Name"}
                                placeholder={"Company Name"}
                                onChange={(e) => setCompanyName(e.target.value)}
                                value={companyName}
                            />

                            <div className={classes.input}>
                                <div className={classes.fieldName}>
                                    <label htmlFor="dropdown">Category</label>
                                </div>
                                <div className={classes.fields1}>
                                    <select onChange={(e) => setCategory(e.target.value)} className={classes.dropdownInput} >
                                        {
                                            jobCategories.map((item) => {
                                                return <option value={item.keyIndex}>{item.Jobfunction}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <EventInputs
                                input={"smalldropdown"}
                                label={"Employment title"}
                                placeholder={"Course Group"}
                                onChange={(e) => setEmployementType(e.target.value)}
                                displayData={employmentTitles}
                                value={employementType}
                            />
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Number of vacancies"}
                                placeholder={"Number of vacancies"}
                                onChange={(e) => setNoOfVacancies(e.target.value)}
                                value={noOfVacancies}
                            />
                            <EventInputs
                                input={"MultiLineInput"}
                                label={"Job Description(Maximum upto 300 words)"}
                                placeholder={"Job Description"}
                                onChange={(e) => setJobDescription(e.target.value)}
                                value={jobDescription}
                            />

                        </div>
                        <div className={classes.input}>
                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Minimum experience required(In year)"}
                                placeholder={"Minimum experience required(In year)"}
                                onChange={(e) => setMinExp(e.target.value)}
                                value={minExp}
                            />
                            <EventInputs
                                input={"DateInput"}
                                label={"Job Start Date"}
                                placeholder={"Job Start Date"}
                                onChange={(e) => setJobStartDate(e.target.value)}
                                value={jobStartDate}
                            />
                            <EventInputs
                                input={"smalldropdown"}
                                label={"Offered Salary"}
                                placeholder={"Choose the range"}
                                onChange={(e) => setOfferedSalary(e.target.value)}
                                displayData={salaryRange}
                            // value={type}
                            />
                            <div className={classes.CheckBoxHeading}>Recieve application by :</div>
                            <div className={classes.checkbox}>
                                <div className={classes.checkboxposition}>
                                    <div>External website</div>
                                    <input type="Checkbox"
                                        className={classes.checkbox_input}
                                        name="ExternalWebsiteInterview" id="ExternalWebsiteInterview" checked={recieveType.includes("External website")} onChange={(e) => checkField(e, "External website")}
                                    />
                                </div>
                                <div className={classes.checkboxposition}>
                                    <div>Email</div>
                                    <input type="Checkbox"
                                        className={classes.checkbox_input}
                                        name="EmailInterview" id="EmailInterview" checked={recieveType.includes("Email")} onChange={(e) => checkField(e, "Email")}
                                    />
                                </div>
                                <div className={classes.checkboxposition}>
                                    <div>Call</div>
                                    <input type="Checkbox"
                                        className={classes.checkbox_input}
                                        name="CallInterview" id="CallInterview" checked={recieveType.includes("Call")} onChange={(e) => checkField(e, "Call")}
                                    />
                                </div>
                                <div className={classes.checkboxposition}>
                                    <div>Walk-in</div>
                                    <input type="Checkbox"
                                        className={classes.checkbox_input}
                                        name="WalkInInterview" id="WalkInInterview" checked={recieveType.includes("Walk-in")} onChange={(e) => checkField(e, "Walk-in")}
                                    />
                                </div>
                            </div>
                            <div className={classes.CheckBoxHeading}>How would you like to recieve application?</div>
                            {recieveType.includes("External website") &&
                                <EventInputs
                                    input={"SingleLineInput"}
                                    label={" Website address"}
                                    placeholder={"Website address"}
                                    onChange={(e) => setWebsiteLinkInterview(e.target.value)}
                                    value={websiteLinkInterview}
                                />

                            }

                            {recieveType.includes("Email") &&
                                <EventInputs
                                    input={"email"}
                                    label={"Email address"}
                                    placeholder={"Enter the email address"}
                                    onChange={(e) => setEmailAddressInterview(e.target.value)}
                                    value={emailAddressInterview}
                                />

                            }


                            <EventInputs
                                input={"SingleLineInput"}
                                label={" Contact number"}
                                placeholder={"Contact number"}
                                onChange={(e) => setContactNumberInterview(e.target.value)}
                                value={contactNumberInterview}
                            />

                            {recieveType.includes("Walk-in") &&
                                <><EventInputs
                                    input={"SingleLineInput"}
                                    label={"Location of interview"}
                                    placeholder={"Enter the location of interview"}

                                    onChange={(e) => { setLocationInterview(e.target.value); setLocationInterViewSuggestionShow(e.target.value ? true : false) }}
                                    value={locationInterview}
                                />
                                    <div className={classes.citySuggestion}>
                                        {
                                            locationInterViewSuggestionShow &&
                                            (
                                                locationInterViewSuggestion.map((item) => {
                                                    return <div onClick={() => {
                                                        setLocationInterview(item.name);
                                                        selectedKeyIndexLocationInterview(item.KeyIndex); setLocationInterViewSuggestionShow(false)
                                                    }}>{item.name}</div>
                                                })
                                            )
                                        }
                                    </div>
                                    <EventInputs
                                        input={"MultiLineInput"}
                                        label={"Address"}
                                        placeholder={"Enter the address of interview"}
                                        onChange={(e) => setAddressInterview(e.target.value)}
                                        value={addressInterview}
                                    />
                                    <div className={classes.walkInDateTime}>
                                        <div>
                                            <input onFocus={(e) => (e.target.type = "date")} className={classes.inputField} type="text" placeholder="Date(dd/mm/yyyy)" pattern="\d{1,2}/\d{1,2}/\d{4}" onChange={(e) => {
                                                var re = /^[/0-9_]*$/;
                                                re.test(e.target.value) ? setDateInterview(e.target.value) : setDateInterview(dateInterview);
                                            }} value={dateInterview} />

                                        </div>
                                        <div>
                                            <input className={classes.inputField} type="text" placeholder="Time(20:40 or 8:40pm)" pattern="\d{1,2}:\d{2}([ap]m)?" onChange={(e) => {
                                                setTimeInterview(e.target.value)
                                            }} value={timeInterview} />


                                        </div>
                                    </div>
                                </>
                            }
                            <EventInputs
                                input={"ImageInput"}
                                label={"Ad Creative"}
                                placeholder={"Ad Creative"}
                                onChange={(e) => setUploadedAdCreative(e.target.files[0])}
                                type={"file"}
                                accept=".png, .jpg, .jpeg"
                            />
                        </div>

                    </div>
                </div>
                <button className={classes.Upload_button} onClick={() => formValidation()}>
                    {loading ? (
                        <Loader type="TailSpin" color="white" height={20} width={20} />
                    ) : (
                        <>
                            submit
                        </>
                    )}
                </button>
            </div>
            <Modal isModalOpen={isModalOpen} onClose={onModalCloseHandler} />
        </div>
    );
};

export default PostAJobComponent;
