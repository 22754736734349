import React, { useState } from "react";
import classes from "./AddLeadersOfChange.module.css";
import { Link, useHistory, useParams } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";
import BackButton from "../../Components/BackButton";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";
import Loader from "react-loader-spinner";
import TextEditor from "../../Components/TextEditor";
import { useEffect } from "react";
import { AiFillCopy } from "react-icons/ai";

const EditLeadersOfChange = () => {
  const history = useHistory();

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [webImage, setWebImage] = useState("");
  const [description, setDescription] = useState("");
  const [webImageAltTag, setWebImageAltTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [shortDescrition, setShortDescription] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [showImages, setShowImages] = useState([]);
  const [isLive, setIsLive] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getManagerDetailByID();
    multipleImagesUpload();
  }, []);

  const multipleImagesUpload = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/UploadMultipleImagesLeadersofChange",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setShowImages(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getManagerDetailByID = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("locUUID", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/LeadersofChangeByID",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setName(result.data.Name);
          setWebImage(result.data.Logo);
          setDescription(result.data.Description);
          setWebImageAltTag(result.data.imageAltTag);
          setMetaTitle(result.data.MetaTitle);
          setMetaDescription(result.data.MetaDescription);
          setShortDescription(result.data.ShortDescription);
          setCompanyWebsite(result.data.CompanyWebsite);
          setSeoUrl(result.data.SeoURL);
          setIsLive(result.data.IsLive == 1 ? true : false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateGreatManagerInstitute = (e) => {
    e.preventDefault();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("locUUID", id);
    formdata.append("Name", name);
    formdata.append("Logo", typeof webImage === "string" ? "" : webImage);
    formdata.append("imageAltTag", webImageAltTag);
    formdata.append("ShortDescription", shortDescrition);
    formdata.append("CompanyWebsite", companyWebsite);
    formdata.append("MetaTitle", metaTitle);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("Description", description);
    formdata.append("SeoURL", seoUrl);
    formdata.append("IsLive", isLive ? 1 : 0);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EditLeadersofChange",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/leadersOfChange")}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Edit Leaders of Change</div>
            <Link
              to="/BiDashboard/leadersOfChange"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <form
            onSubmit={updateGreatManagerInstitute}
            className={classes.form_input}
          >
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                required={true}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Image "}
                placeholder={"Image "}
                onChange={(e) => setWebImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg"
              />
              {typeof webImage === "string" ? (
                <img src={webImage} className={classes.showImage} />
              ) : (
                <img
                  src={URL.createObjectURL(webImage)}
                  className={classes.showImage}
                />
              )}
              <EventInputs
                input={"SingleLineInput"}
                label={"SEO Url"}
                placeholder={"SEO Url"}
                onChange={(e) => setSeoUrl(e.target.value)}
                value={seoUrl}
                // required={true}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Description"}
                placeholder={"Meta Description"}
                // required={true}
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Website"}
                placeholder={"Company Name"}
                // required={true}
                onChange={(e) => setCompanyWebsite(e.target.value)}
                value={companyWebsite}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Logo Alt Tag"}
                placeholder={"Logo Alt Tag"}
                onChange={(e) => setWebImageAltTag(e.target.value)}
                value={webImageAltTag}
                // required={true}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Title"}
                placeholder={"Meta Title"}
                // required={true}
                onChange={(e) => setMetaTitle(e.target.value)}
                value={metaTitle}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Title"}
                placeholder={"Title"}
                // required={true}
                onChange={(e) => setShortDescription(e.target.value)}
                value={shortDescrition}
              />
            </div>
            <div className={classes.multipleImageContainer}>
              <div className={classes.formGroup}>
                <label htmlFor="image"> Multiple Image : </label>
                <input
                  multiple
                  type="file"
                  // required
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    multipleImagesUpload(e);
                  }}
                />
              </div>
              <div className={classes.gridContainer}>
                {showImages.map((item, index) => (
                  <div className={classes.ImageUrlContainer}>
                    <img
                      src={item.imageURL}
                      alt="hello"
                      className={classes.MultipleImgSelect}
                    />
                    {/* <AiOutlineCloseCircle
                      className={classes.RemoveImgIcon}
                      onClick={() => RemoveMultipleImage(item.id)}
                    /> */}
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(item.imageURL);
                        alert("Copied");
                      }}
                      className={classes.CopyURLIcon}
                    >
                      <AiFillCopy size={22} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="Content">Description :</label>

              <div>
                <TextEditor
                  onChangeContent={setDescription}
                  value={description}
                />
              </div>
            </div>

            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                checked={isLive}
                onChange={() => setIsLive(!isLive)}
                label={"Want to publish this manager"}
                value={isLive}
              />
              <div className={classes.btnWrapper}>
                <button
                  type="submit"
                  className={classes.Upload_button}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader
                      type="ThreeDots"
                      color="#fff"
                      height={10}
                      width={70}
                      visible={true}
                      //3 secs
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLeadersOfChange;
