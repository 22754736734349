import React, { useState, useEffect } from "react";
import classes from "./actofcompassion.module.css";
import LoadingSpinner from "../LoadingSpinner";
import Modal from "../Modal";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import FormModel from "./FormModel";
import { BsEye } from "react-icons/bs";

// import Modal from "../Components/Modal";
const ActOfCompassionList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [actofcompassion, setActOfCompassion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelData, setModelData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ActList();
  }, [currentPage]);
  console.log(currentPage, "test");

  const ActList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/GetCompassionAwards",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setActOfCompassion(result.data);
          console.log(result, "test");
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Act Of Compassion List",
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <>
      <FormModel
        isModalOpen={isModalOpen}
        data={modelData}
        setIsModalOpen={setIsModalOpen}
        onClose={onModalCloseHandler}
      />{" "}
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}></div>

        <div className={classes.table_container}>
          <div className={classes.buttons1}>
            <div>
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                className={classes.buttonone}
              >
                Prev
              </button>

              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </div>
          </div>
          <div className={classes.container}>
            {isLoading ? (
              <div className={classes.spinner}>
                <LoadingSpinner />{" "}
              </div>
            ) : data.length ? (
              <>
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                    <th className={classes.table_heading}>Entity Name</th>
                    <th className={classes.table_heading}>Contact Person</th>
                    <th className={classes.table_heading}>Designation</th>
                    <th className={classes.table_heading}>Phone Number</th>
                    <th className={classes.table_heading}>Email</th>
                    <th className={classes.table_heading}>Office Address</th>
                    <th className={classes.table_heading}>Type Of Company</th>

                    <th className={classes.table_heading}>Action</th>
                  </tr>

                  <tbody>
                    {actofcompassion.map((item, i) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{i + 1}</td>

                          <td className={classes.table_td}>
                            {item.EntityName}
                          </td>
                          <td className={classes.table_td}>
                            {item.ContactPerson}
                          </td>
                          <td className={classes.table_td}>
                            {item.Designation}
                          </td>
                          <td className={classes.table_td}>
                            {item.PhoneNumber}
                          </td>
                          <td className={classes.table_td}>{item.Email}</td>
                          <td className={classes.table_td}>
                            {item.OfficeAddress}
                          </td>
                          <td className={classes.table_td}>
                            {item.TypeOfCompany}
                          </td>

                          <td
                            className={classes.table_td}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModelData(item);
                              setIsModalOpen(true);
                            }}
                          >
                            <span className={classes.table_td_Link}>
                              <BsEye />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h2 className={classes.serachErrorText}>"No Data found !"</h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActOfCompassionList;
