import React, { useEffect, useState } from "react";
import EventInputs from "./EventInputs";
import classes from "./AddEvents.module.css";
import { Link, useHistory, useParams } from "react-router-dom";
import BackButton from "../BackButton";
import Modal from "../Modal";

import "suneditor/dist/css/suneditor.min.css";

const EditYoutubeVideo = () => {
  const history = useHistory();
  const { id } = useParams();
  const [offerTitle, setOfferTitle] = useState("");
  const [mobileImage, setMobileImage] = useState("");
  const [editableCImage1, setEditableCImage1] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [check, setCheck] = useState(0);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    NewEvent();
  }, []);

  const NewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ForHerVideoID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/ForHerVideoDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "jjj");
        if (result.status === "Success" && result.status_code === 200) {
          setOfferTitle(result.data.Title);
          setMobileImage(result.data.Image);
          setCheck(+result.data.IsLive);
          setUrl(result.data.YoutubeUrl);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateOffer = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("ForHerVideoID", id);
    formdata.append("Title", offerTitle);
    formdata.append("YoutubeUrl", url);
    formdata.append(
      "Image",
      typeof mobileImage === "object" ? mobileImage : ""
    );
    formdata.append("IsLive", check);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/ForHerVideoUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/youtube-video")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Edit Youtube Video</div>
            <Link
              to="/BiDashboard/youtube-video"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Youtube Title"}
                placeholder={"Youtube Title"}
                onChange={(e) => setOfferTitle(e.target.value)}
                value={offerTitle}
              />

              <div className={classes.mt2}>
                {editableCImage1 ? (
                  <div>
                    <div className={classes.fieldName}>
                      <label htmlFor="input7">Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          {" "}
                          <img
                            src={mobileImage}
                            alt=""
                            height={50}
                            width={50}
                          />
                        </div>
                        <div>
                          {" "}
                          <button
                            onClick={() => setEditableCImage1(false)}
                            className={classes.input_button}
                          >
                            edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <EventInputs
                    input={"ImageInput"}
                    label={"Image "}
                    placeholder={"Image"}
                    onChange={(e) => setMobileImage(e.target.files[0])}
                    type={"file"}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
              </div>
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Youtube Url"}
                placeholder={"Youtube Url"}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
            </div>
            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                label={"Want to publish this offer"}
                value={check}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => updateOffer()}>
          submit
        </button>
      </div>
    </div>
  );
};

export default EditYoutubeVideo;
