/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./landingpage.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [landingpagedata, setLandingpagedata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    ImpressionApi();
  }, [currentPage]);

  const ImpressionApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/LandingDataCount",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLandingpagedata(result.data);
        setCount(result.totalCount);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>Landing Page</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={landingpagedata}
            filename={"Landingpage.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={landingpagedata} fileName="Landingpage" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => ImpressionApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{currentPage}</span>
        {landingpagedata.length > 9 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Sno</th>
                <th className={classes.table_heading}>Date</th>
                <th className={classes.table_heading}>Page View</th>
                <th className={classes.table_heading}>Entrance</th>
                <th className={classes.table_heading}>Redirected From</th>
                <th className={classes.table_heading}>View</th>
              </tr>

              <tbody>
                {landingpagedata.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.srno}</td>
                      <td className={classes.table_td}>{item.date}</td>
                      <td className={classes.table_td}>{item.pageview}</td>
                      <td className={classes.table_td}>{item.entrence}</td>
                      <td className={classes.table_td}>
                        {item.redirectedFrom}
                      </td>
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/landingpageDetail/" + item.date}
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
