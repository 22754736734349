import React from 'react'
import classes from '../Component/Dashboard.module.css'

const DashboardButton = (props) => {
    
    return (
        <div >
            <button type = "submit" className={classes.themeButton} onClick={props.onClick}>{props.buttontext}</button>
        </div>
    )
}

export default DashboardButton
