import React, { useState, useEffect } from 'react';
import { FaRupeeSign, FaArrowUp, FaBriefcase } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';

import Card from './Card';
import DashboardHeader from './DashboardHeader';
import classes from './Jobs.module.css'

const Jobs = (props) => {
    const [showJobs, setShowJobs] = useState(false);
    const [isLoading, setLoading] = useState(true);

    return (
        <div>
            {!showJobs ?
                <DashboardHeader onClick={() => setShowJobs(true)} heading={"Jobs"} />
                :
                <div className={classes.dashboard_jobs_container}>
                    <div className={classes.dashboard_jobs_open}>
                        <button onClick={() => setShowJobs(false)} className={classes.jobs_button}><h3>Jobs</h3></button>
                        <button onClick={() => setShowJobs(false)}>  <FaArrowUp /></button>
                    </div>
                    {props.isLoading ? (<div className={classes.spinner}><LoadingSpinner /></div>)
                        :
                        (<>
                            <div className={classes.dashboard_container}>
                                <h3 className={classes.job_title}>Today's Data</h3>
                                <div className={classes.card}>

                                    <Card UserProfile={"Job request"} value={props.apidata.dailyJRequest} />
                                    <Link to="/BiDashboard/PaymentSuccToday" className={classes.LinkCard}> <Card UserProfile={"Payment Success"} value={props.apidata.dailyPsuccess} /></Link>
                                    <Link to="/BiDashboard/PaymentFailToday" className={classes.LinkCard}><Card UserProfile={"Payment Fail"} value={props.apidata.dailyPFail} /></Link>
                                    <Link to="/BiDashboard/EasyApplyToday" className={classes.LinkCard}><Card UserProfile={"Easy Apply Clicks"} value={props.apidata.easyApplyT} /></Link>

                                </div>
                                <div className={classes.card}>

                                    <Card UserProfile={"Print Jobs"} value={props.apidata.totalPrintJobsT} />
                                    <Link to="/BiDashboard/OnlineJobs" className={classes.LinkCard}><Card UserProfile={"Online Jobs"} value={props.apidata.totalOnlineJobsT} /></Link>
                                    <Card UserProfile={"Other Jobs"} value={props.apidata.totalOtherJobsT} />

                                </div>
                            </div>
                            <div className={classes.dashboard_container}>
                                <h3 className={classes.job_title}>Monthly Data</h3>
                                <div className={classes.card}>

                                    <Card UserProfile={"Job request"} value={props.apidata.monthlyJRequst} />
                                    <Link to="/BiDashboard/PaymentSuccMonthly" className={classes.LinkCard}><Card UserProfile={"Payment Success"} value={props.apidata.monthlyPsuccess} /></Link>
                                    <Link to="/BiDashboard/PaymentFailMonthly" className={classes.LinkCard}><Card UserProfile={"Payment Fail"} value={props.apidata.monthlyPFail} /></Link>
                                    <Link to="/BiDashboard/EasyApplyMonthly" className={classes.LinkCard}> <Card UserProfile={"Easy Apply Clicks"} value={props.apidata.easyApplyM} /></Link>

                                </div>
                                <div className={classes.card}>

                                    <Card UserProfile={"Print Jobs"} value={props.apidata.totalPrintJobsM} />
                                    <Link to="/BiDashboard/OnlineJobsMonthly" className={classes.LinkCard}><Card UserProfile={"Online Jobs"} value={props.apidata.totalOnlineJobsM} /></Link>
                                    <Card UserProfile={"Other Jobs"} value={props.apidata.totalOtherJobsM} />

                                </div>
                            </div>
                            <div className={classes.dashboard_container}>
                                <h3 className={classes.job_title}>Packages</h3>
                                <table className={classes.dashborad_table}>
                                    <tr className={classes.table_row}>
                                        <th className={classes.table_heading}>Count</th>
                                        <th className={classes.table_heading}>Package 1</th>
                                        <th className={classes.table_heading}>Package 2</th>
                                        <th className={classes.table_heading}>Package 3</th>
                                    </tr>
                                    <tr className={classes.table_row}>
                                        <td className={classes.table_td}>Count</td>
                                        <td className={classes.table_td}>N/A</td>
                                        <td className={classes.table_td}>N/A</td>
                                        <td className={classes.table_td}>N/A</td>
                                    </tr>
                                    <tr className={classes.table_row}>
                                        <td className={classes.table_td}>Rs</td>
                                        <td className={classes.table_td}>N/A</td>
                                        <td className={classes.table_td}>N/A</td>
                                        <td className={classes.table_td}>N/A</td>
                                    </tr>
                                </table>
                            </div>
                        </>)
                    }
                </div>
            }

        </div>
    )
}

export default Jobs
