import React, { useEffect, useState } from 'react'
import classes from './FetchAds.module.css'
import { MdError } from 'react-icons/md';
import Loader from 'react-loader-spinner';
import { useHistory, Link } from 'react-router-dom';
import { AdComp } from './FetchAds';

const PostAJobsAdsList = () => {
    const [fetchAdsData, setFetchAdsData] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchAds = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "start_date": (startDate),
            "end_date": (endDate),
            "page": currentPage
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/getListofPostAJobs", requestOptions)
            .then(response => response.json())
            .then(result => {
                setFetchAdsData(result.data);
                setCount(result.count)
            })
            .catch(error => console.log('error', error))
            .finally(() => { setLoading(false) })

    }
    useEffect(() => {
        fetchAds()
    }, [currentPage])
    return (
        <div className={classes.screen}>
            {/* <div>Total fetched:{totalAdfetcheds}</div> */}
            <div className={classes.screenDiv}>
                <div className={classes.dateDiv}>
                    <label className={classes.dateLable}>Start Date</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <label className={classes.dateLable}>End Date</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className={classes.buttons}>
                    <button type="button" className="button" onClick={() => { fetchAds() }}> Search</button>
                </div>
                <div className={classes.header}>
                    <div>Total ads:{count}</div>
                    <div className={classes.buttons1}>

                        {currentPage > 0 ? <button onClick={() => { setCurrentPage(currentPage - 1) }}>Prev</button> : <></>}
                        <span>{currentPage}</span>
                        <button onClick={() => { setCurrentPage(currentPage + 1) }}>Next</button>
                    </div>

                </div>
                {loading ? <div className={classes.msg} ><Loader type="TailSpin" color="#00BFFF" height={80} width={80} /> </div> : <>{fetchAdsData.length === 0 ? <div className={classes.msg}><MdError className={classes.icon} /><p>Data not found</p></div> :
                    fetchAdsData.map((item, key) => {
                        return <Link to={"/postAJob/jobDashboard/" + item.orderNumber} className={classes.inboxLink} > <AdComp item={item} adType={"inbox"} /></Link>
                    })}</>}
            </div>
        </div>

    )

};

export default PostAJobsAdsList;