import React from 'react'
import PostAJobComponent from '../Components/PostAJobComponent'

const PostAJob = () => {
    return (
        <PostAJobComponent />
    )
}

export default PostAJob
