import React, { useState } from "react";
import classes from "./AddGMI.module.css";
import { Link, useHistory, useParams } from "react-router-dom";

import "suneditor/dist/css/suneditor.min.css";
import BackButton from "../../Components/BackButton";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";
import Loader from "react-loader-spinner";
import TextEditor from "../../Components/TextEditor";
import { useEffect } from "react";

const AddGreatManagerInstitute = () => {
  const history = useHistory();

  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [webImage, setWebImage] = useState("");
  const [description, setDescription] = useState("");
  const [webImageAltTag, setWebImageAltTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [isLive, setIsLive] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getManagerDetailByID();
  }, []);

  const getManagerDetailByID = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("ManagerUUID", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/GeneralManagerDetailByID",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setName(result.Data.ManagerName);
          setDesignation(result.Data.Designation);
          setCompanyName(result.Data.CompanyName);
          setSerialNumber(result.Data.SrNo);
          setSeoUrl(result.Data.ManagerSeoURL);
          setWebImage(result.Data.ManagerImage);
          setDescription(result.Data.ManagerDescription);
          setWebImageAltTag(result.Data.ManagerImageAltTag);
          setMetaTitle(result.Data.MetaTitle);
          setMetaDescription(result.Data.MetaDescription);
          setIsLive(result.Data.IsLive == 1 ? true : false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateGreatManagerInstitute = (e) => {
    e.preventDefault();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append("ManagerUUID", id);
    formdata.append("SrNo", serialNumber);
    formdata.append("ManagerName", name);
    formdata.append("Designation", designation);
    formdata.append("Qualification", "");
    formdata.append(
      "ManagerImage",
      typeof webImage === "string" ? "" : webImage
    );
    formdata.append("ManagerImageAltTag", webImageAltTag);
    formdata.append("CompanyName", companyName);
    formdata.append("MetaTitle", metaTitle);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("ManagerDescription", description);
    formdata.append("ManagerSeoURL", seoUrl);
    formdata.append("IsLive", isLive ? 1 : 0);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/v1/admin1_1/GeneralManagerUpdate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/greatManagerInstitute")}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>
              Edit Great Manager Institute
            </div>
            <Link
              to="/BiDashboard/greatManagerInstitute"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <form
            onSubmit={updateGreatManagerInstitute}
            className={classes.form_input}
          >
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                required={true}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Name"}
                placeholder={"Company Name"}
                required={true}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"SEO Url"}
                placeholder={"SEO Url"}
                onChange={(e) => setSeoUrl(e.target.value)}
                value={seoUrl}
                // required={true}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Alt Tag"}
                placeholder={"Image Alt Tag"}
                onChange={(e) => setWebImageAltTag(e.target.value)}
                value={webImageAltTag}
                required={true}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Description"}
                placeholder={"Meta Description"}
                required={true}
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Designation"}
                placeholder={"Designation"}
                onChange={(e) => setDesignation(e.target.value)}
                value={designation}
                required={true}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Serial Number"}
                placeholder={"Serial Number"}
                required={true}
                onChange={(e) => setSerialNumber(e.target.value)}
                value={serialNumber}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Image "}
                placeholder={"Image "}
                onChange={(e) => setWebImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg"
              />
              {typeof webImage === "string" ? (
                <img src={webImage} className={classes.showImage} />
              ) : (
                <img
                  src={URL.createObjectURL(webImage)}
                  className={classes.showImage}
                />
              )}
              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Title"}
                placeholder={"Meta Title"}
                required={true}
                onChange={(e) => setMetaTitle(e.target.value)}
                value={metaTitle}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="Content">
                Description :{/* ( Length : {removeTags(content).length}) */}
              </label>

              <div>
                <TextEditor
                  onChangeContent={setDescription}
                  value={description}
                />
              </div>
            </div>

            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                checked={isLive}
                onChange={() => setIsLive(!isLive)}
                label={"Want to publish this manager"}
                value={isLive}
              />
              <div className={classes.btnWrapper}>
                <button
                  type="submit"
                  className={classes.Upload_button}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader
                      type="ThreeDots"
                      color="#fff"
                      height={10}
                      width={70}
                      visible={true}
                      //3 secs
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddGreatManagerInstitute;
