import React from "react";
import classes from "./Dashboard.module.css";


const AdsEntry = (props) => {


 

  const comp = () => {
    switch (props.label) {
      case "Locations":
        return (
          <>
           <option value={""} selected ={true} disabled = {true}  >Choose one option</option>
            {
              
              !!props.displayData &&
              props.displayData.map((item, id) => {
                return <option   key = {item.id}  >{item.locName}</option>
              })
            }
          </>
        )
        case "Location":
        return (
          <>
          <option value={""} selected ={true} disabled = {true}  >Choose one option</option>
            {
              !!props.displayData &&
              props.displayData.map((item, id) => {
                return <option selected = {props.defaultLocation === item.locName  ? true : false} key = {item.id} value={item.id}>{item.locName}</option>
              })
            }
          </>
        )
      case "Level":
        return (
          <>
          <option value={""} selected ={true} disabled = {true}  >Choose one option</option>
            {
              !!props.displayData &&
              props.displayData.map((item, id) => {
                return <option value = {item.id} key = {item.id}>{item.levelName}</option>
              })
            }
          </>
        )
      case "Job Function":
        return (
          <>
          <option  selected ={true} disabled = {true}  >Choose one option</option>
            {
              !!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key = {item.id} value = {item.id}>{item.jobName}</option>
              })
            }
          </>
        )
        case "Industry":
        return (
          <>
          <option value={""} selected ={true} disabled = {true}  >Choose one option</option>
            {
              !!props.displayData &&
              props.displayData.map((item, id) => {
                return <option selected={props.defaultIndustry === item.industryName ? true : false} key = {item.id} value ={item.id}>{item.industryName}</option>
              })
            }
          </>
        )
        case "Region":
        return (
          <>
          <option value={""} selected ={true} disabled = {true}  >Choose one option</option>
            {
              !!props.displayData &&
              props.displayData.map((item, id) => {
                return <option selected= {props.defaultRegion === item.code && item.name ? true : false} key = {item.id} value ={item.id}>{item.code}-{item.name}</option>
              })
            }
          </>
        )
        case "Currency":
          return (
            <>
              {
                !!props.displayData &&
                props.displayData.map((item, id) => {
                  return <option selected={props.defaultCurrency === item.type ? true : false} key = {item.id} value ={item.id}  >{item.cc}</option>
                })
              }
            </>
          )
          case "Employment Type":
            return (
              <>
              <option value={""} selected ={true} disabled = {true}  >Choose one option</option>
                {
                  !!props.displayData &&
                  props.displayData.map((item, id) => {
                    return <option selected={props.defaultEmpType === item.type ? true : false} key = {item.id} value ={item.id}>{item.type}</option>
                  })
                }
              </>
            )
      default:
        break;
    }
  }
  
  const inputType = (type) => {
   
    switch (type) {
      case "SingleLineInput":
        return (
          <div>
            <div className={classes.fieldName}>
              <label htmlFor="input">
                {props.label}{" "}
                <span className= {classes.mandatoryIcon}>{props.text}</span>
              </label>
            </div>
            <div className={classes.fields1}>
              <input type="text" name = {props.name} onChange={props.onChange} value={props.value} className={classes.inputField} autoComplete="off" />
            </div>
          </div>
        );

      case "Mobile":
        return (
          <div>
            <div className={classes.fieldName}>
              <label htmlFor="input1">{props.label} 
              <span className= {classes.mandatoryIcon}>{props.text}</span></label>
            </div>
            <div className={classes.fields1}>
              <input
                type="text"
                id=" Mobile"
                name="Mobile"
                value={props.value}
                onChange={props.onChange}
                className={classes.inputField}
              />
            </div>
          </div>
        );
      case "email":
        return (
          <div>
            <div className={classes.fieldName}>
              <label htmlFor="input2">{props.label} 
              <span className= {classes.mandatoryIcon}>{props.text}</span></label>
            </div>
            <div className={classes.fields1}>
              <input
                type="text"
                id="Email"
                name="Email"
                value={props.value}
                onChange={props.onChange}
                className={classes.inputField}
              />
            </div>
          </div>
        );
      case "MultiLineInput":
        return (
          <div>
            <div className={classes.fieldName}>
              <label htmlFor="input3">{props.label}
              <span className= {classes.mandatoryIcon}>{props.text}</span>  </label>
            </div>
            <div className={classes.fields2}>
              <textarea
                rows="10"
                cols="30"
                name="text"
             
                onChange={props.onChange}
                value={props.value}
                className={classes.multiLineTextBox}
              />
            </div>
          </div>
        );
        
      case "smalldropdown":
        return (
          <div >
            <div className={classes.fieldName}>
              <label htmlFor="dropdown">{props.label} 
              <span className= {classes.mandatoryIcon}>{props.text}</span></label>
            </div>
            <div className={classes.fields3}>
              <select onChange={props.onChange} value = {props.value} >
             
                {
                   
                  comp()
                }
              </select>
            </div>
          </div>
        );
      case "largedropdown":
        return (
          <div>
            <div className={classes.fieldName}>
              <label htmlFor="dropdown">{props.label}
              <span className= {classes.mandatoryIcon}>{props.text}</span></label>
            </div>
            <div className={classes.fields3}>
              <select onChange= {props.onChange} > 
             
                {
                  comp()
                }
              </select>
            </div>
          </div>
        );
      case "DateInput":
        return (
          <div>
            <div className={classes.fieldName}>
              <label htmlFor="input3">
                {props.label}
                <span className= {classes.mandatoryIcon}>{props.text}</span>
              </label>
            </div>
            <div className={classes.fields1}>
              <input type="date" id="date" name="date" className={classes.inputField} onChange = {props.onChange} />
            </div>
          </div>
        );
        case "checkBox":
        return (
          <div className={classes.checkbox}>
            <div >
              <label htmlFor="input4">
                {props.label}
                <span className= {classes.mandatoryIcon}>{props.text}</span>
              </label>
            </div>
            <div>
              <input type="checkbox" id={props.id} name={props.name}  onChange = {props.onChange} checked={props.checked==="1"?true:false} />
            </div>
          </div>
        );
        case "File": 
        return(
          <div>
            <div>
              <label htmlFor = "input 5">
                {props.label} 
                <span className= {classes.mandatoryIcon}>{props.text}</span>
              </label>
            </div>
            <div>
              <input type = "file" onChange = {props.onChange} value = {props.value} accept = {props.accept} name = {props.name}/>
            </div>
          </div>
        )
        case "Date":
          return(
            <div className = {classes.dateInput}>
            <div >
              <label htmlFor = "input 5">
                {props.label} 
                <span className= {classes.mandatoryIcon}>{props.text}</span>
              </label>
            </div>
            <div >
              <input type = "date" onChange = {props.onChange} value = {props.value}  name = {props.name} className = {classes.dateInput}  />
            </div>
          </div>

          )     
          case "Time":
            return(
              <div className={classes.fieldName}>
              <div >
                <label htmlFor = "input 5">
                  {props.label}
                   <span className= {classes.mandatoryIcon}>{props.text}</span>
                </label>
              </div>
              <div className={classes.fields1} >
                <input type = "time" onChange = {props.onChange} value = {props.value}  name = {props.name} className={classes.inputField}  />
              </div>
            </div>
  
            )  
      default:
        console.log("Invalid Input");
        break;
    }
  };

  return <div>{inputType(props.input)}</div>;
};

export default AdsEntry;
