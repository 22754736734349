import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import BackButton from "../BackButton";
import classes from "./EventDetail.module.css";

const EventDetail = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    viewEvent();
  }, []);
  console.log(data);
  const viewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/view-event", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        //   console.log(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className={classes.eventdetail_container}>
      {/* {data.map((item) => ( */}
      <div className={classes.eventdetail_main}>
        <div className={classes.event_detail}>
          <img
            src={data.event_image}
            alt=""
            className={classes.event_image}
          ></img>
          <div className={classes.event_text}>
            <table>
              <tbody>
                <tr>
                  <th className={classes.table_th}>Event name :-</th>
                  <td className={classes.table_td}>{data.eventName}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Event Type :</th>
                  <td className={classes.table_td}>{data.eventType} </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Event Categories :</th>
                  <td className={classes.table_td}></td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Event Organizer :</th>
                  <td className={classes.table_td}>{data.event_organizer}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Event speaker Name :</th>
                  <td className={classes.table_td}>
                    {data.event_speaker_name}
                  </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>
                    Event Speaker Designation :
                  </th>
                  <td className={classes.table_td}>
                    {data.event_speaker_designation}
                  </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Location :</th>
                  <td className={classes.table_td}>{data.eventLocation}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Url :</th>
                  <td className={classes.table_td}>{data.eventUrl}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={classes.detail_bottom}>
          <h4>Description:</h4>
          <p className={classes.description}>
            <div dangerouslySetInnerHTML={{ __html: data.eventDescription }} />
          </p>
        </div>
        <div className={classes.Time_date}>
          <div>
            <div className={classes.date}>Event Date</div>
            <div className={classes.date_text}>{data.event_date}</div>
            <div className={classes.date}>Event Time</div>
            <div className={classes.date_text}>{data.event_time}</div>
          </div>
          <div>
            <div className={classes.date}>Event Start Date</div>
            <div className={classes.date_text}>{data.eventStartDate}</div>
            <div className={classes.date}>Event Start Time</div>
            <div className={classes.date_text}>{data.event_start_time}</div>
          </div>
          <div>
            <div className={classes.date}>Event End Date</div>
            <div className={classes.date_text}>{data.eventEndDate}</div>
            <div className={classes.date}>Event End Time</div>
            <div className={classes.date_text}>{data.event_end_time}</div>
          </div>
        </div>
        <Link to="/BiDashboard/events" className={classes.BackButton}>
          <BackButton />
        </Link>
      </div>
      {/* ))
            } */}
    </div>
  );
};

export default EventDetail;
