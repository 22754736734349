import React, { useState } from "react";

import classes from "../Podcasts/AddPodcastVideo.module.css"
import Modal from "../../Components/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import Loader from "react-loader-spinner";
import EventInputs from "../../Components/Events/EventInputs";
import BackButton from "../../Components/BackButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddPodcastVideo = () => {

    const [loading, setLoading] = useState(false);
    const [erorrshow, setErrorShow] = useState(false);
    const [internalErrorShow, setInternalErrorShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [url, setUrl] = useState("");
    const [thumbnailImage, setThumbnailImage] = useState("");
    const [Title, setTitle] = useState("")
    const [isPublished, setIsPublished] = useState(false);

    let history = useHistory();

    const AddVideoApi = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92");

        var formdata = new FormData();
        formdata.append("YoutubeUrl", url);
        formdata.append("Image", thumbnailImage);
        formdata.append("IsLive", isPublished ? "1" : "0");
        formdata.append("Title", Title);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(
            // `${process.env.REACT_APP_API_URL}/adminapi/InsertWealthYoutubevideoURL`,
            `${process.env.REACT_APP_API_URL}/v1/admin1_1/PodcastVideoInsert`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success" && result.status_code === 200) {
                    setModalShow(true);
                } else if (result.status_code === 400 || result.status_code === 500) {
                    setInternalErrorShow(true);
                } else if (result.status_code === 300) {
                    setErrorShow(true);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const pageInfo = {
        currentPageName: "Add Podcast Video",
        previousPageName: "Podcast Video List",
        previousPage: "/BiDashboard/podcast-videos",
    };

    const currentURL = window.location.href;
    return (
        <div className={classes.top_div}>
            {modalShow && (
                <Modal
                    heading={"Video added successfully"}
                    onClick={() => history.push("/BiDashboard/podcast-videos")}
                />
            )}
            {erorrshow && (
                <Modal
                    heading={"Required Fields Are Missing"}
                    onClick={() => setErrorShow(false)}
                />
            )}
            {internalErrorShow && (
                <Modal
                    heading={"Internal server error"}
                    onClick={() => setInternalErrorShow(false)}
                />
            )}

            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.TimsbiBreadcrumb}>
                        {" "}
                        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
                    </div>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Add Podcast Video</div>
                        <Link
                            to="/BiDashboard/podcast-videos"
                            className={classes.BackButton}
                        >
                            <BackButton />
                        </Link>
                    </div>
                    <div className={classes.form_input}>
                        <div className={classes.input}>

                            <EventInputs
                                input={"ImageInput"}
                                label={"Thumbnail Image"}
                                placeholder={"Thumbnail Image"}
                                onChange={(e) => setThumbnailImage(e.target.files[0])}
                            />

                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Podcast Video Code"}
                                placeholder={"Embed Code"}
                                onChange={(e) => setUrl(e.target.value)}
                                value={url}
                            />

                            <EventInputs
                                input={"SingleLineInput"}
                                label={"Title"}
                                placeholder={"Title"}
                                onChange={(e) => setTitle(e.target.value)}
                                value={Title}
                            />



                        </div>
                    </div>
                    <div className={classes.checkbox}>
                        <EventInputs
                            input={"CheckBox"}
                            onChange={() => setIsPublished(!isPublished)}
                            label={"Live"}
                            value={isPublished}
                        />
                    </div>
                </div>
                <button className={classes.Upload_button} onClick={() => AddVideoApi()}>
                    {loading ? (
                        <Loader
                            type="TailSpin"
                            color="white"
                            height={20}
                            width={50}
                            className="text-center"
                        />
                    ) : (
                        "Add"
                    )}
                </button>
            </div>
        </div>
    );
};

export default AddPodcastVideo;
