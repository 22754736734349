import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './PartnerCompaniesHeader.module.css';

const PartnerCompaniesHeader = () => {
    const { pathname } = useLocation();
    return (
        <div className={classes.header_container}>
            <div className={classes.navbar}>
                <Link to="/today" className={`${classes.link} ${pathname === "/today" || pathname.includes("/PartnerCompanies") ? classes.active_link : ""}`}>Today</Link>
                <Link to="/weekly" className={`${classes.link} ${pathname === "/weekly" ? classes.active_link : ""}`}>Weekly</Link>
                <Link to="/monthly" className={`${classes.link} ${pathname === "/monthly" ? classes.active_link : ""}`}>Monthly</Link>
                {/* <NavLink to="custom" activeClassName={classes.active_link} className={classes.link}>Custom</NavLink> */}
            </div>
        </div >
    )
}

export default PartnerCompaniesHeader
