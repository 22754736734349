import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "../Components/getuserjobposting.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const GetListofUserspostingJob = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [userjobposting, setUserjobposting] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    GetListofUserspostingJobApi();
  }, []);

  const GetListofUserspostingJobApi = (date) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      start_date:
        startDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      end_date:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getListofUserspostingJob",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setUserjobposting(result.data || []);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>List of Users posting Jobs</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={userjobposting}
            filename={"userpostingjobdetail.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={userjobposting}
            fileName="userpostingjobdetail"
          />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={
                startDate ||
                new Date()
                  .toLocaleDateString("in", {
                    day: "2-digit",
                    year: "numeric",
                    month: "2-digit",
                  })
                  .split("/")
                  .reverse()
                  .join("-")
              }
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={
                endDate ||
                new Date()
                  .toLocaleDateString("in", {
                    day: "2-digit",
                    year: "numeric",
                    month: "2-digit",
                  })
                  .split("/")
                  .reverse()
                  .join("-")
              }
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => GetListofUserspostingJobApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.headerPageNavigation}></div>

      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {userjobposting.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>User</th>
                  <th className={classes.table_heading}>Inbox</th>
                  <th className={classes.table_heading}>Drafts</th>
                  <th className={classes.table_heading}>QC Pending</th>
                  <th className={classes.table_heading}>Live Jobs</th>
                  <th className={classes.table_heading}>Number Of ads</th>
                  <th className={classes.table_heading}>Work Status</th>
                  <th className={classes.table_heading}>Created Date</th>
                  <th className={classes.table_heading}>Updated Date</th>
                  <th className={classes.table_heading}>Updated By </th>
                </tr>

                <tbody>
                  {userjobposting.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.createdBy}</td>
                        <td className={classes.table_td}>{item.inbox}</td>
                        <td className={classes.table_td}>{item.drafts}</td>
                        <td className={classes.table_td}>{item.QCPending}</td>
                        <td className={classes.table_td}>{item.liveJobs}</td>
                        <td className={classes.table_td}>{item.numberOfads}</td>
                        <td className={classes.table_td}>{item.workStatus}</td>
                        <td className={classes.table_td}>{item.createdDate}</td>
                        <td className={classes.table_td}>{item.updatedDate}</td>
                        <td className={classes.table_td}>{item.updatedBy}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GetListofUserspostingJob;
