import React, { useState, useEffect } from 'react'
import LoadingSpinner from '../Components/LoadingSpinner';
import classes from './RecruiterSignupMonthly.module.css';
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const OnlineJobMonthly = () => {


    const [isLoading, setLoading] = useState(false)
    const [jobsMonthly, setJobsMonthly] = useState([])


    useEffect(() => {
        onlineJobsApi()
    }, [])

    const onlineJobsApi = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/adminapi/online-jobs", requestOptions)
            .then(response => response.json())
            .then((result) => {
                setJobsMonthly(result.Monthly)

            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false));
    }


    return (

        <div className={classes.dashboard_container}>
            <div className={classes.events_title}>Monthly Online Job</div>
            <div className={classes.button_container}>
                <CSVLink
                    data={jobsMonthly}
                    filename={"jobmonthly-table.csv"}
                    target="_blank"
                >
                    <button className={classes.button_articleList}>CSV</button>
                </CSVLink>
                <ExcelDownload csvData={jobsMonthly} fileName="jobmonthly-table" />
            </div>
            {isLoading ? <div className={classes.spinner}><LoadingSpinner /></div>
                :
                <div className={classes.table_container}>

                    <div className={classes.container}>
                        <table className={classes.orders_table}>
                            <tr className={classes.table_row}>
                                <th className={classes.table_heading}>Id</th>
                                <th className={classes.table_heading}>Category</th>
                                <th className={classes.table_heading}>Job Type</th>
                                <th className={classes.table_heading}>Position </th>
                                <th className={classes.table_heading}>Location</th>
                                <th className={classes.table_heading}>Company Name</th>
                                <th className={classes.table_heading}>Company Website</th>
                                <th className={classes.table_heading}>Company Address</th>
                                <th className={classes.table_heading}>Average Salary Offered</th>
                                <th className={classes.table_heading}>Job Started Date</th>
                                <th className={classes.table_heading}>ApplicationUrl </th>
                                <th className={classes.table_heading}>Contact Email</th>
                                <th className={classes.table_heading}>Contact Person</th>
                                <th className={classes.table_heading}>Number Of Vacancy </th>
                                <th className={classes.table_heading}>Min Experience</th>
                                <th className={classes.table_heading}>Interview Location</th>
                                <th className={classes.table_heading}>Job Status </th>
                                <th className={classes.table_heading}>Company Description </th>
                            </tr>
                            <tbody>
                                {jobsMonthly.map((item, value) => {
                                    return (
                                        <tr className={classes.table_row} >
                                            <td className={classes.table_td}>{item.id}</td>
                                            <td className={classes.table_td}>{item.Category}</td>
                                            <td className={classes.table_td}>{item.JobType}</td>
                                            <td className={classes.table_td}>{item.Position}</td>
                                            <td className={classes.table_td}>{item.Location}</td>
                                            <td className={classes.table_td}>{item.CompanyName}</td>
                                            <td className={classes.table_td}>{item.CompanyWebsite}</td>
                                            <td className={classes.table_td}>{item.CompanyAddress}</td>
                                            <td className={classes.table_td}>{item.AverageSalaryOffered}</td>
                                            <td className={classes.table_td}>{item.AverageSalaryOffered}</td>
                                            <td className={classes.table_td}>{item.ApplicationUrl}</td>
                                            <td className={classes.table_td}>{item.Contactmail}</td>
                                            <td className={classes.table_td}>{item.ContactPerson}</td>
                                            <td className={classes.table_td}>{item.numberOfVacancy}</td>
                                            <td className={classes.table_td}>{item.minExperience}</td>
                                            <td className={classes.table_td}>{item.interviewLocation}</td>
                                            <td className={classes.table_td}>{item.jobStatus}</td>
                                            <td className={classes.table_td}>{item.companyDescription}</td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>
    )

}

export default OnlineJobMonthly
