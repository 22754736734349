import React, { useState, useEffect, useRef } from "react";
import classes from "./Articles.module.css";
import { BsEye } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import LoadingSpinner from "../Components/LoadingSpinner";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import ConfirmModal from "../Components/ConfirmModal";
import Modal from "../Components/Modal";

const Articles = (props) => {
  let articleCount = 0;
  const [articleData, setArticleData] = useState([]);
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const tableRef = useRef();
  const doc = new jsPDF();
  const [isdesc, setIsdesc] = useState("ASC");
  const [isid, setIsid] = useState("ASC");
  const [istitle, setIstitle] = useState("ASC");
  const [issub, setIssub] = useState("ASC");
  const [currentPage, setCurrentPage] = useState(1);
  const [ispage, setIspage] = useState("ASC");
  const [issource, setIssource] = useState("ASC");
  const [articleUUID, setArticleUUID] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() - 30);

    let startDateFormat =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();

    const monthFormat =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);

    var finalDate =
      date.getFullYear() + "-" + monthFormat + "-" + startDateFormat;

    setStartDate(finalDate);

    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate());

    let endDateFormat =
      todayDate.getDate().toString().length === 1
        ? "0" + todayDate.getDate()
        : todayDate.getDate();

    const todayMonthFormat =
      todayDate.getMonth() + 1 > 9
        ? todayDate.getMonth() + 1
        : "0" + (todayDate.getMonth() + 1);

    var finalToday =
      todayDate.getFullYear() + "-" + todayMonthFormat + "-" + endDateFormat;
    setEndDate(finalToday);
  }, []);

  useEffect(() => {
    if (currentPage && endDate && startDate) {
      ArticleList();
    }
  }, [currentPage, startDate, endDate]);

  const history = useHistory();

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePDF = () => {
    autoTable(doc, { html: "#article-table" });
    doc.save("article-table.pdf");
  };

  const handleNewArticle = () => {
    history.push("/BiDashboard/newArticle");
  };

  const ExcelArticleList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      start_date: date.startDate ? date.startDate : startDate,
      end_date: date.endDate ? date.endDate : endDate,
    });

    console.log(raw);
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/articles-by-date", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setData(result.Article);
          setArticleData(result.Article);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const ArticleList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      start_date: date.startDate ? date.startDate : startDate,
      end_date: date.endDate ? date.endDate : endDate,
      page: currentPage,
    });

    console.log(raw);
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/articles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setData(result.Article);
          setArticleData(result.Article);
          setCategoryList(result.Category);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const unPublishArticleAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: articleUUID,
      isDeleted: "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/delete-article", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        ArticleList();
        setModalShow(true);
      })
      .catch((error) => console.log("error", error));
  };

  const ArticleSearch = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      SearchCategory: searchCategory,
      SearchTerm: searchText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/v1/api/apiTimes/SearchArticles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200)
          setData(result.data);
        else setData([]);
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const sortingtitle = (col) => {
    if (istitle === "ASC") {
      const sortedtitle = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedtitle);
      setIstitle("DSC");
    }
    if (istitle === "DSC") {
      const sortedtitle = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedtitle);
      setIstitle("ASC");
    }
  };
  const sortingArticleid = (col) => {
    if (isid === "ASC") {
      const sortedid = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedid);
      setIsid("DSC");
    }
    if (isid === "DSC") {
      const sortedid = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedid);
      setIsid("ASC");
    }
  };

  const sortingdecription = (col) => {
    if (isdesc === "ASC") {
      const sortedid = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedid);
      setIsdesc("DSC");
    }
    if (isdesc === "DSC") {
      const sortedid = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedid);
      setIsdesc("ASC");
    }
  };
  const sortingsub = (col) => {
    if (issub === "ASC") {
      const sortesub = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortesub);
      setIssub("DSC");
    }
    if (issub === "DSC") {
      const sortesub = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortesub);
      setIssub("ASC");
    }
  };
  const sortingsource = (col) => {
    if (issource === "ASC") {
      const sortedsource = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setData(sortedsource);
      setIssource("DSC");
    }
    if (issource === "DSC") {
      const sortedsource = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setData(sortedsource);
      setIssource("ASC");
    }
  };
  const sortingpageview = (col) => {
    if (ispage === "ASC") {
      const sortedpage = [...data].sort((a, b) => (+a[col] < +b[col] ? 1 : -1));
      setData(sortedpage);
      setIspage("DSC");
    }
    if (ispage === "DSC") {
      const sortedpage = [...data].sort((a, b) => (+a[col] > +b[col] ? 1 : -1));
      setData(sortedpage);
      setIspage("ASC");
    }
  };
  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {confirmModalShow && (
          <ConfirmModal
            heading={"Are you sure that you want to delete this data?"}
            onClick={() => unPublishArticleAPI()}
            onClose={() => setConfirmModalShow(false)}
          />
        )}
        {modalShow && (
          <Modal
            heading={"Data deleted successfully"}
            onClick={() => {
              setModalShow(false);
              setConfirmModalShow(false);
            }}
          />
        )}

        <div className={classes.header}>Search Articles:</div>
        <div className={classes.search_container}>
          <input
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={"Title"}
            className={classes.title_input}
          />
          <select
            onChange={(e) => setSearchCategory(e.target.value)}
            name="Carrer Advice"
            id="cars"
            className={classes.option_input}
          >
            <option disabled selected>
              Please select{" "}
            </option>

            {categoryList.map((item) => (
              <option value={item.category}>{item.category}</option>
            ))}
          </select>
          <button className={classes.button} onClick={ArticleSearch}>
            Search
          </button>
          {props.accessLevel === "TIMES" ? (
            ""
          ) : (
            <button className={classes.button} onClick={handleNewArticle}>
              New Article
            </button>
          )}{" "}
        </div>

        <div className={classes.header}>Article List:</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <button
              onClick={() =>
                navigator.clipboard.writeText(JSON.stringify(data))
              }
              className={classes.button_articleList}
            >
              COPY
            </button>
            <CSVLink data={data} filename={"article-table.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="article-table" />

            <button onClick={handlePDF} className={classes.button_articleList}>
              PDF
            </button>

            <button
              className={classes.button_articleList}
              onClick={handlePrint}
            >
              PRINT
            </button>
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={date.startDate ? date.startDate : startDate}
                onChange={(e) =>
                  setDate({ ...date, startDate: e.target.value })
                }
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={date.endDate ? date.endDate : endDate}
                onChange={(e) => setDate({ ...date, endDate: e.target.value })}
              />
            </div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() => {
                  setCurrentPage(1);
                  ExcelArticleList();
                }}
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          className={classes.headerPageNavigation}
          style={{ marginTop: "50px" }}
        >
          <div className={classes.buttons1}>
            {currentPage > 0 ? (
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              >
                Prev
              </button>
            ) : (
              <></>
            )}
            <span>{currentPage}</span>
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sr. No.</th>
                  <th className={classes.table_heading}>
                    Article Id{" "}
                    {isid === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingArticleid("ArticleId")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingArticleid("ArticleId")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Title{" "}
                    {istitle === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingtitle("title")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingtitle("title")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Short Description</th>
                  <th className={classes.table_heading}>
                    Page Views{" "}
                    {ispage === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingpageview("readCount")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingpageview("readCount")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Article Source{" "}
                    {issource === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsource("author")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsource("author")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Categories
                    {isdesc === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdecription("category")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdecription("category")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Sub Categories{" "}
                    {issub === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsub("subCategory")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsub("subCategory")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>Status</th>
                  <th className={classes.table_heading}>Created By</th>
                  <th className={classes.table_heading}>Updated By</th>
                  <th className={classes.table_heading}>Last Updated</th>
                  <th className={classes.table_heading}>Article URL</th>
                  <th className={classes.table_heading}>Preview Link</th>

                  <th className={classes.table_heading}>Edit</th>

                  {props.accessLevel === "TIMES" ? (
                    ""
                  ) : (
                    <th className={classes.table_heading}>Delete</th>
                  )}
                </tr>
                <tbody>
                  {data.map((item, index) => (
                    <tr className={classes.table_row} key={index}>
                      <td className={classes.table_td}>
                        {currentPage == 1
                          ? index + currentPage
                          : index + 1 + 24 * (currentPage - 1)}
                      </td>
                      <td className={classes.table_td}>{item.ArticleId}</td>
                      <td
                        className={classes.table_td}
                        style={{ minWidth: "200px" }}
                      >
                        {item.title}
                      </td>
                      <td
                        className={classes.table_td}
                        style={{ minWidth: "300px" }}
                      >
                        {item.shortDescription}
                      </td>
                      <td className={classes.table_td}>{item.readCount}</td>
                      <td className={classes.table_td}>{item.author}</td>
                      <td className={classes.table_td}>{item.category}</td>
                      <td className={classes.table_td}>{item.subCategory}</td>

                      <td className={classes.table_td}>
                        {item.isPublished == "1"
                          ? "Published"
                          : "Not Published"}
                      </td>
                      <td className={classes.table_td}>{item.createdBy}</td>
                      <td className={classes.table_td}>{item.updatedBy}</td>
                      <td className={classes.table_td}>{item.updatedDate}</td>
                      <td className={classes.table_td}>
                        <a
                          href={
                            "https://timesascent.com/articles/" +
                            `${item.title}` +
                            "/" +
                            `${item.ArticleId}`
                          }
                          className={classes.ArticleUrl}
                        >
                          Article_Url
                        </a>
                      </td>
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/preview/" + item.SeoURL}
                          className={classes.articleLink}
                        >
                          <BsEye size={20} />
                        </Link>{" "}
                      </td>

                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/editArticle/" + item.ArticleId}
                          className={classes.articleLink}
                        >
                          <FiEdit size={20} />
                        </Link>
                      </td>

                      {props.accessLevel === "TIMES" ? (
                        ""
                      ) : (
                        <td className={classes.table_td}>
                          <div
                            onClick={() => {
                              setArticleUUID(item.ArticleId);
                              setConfirmModalShow(true);
                            }}
                            className={classes.articleLinkDelete}
                          >
                            <MdDeleteForever size={20} />
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default Articles;
