import React, { useEffect, useState } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./ContactUsDetails.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const ContactUsDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    AdvertiseNow();
  }, []);

  const AdvertiseNow = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/contact-us", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  console.log(data);

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>Contact Us</div>
      <div className={classes.button_container}>
        <CSVLink data={data} filename={"contactus-table.csv"} target="_blank">
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <ExcelDownload csvData={data} fileName="contactus-table" />
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Company Name</th>
                <th className={classes.table_heading}>Full Name</th>
                <th className={classes.table_heading}>Email</th>
                <th className={classes.table_heading}>Mobile</th>
                <th className={classes.table_heading}>Website</th>
                <th className={classes.table_heading}>Message</th>
                <th className={classes.table_heading}>Created at</th>
                {/* <th className={classes.table_heading}>Updated at</th> */}
              </tr>
              <tbody>
                {data.map((item, index) => {
                  return isLoading ? (
                    <div className={classes.spinner}>
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <tr className={classes.table_row} key={index + 1}>
                      <td className={classes.table_td}>{item.company_name}</td>
                      <td className={classes.table_td}>{item.full_name}</td>
                      <td className={classes.table_td}>{item.email}</td>
                      <td className={classes.table_td}>{item.mobile}</td>
                      <td
                        className={`${classes.table_td} ${classes.lineClampTable}`}
                      >
                        {item.company_category}
                      </td>
                      <td
                        className={classes.table_td}
                        style={{ minWidth: "400px" }}
                      >
                        {item.form_message}
                      </td>
                      <td className={classes.table_td}>{item.created_at}</td>
                      {/* <td className={classes.table_td}>{item.updated_at}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUsDetails;
