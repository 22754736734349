import React, { useState, useEffect } from "react";
import classes from "../Mailer/actofcompassion.module.css";

import { BsEye } from "react-icons/bs";
import LoadingSpinner from "../Components/LoadingSpinner";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "../Components/Modal";



const HrAbout = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [actofcompassion, setActOfCompassion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelData, setModelData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    ActList();
  }, [currentPage]);
 

  const ActList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "PageName": "Hrconclave"
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/adminapi/GetAboutUs`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setActOfCompassion(result.data);
        console.log(result.data.mailer_data, "rhrghrhdfghg")
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const DeleteApi = (Id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: Id,
      IsLive:"0"
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink +`/adminapi/DeleteAboutUs`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          ActList();
          setModalShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const pageInfo = {
    currentPageName: "About HR List",
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <>
  {modalShow && (
        <Modal
          heading={"Deleted successfully"}
          onClick={() => {
            setModalShow(false);
          }}
        />
      )}
      <div className={classes.dashboard_container}>
    

        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}></div>

        <div className={classes.table_container}>
          <div className={classes.buttons1}>
            <div>
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                className={classes.buttonone}
              >
                Prev
              </button>

              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
              <button className={classes.Addbtn}>
              <Link to="/BiDashboard/AddAbout" className={classes.Edit_link}>
                Add About
              </Link>
            </button>
            </div>
            
          </div>
          <div className={classes.container}>
            {isLoading ? (
              <div className={classes.spinner}>
                <LoadingSpinner />{" "}
              </div>
            ) : actofcompassion.length? (
              <>
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                    <th className={classes.table_heading}>Title</th>
                    <th className={classes.table_heading}>Description</th>
                    <th className={classes.table_heading}>Action</th>
                   

                  
                  </tr>

                  <tbody>
                    {actofcompassion.map((item, i) => {

                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{i + 1}</td>

                          <td className={classes.table_td}>
                            {item.Title}
                          </td>
                          <td className={classes.table_td}>
                            {item.Description}
                          </td>
                          <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.Id)}
                            >
                              Delete
                            </button>
                          </td>
                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h2 className={classes.serachErrorText}>"No Data found !"</h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HrAbout;
