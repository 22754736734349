import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./FAQ.module.css";

import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";

import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Modal from "../Components/Modal";
const WebinarForm = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [HomeYouTubeVideos, setHomeYouTubeVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
 
  const [modalShow, setModalShow] = useState(false);

 

  useEffect(() => {
    HomeYouTubeVideosApi();
  }, [currentPage]);
  console.log(currentPage, "test");

  const HomeYouTubeVideosApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/adminapi/GetWealthUsers`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setHomeYouTubeVideos(result.data);
          console.log(result, "test");
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

 

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "Webinar Form List",
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={"Deleted successfully"}
          onClick={() => {
            setModalShow(false);
          }}
        />
      )}

      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
       

        <div className={classes.table_container}>
          <div className={classes.buttons1}>
            <div>
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                className={classes.buttonone}
              >
                Prev
              </button>

              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </div>
          </div>
          <div className={classes.container}>
            {isLoading ? (
              <div className={classes.spinner}>
                <LoadingSpinner />{" "}
              </div>
            ) : data.length ? (
              <>
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                 
                    <th className={classes.table_heading}>Name</th>
                    <th className={classes.table_heading}>E-mail</th>
                    <th className={classes.table_heading}>Phone</th>
                    <th className={classes.table_heading}>City</th>
                    <th className={classes.table_heading}>Organization</th>
                    <th className={classes.table_heading}>WebinarSource</th>
                    <th className={classes.table_heading}>Date</th>
                    <th className={classes.table_heading}>Time</th>
                    </tr>
                  

                  <tbody>
                    {HomeYouTubeVideos.map((item, i) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{i + 1}</td>
                        
                          <td className={classes.table_td}>{item.FirstName} {item.LastName}</td>
                          <td className={classes.table_td}>{item.Email}</td>
                          <td className={classes.table_td}>{item.Phone}</td>
                          <td className={classes.table_td}>{item.City}</td>
                          <td className={classes.table_td}>{item.Organization}</td>
                          <td className={classes.table_td}>{item.WebinarSource}</td>

                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(11, 16)}
                          </td>

                        

                         
                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h2 className={classes.serachErrorText}>"No Data found !"</h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WebinarForm;
