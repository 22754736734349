import React, { useState } from 'react';
import classes from './PostAJobInput.module.css';
import { AiOutlineEye } from "react-icons/ai";
import { BsEyeSlash } from "react-icons/bs"

const PostAJobInput = (props) => {
    const [passwordShow, setPasswordShow] = useState(true);


    const inputType = (type) => {

        switch (type) {
            case "SingleLineInput":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input">
                                {props.label}{" "}
                            </label>
                        </div>
                        <div className={classes.fields1}>
                            <input type="text"
                                name={props.name}
                                onChange={props.onChange}
                                placeholder={props.placeholder}
                                value={props.value}
                                className={classes.inputField}
                                autoComplete="off" />
                        </div>
                    </div>
                );
            case "email":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input2">{props.label}  </label>
                        </div>
                        <div className={classes.fields1}>
                            <input
                                type="text"
                                id="Email"
                                name="Email"
                                value={props.value}
                                onChange={props.onChange}
                                className={classes.inputField}
                                placeholder={props.placeholder}
                            />
                        </div>
                    </div>
                );
            case "MultiLineInput":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input3">{props.label}  </label>
                        </div>
                        <div className={classes.fields2}>
                            <textarea
                                rows="10"
                                cols="30"
                                name="text"
                                onChange={props.onChange}
                                value={props.value}
                                className={classes.multiLineTextBox}
                                placeholder={props.placeholder}
                            />
                        </div>
                    </div>
                );
            case "DateInput":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input3">
                                {props.label}
                            </label>
                        </div>
                        <div className={classes.fields1}>
                            <input type="text"
                                id="date"
                                name="date"
                                className={classes.inputField}
                                onChange={props.onChange}
                                onFocus={(e) => (e.target.type = "date")}
                                placeholder={props.placeholder}
                                value={props.value} />
                        </div>
                    </div>
                );
            case "TimeInput":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input5">
                                {props.label}
                            </label>
                        </div>
                        <div className={classes.fields1}>
                            <input type="text"
                                id="time"
                                name="time"
                                className={classes.inputField}
                                onChange={props.onChange}
                                onFocus={(e) => (e.target.type = "time")}
                                placeholder={props.placeholder}
                                value={props.value} />
                        </div>
                    </div>
                );
            case "Number":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input5">
                                {props.label}
                            </label>
                        </div>
                        <div className={classes.fields1}>
                            <input type="tel"
                                id="tel"
                                name="number"
                                className={classes.inputField}
                                onChange={props.onChange}
                                onFocus={(e) => (e.target.type = "number")}
                                placeholder={props.placeholder}
                                value={props.value} />
                        </div>
                    </div>
                );
            case "ImageInput":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input5" >
                                {props.label}
                            </label>
                        </div>
                        <div className={classes.fields1}>
                            <input type="file"
                                id="input5"
                                name="image"
                                className={classes.inputField}
                                onChange={props.onChange}
                            />
                        </div>
                    </div>
                );
            case "UpdateImageInput":
                return (
                    <div className={classes.input}>
                        <div className={classes.fieldName}>
                            <label htmlFor="input7" >
                                {props.label}
                            </label>

                        </div>
                        <div className={classes.fields1}>
                            <input type="file"
                                id="input5"
                                name="image"
                                className={classes.inputField}
                                onChange={props.onChange}
                            />


                        </div>
                    </div>
                );
            case "CheckBox":
                return (

                    <div className={classes.CheckBoxContainer}>

                        <input type="Checkbox"
                            className={classes.checkbox_input}
                            onChange={props.onChange}
                            checked={props.value}
                            value={props.value} />
                        <div htmlFor="input6" >
                            {props.label}
                        </div>
                    </div>


                )

            case "smalldropdown":
                return (
                    <div className={classes.input}>
                        {/* <div className={classes.fieldName}>
                <label htmlFor="dropdown">{props.label}</label>
              </div> */}
                        <div className={classes.fields1}>
                            <select onChange={props.onChange} className={classes.dropdownInput} value={props.value} >
                                {
                                    !!props.displayData &&
                                    props.displayData.map((item, id) => {
                                        return <option key={id} >{item.type}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                );
            case "Countrydropdown":
                return (
                    <div className={classes.input}>
                        {/* <div className={classes.fieldName}>
                <label htmlFor="dropdown">{props.label}</label>
              </div> */}
                        <div className={classes.fields1}>
                            <select onChange={props.onChange} className={classes.countrydropdown} value={props.value} >
                                {
                                    !!props.CountryName &&
                                    props.CountryName.map((item, id) => {
                                        return <option key={id} >{item.type}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                );
            case "radioOne":

                return (
                    <div className={classes.formContainer}>
                        {/* <label className={classes.lableDesign}>
                            {props.label}
                        </label> */}
                        <div className={classes.formContainer}>
                            <div className={classes.inputArea}><input type={"radio"} name={props.label} placeholder={props.placeHolder} value={props.value} onChange={props.onChange} /><span>{props.value}</span> </div>
                        </div>
                    </div>
                );
            case "radioTwo":
                return (
                    <div className={classes.formContainer}>
                        <div className={classes.inputArea}><input type={"radio"} name={props.label} placeholder={props.placeHolder} value={props.value} onChange={props.onChange} /><span>{props.value}</span> </div>

                    </div>
                );
            case "password":
                return (

                    <div className={classes.MainContainer}>
                        <label className={classes.lableDesign}>
                            {props.label}
                        </label>
                        <div className={classes.input_fields}>
                            <input type={passwordShow ? "password" : "text"} name={props.label} className={classes.inputArea_withIcon} placeholder={props.placeHolder} value={props.value} onChange={props.onChange} />
                            {passwordShow ?
                                <AiOutlineEye onClick={() => setPasswordShow(!passwordShow)} className={classes.eyeIcon} /> : <>
                                    <BsEyeSlash onClick={() => setPasswordShow(!passwordShow)} className={classes.eyeIcon} />
                                </>}
                        </div>
                    </div>)
            default:
                console.log("Invalid Input");
                break;
        }
    };

    return <div>{inputType(props.input)}</div>;

}

export default PostAJobInput
