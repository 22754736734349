import React, { useState, useEffect, useCallback } from "react";
import classes from "../Quiz/Quizadd.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import debounce from "lodash.debounce";

const AddQuizSection = () => {
  const [loading, setLoading] = useState(false);
  const [quizTitle, setQuizTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [BrandLogo, setBrandLogo] = useState("");

  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [category, setCategory] = useState("");
  const [poweredBy, setPoweredBy] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [type, setType] = useState("");

  const fetchCategoryList = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/QuizListCategory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCategoryList(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  // Search for quiz questions based on the search term
  const searchCategory = useCallback(
    debounce((term) => {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
      );

      const formdata = new FormData();
      formdata.append("SearchTerm", term);
      formdata.append("Type", "All");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      setLoading(true);

      fetch( global.apiLink +"/adminapi/QuizSearchAPI", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setQuestionList(result.data || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setQuestionList([]);
          setLoading(false);
        });
    }, 500),
    [type]
  );

  useEffect(() => {
    if (type) {
      searchCategory(""); // Trigger search with an empty term on type change
    }
  }, [type, searchCategory]);

  // Handle checkbox changes when selecting questions
  const handleCheckboxChange = (qaUUID) => {
    setSelectedQuestions((prevSelected) => {
      if (prevSelected.includes(qaUUID)) {
        return prevSelected.filter((uuid) => uuid !== qaUUID);
      }
      if (prevSelected.length < 100) {
        return [...prevSelected, qaUUID];
      }
      return prevSelected;
    });
  };

  function formatDate(date) {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return "";

    // Adjust the date to account for the timezone offset
    const timeZoneOffset = parsedDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(parsedDate.getTime() - timeZoneOffset);

    // Format the date to a string in the required format
    const formattedDate = adjustedDate.toISOString().slice(0, 16);
    return formattedDate;
  }

  // Handle search input changes
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchCategory(term);
  };

  // Submit the quiz data
  const AddMovementApi = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    const formdata = new FormData();
    formdata.append("QuizTitle", quizTitle);
    formdata.append("Image", image);
    formdata.append("BrandLogo", BrandLogo);
    formdata.append("Description", Description);
    formdata.append("QuizType", category);
    formdata.append("PoweredBy", poweredBy);
    formdata.append("StartDate", startDate);
    formdata.append("EndDate", endDate);

    // Append selected questions (QAUUIDs) as a comma-separated string
    const selectedQAUUIDs = selectedQuestions.join(",");
    formdata.append("QAUUID", selectedQAUUIDs);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/QuizSetInsert", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  // Page info for breadcrumbs
  const pageInfo = {
    currentPageName: "Create Quiz ",
    previousPageName: "QuizList",
    previousPage: "/BiDashboard/QuizList",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/QuizList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Create Quiz</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Quiz Name"}
                placeholder={"Quiz Name"}
                onChange={(e) => setQuizTitle(e.target.value)}
                value={quizTitle}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Quiz Image"}
                placeholder={"Quiz Image"}
                onChange={(e) => setImage(e.target.files[0])}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Brand Logo"}
                placeholder={"Brand Logo"}
                onChange={(e) => setBrandLogo(e.target.files[0])}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Search Quiz Question"}
                placeholder={"Type to search..."}
                onChange={handleSearchChange}
                value={searchTerm}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Quiz Description"}
                placeholder={"Quiz Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={Description}
              />
              <EventInputs
                input={"smalldropdown"}
                label={"Quiz Category"}
                placeholder={"Quiz Category"}
                onChange={(e) => setCategory(e.target.value)}
                displayData={categoryList}
                value={category}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Powered By"}
                placeholder={"Powered By"}
                onChange={(e) => setPoweredBy(e.target.value)}
                value={poweredBy}
              />
              <label className={classes.dateLabel}>Start Date</label>
              <div className={classes.dropdown}>
                <input
                  className={classes.drop}
                  type="datetime-local"
                  value={formatDate(startDate)}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <label className={classes.dateLabel}>End Date</label>
              <div className={classes.dropdown}>
                <input
                  className={classes.drop}
                  type="datetime-local"
                  value={formatDate(endDate)}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className={classes.date}></div>
          {/* Display the search results with checkboxes */}
          {questionList.length > 0 && (
            <div className={classes.table_div}>
              <table>
                <thead>
                  <tr>
                    <td>Sr No.</td>
                    <td>Select</td>
                    <td>Question Name</td>
                    <td>Correct Answer</td>
                  </tr>
                </thead>
                <tbody>
                  {questionList.map((item, index) => (
                    <tr key={item.QAUUID}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedQuestions.includes(item.QAUUID)}
                          onChange={() => handleCheckboxChange(item.QAUUID)}
                          disabled={
                            !selectedQuestions.includes(item.QAUUID) &&
                            selectedQuestions.length >= 100
                          }
                        />
                      </td>
                      <td>{item.Question}</td>
                      <td>{item.CorrectAnswer}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={classes.center}>
                {loading && (
                  <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  />
                )}
              </div>
              <div>
                {selectedQuestions.length >= 100 && (
                  <p>You can only select up to 100 questions.</p>
                )}
              </div>
            </div>
          )}

          {/* Permanent Selected Questions Table */}

          {questionList.length === 0 && !loading && <p>No data found</p>}
        </div>

        <button
          className={classes.Upload_button}
          onClick={() => AddMovementApi()}
        >
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddQuizSection;
