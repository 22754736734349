import React, { useState, useEffect, useRef } from "react";
import classes from "./InspectionUrl.module.css";
import { CSVLink } from "react-csv";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";
import ConfirmModal from "../../Components/ConfirmModal";
import Modal from "../../Components/Modal";
import GoogleIndexModal from "../../Components/GoogleIndex/GoogleIndexModal";

const InspectionUrl = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  // const [count, setCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("");
  // const perPage = 20;

  const tableRef = useRef();

  useEffect(() => {
    getInspectionUrlList();
  }, []);

  const getInspectionUrlList = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/GoogleURLInspectionList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.data);
          // setCount(result.ManagerCount);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const handleInspectUrlFunc = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      url: url,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/GoogleURLInspection",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
          setStatus(
            result?.Data?.Api_Response.inspectionResult?.indexStatusResult
              ?.coverageState
          );
          getInspectionUrlList();
          setUrl("");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    // history.push("/BiDashboard/articles");
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {/* <div className={classes.header}>Podcasts</div> */}
        {confirmModalShow && (
          <ConfirmModal
            heading={"Are you sure that you want to delete this data?"}
            // onClick={() => deleteGMI()}
            onClose={() => setConfirmModalShow(false)}
          />
        )}
        {modalShow && (
          <Modal
            heading={status}
            onClick={() => {
              setModalShow(false);
              setConfirmModalShow(false);
              setIsModalOpen(false);
            }}
          />
        )}
        <GoogleIndexModal
          isModalOpen={isModalOpen}
          url={url}
          isLoading={isLoading}
          modalTitle="Inspect URL"
          setUrl={setUrl}
          handleSubmit={handleInspectUrlFunc}
          setIsModalOpen={setIsModalOpen}
          onClose={onModalCloseHandler}
        />

        <div className={classes.header}>Inspection URL List:</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            {/* <CSVLink data={data} filename={"gmi-table.csv"} target="_blank">
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="gmi-table" /> */}
          </div>
          <div className={classes.buttons}>
            <button
              className={classes.button}
              onClick={() => setIsModalOpen(true)}
            >
              Inspect URL
            </button>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sr. No.</th>
                  <th className={classes.table_heading}>Id. No.</th>
                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>Time</th>
                  <th className={classes.table_heading}>Status</th>
                  <th className={classes.table_heading}>URL</th>
                </tr>
                <tbody>
                  {data.map((item, i) => (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{i + 1}</td>
                      <td className={classes.table_td}>{item.Id}</td>
                      <td className={classes.table_td}>
                        {item.Created_Date.slice(0, 10)}
                      </td>
                      <td className={classes.table_td}>
                        {item.Created_Date.slice(11, 16)}
                      </td>
                      <td className={classes.table_td}>
                        {
                          item?.Api_Response.inspectionResult?.indexStatusResult
                            ?.coverageState
                        }
                      </td>
                      <td className={classes.table_td}>{item.url}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div>
                {count > 20 && (
                  <Paginate
                    maxPage={count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                  />
                )}
              </div> */}
            </>
          ) : (
            <h2 className={classes.serachErrorText}>"No Articles found !"</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default InspectionUrl;
