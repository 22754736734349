import React, { useEffect, useState } from "react";
import classes from "./FetchAds.module.css";
import { MdError } from "react-icons/md";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";

const FetchAdsJunkList = () => {
  const [fetchAdsData, setFetchAdsData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchAds = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      start_date: "",
      end_date: "",
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getPrintAdsIsDeletedListForQc",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setFetchAdsData(result.data);
        } else if (result.status_code === 300) {
          setFetchAdsData([]);
        }
        setCount(result.totalAdForQc);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchAds();
  }, [currentPage]);
  return (
    <div className={classes.screen}>
      <div className={classes.screenDiv}>
        <div className={classes.dateDiv}>
          <label className={classes.dateLable}>Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label className={classes.dateLable}>End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            className="button"
            onClick={() => {
              fetchAds();
            }}
          >
            {" "}
            Search
          </button>
        </div>
        <div className={classes.header}>
          <div>Total Ads:{count}</div>
          <div className={classes.buttons1}>
            {currentPage > 0 ? (
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              >
                Prev
              </button>
            ) : (
              <></>
            )}
            <span>{currentPage}</span>
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Next
            </button>
          </div>
        </div>
        {loading ? (
          <div className={classes.msg}>
            <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />{" "}
          </div>
        ) : (
          <>
            {fetchAdsData?.length === 0 ? (
              <div className={classes.msg}>
                <MdError className={classes.icon} />
                <p>Data not found</p>
              </div>
            ) : (
              fetchAdsData?.map((item, key) => {
                if (
                  item.createdBy === "NON_ASCENT_JOB" ||
                  item.createdBy === "postajob"
                ) {
                  return <></>;
                }
                return (
                  <AdComp
                    item={item}
                    adType={"fetch ads"}
                    fetchAds={fetchAds}
                  />
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
};
export const AdComp = ({ item, adType, fetchAds }) => {
  const [message, setMessage] = useState("");
  const [hideComp, setHideComp] = useState(false);
  const [loading, setLoading] = useState();
  const [timeOut, setTimeOut] = useState(true);
  const userEmail = sessionStorage.getItem("googleBiEmail");
  const history = useHistory();

  useEffect(() => {
    if (!message) return;
    setTimeout(() => {
      setTimeOut(false);
      setHideComp(true);
    }, 2000);
  }, [message]);

  const restoreApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "csrftoken=Cq0kel3BZFYQxSoAB0RfXmsGm3HpN2xEMCW0Crr2paehWAdi8k3nbAsJeqmGqrqP"
    );

    var raw = JSON.stringify({
      Id: item.id,
      Value: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/DeleteJob", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setMessage("Data restored");
          fetchAds();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {hideComp ? (
        <></>
      ) : loading ? (
        <div className={classes.msg}>
          <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />{" "}
        </div>
      ) : (
        <>
          {message && timeOut ? (
            <div className={classes.msg}>{message}</div>
          ) : (
            <div className={classes.jobItem}>
              <div className={classes.jobItemImage}>
                <img src={item.imageUrl} alt="jobImage" />
              </div>
              <div className={classes.jobItemInfo}>
                <h3 className={classes.jobInfoTitle}>
                  Publication : {item.positionTitle}
                </h3>
                <p>Edition : {item.edition ? item.edition : "null"}</p>
                <p>Issue Date : {item.issueDate}</p>
                <p>Order No : {item.orderNumber ? item.orderNumber : "null"}</p>
                <p>
                  Client : {item.companyDetails ? item.companyDetails : "null"}{" "}
                </p>
                <p>
                  Number of Posting : {item.vacancies ? item.vacancies : "null"}
                </p>
                <p>Status : {item.status ? item.status : "null"}</p>
                <div className={classes.fetchButton}>
                  <button
                    type="button"
                    onClick={() => {
                      restoreApi();
                    }}
                  >
                    Restore
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default FetchAdsJunkList;
