import React, { useState, useEffect } from "react";
import { GrImage, GrList, GrZoomIn, GrZoomOut } from "react-icons/gr";
import { AiOutlineCloseCircle, } from "react-icons/ai";
// import { IoIosClose } from "react-icons/io";
import classes from "./JobAds.module.css";
import AdsEntry from "./AdsEntry";
import Heading from "./Heading";
import DashboardButton from "./DashboardButton";
import { useParams, useHistory } from "react-router-dom";
import AdminLoader from "./AdminLoader";



const Qcform = (props) => {


  const { id } = useParams();


  const [mobile, setMobile] = useState(null);
  const [loading, setLoading] = useState("")
  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState([])
  const [compDesc, setCompDesc] = useState()
  const [selectedLoc, setSelectedLoc] = useState()
  const [address, setAddress] = useState()

  const [existingLoc, setExistingLoc] = useState()
  const [adDetailImage, setAdDetailImage] = useState()
  const [website, setWebsite] = useState()
  const [location, setLocation] = useState();
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [companySuggestionsShow, setCompanySuggestionsShow] = useState(true);
  const [selectedCompId, setSelectedCompId] = useState();

  useEffect(() => {
    adDetailsApi();
    selectionDetailsApi();
    getCompanyDetail();

  }, [id]);

  let history = useHistory();

  const adDetailsApi = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": (id)
    });

    var requestOptions = {
      body: raw,
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/ad-details", requestOptions)
      .then(response => response.json())
      .then(result => {
        setAdDetailImage(result.data.imageUrl)



      })
      .catch(error => console.log('error', error));
  }


  const selectionDetailsApi = (data) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.onecorp.co.in/adminapi/selection-details", requestOptions)
      .then(response => response.json())
      .then(result => {
        setLocation(result.locationdata);
        setCompany(result.companydata)

      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(false));
  }




  // save company api
  const saveCompany = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Id": selectedCompId,
      "Name": companyName,
      "CompanyDescription": compDesc,
      "Phone": mobile,
      "Website": website,
      "Address": address,
      "Location": selectedLoc,

    });


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    // fetch(global.apiLink+"/v1/api/apiTimes/UpdateCompany", requestOptions)
    //   .then(response => response.json())
    //   .then((result) => {

    //      console.log(result);
    //     if(result.status === "SUCCESS" && result.status_code === 200){
    //       history.push( '/JobPostingDetail/'+  id + "/" +  selectedCompId )
    //     }else {
    //       alert("Company not updated")
    //     }
    //     })
    //   .catch(error => console.log('error', error))
    //   .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (companyName !== "") {
      let val = false;
      setCompanySuggestions(company.filter((val) => {
        if (val.compName === companyName) {
          val = true
        }
        return (val.compName ? val.compName.toLowerCase().toString().startsWith(companyName.toLowerCase()) : null)
      }))
      if (val) {
        setCompanySuggestionsShow(false)

      }
      setCompanySuggestionsShow(true)
    }
    else {
      setCompanySuggestions([])
      setCompanySuggestionsShow(false)
    }
  }, [companyName])


  const getCompanyDetail = (companyId, companyName) => {

    setCompanyName(companyName);
    setCompanySuggestionsShow(false);
    setSelectedCompId(companyId)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "CompanyId": companyId
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(global.apiLink + "/v1/api/apiTimes/GetCompanyDetail", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setCompDesc(result.data.companyDescription)
        setAddress(result.data.address)
        setWebsite(result.data.website)
        setMobile(result.data.phone)
        setExistingLoc(result.data.city)
      })
      .catch(error => console.log('error', error));
  }

  return (
    <div className={classes.jobAds}>
      <div>
        <div className={classes.jobAdsImageField}>
          <div className={classes.jobAdsIcons}>
            <GrZoomIn className={classes.icon} />
            <GrZoomOut className={classes.icon} />
            <GrImage className={classes.icon} />
            <GrList className={classes.icon} />
            <AiOutlineCloseCircle className={classes.icon} />
          </div>
          <div className={classes.jobAdsImage}>
            <img
              src={adDetailImage}
              alt="jobAdsImage"
            />
          </div>
        </div>
      </div>

      {loading ? <div className={classes.loadingLayout}> <AdminLoader /> </div> :
        <div className={classes.JobAdsEntry}>
          <Heading headingtype={"BgHeading"} headingtext={"Company Details"} />
          <div className={classes.adEntry}>
            <div>

              <AdsEntry input={"SingleLineInput"} name={"Company Name"} label={"Company Name"} onChange={(e) => { setCompanyName(e.target.value) }} value={companyName} />
              <div className={classes.suggestion}>
                {companySuggestionsShow ? <div>
                  {
                    companySuggestions.map((val) => {
                      return <p onClick={() => {
                        getCompanyDetail(val.id, val.compName)
                      }}>
                        {val.compName}
                      </p>
                    })
                  }
                </div>
                  :
                  <></>
                }</div>
            </div>

            <AdsEntry input={"MultiLineInput"} label={"Company Description"} onChange={(e) => setCompDesc(e.target.value)} value={compDesc} />

            <AdsEntry input={"Mobile"} label={"Tel"} onChange={(e) => setMobile(e.target.value)} value={mobile} />
            {/* <div className={classes.errors}>{mobileError}</div> */}
            <AdsEntry input={"SingleLineInput"} label={"Website"} value={website} onChange={(e) => setWebsite(e.target.value)} />
            <AdsEntry input={"MultiLineInput"} label={"Address"} value={address} onChange={(e) => setAddress(e.target.value)} />
            <AdsEntry input={'smalldropdown'} label={"Location"} displayData={location} onChange={(e) => setSelectedLoc(e.target.value)} defaultLocation={existingLoc} />
            {/* <AdsEntry input={'SingleLineInput'} label={"Advertiser Name"} onChange={(e) => setAdvName(e.target.value)} /> */}
            <div className={classes.buttonDiv} >
              <DashboardButton buttontext={"Verify"} onClick={() => saveCompany()} />
            </div>
          </div>

        </div>
      }

    </div>
  );
};

export default Qcform;
