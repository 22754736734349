import React from 'react';
import { Link,useLocation } from 'react-router-dom';
import classes from './OrdersHeader.module.css';

const OrdersHeader = () => {
    const {pathname}=useLocation();
    return (
        <div className={classes.header_container}>
            <div className={classes.navbar}>
                <Link  to="/Success"  className={`${classes.link} ${pathname === "/Success"||pathname.includes("/orders")? classes.active_link : ""}`}>Success</Link>
                <Link to="/Pending"  className={`${classes.link} ${pathname === "/Pending"? classes.active_link : ""}`}>Pending</Link>
                <Link to="/Cancel"  className={`${classes.link} ${pathname === "/Cancel"? classes.active_link : ""}`}>Cancel</Link>
            </div>
        </div >
    )
}

export default OrdersHeader
