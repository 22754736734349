import React from "react";
import classes from "./Modal.module.css";
import { BsPatchCheck } from "react-icons/bs";

const Modal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }
  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        <BsPatchCheck style={{ width: "30px", height: "30px", marginBottom: "10px" }} />
        <h2 style={{ textAlign: "center" }}>Form has been submitted successfully</h2>
        <button onClick={props.onClose}>Close</button>
      </div>
    </>
  );
};

export default Modal;
