import React from "react";
import classes from "./Modal.module.css";

const FormModel = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        />
        <h2 style={{ textAlign: "center" }}>
          Form has been submitted successfully
        </h2> */}
        <div className={classes.eventdetail_container}>
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <div className={classes.images_div}>
                <div className={classes.table_th} style={{fontWeight: '700'}}>Certificate of Incorporation/ GST Certificate:</div>
                <img
                  src={props.data.GSTCertificate}
                  alt=""
                  className={classes.courseDisplayCard}
                  style={{width: '200px', height: '200px'}}
                ></img>

                <div className={classes.table_th} style={{fontWeight: '700', marginTop: '10px'}}>Initiative Supporting Collaterals:</div>
                <img
                  src={props.data.SupportingCollaterals}
                  alt=""
                  className={classes.SupportingCollaterals}
                  style={{width: '200px', height: '200px'}}
                ></img>

<div className={classes.table_th} style={{fontWeight: '700', marginTop: '10px'}}>Impact Report:</div>
                <img
                  src={props.data.ImpactReport}
                  alt=""
                  className={classes.ImpactReport}
                  style={{width: '200px', height: '200px'}}
                ></img>

<div className={classes.table_th} style={{fontWeight: '700', marginTop: '10px'}}>Other documents supporting the initiative:</div>
                <img
                  src={props.data.OtherDocuments}
                  alt=""
                  className={classes.OtherDocuments}
                  style={{width: '200px', height: '200px'}}
                ></img>

              </div>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                    <tr>
                      <th className={classes.table_th}>Incorporation Year :-</th>
                      <td className={classes.table_td}>{props.data.IncorporationYear}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Segment :</th>
                      <td className={classes.table_td}>{props.data.Segment} </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>CSR Spending :</th>
                      <td className={classes.table_td}>
                        {props.data.CSRSpending}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Category :</th>
                      <td className={classes.table_td}>{props.data.Category}</td>
                    </tr>
                    {/* <tr>
                      <th className={classes.table_th}>Course Source :</th>
                      <td className={classes.table_td}>{props.data.course_source}</td>
                    </tr> */}
                    <tr>
                      <th className={classes.table_th}>Project Name :</th>
                      <td className={classes.table_td}>{props.data.ProjectName}</td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Date Of Project :</th>
                      <td className={classes.table_td}>
                        {props.data.DateOfProject}
                      </td>
                    </tr>
                     <tr>
                      <th className={classes.table_th}>CSRInitiativeDetails:</th>
                      <td className={classes.table_td}>
                        {props.data.CSRInitiativeDetails}
                      </td>
                    </tr> 
                     <tr>
                      <th className={classes.table_th}>Impact :</th>
                      <td className={classes.table_td}>
                        {props.data.Impact}
                      </td>
                    </tr>
                     <tr>
                      <th className={classes.table_th}>Stakeholder Engagement :</th>
                      <td className={classes.table_td}>
                      {props.data.StakeholderEngagement}
                      </td>
                    </tr> 
                     <tr>
                      <th className={classes.table_th}>Scalability :</th>
                      <td className={classes.table_td}>{props.data.Scalability}</td>
                    </tr> 
                    {/* <tr>
                      <th className={classes.table_th}>Course Group :</th>
                      <td className={classes.table_td}>{props.data.course_group}</td>
                    </tr> */}

                  
                    
                  
                  </tbody>
                </table>
              </div>
            </div>

           
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormModel;
