import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from './GoogleSignIn.module.css';
import { GoogleLogin } from 'react-google-login';


const GoogleSignIn = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const onSuccess = (res) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Email": (res.profileObj.email),
      "RequestUrl": "url"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/v1/api/apiTimes/BIAdminAutheri", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === 'SUCCESS') {
          sessionStorage.setItem("paperAdsEmail", res.profileObj.email)
          history.push("/timePaperads/inbox")
        }
        else {
          setError(true)

        }
      })
      .catch(error => console.log('error', error));

  }
  const onFailure = (res) => {
    console.log(res);
  }




  return (<div className={classes.container}>

    <GoogleLogin className={classes.button}
      clientId="148730292611-q449eq3pm3tokgp1fgntkn0jf6ebuvpt.apps.googleusercontent.com"
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
    />
    <div >{error && <div>
      <h2 className={classes.error}>Error Code: 007</h2>
      <p className={classes.error_text}>please login with authorized id</p>
    </div>}</div>


  </div>
  );
}
export default GoogleSignIn