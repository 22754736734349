import React, { useState, useEffect, useCallback } from "react";
import classes from "../Movement Of Professional/movementadd.module.css";
import EventInputs from "../Events/EventInputs";
import Modal from "../../Components/Modal";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../TimesBreadCrumbs";
import TextEditor from "../TextEditor";
import debounce from 'lodash.debounce'; 
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const EditQuizSection = () => {

  const options = [
   
    { value: 'Quiz', label: 'Quiz' },
    { value: 'Category', label: 'Category' },
  ];


  const [loading, setLoading] = useState("");
  const [quizTitle, setQuizTitle] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
 
 
  const [Description, setDescription] = useState("");
 
  const [image , setImage ] = useState("");
  const [BrandLogo , setBrandLogo] = useState("");
 
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState("");
    const [poweredBy , setPoweredBy ] = useState("");
    const [profilepic, setProfilepic] = useState("");
    const [BrandImage, setBrandImage]=useState('')
    
    const { id } = useParams();


    function formatDate(date) {
      if (!date) return '';
  
      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) return '';
  
      // Adjust the date to account for the timezone offset
      const timeZoneOffset = parsedDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
      const adjustedDate = new Date(parsedDate.getTime() - timeZoneOffset);
  
      // Format the date to a string in the required format
      const formattedDate = adjustedDate.toISOString().slice(0, 16);
      return formattedDate;
    }


    useEffect(() => {
        getManagerDetailByID();
        
    }, []);
    
    const getManagerDetailByID = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );

        var formdata = new FormData();
        formdata.append("QuizDataSetUUID", id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            global.apiLink +"/adminapi/GetQuizSetById",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === "Success" && result.status_code == 200) {
                
                    //setImage(result.data.ImageURL);
                    setCategory(result.data.QuizType)
                    setPoweredBy(result.data.PoweredBy)
                    setBrandLogo(result.data.BrandLogo)
                    setProfilepic(result.data.ImageURL);
                    setQuizTitle(result.data.QuizTitle);
                    setDescription(result.data.Description);
                    setStartDate(result.data.StartDate);
                    setEndDate(result.data.EndDate);
                 
                
                   
                }
                
            })
            .catch((error) => console.log("error", error));
    };


    const fetchCategoryList = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
        );

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(global.apiLink +"/adminapi/QuizListCategory", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setCategoryList(result.data);
            }
            )
            .catch((error) => console.log("error", error));
    }

    useEffect(() => {
        fetchCategoryList();
    }
        , []);


   
    
    
    

    const UpdateMovementApi = () => {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
  
      const formdata = new FormData();
      formdata.append("QuizDataSetUUID", id);
      formdata.append("QuizTitle", quizTitle);
      formdata.append("Image", image);
      formdata.append("BrandLogo", BrandImage);
      formdata.append("Description", Description);
      formdata.append("StartDate", startDate);
      formdata.append("EndDate", endDate);
      formdata.append("QuizType", category);
      formdata.append("Description", Description);
      formdata.append("PoweredBy", poweredBy);
  
      // Append selected questions (QAUUIDs) as a comma-separated string
   
  
      const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
      };
  
      fetch( global.apiLink +"/adminapi/UpdateQuizSet", requestOptions)
          .then((response) => response.json())
          .then((result) => {
              if (result.status === "Success" && result.status_code === 200) {
                  setModalShow(true);
              } else if (result.status_code === 400 || result.status_code === 500) {
                  setInternalErrorShow(true);
              } else if (result.status_code === 300) {
                  setErrorShow(true);
              }
          })
          .catch((error) => console.log("error", error))
          .finally(() => setLoading(false));
  };

  const pageInfo = {
    currentPageName: "Create Quiz ",
    previousPageName: "AddQuizSection",
    previousPage: "/BiDashboard/QuizList",
  };



  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/QuizList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>
             Update Quiz
            </div>
            
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Quiz Name"}
                placeholder={"Quiz Name"}
                onChange={(e) => setQuizTitle(e.target.value)}
                value={quizTitle}
              />
        <EventInputs
                input={"ImageInput"}
                label={"Profile Pic"}
                placeholder={"Profile Pic"}
                onChange={(e) => setImage(e.target.files[0])}
              />
              {image ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(image)}
                  className={classes.imgicon}
                />
              ) : (
                <img
                  src={profilepic}
                  alt="movement"
                  className={classes.imgicon}
                />
              )}

              <EventInputs
                input={"ImageInput"}
                label={"Brand Logo"}
                placeholder={"Brand Logo"}
                onChange={(e) => setBrandImage(e.target.files[0])}
              />
              {BrandImage ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={URL.createObjectURL(BrandImage)}
                  className={classes.imgicon}
                />
              ) : (
                <img
                  src={BrandLogo}
                  alt="movement"
                  className={classes.imgicon}
                />
              )}
           
             
       
      



            </div>
 
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Quiz Description"}
                placeholder={"Quiz Description"}
                onChange={(e) => setDescription(e.target.value)}
                value={Description}
              />
            <EventInputs
                                input={"smalldropdown"}
                                label={"Quiz Category"}
                                placeholder={"Quiz Category"}
                                onChange={(e) => setCategory(e.target.value)}
                                displayData={categoryList}
                                value={category}
                            />

              <EventInputs
                input={"SingleLineInput"}
                label={"Powered By"}
                placeholder={"Powered By"}
                onChange={(e) => setPoweredBy(e.target.value)}
                value={poweredBy}
              />
              
              <label className={classes.dateLabel}>Start Date</label>
<div className={classes.dropdown}>
               
                <input
                  className={classes.drop}
                  type="datetime-local"
                  value={formatDate(startDate)}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <label className={classes.dateLabel}>End Date</label>
              <div className={classes.dropdown}>
                
                <input
                  className={classes.drop}
                  type="datetime-local"
                  value={formatDate(endDate)}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            
            </div>
          </div>

        

        </div>


   

        <button
          className={classes.Upload_button}
          onClick={() => UpdateMovementApi()}
        >
          {loading ? (
            <Loader
              type="TailSpin"
              color="white"
              height={20}
              width={50}
              className="text-center"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditQuizSection;
