import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./CourseDetails.module.css";
import { Link } from "react-router-dom";
import BackButton from "../BackButton";

const CourseDetail = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    viewCourse();
  }, []);
  console.log(data);
  const viewCourse = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/getcoursebyid-admin",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        //   console.log(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  // console.log("id:" + id, data.course_category)
  return (
    <div className={classes.eventdetail_container}>
      {/* {data.map((item) => ( */}
      <div className={classes.eventdetail_main}>
        <div className={classes.event_detail}>
          <div className={classes.images_div}>
            <div className={classes.table_th}>Course Image</div>
            <img
              src={data.course_image}
              alt=""
              className={classes.course_image}
            ></img>

            <div className={classes.table_th}>Provider Logo</div>
            <img
              src={data.courseProviderLogo}
              alt=""
              className={classes.course_provider_img}
            ></img>
          </div>
          <div className={classes.event_text}>
            <table>
              <tbody>
                <tr>
                  <th className={classes.table_th}>Course Title :-</th>
                  <td className={classes.table_td}>{data.course_title}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Type :</th>
                  <td className={classes.table_td}>{data.course_type} </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Category :</th>
                  <td className={classes.table_td}>{data.course_category}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Id :</th>
                  <td className={classes.table_td}>{data.course_id}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Source :</th>
                  <td className={classes.table_td}>{data.course_source}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course url :</th>
                  <td className={classes.table_td}>{data.course_url}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Relevant :</th>
                  <td className={classes.table_td}>{data.courseRelevant}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Certification:</th>
                  <td className={classes.table_td}>{data.certification} </td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Created At:</th>
                  <td className={classes.table_td}>{data.course_created_at}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course UpdatedAt :</th>
                  <td className={classes.table_td}>{data.course_updated_at}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Industry :</th>
                  <td className={classes.table_td}>{data.industry}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Course Group :</th>
                  <td className={classes.table_td}>{data.course_group}</td>
                </tr>

                <tr>
                  <th className={classes.table_th}>Course Duration:</th>
                  <td className={classes.table_td}>{data.courseDuration}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Provider Name :</th>
                  <td className={classes.table_td}>{data.providerName}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Provider Website :</th>
                  <td className={classes.table_td}>{data.providerWebsite}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>Start Date :</th>
                  <td className={classes.table_td}>{data.startDate}</td>
                </tr>
                <tr>
                  <th className={classes.table_th}>End Date :</th>
                  <td className={classes.table_td}>{data.endDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={classes.detail_bottom}>
          <h4>Description:</h4>
          <p className={classes.description}>
            <div dangerouslySetInnerHTML={{ __html: data.course_desc }} />
          </p>
        </div>
        <Link to="/BiDashboard/courses" className={classes.BackButton}>
          <BackButton />
        </Link>
      </div>

      {/* ))
            } */}
    </div>
  );
};

export default CourseDetail;
