import React, { useState } from "react";
import classes from "./AddLeadersOfChange.module.css";
import { Link, useHistory, useParams } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Loader from "react-loader-spinner";
import { useEffect } from "react";
import { AiFillCopy } from "react-icons/ai";
import BackButton from "../Components/BackButton";
import Modal from "../Components/Modal";
import EventInputs from "../Components/Events/EventInputs";
import TextEditor from "../Components/TextEditor";

const EditHrconclave = () => {
  const history = useHistory();
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [webImage, setWebImage] = useState("");
  const [description, setDescription] = useState("");
  const [webImageAltTag, setWebImageAltTag] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [Title, setTitle] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [CompanyLogo, setCompanyLogo] = useState("");
  console.log("comasdad", CompanyLogo);

  const [designation, setDesignation] = useState("");
  console.log(designation, "hbfjhwrgrfblwbfiwgfwhfb");
  const [showImages, setShowImages] = useState([]);
  const [isLive, setIsLive] = useState(false);
  const [Image, setImage] = useState("");
  console.log("aSasAS", Image);
  const { id } = useParams();

  useEffect(() => {
    getManagerDetailByID();
    multipleImagesUpload();
  }, []);

  const multipleImagesUpload = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/UploadMultipleImagesHrConclave",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setShowImages(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getManagerDetailByID = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/HrConclaveParticipantByID",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "Success" && result.status_code == 200) {
          setDesignation(result.data.Designation);
          setName(result.data.Name);
          setImage(result.data.Image);
          setWebImage(result.data.Logo);
          setCompanyLogo(result.data.ComapnyLogo);
          setDescription(result.data.AboutPerson);
          setWebImageAltTag(result.data.imageAltTag);
          setMetaTitle(result.data.MetaTitle);
          setMetaDescription(result.data.MetaDescription);

          setCompanyName(result.data.CompanyName);
          setSeoUrl(result.data.SeoURL);
          setIsLive(result.data.IsLive == 1 ? true : false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateGreatManagerInstitute = (e) => {
    e.preventDefault();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("Id", id);
    formdata.append("Name", name);
    formdata.append("ComapnyLogo", CompanyLogo);
    formdata.append("Image", Image);
    formdata.append("imageAltTag", webImageAltTag);
    formdata.append("CompanyName", CompanyName);
    formdata.append("MetaTitle", metaTitle);
    formdata.append("MetaDescription", metaDescription);
    formdata.append("AboutPerson", description);
    formdata.append("Designation", designation);
    formdata.append("IsLive", isLive ? 1 : 0);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EditHrConclaveParticipant",

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data Updated successfully"}
          onClick={() => history.push("/BiDashboard/HrConclave")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Edit HR Conclave</div>
            <Link to="/BiDashboard/HrConclave" className={classes.BackButton}>
              <BackButton />
            </Link>
          </div>
          <form
            onSubmit={updateGreatManagerInstitute}
            className={classes.form_input}
          >
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Name"}
                required={true}
                placeholder={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <EventInputs
                input={"ImageInput"}
                label={"Logo"}
                placeholder={"Logo"}
                onChange={(e) => setCompanyLogo(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
              {CompanyLogo ? (
                typeof CompanyLogo === "string" ? (
                  <img src={CompanyLogo} className={classes.showImage} />
                ) : (
                  <img src={CompanyLogo} className={classes.showImage} />
                )
              ) : null}

              {console.log("logo-data", CompanyLogo, typeof CompanyLogo)}
              {console.log("image-data", Image, typeof Image)}

              <EventInputs
                input={"ImageInput"}
                label={"Image"}
                placeholder={"Hr Image"}
                onChange={(e) => setImage(e.target.files[0])}
                type={"file"}
                accept=".png, .jpg, .jpeg, .webp"
              />
              {Image ? (
                typeof Image === "string" ? (
                  <img src={Image} className={classes.showImage} />
                ) : (
                  <img src={Image} className={classes.showImage} />
                )
              ) : null}

              <EventInputs
                input={"SingleLineInput"}
                label={"Designation"}
                placeholder={"Designation"}
                onChange={(e) => setDesignation(e.target.value)}
                value={designation}
              />
            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Description"}
                placeholder={"Meta Description"}
                required={true}
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Company Name"}
                placeholder={"Company Name"}
                onChange={(e) => setCompanyName(e.target.value)}
                value={CompanyName}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Logo Alt Tag"}
                placeholder={"Logo Alt Tag"}
                onChange={(e) => setWebImageAltTag(e.target.value)}
                value={webImageAltTag}
                required={true}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Meta Title"}
                placeholder={"Meta Title"}
                required={true}
                onChange={(e) => setMetaTitle(e.target.value)}
                value={metaTitle}
              />
            </div>

            <div className={classes.multipleImageContainer}>
              <div className={classes.formGroup}>
                <label htmlFor="image"> Multiple Image : </label>
                <input
                  multiple
                  type="file"
                  // required
                  accept=".png, .jpg, .jpeg"
                  name="image"
                  onChange={(e) => {
                    multipleImagesUpload(e);
                  }}
                />
              </div>
              <div className={classes.gridContainer}>
                {showImages.map((item, index) => (
                  <div className={classes.ImageUrlContainer}>
                    <img
                      src={item.ImageURL}
                      alt="hello"
                      className={classes.MultipleImgSelect}
                    />
                    {/* <AiOutlineCloseCircle
                      className={classes.RemoveImgIcon}
                      onClick={() => RemoveMultipleImage(item.id)}
                    /> */}
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(item.ImageURL);
                        alert("Copied");
                      }}
                      className={classes.CopyURLIcon}
                    >
                      <AiFillCopy size={22} />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.formGroup}>
              <label htmlFor="Content">Description :</label>
              <div>
                <TextEditor
                  onChangeContent={setDescription}
                  value={description}
                />
              </div>
            </div>

            <div className={classes.checkbox}>
              <div className={classes.checkbox}>
                <EventInputs
                  input={"CheckBox"}
                  onChange={() => setIsLive(!isLive)}
                  label={"Want to publish"}
                  value={isLive}
                />
                <div className={classes.btnWrapper}>
                  <button
                    type="submit"
                    className={classes.Upload_button}
                    disabled={loading}
                  >
                    {loading ? (
                      <Loader
                        type="ThreeDots"
                        color="#fff"
                        height={10}
                        width={70}
                        visible={true}
                        //3 secs
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditHrconclave;
