import React, { useState, useEffect } from "react";
import classes from "./TOIWidget.module.css";
import { useParams, Link } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Modal from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";

const UpdateTOIWidget = () => {

  const { id } = useParams();
  const [articlesId, setArticlesId] = useState();
  const [target, setTarget] = useState();
  const [image, setImage] = useState();
  const [imageId, setImageId] = useState();
  const [imgSize, setImgSize] = useState();
  const [hl, setHl] = useState();
  const [ag, setAg] = useState();
  const [wu, setWu] = useState();
  const [tn, setTn] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);

  useEffect(() => {
    getEventDetail();
  }, [])

  const NewEvent = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("widgetId", id)
    formdata.append("articleId", articlesId);
    formdata.append("target", target);
    formdata.append("imgurl", image);
    formdata.append("imageid", imageId);
    formdata.append("hl", (hl ? hl : ""));
    formdata.append("imagesize", (imgSize ? imgSize : ""));
    formdata.append("ag", (ag ? ag : ""));
    formdata.append("tn", (tn ? tn : ""));
    formdata.append("wu", (wu ? wu : ""));
    console.log(formdata);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(global.apiLink + "/adminapi/widget-update", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
          setArticlesId("");
          setTarget("");
          setImageId("");
          setImage("");
          setAg("");
          setHl("");
          setWu("");
          setTn("");
          setImgSize("");
        }
        else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        }
        else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getEventDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "widgetId": id
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/widget-view", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setArticlesId(result.data.articleId);
        setTarget(result.data.target);
        setImage(result.data.imgurl);
        setImageId(result.data.imageid);
        setImgSize(result.data.imagesize);
        setHl(result.data.hl);
        setAg(result.data.ag);
        setTn(result.data.tn);
        setWu(result.data.wu);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.top_div}>

      {modalShow && <Modal heading={"Data inserted successfully"} onClick={() => setModalShow(false)} />}
      {erorrshow && <Modal heading={"Required Fields Missing"} onClick={() => setErrorShow(false)} />}
      {internalErrorShow && <Modal heading={"Internal server error"} onClick={() => setInternalErrorShow(false)} />}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update TOIWidget</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Article Id"}
                placeholder={"Article Id"}
                onChange={(e) => setArticlesId(e.target.value)}
                value={articlesId}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Target"}
                placeholder={"Target"}
                onChange={(e) => setTarget(e.target.value)}
                value={target}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"hl"}
                placeholder={"hl"}
                onChange={(e) => setHl(e.target.value)}
                value={hl}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"ag"}
                placeholder={"ag"}
                onChange={(e) => setAg(e.target.value)}
                value={ag}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"wu"}
                placeholder={"wu"}
                onChange={(e) => setWu(e.target.value)}
                value={wu}
              />
            </div>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"tn"}
                placeholder={"tn"}
                onChange={(e) => setTn(e.target.value)}
                value={tn}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Size"}
                placeholder={"Image Size"}
                onChange={(e) => setImgSize(e.target.value)}
                value={imgSize}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Id"}
                placeholder={"Image Id"}
                onChange={(e) => setImageId(e.target.value)}
                value={imageId}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Image Url"}
                placeholder={"Image Url"}
                onChange={(e) => setImage(e.target.value)}
                value={image}
              />
            </div>
          </div>
        </div>
        <div className={classes.UpdateBtnContainer}>
          <button className={classes.Upload_button} onClick={() => NewEvent()}>Update</button>
          <Link to="/BiDashboard/TOIWidgetList" className={classes.UpdateBtn}>Back</Link>
        </div>
      </div>
    </div>
  );
};
export default UpdateTOIWidget;
