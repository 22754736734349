import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./impression.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";

const ImpressionWidget = () => {
  const [isLoading, setLoading] = useState(false);
  const [widgetdatadetail, setWidgetdatadetail] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const currentURL = window.location.href.split("/").pop();
  useEffect(() => {
    ImpressiondetailApi();
  }, [currentPage]);

  const ImpressiondetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      company: currentURL,
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getWidgetsDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setWidgetdatadetail(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const pageInfo = {
    currentPageName: "Impression Of Widget Detail Page",
    previousPageName: "Impression Of Widgets",
    previousPage: "/BiDashboard/ImpressionWidget",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>
        Impression Of Widget Detail Page
      </div>
      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={widgetdatadetail}
            filename={"getWidgetdetails.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload
            csvData={widgetdatadetail}
            fileName="getWidgetdetails"
          />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => ImpressiondetailApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {widgetdatadetail.length > 9 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {widgetdatadetail.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>Comapany Name</th>
                  <th className={classes.table_heading}>Company Url</th>
                  <th className={classes.table_heading}>Redirected From</th>
                  <th className={classes.table_heading}>Date</th>
                </tr>

                <tbody>
                  {widgetdatadetail.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.CompanyName}</td>
                        <td className={classes.table_td}>
                          <a href={item.cUrl} target="_blank" rel="noreferrer">
                            Page Url
                          </a>
                        </td>
                        <td className={classes.table_td}>
                          <a
                            href={item.redirectedFrom}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Redirection From
                          </a>
                        </td>
                        <td className={classes.table_td}>{item.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImpressionWidget;
