import React , {useState , useEffect} from 'react'
import DashboardMain from '../Component/DashboardMain'
import DashNav from '../Component/DashNav';
import AdminLoader from "../Component/AdminLoader";
import classes from "./inbox.module.css"

const Inbox = () => {
    

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    
    useEffect(() => {
      Apiinbox()
    }, []);
  
    const Apiinbox = () => {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
      myHeaders.append("Content-Type", "application/json");
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
  
      fetch("https://api.onecorp.co.in/adminapi/inbox", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          setData(result.data)
        })
        .catch(error => console.log('error', error))
        .finally(() => setLoading(false));
  
    }
    return (
      <div className={classes.MainContainer}>
      <DashNav/>
      {loading ? <div className={classes.loadingLayout}>
    <AdminLoader />
  </div>: 
      <>
       {data.map((item, key) => {
        return <DashboardMain apidata={item} adType={"editform"} />
      })}</>}
       

      </div>
    )
}

export default Inbox
