import React from "react";
import classes from "./DataPoints.module.css";
import UsersDataPoints from "../DataPoints/UsersDataPoints";

const DataPoints = () => {
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.table_container}>
        <UsersDataPoints />{" "}
      </div>
    </div>
  );
};

export default DataPoints;
