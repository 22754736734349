import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import classes from "../Global/AddEvents.module.css";
import Loader from "react-loader-spinner";
import { Link, useHistory, useParams } from "react-router-dom";
import BackButton from "../BackButton";
import TextEditor from "../TextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import EventInputs from "../Events/EventInputs";

const EditTestimonials = () => {
  const history = useHistory();
  const { Id } = useParams();
  const [Name, setName] = useState("");

  const [description, setDescription] = useState("");

  const [passLoader, setPassLoader] = useState();
  const [eventName, setEventName] = useState();
  const [location, setLocation] = useState();

  const [type, setType] = useState();
  const [organizer, setOrganizer] = useState();
  const [speakerName, setSpeakerName] = useState();
  const [speakerDesignation, setSpeakerDesignation] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [eventImage, setEventImage] = useState();
  const [eventImageUrl, setEventImageUrl] = useState();
  const [eventSpeakerImage, setEventSpeakerImage] = useState();
  const [eventSpeakerUrl, setEventSpeakerUrl] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [categories, setCategories] = useState();
  const [check, setCheck] = useState(0);
  const [editable, setEditable] = useState(true);
  const [editableEventImage, setEditableEventImage] = useState(true);
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [url, setUrl] = useState();

  useEffect(() => {
    getEventDetail();
  }, []);
  const NewEvent = () => {
    setPassLoader(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    const formdata = new FormData();
    formdata.append("Id", Id);
    formdata.append("Name", Name);
    formdata.append("Description", description);
    formdata.append("IsLive", check);

    if (eventImage) {
      formdata.append("AuthorImage", eventImage);
    }

    if (eventSpeakerImage) {
      formdata.append("CompanyLogo", eventSpeakerImage);
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/UpdateTestimonial",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setPassLoader(false);
      });
  };

  const getEventDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: Id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/GetTestimonialById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setName(result.data.Name);
        setData(result.data);

        setDescription(result.data.Description);

        setEventSpeakerUrl(result.data.AuthorImageUrl);

        setSpeakerName(result.data.event_speaker_name);
        setEventImageUrl(result.data.CompanyLogoUrl);
        setCheck(result.data.IsLive);
        setOrganizer(result.data.event_organizer);
        setUrl(result.data.eventUrl);

        //   console.log(result.data)
      })
      .catch((error) => console.log("error", error));
  };

  const onBodyChangeHandler = (textEditorValue) => {
    setDescription(textEditorValue);
  };

  return (
    <>
      <div className={classes.top_div}>
        {modalShow && (
          <Modal
            heading={"Data updated successfully"}
            onClick={() => history.push("/BiDashboard/TestimonialsList")}
          />
        )}
        {erorrshow && (
          <Modal
            heading={"Required Fields Missing"}
            onClick={() => setErrorShow(false)}
          />
        )}
        {internalErrorShow && (
          <Modal
            heading={"Internal server error"}
            onClick={() => setInternalErrorShow(false)}
          />
        )}

        <div className={classes.container}>
          <div className={classes.form}>
            <div className={classes.form_header_container}>
              <div className={classes.form_header}>Testimonials</div>
              <Link
                to="/BiDashboard/TestimonialsList"
                className={classes.BackButton}
              >
                <BackButton />
              </Link>
            </div>
            {/* {data.map((item) => ( */}
            <div className={classes.form_input}>
              <div className={classes.input}>
                <EventInputs
                  input={"SingleLineInput"}
                  label={"Name"}
                  placeholder={"Name"}
                  onChange={(e) => setName(e.target.value)}
                  value={Name}
                />

                <div className={classes.AddHrProfessional_Description}>
                  Description
                </div>
                <div className={classes.AddHrProfessional_Desc}>
                  {/* <SunEditor
                    onChange={(e) => {
                      setDescription(e);
                    }}
                    value={description}
                    setContents={description}
                  /> */}
                  <div>
                    <TextEditor
                      onChangeContent={setDescription}
                      value={description}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.input}>
                <EventInputs
                  input={"DateInput"}
                  label={"Date"}
                  placeholder={"Event Start Date"}
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate}
                />

                {editable && eventSpeakerUrl ? (
                  <div>
                    {/* Render the existing image with an edit button */}
                    <div className={classes.fieldName}>
                      <label htmlFor="input6">Author Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          <img
                            src={eventSpeakerUrl}
                            alt=""
                            width={50}
                            height={50}
                          />
                        </div>
                        <div>
                          <button
                            onClick={() => setEditable(false)}
                            className={classes.input_button}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  /* Render other fields without image upload */
                  <EventInputs
                    input={"UpdateImageInput"}
                    label={"Author image"}
                    placeholder={"Event Speaker Image"}
                    onChange={(e) => setEventSpeakerImage(e.target.files[0])}
                    value={eventSpeakerImage}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
                {editableEventImage && eventImageUrl ? (
                  <div>
                    {/* Render the existing event image with an edit button */}
                    <div className={classes.fieldName}>
                      <label htmlFor="input7">Upload Event Image</label>
                    </div>
                    <div className={classes.field1}>
                      <div className={classes.inputField}>
                        <div className={classes.inputFieldText}>
                          <img
                            src={eventImageUrl}
                            alt=""
                            width={50}
                            height={50}
                          />
                        </div>
                        <div>
                          <button
                            onClick={() => setEditableEventImage(false)}
                            className={classes.input_button}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  /* Render other fields without event image upload */
                  <EventInputs
                    input={"UpdateImageInput"}
                    label={"Company Logo"}
                    placeholder={"Event Image"}
                    onChange={(e) => setEventImage(e.target.files[0])}
                    value={eventImage}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
              </div>
              <div className={classes.checkbox}>
                <EventInputs
                  input={"CheckBox"}
                  onChange={() => setCheck(!check === true ? 1 : 0)}
                  label={"Want to publish this event"}
                  value={check}
                />
              </div>
            </div>
            {/* ))
                    } */}
          </div>
          <button className={classes.Upload_button} onClick={() => NewEvent()}>
            {passLoader ? (
              <Loader type="TailSpin" color="white" height={20} width={20} />
            ) : (
              <> Update</>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditTestimonials;
