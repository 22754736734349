import React from "react";
import classes from "./Modal.module.css";
import EventInputs from "../Events/EventInputs";
import Loader from "react-loader-spinner";

const GoogleIndexModal = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        /> */}
        <h2 className={classes.heading} style={{ textAlign: "center" }}>
          {props.modalTitle}
        </h2>
        <div className={classes.eventdetail_container}>
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <EventInputs
                input={"SingleLineInput"}
                label={props.modalTitle}
                placeholder={"Enter URL"}
                // required={true}
                onChange={(e) => props.setUrl(e.target.value)}
                value={props.url}
              />
              {props.modalTitle !== "Inspect URL" && (
                <EventInputs
                  input={"ImageInput"}
                  label={"Upload Excel"}
                  placeholder={"Upload Excel"}
                  // required={true}
                  onChange={(e) => props.setUrlExcel(e.target.files[0])}
                  type={"file"}
                  accept=".xlsx"
                />
              )}
            </div>

            <div className={classes.buttonContainer}>
              <div className={classes.BackButton}>
                <button onClick={props.onClose}>Close</button>
                {/* <BackButton /> */}
              </div>
              <div className={classes.BackButton}>
                <button onClick={props.handleSubmit}>
                  {props.isLoading ? (
                    <Loader
                      type="ThreeDots"
                      color="#fff"
                      height={10}
                      width={70}
                      visible={true}
                      //3 secs
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                {/* <BackButton /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleIndexModal;
