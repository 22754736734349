import React, { useState } from "react";
import PostAJobInput from "../Components/RecruiterSignUp/PostAJobInput";
import classes from "./PostAJobForm.module.css";
import { Link, useHistory } from "react-router-dom";
// import BackButton from "../BackButton";
// import Modal from '../Modal';
// import TextEditor from "../TextEditor";

const RecruiterSignUp = () => {

    //   const history = useHistory()

    //   const [eventName, setEventName] = useState();
    //   const [location, setLocation] = useState();
    //   const [description, setDescription] = useState();
    //   const [type, setType] = useState();
    //   const [organizer, setOrganizer] = useState();
    //   const [speakerName, setSpeakerName] = useState();
    //   const [speakerDesignation, setSpeakerDesignation] = useState();
    //   const [startTime, setStartTime] = useState();
    //   const [endTime, setEndTime] = useState();
    //   const [startDate, setStartDate] = useState();
    //   const [endDate, setEndDate] = useState();
    //   const [eventImage, setEventImage] = useState();
    //   const [eventSpeakerImage, setEventSpeakerImage] = useState();
    //   const [date, setDate] = useState();
    //   const [time, setTime] = useState();
    //   const [categories, setCategories] = useState();
    //   const [check, setCheck] = useState(0);
    //   const [modalShow, setModalShow] = useState(false);
    //   const [erorrshow, setErrorShow] = useState(false);
    //   const [internalErrorShow, setInternalErrorShow] = useState(false);
    //   const [url, setUrl] = useState();


    //   const NewEvent = () => {
    //     var myHeaders = new Headers();
    //     myHeaders.append(
    //       "Authorization",
    //       "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    //     );

    //     var formdata = new FormData();
    //     formdata.append("eventName", (eventName ? eventName : ""));
    //     formdata.append("eventLocation", (location ? location : ""));
    //     formdata.append("eventDescription", (description ? description : ""));
    //     formdata.append("eventType", (type ? type : ""));
    //     formdata.append("event_time", (time ? time : ""));
    //     formdata.append("event_date", (date ? date : ""));
    //     formdata.append("event_organizer", (organizer ? organizer : ""));
    //     formdata.append("event_speaker_name ", (speakerName ? speakerName : ""));
    //     formdata.append("event_speaker_designation", (speakerDesignation ? speakerDesignation : ""));
    //     formdata.append("event_speaker_image", (eventSpeakerImage ? eventSpeakerImage : ""));
    //     formdata.append("event_image", (eventImage ? eventImage : ""));
    //     formdata.append("event_start_time", (startTime ? startTime : ""));
    //     formdata.append("event_end_time", (endTime ? endTime : ""));
    //     formdata.append("eventStartDate", (startDate ? startDate : ""));
    //     formdata.append("eventEndDate", (endDate ? endDate : ""));
    //     formdata.append("isPublished", check);
    //     formdata.append("eventCategories", (categories ? categories : ""));
    //     formdata.append("eventUrl", (url ? url : ""));

    //     console.log(formdata);
    //     var requestOptions = {
    //       method: "POST",
    //       headers: myHeaders,
    //       body: formdata,
    //       redirect: "follow",
    //     };
    //     fetch(global.apiLink+"/adminapi/new-event", requestOptions)
    //       .then((response) => response.json())
    //       .then((result) => {
    //         if (result.status === "SUCCESS" && result.status_code === 200) {
    //           setModalShow(true);
    //         }
    //         else if (result.status_code === 400 || result.status_code === 500) {
    //           setInternalErrorShow(true);
    //         }
    //         else if (result.status_code === 300) {
    //           setErrorShow(true);
    //         }

    //       })
    //       .catch((error) => console.log("error", error));
    //   };

    //   const onBodyChangeHandler =(textEditorValue) =>{
    //     setDescription(textEditorValue);
    //   };
    const coursegroup = [
        {
            type: "Initial"
        },
        {
            type: "Initial"
        },
        {
            type: "Initial"
        },
        {
            type: "Initial"
        },

    ]

    const countryname = [
        {
            type: "India"
        },
        {
            type: "Pakistan"
        }
    ]

    return (
        <div className={classes.top_div}>



            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.form_header_container}>
                        <div className={classes.form_header}>Recruiter Sign Up</div>
                        <Link to="/BiDashboard/events" className={classes.BackButton}>
                            {/* <BackButton /> */}
                        </Link>
                    </div>
                    <PostAJobInput
                        input={"smalldropdown"}
                        label={"Course Group"}
                        placeholder={"Course Group"}

                        displayData={coursegroup}

                    />
                    <div className={classes.form_input}>
                        <div className={classes.input}>
                            <PostAJobInput
                                input={"SingleLineInput"}
                                label={"Name"}
                                placeholder={"Enter your full name"}
                            // onChange={(e) => setEventName(e.target.value)}
                            // value={eventName}
                            />
                            <div className={classes.Gender}>Gender</div>
                            <div className={classes.GenderRadioButton}>
                                <PostAJobInput
                                    input={"radioOne"}
                                    //   label={"Gender"}
                                    value={"Male"}
                                />
                                <PostAJobInput
                                    input={"radioTwo"}
                                    //   label={"Gender"}
                                    value={"Female"}
                                />
                            </div>
                            <PostAJobInput
                                input={"SingleLineInput"}
                                label={"Email"}
                                placeholder={"Enter your Email id"}
                            // onChange={(e) => setLocation(e.target.value)}
                            // value={location}
                            />
                            <PostAJobInput
                                input={"Number"}
                                label={"Mobile"}
                                placeholder={"Enter your mobile number"}
                            // onChange={(e) => setOrganizer(e.target.value)}
                            // value={organizer}
                            />
                            <PostAJobInput
                                input={"SingleLineInput"}
                                label={"GSTIN (Optional)"}
                                placeholder={"GSTIN"}
                            // onChange={(e) => setUrl(e.target.value)}
                            // value={url}
                            />
                        </div>
                        <div className={classes.input}>
                            <PostAJobInput
                                input={"SingleLineInput"}
                                label={"Address line1"}
                                placeholder={""}
                            // onChange={(e) => setDescription(e.target.value)}
                            // value={description}
                            />
                            <PostAJobInput
                                input={"SingleLineInput"}
                                label={"Address line2 (Optional)"}
                                placeholder={""}
                            // onChange={(e) => setDescription(e.target.value)}
                            // value={description}
                            />
                            <PostAJobInput
                                input={"Countrydropdown"}
                                // label={"Course Group"}
                                // placeholder={"Course Group"}
                                // onChange={(e) => setCourseGroup(e.target.value)}
                                CountryName={countryname}
                            // value={courseGroup}
                            />
                            <PostAJobInput
                                input={"password"}
                                label={"Create Password"}
                                placeHolder={""}
                            // onChange={(e) => setPassword(e.target.value)}
                            // value={password}
                            />
                            <PostAJobInput
                                input={"password"}
                                label={"Confirm Password"}
                                placeHolder={""}
                            // onChange={(e) => setPassword(e.target.value)}
                            // value={password}
                            />
                        </div>
                        <div className={classes.checkbox}>
                            <PostAJobInput
                                input={"CheckBox"}
                                // onChange={() => setCheck(!check === true ? 1 : 0)}
                                label={"I agree to use the details to create my Recruiter Profile & display it on the Times Ascent site and also agree to be bound by the Terms & Privacy Policy of Times Ascent"}
                            // value={check}
                            />
                        </div>
                    </div>
                </div>
                <button className={classes.Upload_button} >
                    submit
                </button>
            </div>
        </div>
    );
};

export default RecruiterSignUp;
