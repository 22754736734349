import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./resumeuploadlast.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";

const ResumeUploadLastMonth = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [lastresume, setLastresume] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);
  const [isdate, setIsdate] = useState("ASC");
  const [issno, setIssno] = useState("ASC");
  const [resumecount, setResumecount] = useState("ASC");
  useEffect(() => {
    ResumeLastMonthApi();
  }, [currentPage]);

  const ResumeLastMonthApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getResumeUploads",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLastresume(result.data);
        setCount(result.totalCount);
        setFirstDate(result.startdate);
        setLastDate(result.endDate);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  console.log(startDate, "Start Date");
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  const sortingdate = (col) => {
    if (isdate === "ASC") {
      const sorteddate = [...lastresume].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastresume(sorteddate);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddate = [...lastresume].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastresume(sorteddate);
      setIsdate("ASC");
    }
  };
  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...lastresume].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setLastresume(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...lastresume].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setLastresume(sortsno);
      setIssno("ASC");
    }
  };

  const pageInfo = {
    currentPageName: "Resume Uploads Count Page",
    previousPageName: "DataPoints",
    previousPage: "DataPoints",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Resume Uploads Count Page</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={lastresume}
            filename={"Resume_Upload_Table.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={lastresume} fileName="Resume_Upload" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || firstdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || lastdate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() =>
                formValidation()
                  ? ResumeLastMonthApi()
                  : console.log("Something Wrong")
              }
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      <div className={classes.buttons1}>
        {currentPage !== 1 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            className={classes.buttonone}
          >
            Prev
          </button>
        ) : (
          <></>
        )}
        <span>{currentPage}</span>
        {lastresume.length > 9 ? (
          <button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            className={classes.buttonone}
          >
            Next
          </button>
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {lastresume.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>
                    Sno
                    {issno === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Date
                    {isdate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Date")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Date")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Resume Uploads</th>
                </tr>

                <tbody>
                  {lastresume.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.Date}</td>
                        <td className={classes.table_td}>
                          <Link
                            to={
                              "/BiDashboard/ResumeUploadsLastMonthDetail/" +
                              item.Date
                            }
                          >
                            {item.NumberOfResumeUpload}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeUploadLastMonth;
