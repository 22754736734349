import React from 'react'
import classes from './Dashboard.module.css';
const Heading = (props) => {

    const headingCase = (type) => {
        switch (type) {
            case "BgHeading":
                return(
                    <div className = {classes.bgHeading}>
                        <div className={classes.headingText}>{props.headingtext} </div>
                    </div>
                )
            case "SimpleHeading":
                return(
                    <div className ={classes.simpleHeading}>
                        {props.headingtext}
                    </div>
                )
        
            default:
                console.log("Invalid Heading");
                break;
        }
    }
    return (
        <div>
           {headingCase(props.headingtype)}
        </div>
    )
}

export default Heading
