import React, { useState, useEffect, useRef } from "react";
import classes from "./ArticleManager.module.css";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useHistory, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import LoadingSpinner from "../../Components/LoadingSpinner";
import ExcelDownload from "../../Components/Articles/ExcelDownload";
import Paginate from "../../Components/Pagination";
import ConfirmModal from "../../Components/ConfirmModal";
import Modal from "../../Components/Modal";

const ArticleManager = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const doc = new jsPDF();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [count, setCount] = useState(0);
  const [managerUUID, setManagerUUID] = useState("");
  const [errorModal, setErrorModal] = useState("");
  const perPage = 20;

  const tableRef = useRef();

  useEffect(() => {
    articleManager();
  }, [currentPage]);

  const history = useHistory();

  const handleNewArticle = () => {
    history.push("/BiDashboard/AddArticleManager");
  };

  const articleManager = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      page: currentPage,
      content: perPage,
      StartDate: date.startDate ? date.startDate : "",
      EndDate: date.endDate ? date.endDate : "",
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/GetArticlesManager",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code == 200 && result.status === "Success") {
          setData(result.data);
          setCount(result.count);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteManager = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("UUID", managerUUID);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/DeleteArticlesManager",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          setModalShow(true);
          articleManager();
        } else {
          setErrorModal(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.container}>
        {confirmModalShow && (
          <ConfirmModal
            heading={"Are you sure that you want to delete this data?"}
            onClick={() => deleteManager()}
            onClose={() => setConfirmModalShow(false)}
          />
        )}
        {modalShow && (
          <Modal
            heading={"Data deleted successfully"}
            onClick={() => {
              setModalShow(false);
              setConfirmModalShow(false);
            }}
          />
        )}
        {errorModal && (
          <Modal
            heading={"Unable to delete the data."}
            onClick={() => {
              setErrorModal(false);
              setConfirmModalShow(false);
            }}
          />
        )}

        <div className={classes.header}>Article Manager List :</div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={data}
              filename={"article-manager-table.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={data} fileName="article-manager-table" />
          </div>
          <div className={classes.buttons}>
            <button className={classes.button} onClick={handleNewArticle}>
              Add New
            </button>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className={classes.spinner}>
              <LoadingSpinner />{" "}
            </div>
          ) : data.length ? (
            <>
              <table
                id="article-table"
                ref={tableRef}
                className={classes.orders_table}
              >
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sr. No.</th>
                  <th className={classes.table_heading}>Image</th>
                  <th className={classes.table_heading}>Name</th>
                  <th className={classes.table_heading}>Designation</th>
                  <th className={classes.table_heading}>Company Name</th>
                  <th className={classes.table_heading}>Short Description</th>
                  <th className={classes.table_heading}>Quotes</th>
                  <th className={classes.table_heading}>Status</th>
                  <th className={classes.table_heading}>Edit</th>
                  <th className={classes.table_heading}>Delete</th>
                </tr>
                <tbody>
                  {data.map((item, idx) => (
                    <tr key={item.Id} className={classes.table_row}>
                      <td className={classes.table_td}>{idx + 1}</td>
                      <td className={classes.table_td}>
                        <div>
                          <img
                            src={item.Image}
                            alt={item.AltImage || item.Name}
                            className={classes.imageShow}
                          />
                        </div>
                      </td>
                      <td className={classes.table_td}>{item.Name}</td>
                      <td className={classes.table_td}>{item.Designation}</td>
                      <td className={classes.table_td}>{item.CompanyName}</td>
                      <td className={classes.table_td}>
                        {item.ShortDescription}
                      </td>
                      <td className={classes.table_td}>{item.Quotes}</td>
                      <td className={classes.table_td}>
                        {item.IsLive == "1" ? "Live" : "Not Live"}
                      </td>
                      <td className={classes.table_td}>
                        <Link
                          to={"/BiDashboard/editArticleManager/" + item.UUID}
                          className={classes.articleLink}
                        >
                          <FiEdit size={20} />
                        </Link>
                      </td>
                      <td className={classes.table_td}>
                        <div
                          onClick={() => {
                            setManagerUUID(item.UUID);
                            setConfirmModalShow(true);
                          }}
                          className={classes.articleLinkDelete}
                        >
                          <MdDeleteForever size={20} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {count > 20 && (
                  <Paginate
                    maxPage={count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={perPage}
                  />
                )}
              </div>
            </>
          ) : (
            <h2 className={classes.serachErrorText}>
              "No Article Managers Found !"
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleManager;
