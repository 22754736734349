import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./articledetail.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import Card from "../Components/Dashboard/Card";
import { Link } from "react-router-dom";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const ArticleDetail = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [articledetail, setArticledetail] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState("");
  const currentURL = window.location.href.split("/").pop();
  const [currentPage, setCurrentPage] = useState(1);
  const [issno, setIssno] = useState("DSC");
  const [isfirstname, setIsfirstname] = useState("ASC");
  const [isauthor, setIsauthor] = useState("ASC");
  useEffect(() => {
    ArticledetailApi();
  }, [currentPage]);

  const ArticledetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      articleId: currentURL,
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/getarticleDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setArticledetail(result.data);
        setCount(result.count);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  console.log(startDate, "Start Date");
  const pageInfo = {
    currentPageName: "Articles Detailed Page",
    previousPageName: "Articles Data Points",
    previousPage: "/BiDashboard/ArticleData",
  };

  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...articledetail].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setArticledetail(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...articledetail].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setArticledetail(sortsno);
      setIssno("ASC");
    }
  };
  const sortingfirstname = (col) => {
    if (isfirstname === "ASC") {
      const sortedfirst = [...articledetail].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setArticledetail(sortedfirst);
      setIsfirstname("DSC");
    }
    if (isfirstname === "DSC") {
      const sortedfirst = [...articledetail].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setArticledetail(sortedfirst);
      setIsfirstname("ASC");
    }
  };
  const sortingauthor = (col) => {
    if (isauthor === "ASC") {
      const sortedfirst = [...articledetail].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setArticledetail(sortedfirst);
      setIsauthor("DSC");
    }
    if (isauthor === "DSC") {
      const sortedfirst = [...articledetail].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setArticledetail(sortedfirst);
      setIsauthor("ASC");
    }
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>Article Detailed Data</div>

      <div className={classes.DateFilter_container}>
        <div className={classes.button_container}>
          <CSVLink
            data={articledetail}
            filename={"ArticleDataDetail.csv"}
            target="_blank"
          >
            <button className={classes.button_articleList}>CSV</button>
          </CSVLink>
          <ExcelDownload csvData={articledetail} fileName="ArticleDataDetail" />
        </div>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || currentURL}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || currentURL}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => ArticledetailApi()}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.count}>Count :</div>
        <div className={classes.count_main}>{count}</div>
      </div>
      {count > 10 ? (
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {articledetail.length <= 10 ? (
            <>
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {articledetail.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>
                    Sno
                    {issno === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingsrno("sno")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Article Image</th>

                  <th className={classes.table_heading}>
                    Title
                    {isfirstname === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("title")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingfirstname("title")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>
                    Author{" "}
                    {isauthor === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingauthor("author")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingauthor("author")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Date</th>
                  <th className={classes.table_heading}>Article Source</th>
                </tr>

                <tbody>
                  {articledetail.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>
                          {item.imageSource ? (
                            <img
                              src={item.imageSource}
                              className={classes.table_td_image}
                              alt="no media"
                            />
                          ) : (
                            <img
                              src="/dummy-image-square.jpg"
                              className={classes.table_td_image}
                              alt="no media"
                            />
                          )}
                        </td>
                        <td className={classes.table_td}>{item.title}</td>
                        <td className={classes.table_td}>{item.author}</td>
                        <td className={classes.table_td}>{item.date}</td>
                        <td className={classes.table_td}>
                          {item.articleSource}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;
