import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./EasyApplyToday.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";

const EasyApplyToday = () => {
  const [isLoading, setLoading] = useState(false);
  const [today, setToday] = useState([]);

  useEffect(() => {
    EasyApplyApi();
  }, []);

  const EasyApplyApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/easy-apply", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setToday(result.Today);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.dashboard_container}>
      <div className={classes.events_title}>Easy Apply Today's Clicks</div>
      <div className={classes.button_container}>
        <CSVLink
          data={today}
          filename={"easyapplytoday-table.csv"}
          target="_blank"
        >
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <ExcelDownload csvData={today} fileName="easyapplytoday-table" />
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            <table className={classes.orders_table}>
              <tr className={classes.table_row}>
                <th className={classes.table_heading}>Id</th>
                <th className={classes.table_heading}>Candidate Id</th>
                <th className={classes.table_heading}>Company</th>
                <th className={classes.table_heading}>Event </th>
                <th className={classes.table_heading}>Page </th>
              </tr>
              <tbody>
                {today.map((item, value) => {
                  return (
                    <tr className={classes.table_row}>
                      <td className={classes.table_td}>{item.id}</td>
                      <td className={classes.table_td}>{item.candidateId}</td>
                      <td className={classes.table_td}>{item.company}</td>
                      <td className={classes.table_td}>{item.event}</td>
                      <td className={classes.table_td}>{item.page}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default EasyApplyToday;
