import React, { useState, useEffect } from "react";
import classes from "./ViewArticle.module.css";
import { useParams } from "react-router-dom";
const View = () => {
  const { id } = useParams();

  const [ArticleID, setArticleID] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState("");
  const [timeBaseUrl, setTimeBaseUrl] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [image, setImage] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [count, setCount] = useState("");
  const [readCount, setReadCount] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [primeThumbnail, setPrimeThumbnail] = useState("");
  const [author, setAuthor] = useState("");
  const [data, setData] = useState();
  const [createDate, setCreateDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [isFeaured, setIsFeaured] = useState("");
  const [articalId, setArticalId] = useState("");
  const [pthumbimage, setPthumbimage] = useState("");

  useEffect(() => {
    getDetailApi();
  }, []);

  const getDetailApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/view-article", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result.data, "kkkkk");
        setTitle(result.data.title);
        setShortDescription(result.data.shortDescription);
        setContent(result.data.content);
        // setTags();
        setTimeBaseUrl(result.data.times_base_url);
        setImagePath(result.data.imagePAth);
        setVideoLink(result.data.videoLink);
        // setCount();
        setReadCount(result.data.readCount);
        setCategory(result.data.category);
        setSubCategory(result.data.subCategory);
        setThumbnail(result.data.thumbnail);
        setPrimeThumbnail(result.data.primeThumb);
        setCreateDate(result.data.createdDate);
        setUpdateDate(result.data.updatedDate);
        setIsFeaured(result.data.isFeaturedArticle);
        setArticalId(result.data.ArticleId);
        setPthumbimage(result.data.mobileThumb);
        // setAuthor();
      })
      .catch((error) => console.log("error", error));
  };

  const fullImagePath = timeBaseUrl + imagePath;

  return (
    <div className={classes.NewArticle}>
      <h4 className={classes.ArticleHeading}>Article ID : {articalId}</h4>
      <form>
        <div className={classes.FormContainer}>
          <div className={classes.leftSide}>
            <div className={classes.formGroup}>
              {/* <label htmlFor="title">Title : </label>
                            <input type="text" id="title" name="title" placeholder="Title" value={title} /> */}
              <h4 className={classes.formGroup_TextTitle}>
                Title : <b>{title}</b>
              </h4>
            </div>
            <div>
              <p className={classes.artical_date}>
                Created date : {createDate}
              </p>
              <p className={classes.artical_date}>
                Updated date : {updateDate}
              </p>
            </div>
            <div>
              <p className={classes.artical_date}>
                Short description : {shortDescription}
              </p>
            </div>
            <div className={classes.Img_Artical}>
              <img src={fullImagePath} alt="" height="350" width="600" />
            </div>
            <div>
              <p className={classes.artical_date}>
                Content :<div dangerouslySetInnerHTML={{ __html: content }} />
              </p>
            </div>

            {/* <div className={classes.formGroup}>
                            <label htmlFor="shortDescription">Short Description : </label>
                            <textarea
                                rows="4"
                                cols="50"
                                id="shortDescription"
                                name="shortDescription"
                                placeholder="Write Description"
                                value={shortDescription}
                            />
                        </div> */}
            {/* <div className={classes.formGroup}>
                            <label htmlFor="Content">Content : </label>
                            <div className={classes.content}>
                                <TextEditor value={content} />
                            </div>
                        </div> */}
            {/* <div className={classes.formGroup}>
                            <label htmlFor="tags">Tags : </label>
                            <input type="text" name="tags" placeholder="Tags" />
                        </div> */}
            {/* <div className={classes.imageVideo}>
              {!!videoLink && (
                <div className={classes.formGroup}>
                  <label htmlFor="video">Video : </label>
                  <video width="200">
                    <source src={videoLink} type="video/mp4" />
                  </video>
                </div>
              )}
            </div> */}
            {/* <div className={classes.submitDiv}>
                            <button  className={classes.submitButton}>
                                Edit
                            </button>
                        </div> */}
          </div>
          <div className={classes.rightSide}>
            <h5 className={classes.otherDetailHeading}>Other Details</h5>
            <div className={classes.formGroup}>
              <p className={classes.artical_date}>
                Featured Article : {isFeaured}
              </p>
              <p className={classes.artical_date}>Live : </p>
              <p className={classes.artical_date}>Count : {readCount} </p>
              <p className={classes.artical_date}>Category : {category} </p>
              <p className={classes.artical_date}>
                SubCategory : {subCategory}{" "}
              </p>
            </div>
            <div className={classes.formGroup}>
              <p className={classes.artical_date}>Mobile Thumbnail :</p>
              <div>
                {" "}
                <img
                  src={pthumbimage}
                  className={classes.articleImage}
                  alt="Thumbnailimage"
                />
              </div>
            </div>

            {/* <div className={classes.formGroup}>
                            <label htmlFor="live">Live : </label>
                            <input type="text" name="live" />
                        </div>
                        <div className={classes.formGroup}>
                            <label htmlFor="Count">Count : </label>
                            <input type="text" name="Count" placeholder="Count" />
                        </div> */}

            {/* <div className={classes.formGroup}>
                            <label htmlFor="ReadCount">Read Count : </label>
                            <input type="text" name="ReadCount" placeholder="Read Count" value={readCount} />
                        </div> */}

            {/* <div className={classes.formGroup}>
                            <label htmlFor="category">Category : </label>
                            <input type="text" value={category} />
                        </div> */}

            {/* <div className={classes.formGroup}>
                            <label htmlFor="subCategory">Sub Category : </label>
                            <input type="text" value={subCategory} />
                        </div>

                        <div className={classes.formGroup}>
                            <label htmlFor="Thumbnail">Thumbnail : </label><br/>
                            <img src={thumbnail} className={classes.rightSideImage} alt="Thumnail"  />
                        </div>

                        <div className={classes.formGroup}>
                            <label htmlFor="PrimeThumbnail">Prime Thumbnail : </label><br/>
                            <img src={primeThumbnail} className={classes.rightSideImage} alt="primeThumbnail" />
                        </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default View;
