import React from 'react';
import Loader from "react-loader-spinner";

const LoadingSpinner = () => {
    return(
        <div >
        <Loader
        type="ThreeDots"
        color="#f02f39"
        height={100}
        width={100}
        visible={true}
       //3 secs
      />
        </div>
        )
}

export default LoadingSpinner
