import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AdminLoader from '../Component/AdminLoader'
import classes from './Recruiter.module.css'

const Recruiters = () => {
    const [recruiterData, setRecruiterData] = useState('')
    const [jobDetils, setJobDetils] = useState('')
    const [loading, setLoading] = useState(false)

    const { id } = useParams()

    useEffect(() => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 77927b69bb144b065ca11bf2a9d452819cd852db");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "RecruiterId": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/v1/api/apiTimes/GetrecruterDetails", requestOptions)
            .then(response => response.json())
            .then((result) => {
                setRecruiterData(result.recruiterDetail)
                setJobDetils(result.jobDetails)
                console.log(result)

            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(false));

    }, [id])




    return loading ? (
        <div className={classes.loadingLayout}><AdminLoader /></div>)
        : (

            <div className={classes.main_container}>
                <div>

                    <div className={classes.jobDetails}>

                        <div className={classes.recruiterDetails}>
                            <h2>RECRUITER DETAILS</h2>
                            <h4 className={classes.tag}>Recruiter Id</h4>
                            <h4 >Name</h4>
                            <h4>Gender</h4>
                            <h4>Address</h4>
                            <h4>Email</h4>
                            <h4>Mobile</h4>
                            <h4>City</h4>
                            <h4>Country</h4>
                        </div>
                        <div className={classes.recruitersData}>
                            <h4 className={classes.tag}> {recruiterData.recruiterId} </h4>
                            <h4 >{recruiterData.initial + recruiterData.name}</h4>
                            <h4>{recruiterData.gender}</h4>
                            <h4>{recruiterData.address1 + recruiterData.address2}</h4>
                            <h4>{recruiterData.email}</h4>
                            <h4>{recruiterData.mobile}</h4>
                            <h4>{recruiterData.city}</h4>
                            <h4>{recruiterData.country}</h4>

                        </div>

                    </div>
                    <div className={classes.linkDiv}>
                        <Link to={"/BiDashboard/JobDetails/" + id} className={classes.link}>Job Details</Link>
                        <Link to={"/BiDashboard/OrderDetails/" + id} className={classes.link}>Order Details</Link>
                        <Link to="/BiDashboard/RecruiterDetails" className={classes.link}>Back</Link>
                    </div>

                </div>
            </div>
        )
}


export default Recruiters
