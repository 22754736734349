import React from 'react';
import classes from "./PivotTable.module.css"

const PivotTable = () => {

    const partnerheading = [
        {
            partnercompany: "Set My Carrer",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
        {
            partnercompany: "SkillLync",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                }
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
        {
            partnercompany: "TopResume",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                }
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
        {
            partnercompany: "MentorKart",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                }
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
        {
            partnercompany: "9Links",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                }
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
        {
            partnercompany: "InterviewBuddy",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                }
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
        {
            partnercompany: "MIT",
            impression: "Impression",
            unique: "Unique users on partner page",
            pageview: "PageView",
            redirection: "Redirection",
            partnerData: [
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                },
                {
                    date: "September",
                    impcount: "12",
                    unicount: "30",
                    pageviewcount: "40",
                    redirectioncount: "10"
                }
            ],
            partnertotaldata:[
                {
                    imptotal:"24",
                    unitotal:"60",
                    pageviewtotal:"80",
                    redirectiontotal:"20"
                }
            ]
        },
    ]

    const date = [
        {
            month: "september"
        },
        {
            month: "october"
        }
    ]

    return (
        <div className={classes.PivotMainContainer}>
            <div className={classes.PivotHeadingConatiner}>
                <div className={classes.PivotdateContainer}>
                    <div className={classes.PivotSerialNoContainer}></div>
                    <div>
                        <div className={classes.PivotCountDate}>Date</div>
                        {date.map((item) => {
                            return <div className={classes.PivotCount}>{item.month}</div>
                        })}
                         <div className={classes.PivotCount}>Total</div>
                    </div>
                </div>
                {partnerheading.map((item) => {
                    return <div className={classes.PivotPartnerHeadingcontainer}>
                        <div className={classes.PivotPartnerHeading}>{item.partnercompany}</div>
                        {<div className={classes.PivotPartnerCountContainer}>
                            <div className={classes.PivotCount}>{item.impression}</div>
                            <div className={classes.PivotCount}>{item.unique}</div>
                            <div className={classes.PivotCount}>{item.pageview}</div>
                            <div className={classes.PivotCount}>{item.redirection}</div>
                        </div>}
                        {item.partnerData.map((item) => {
                            return <div className={classes.PivotDataContainer}>
                                <div className={classes.PivotCount}>{item.impcount}</div>
                                <div className={classes.PivotCount}>{item.unicount}</div>
                                <div className={classes.PivotCount}>{item.pageviewcount}</div>
                                <div className={classes.PivotCount}>{item.redirectioncount}</div>
                            </div>
                        })}
                        {item.partnertotaldata.map((item)=>{
                            return <div className={classes.PivotDataContainer}>
                            <div className={classes.PivotCount}>{item.imptotal}</div>
                            <div className={classes.PivotCount}>{item.unitotal}</div>
                            <div className={classes.PivotCount}>{item.pageviewtotal}</div>
                            <div className={classes.PivotCount}>{item.redirectiontotal}</div>
                        </div>
                        })}
                    </div>
                })}
            </div>
            <div>
            </div>
        </div>
    );
}
export default PivotTable;
