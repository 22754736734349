import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./mentor.module.css";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Components/Articles/ExcelDownload";
import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
const Mentorkart = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [today, setToday] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [firstdate, setFirstDate] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState(false);

  const [mobile, setMobile] = useState("ASC");
  const [isemail, setIsemail] = useState("ASC");
  const [isid, setIsid] = useState("ASC");
  const [isdate, setIsdate] = useState("ASC");
  useEffect(() => {
    MentorkartApi();
    MentorkartallApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const MentorkartApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/mentorkart",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setToday(result.data);
        setCount(result.count);
        setFirstDate(result.startdate);
        setLastDate(result.endDate);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const MentorkartallApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/mentorkart-alldata",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAlldata(result.data);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };
  // const pageInfo = {
  //   currentPageName: "Mentorkart Detail Page",
  //   previousPageName: "DataPoints",
  //   previousPage: "DataPoints",
  // };

  const sortingdate = (col) => {
    if (isdate === "ASC") {
      const sorteddate = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sorteddate);
      setIsdate("DSC");
    }
    if (isdate === "DSC") {
      const sorteddate = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sorteddate);
      setIsdate("ASC");
    }
  };
  const sortingemail = (col) => {
    if (isemail === "ASC") {
      const sortedemail = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedemail);
      setIsemail("DSC");
    }
    if (isemail === "DSC") {
      const sortedemail = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedemail);
      setIsemail("ASC");
    }
  };

  const sortingmobile = (col) => {
    if (mobile === "ASC") {
      const sortedmobile = [...today].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setToday(sortedmobile);
      setMobile("DSC");
    }
    if (mobile === "DSC") {
      const sortedmobile = [...today].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setToday(sortedmobile);
      setMobile("ASC");
    }
  };
  const sortingid = (col) => {
    if (isid === "ASC") {
      const sortedid = [...today].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setToday(sortedid);
      setIsid("DSC");
    }
    if (isid === "DSC") {
      const sortedid = [...today].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setToday(sortedid);
      setIsid("ASC");
    }
  };
  const pageInfo = {
    currentPageName: "Mentorkart Data Points",
    previousPageName: "DataPoints",
    previousPage: "DataPoints",
  };
  return (
    <div className={classes.dashboard_container}>
      <div className={classes.TimsbiBreadcrumb}>
        {" "}
        <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
      </div>
      <div className={classes.events_title}>MentorKart Data</div>
      <div className={classes.DateFilter_container}>
        <div className={classes.div}>
          {" "}
          Download all records:{" "}
          <ExcelDownload
            csvData={alldata}
            fileName="mentorkart-all-data_table"
          />
        </div>
        <CSVLink
          data={alldata}
          filename={"mentorkart_all_data.csv"}
          target="_blank"
        >
          <button className={classes.button_articleList}>CSV</button>
        </CSVLink>
        <div className={classes.dateDiv}>
          <div>
            <label className={classes.dateLable}>Start Date</label>
            <input
              type="date"
              value={startDate || firstdate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className={classes.dateLable}>End Date</label>
            <input
              type="date"
              value={endDate || lastdate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className={classes.form_error}>{errors.startDate}</div>
          <div className={classes.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => {
                formValidation()
                  ? MentorkartApi() || MentorkartallApi()
                  : console.log("Something Wrong");
              }}
            >
              {" "}
              Search
            </button>
          </div>
        </div>
      </div>
      <div className={classes.headerPageNavigation}>
        <div className={classes.card}>
          <div className={classes.count}>Count :</div>
          <div className={classes.count_main}>{count}</div>
        </div>
        <div className={classes.buttons1}>
          {currentPage !== 1 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              className={classes.buttonone}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          <span>{currentPage}</span>
          {today.length > 9 ? (
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
              className={classes.buttonone}
            >
              Next
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className={classes.spinner}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={classes.table_container}>
          <div className={classes.container}>
            {today.length > 0 ? (
              <table className={classes.orders_table}>
                <tr className={classes.table_row}>
                  <th className={classes.table_heading}>Sno</th>
                  <th className={classes.table_heading}>
                    Candidate Id
                    {isid === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingid("candidateId")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingid("candidateId")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Company Name</th>

                  <th className={classes.table_heading}>
                    Email
                    {isemail === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingemail("email")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>

                  <th className={classes.table_heading}>
                    Mobile
                    {mobile === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingmobile("mobile")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Event Name </th>
                  <th className={classes.table_heading}>
                    Created Date
                    {isdate === "ASC" ? (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Date")}
                      >
                        <AiOutlineArrowDown />
                      </span>
                    ) : (
                      <span
                        className={classes.arrow}
                        onClick={() => sortingdate("Date")}
                      >
                        <AiOutlineArrowUp />
                      </span>
                    )}
                  </th>
                  <th className={classes.table_heading}>Page Url</th>
                  <th className={classes.table_heading}>Redirection Url</th>
                </tr>

                <tbody>
                  {today.map((item, value) => {
                    return (
                      <tr className={classes.table_row}>
                        <td className={classes.table_td}>{item.sno}</td>
                        <td className={classes.table_td}>{item.candidateId}</td>
                        <td className={classes.table_td}>{item.company}</td>
                        <td className={classes.table_td}>{item.email}</td>
                        <td className={classes.table_td}>{item.mobile}</td>
                        <td className={classes.table_td}>{item.event}</td>
                        <td className={classes.table_td}>{item.createdDate}</td>
                        <td className={classes.table_td}>
                          <a href={item.page} target="_blank" rel="noreferrer">
                            Page Url
                          </a>
                        </td>
                        <td className={classes.table_td}>
                          <a
                            href={item.redirectionUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Redirection Url
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className={classes.No_data}>No data found!</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Mentorkart;
