import React from "react";
import classes from "./Modal.module.css";

const EventModel = (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  return (
    <>
      <div className={classes.overlay} onClick={props.onClose}></div>
      <div className={classes.ModalStyle}>
        {/* <BsPatchCheck
          style={{ width: "30px", height: "30px", marginBottom: "10px" }}
        />
        <h2 style={{ textAlign: "center" }}>
          Form has been submitted successfully
        </h2> */}
        <div className={classes.eventdetail_container}>
          {/* {props.datamap((item) => ( */}
          <div className={classes.eventdetail_main}>
            <div className={classes.event_detail}>
              <img
                src={props.data.eventBannerImage}
                alt=""
                className={classes.event_image}
              ></img>
              <div className={classes.event_text}>
                <table>
                  <tbody>
                    <tr>
                      <th className={classes.table_th}>Event Name :</th>
                      <td className={classes.table_td}>
                        {props.data.eventName}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Person Name :</th>
                      <td className={classes.table_td}>
                        {props.data.personName}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Person Contact :</th>
                      <td className={classes.table_td}>
                        {props.data.personContact}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Person Email :</th>
                      <td className={classes.table_td}>
                        {props.data.personEmail}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Event Type :</th>
                      <td className={classes.table_td}>
                        {props.data.eventType}{" "}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Event Categories :</th>
                      <td className={classes.table_td}>
                        {props.data.eventCategory}
                      </td>
                    </tr>                   
                    <tr>
                      <th className={classes.table_th}>Event Speaker Name :</th>
                      <td className={classes.table_td}>
                        {props.data.eventSpeakerName}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Event Company :</th>
                      <td className={classes.table_td}>
                        {props.data.eventCompanyName}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>
                        Event Company Appearing :
                      </th>
                      <td className={classes.table_td}>
                        {props.data.eventCompanyAppearing}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Redirection URL :</th>
                      <td className={classes.table_td}>
                        {props.data.redirectionUrl}
                      </td>
                    </tr>
                    <tr>
                      <th className={classes.table_th}>Location :</th>
                      <td className={classes.table_td}>
                        {props.data.eventLocation}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className={classes.detail_bottom}>
              <h4>Description:</h4>
              <p className={classes.description}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.data.eventShortDescription,
                  }}
                />
              </p>
            </div>
            <div className={classes.Time_date}>
              <div>
                <div className={classes.date}>Event Mode</div>
                <div className={classes.date_text}>{props.data.eventMode}</div>
                <div className={classes.date}>Event Price</div>
                <div className={classes.date_text}>{props.data.eventPrice}</div>
              </div>
              <div>
                <div className={classes.date}>Event Start Date</div>
                <div className={classes.date_text}>
                  {props.data.eventStartDate}
                </div>
                <div className={classes.date}>Event Start Time</div>
                <div className={classes.date_text}>
                  {props.data.eventStartTime}
                </div>
              </div>
              <div>
                <div className={classes.date}>Event End Date</div>
                <div className={classes.date_text}>
                  {props.data.eventEndDate}
                </div>
                <div className={classes.date}>Event End Time</div>
                <div className={classes.date_text}>
                  {props.data.eventEndTime}
                </div>
              </div>



              <div>
                <div className={classes.date}>Event Limitation</div>
                <div className={classes.date_text}>
                  {props.data.eventLimitation}
                </div>
                <div className={classes.date}>Event Number Seats</div>
                <div className={classes.date_text}>
                  {props.data.eventNumberSeats}
                </div>
              </div>
            </div>
            <div className={classes.BackButton}>
              <button onClick={props.onClose}>Close</button>
              {/* <BackButton /> */}
            </div>
          </div>
          {/* ))
            } */}
        </div>
      </div>
    </>
  );
};

export default EventModel;
