import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner";
import classes from "./PodcastVideos.module.css"
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "../../Components/Modal";

const PodcastVideos = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [PodcastVideos, setPodcastVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [errors, setErrors] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const history = useHistory();

    useEffect(() => {
        PodcastVideosApi();
    }, [currentPage]);

    const PodcastVideosApi = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            StartDate: startDate || date,
            EndDate:
                endDate ||
                new Date()
                    .toLocaleDateString("in", {
                        day: "2-digit",
                        year: "numeric",
                        month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-"),
            page: currentPage,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        // fetch(`${process.env.REACT_APP_API_URL}/v1/admin1_1/PodcastVideoList`, requestOptions)
        fetch(`${process.env.REACT_APP_API_URL}/v1/admin1_1/PodcastVideoList`, requestOptions)

            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200) {
                    setPodcastVideos(result.data);
                    setData(result.data);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => setLoading(false));
    };

    const DeleteApi = (UUID) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            PodcastVideoID: UUID,

        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        // fetch(`${process.env.REACT_APP_API_URL}/v1/admin1_1/PodcastVideoList`, requestOptions)
        fetch(`${process.env.REACT_APP_API_URL}/v1/admin1_1/PodcastVideoDelete`, requestOptions)

            .then((response) => response.json())
            .then((result) => {
                if (result.status_code === 200) {
                    PodcastVideosApi();
                    setModalShow(true);
                }
            })
            .catch((error) => console.log("error", error));
    }

    let today = new Date();
    today.setDate(today.getDate() - 60);
    let date = new Date(today)
        .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");

    const pageInfo = {
        currentPageName: "Podcast Video List",
    };
    return (
        <>
            {modalShow && (
                <Modal
                    heading={"Deleted successfully"}
                    onClick={() => {
                        setModalShow(false);
                    }}
                />
            )}

            <div className={classes.dashboard_container}>
                <div className={classes.TimsbiBreadcrumb}>
                    <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
                </div>
                <div className={classes.DateFilter_container}>
                    <div>
                        <button className={classes.Addbtn}>
                            <Link
                                to="/BiDashboard/newPodcastVideo"
                                className={classes.Edit_link}
                            >
                                Add Videos
                            </Link>
                        </button>
                    </div>
                </div>


                <div className={classes.table_container}>
                    <div className={classes.buttons1}>
                        <div>

                            <button
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                }}
                                className={classes.buttonone}
                            >
                                Prev
                            </button>



                            <button
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                }}
                                className={classes.buttonone}
                            >
                                Next
                            </button>

                        </div>
                    </div>
                    <div className={classes.container}>
                        {isLoading ? (
                            <div className={classes.spinner}>
                                <LoadingSpinner />{" "}
                            </div>
                        ) : data.length ? (
                            <>

                                <table className={classes.orders_table}>
                                    <tr className={classes.table_row}>
                                        <th className={classes.table_heading}>Sr. No.</th>
                                        <th className={classes.table_heading}>Image</th>
                                        <th className={classes.table_heading}>Id</th>
                                        <th className={classes.table_heading}>Date</th>
                                        <th className={classes.table_heading}>Time</th>
                                        <th className={classes.table_heading}>

                                            <b>Video URL</b>
                                        </th>

                                        <th className={classes.table_heading}>Action</th>
                                    </tr>

                                    <tbody>
                                        {PodcastVideos.map((item, i) => {
                                            return (
                                                <tr className={classes.table_row}>
                                                    <td className={classes.table_td}>{i + 1}</td>
                                                    <td className={classes.table_td}>
                                                        <img
                                                            src={item.ImageURL}
                                                            alt={item.ImageURL}
                                                            className={classes.table_td_image}
                                                        />
                                                    </td>
                                                    <td className={classes.table_td}>{item.id}</td>
                                                    <td className={classes.table_td}>{item.CreatedAt.slice(0, 10)}</td>
                                                    <td className={classes.table_td}>{item.CreatedAt.slice(11, 16)}</td>
                                                    <td className={classes.table_td}>{item.URL}</td>

                                                    <td className={classes.table_td}>
                                                        <button
                                                            className={classes.delbtn}
                                                            onClick={() => DeleteApi(item.UUID)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>


                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </>
                        ) : (
                            <h2 className={classes.serachErrorText}>"No Data found !"</h2>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

export default PodcastVideos;
