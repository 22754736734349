import classes from '../Components/TimesBreadCrumbs.module.css';
import React from 'react';
import { Link } from 'react-router-dom'

const TimesBreadCrumbs = React.memo(props => {

  return (
    <div className={classes.mainBreadcrumbs}>
      <Link color="inherit" href="/" to={"/BiDashboard/Dashboard"}>
      Dashboard
      </Link>
      <p className={classes.slash}> / </p>
      <Link color="inherit" href="/" to={props.pageInfo.previousPage}>
        {props.pageInfo.previousPageName}
      </Link>
      <p className={classes.slash}> / </p>
      <div>{props.pageInfo.currentPageName} </div>
    </div>
  )

})

export default TimesBreadCrumbs;