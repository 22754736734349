import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./JobWebstoryModal.module.css";
import { ImCross } from "react-icons/im";
import Loader from "react-loader-spinner";
import ModalAlert from "../../Components/Modal";
import EventInputs from "../../Components/Events/EventInputs";

const JobWebstoryModal = (props) => {
  const [title, setTitle] = useState(props.data.Title ? props.data.Title : "");
  const [metaTitle, setMetaTitle] = useState(
    props.data.MetaTitle ? props.data.MetaTitle : ""
  );
  const [metaDescription, setMetaDescription] = useState(
    props.data.MetaDescription ? props.data.MetaDescription : ""
  );
  const [description, setDescription] = useState(
    props.data.Description ? props.data.Description : ""
  );
  const [seoUrl, setSeoUrl] = useState(
    props.data.SeoURL ? props.data.SeoURL : `web-stories/`
  );
  const [image, setImage] = useState("");
  const [check, setCheck] = useState(
    props.data.IsLive ? (props.data.IsLive == "1" ? 1 : 0) : 0
  );
  const [newImage] = useState(props.data.ImageUrl ? props.data.ImageUrl : "");
  const [orderNumber] = useState(
    props.data.OrderNumber ? props.data.OrderNumber : props.orderNumber
  );

  const removeSpacesWithHyphens = (str) => {
    return str
      ?.replace(/[^\w\s-]/g, "-")
      .replace(/\s+/g, "-")
      .replace(/--+/g, "-")
      .replace(/^-/, "")
      .replace(/^-+|-+$|(-)+/g, "$1")
      .toLowerCase()
      .trim();
  };

  const handleClose = () => props.setShow(false);

  // const userEmail = sessionStorage.getItem("googleBiEmail");

  return (
    <>
      <>
        <Modal
          show={props.show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          // size="lg"
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            {props.modalShow && (
              <ModalAlert
                heading={"Data inserted successfully"}
                onClick={() => {
                  props.getWebstorySummaryData();
                  props.setModalShow(false);
                  props.setShow(false);
                }}
              />
            )}
            {props.erorrshow && (
              <ModalAlert
                heading={"Required Fields Are Missing"}
                onClick={() => props.setErrorShow(false)}
              />
            )}
            {props.internalErrorShow && (
              <ModalAlert
                heading={"Internal server error"}
                onClick={() => props.setInternalErrorShow(false)}
              />
            )}
            <div className={classes.d_flex}>
              <div className={classes.formDiv}>
                <div className={classes.header}>
                  <h4>
                    {props.data.WebStoriesSummaryID
                      ? "Edit Webstory"
                      : "Add Webstory"}
                  </h4>
                  <ImCross onClick={handleClose} />
                </div>

                <div className={classes.container}>
                  <div className={classes.form}>
                    <div className={classes.form_header_container}>
                      {/* <div className={classes.form_header}>Add Webstory</div> */}
                      <div className={classes.checkbox}>
                        <span className={classes.live}>Live</span>
                        <EventInputs
                          input={"CheckBox"}
                          onChange={() => setCheck(!check === true ? 1 : 0)}
                          value={check}
                        />
                      </div>
                    </div>
                    <div className={classes.form_input}>
                      <div className={classes.input}>
                        <EventInputs
                          input={"SingleLineInput"}
                          label={"Title"}
                          placeholder={"Title"}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            setSeoUrl(
                              `web-stories/${removeSpacesWithHyphens(
                                e.target.value
                              )}`
                            );
                          }}
                          value={title}
                        />
                        <EventInputs
                          input={"SingleLineInput"}
                          label={"Meta Title"}
                          placeholder={"Meta Title"}
                          maxLength={70}
                          onChange={(e) => setMetaTitle(e.target.value)}
                          value={metaTitle}
                        />
                        <div
                          style={{
                            display: "flex",
                            fontSize: "12px",
                            justifyContent: "space-between",
                            padding: "0px 12px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            Character count{" "}
                            {metaTitle?.length ? metaTitle?.length : "0"}
                          </div>
                          <div>(Maximum 70 Characters)</div>
                        </div>
                        <EventInputs
                          input={"SingleLineInput"}
                          label={"Description"}
                          placeholder={"Description"}
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                        />
                        <EventInputs
                          input={"ImageInput"}
                          label={"Card Image"}
                          placeholder={"Card Image"}
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                        {image && (
                          // eslint-disable-next-line jsx-a11y/alt-text
                          <img
                            src={URL.createObjectURL(image)}
                            className={classes.imgicon}
                          />
                        )}{" "}
                        {newImage && !image && (
                          <img
                            src={newImage}
                            alt="icon"
                            className={classes.imgicon}
                          />
                        )}
                      </div>

                      <div className={classes.input}>
                        <EventInputs
                          input={"SingleLineInput"}
                          label={"Order Number"}
                          placeholder={"Order Number"}
                          // onChange={(e) => setOrderNumber(e.target.value)}
                          value={orderNumber}
                        />
                        <EventInputs
                          input={"SingleLineInput"}
                          maxLength={170}
                          label={"Meta Description"}
                          placeholder={"Meta Description"}
                          onChange={(e) => setMetaDescription(e.target.value)}
                          value={metaDescription}
                        />
                        <div
                          style={{
                            display: "flex",
                            fontSize: "12px",
                            justifyContent: "space-between",
                            padding: "0px 12px",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            Character count{" "}
                            {metaDescription?.length
                              ? metaDescription?.length
                              : "0"}
                          </div>
                          <div>(Maximum 170 Characters)</div>
                        </div>
                        <EventInputs
                          input={"SingleLineInput"}
                          label={"SEO URL"}
                          placeholder={"SEO URL"}
                          onChange={(e) => setSeoUrl(e.target.value)}
                          value={seoUrl}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className={classes.Upload_button}
                    onClick={() =>
                      props.data.WebStoriesSummaryID
                        ? props.UpdateWebstoryApi(
                            props.data.WebStoriesSummaryID,
                            orderNumber,
                            title,
                            description,
                            check,
                            seoUrl,
                            metaDescription,
                            metaTitle,
                            image,
                            newImage
                          )
                        : props.AddWebstory(
                            orderNumber,
                            title,
                            description,
                            image,
                            check,
                            seoUrl,
                            metaDescription,
                            metaTitle
                          )
                    }
                  >
                    {props.loading ? (
                      <Loader
                        type="TailSpin"
                        color="white"
                        height={20}
                        width={50}
                        className="text-center"
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button> */}
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default JobWebstoryModal;
