import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner";
import classes from "../GuestArticle/guestlist.module.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ExcelDownload from "../Articles/ExcelDownload";
import Loader from "react-loader-spinner";
import TimesBreadCrumbs from "../TimesBreadCrumbs";

const GuestList = () => {
  const [loading, setLoading] = useState(false);
  const [guestlist, setGuestlist] = useState([]);
  const [guestalllist, setGuestalllist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState(false);
  const [count, setCount] = useState("");
  const [issno, setIssno] = useState("ASC");
  useEffect(() => {
    GuestListApi();
    GuestListAllApi();
  }, [currentPage]);

  const GuestListApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      Page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/ForHerArticlesList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setGuestlist(result.data);
          setCount(result.datacount);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const GuestListAllApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/ForHerArticlesWithoutPage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setGuestalllist(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const DeleteApi = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
      IsDeleted: "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/DeleteForHerArticles",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          GuestListApi();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const formValidation = () => {
    let isValid = true;
    let error = {};

    if (!startDate || !endDate) {
      isValid = false;
      error.startDate = "Please Select Both Dates";
    }

    setErrors(error);
    return isValid;
  };

  let today = new Date();
  today.setDate(today.getDate() - 180);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "Guest Aricle List",
  };
  const sortingsrno = (col) => {
    if (issno === "ASC") {
      const sortsno = [...guestlist].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setGuestlist(sortsno);
      setIssno("DSC");
    }
    if (issno === "DSC") {
      const sortsno = [...guestlist].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setGuestlist(sortsno);
      setIssno("ASC");
    }
  };
  return (
    <>
      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div className={classes.button_container}>
            <CSVLink
              data={guestalllist}
              filename={"guestalllist.csv"}
              target="_blank"
            >
              <button className={classes.button_articleList}>CSV</button>
            </CSVLink>
            <ExcelDownload csvData={guestalllist} fileName="guestalllist" />
          </div>
          <div className={classes.dateDiv}>
            <div>
              <label className={classes.dateLable}>Start Date</label>
              <input
                type="date"
                value={startDate || date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label className={classes.dateLable}>End Date</label>
              <input
                type="date"
                value={
                  endDate ||
                  new Date()
                    .toLocaleDateString("in", {
                      day: "2-digit",
                      year: "numeric",
                      month: "2-digit",
                    })
                    .split("/")
                    .reverse()
                    .join("-")
                }
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className={classes.form_error}>{errors.startDate}</div>
            <div className={classes.buttons}>
              <button
                type="button"
                className="button"
                onClick={() =>
                  formValidation()
                    ? GuestListApi()
                    : console.log("Something Wrong")
                }
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classes.spinner}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={classes.table_container}>
            <div className={classes.buttons1}>
              <div>
                {currentPage !== 1 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    className={classes.buttonone}
                  >
                    Prev
                  </button>
                ) : (
                  <></>
                )}
                <span>{count >= 20 ? currentPage : <></>}</span>
                {guestlist.length >= 20 ? (
                  <button
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    className={classes.buttonone}
                  >
                    Next
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <button className={classes.Addbtn}>
                  <Link
                    to="/BiDashboard/AddGuestArticle"
                    className={classes.Edit_link}
                  >
                    Add Guest Article
                  </Link>
                </button>
              </div>
            </div>
            <div className={classes.container}>
              {guestlist.length > 0 ? (
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>
                      Id{" "}
                      {issno === "ASC" ? (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingsrno(" Id")}
                        >
                          <AiOutlineArrowDown />
                        </span>
                      ) : (
                        <span
                          className={classes.arrow}
                          onClick={() => sortingsrno(" Id")}
                        >
                          <AiOutlineArrowUp />
                        </span>
                      )}
                    </th>
                    <th className={classes.table_heading}>Title</th>
                    <th className={classes.table_heading}>Article Id</th>
                    <th className={classes.table_heading}>Article type</th>
                    <th className={classes.table_heading}>Article Created</th>

                    <th className={classes.table_heading}>Article Updated</th>

                    <th className={classes.table_heading}>Created At</th>
                    <th className={classes.table_heading}>Update</th>
                    <th className={classes.table_heading}>Delete</th>
                  </tr>

                  <tbody>
                    {guestlist.map((item, value) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{item.id}</td>

                          <td className={classes.table_td}>{item.title}</td>
                          <td className={classes.table_td}>{item.articleId}</td>
                          <td className={classes.table_td}>
                            {item.articletype}
                          </td>

                          <td className={classes.table_td}>
                            {item.createdDate}
                          </td>
                          <td className={classes.table_td}>
                            {item.updatedDate}
                          </td>
                          <td className={classes.table_td}>{item.createdAt}</td>
                          <td className={classes.table_td}>
                            <Link
                              to={"/BiDashboard/EditGuestArticle/" + item.id}
                              className={classes.Edit_link_two}
                            >
                              Edit
                            </Link>
                          </td>
                          <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.id)}
                            >
                              {loading ? (
                                <Loader
                                  type="TailSpin"
                                  color="white"
                                  height={20}
                                  width={50}
                                  className="text-center"
                                />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={classes.No_data}>No data found!</div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GuestList;
