import React, { useState } from "react";

import classes from "./updateimage.module.css";
import EventInputs from "../../Components/Events/EventInputs";
import Modal from "../../Components/Modal";
import { useHistory } from "react-router-dom";
import TimesBreadCrumbs from "../../Components/TimesBreadCrumbs";
import { useParams } from "react-router-dom";
const UpdateImage = () => {
  const [sno, setSno] = useState("");
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let history = useHistory();
  const { id } = useParams();
  const UpdateApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      bannerId: id,
      serialNumber: sno,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://npopvdreck.execute-api.ap-southeast-1.amazonaws.com/production/adminapi/EditBannerImage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const pageInfo = {
    currentPageName: "Update Banner Position",
    previousPageName: "Banner Image List",
    previousPage: "/BiDashboard/BannerImageList",
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data inserted successfully"}
          onClick={() => history.push("/BiDashboard/BannerImageList")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Are Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.TimsbiBreadcrumb}>
            {" "}
            <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
          </div>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Update Banner Position</div>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"Number"}
                label={"Position"}
                placeholder={"Position"}
                onChange={(e) => setSno(e.target.value)}
                value={sno}
              />
            </div>
          </div>
        </div>
        <button className={classes.Upload_button} onClick={() => UpdateApi()}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default UpdateImage;
